export const CLIENT_NAMES_LIST = [
  {
    value: '84984438',
    name: 'Experience GLA',
    abbreviation: 'GLA',
    domainName: 'portal.terraed',
    domainUrl: 'https://portal.terraed.com',
  },
  {
    value: '47821104',
    name: 'Summer Spring Board',
    abbreviation: 'SSB',
    domainName: 'portal.terraed',
    domainUrl: 'https://portal.terraed.com',
  },
  // {
  //   value: 'terraEdu',
  //   name: 'Terra Education',
  //   abbreviation: 'Terra Edu',
  //   domainName: 'localhost',
  //   domainUrl: 'http://localhost:3000',
  // },
  // {
  //   value: 'teenTravelNetwork',
  //   name: 'Teen Travel Network',
  //   abbreviation: 'TTN',
  //   domainName: 'teentravelnetwork.local',
  //   domainUrl: 'http://teentravelnetwork.local:3000',
  // },
]

export const payment_types = [
  {
    raw: 'american_express_credit',
    value: 'Amex (Credit)',
    type: 'Credit Card',
    icon: 'fa-brands fa-cc-visa',
  },
  {
    raw: 'mastercard_credit',
    value: 'Mastercard (Credit)',
    type: 'Credit Card',
    icon: 'fa-brands fa-cc-visa',
  },
  {
    raw: 'visa_debit',
    value: 'Visa (Debit)',
    type: 'Debit Card',
    icon: 'fa-brands fa-cc-visa',
  },
  {
    raw: 'savings',
    value: 'Bank Account with Routing Number',
    type: 'Savings Account',
  },
  {
    raw: 'visa_credit',
    value: 'Visa (Credit)',
    type: 'Credit Card',
    icon: 'fa-brands fa-cc-visa',
  },
  {
    raw: 'checking',
    value: 'Bank Account with Routing Number',
    type: 'Checking Account',
  },
]

export const opp_status = [
  'Incomplete - Abandoned',
  'Incomplete - Deposit Due',
  'Incomplete - Deposit Paid',
  'Applied - Pending',
  'Enrolled - Balance Due',
  'Enrolled - Paid',
]

export const predeparture_status = ['No', 'Yes', 'In Progress', 'Received - Needs Review']

export const usStates = [
  {name: 'ALABAMA', abbreviation: 'AL'},
  {name: 'ALASKA', abbreviation: 'AK'},
  {name: 'AMERICAN SAMOA', abbreviation: 'AS'},
  {name: 'ARIZONA', abbreviation: 'AZ'},
  {name: 'ARKANSAS', abbreviation: 'AR'},
  {name: 'CALIFORNIA', abbreviation: 'CA'},
  {name: 'COLORADO', abbreviation: 'CO'},
  {name: 'CONNECTICUT', abbreviation: 'CT'},
  {name: 'DELAWARE', abbreviation: 'DE'},
  {name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
  {name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
  {name: 'FLORIDA', abbreviation: 'FL'},
  {name: 'GEORGIA', abbreviation: 'GA'},
  {name: 'GUAM', abbreviation: 'GU'},
  {name: 'HAWAII', abbreviation: 'HI'},
  {name: 'IDAHO', abbreviation: 'ID'},
  {name: 'ILLINOIS', abbreviation: 'IL'},
  {name: 'INDIANA', abbreviation: 'IN'},
  {name: 'IOWA', abbreviation: 'IA'},
  {name: 'KANSAS', abbreviation: 'KS'},
  {name: 'KENTUCKY', abbreviation: 'KY'},
  {name: 'LOUISIANA', abbreviation: 'LA'},
  {name: 'MAINE', abbreviation: 'ME'},
  {name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
  {name: 'MARYLAND', abbreviation: 'MD'},
  {name: 'MASSACHUSETTS', abbreviation: 'MA'},
  {name: 'MICHIGAN', abbreviation: 'MI'},
  {name: 'MINNESOTA', abbreviation: 'MN'},
  {name: 'MISSISSIPPI', abbreviation: 'MS'},
  {name: 'MISSOURI', abbreviation: 'MO'},
  {name: 'MONTANA', abbreviation: 'MT'},
  {name: 'NEBRASKA', abbreviation: 'NE'},
  {name: 'NEVADA', abbreviation: 'NV'},
  {name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
  {name: 'NEW JERSEY', abbreviation: 'NJ'},
  {name: 'NEW MEXICO', abbreviation: 'NM'},
  {name: 'NEW YORK', abbreviation: 'NY'},
  {name: 'NORTH CAROLINA', abbreviation: 'NC'},
  {name: 'NORTH DAKOTA', abbreviation: 'ND'},
  {name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
  {name: 'OHIO', abbreviation: 'OH'},
  {name: 'OKLAHOMA', abbreviation: 'OK'},
  {name: 'OREGON', abbreviation: 'OR'},
  {name: 'PALAU', abbreviation: 'PW'},
  {name: 'PENNSYLVANIA', abbreviation: 'PA'},
  {name: 'PUERTO RICO', abbreviation: 'PR'},
  {name: 'RHODE ISLAND', abbreviation: 'RI'},
  {name: 'SOUTH CAROLINA', abbreviation: 'SC'},
  {name: 'SOUTH DAKOTA', abbreviation: 'SD'},
  {name: 'TENNESSEE', abbreviation: 'TN'},
  {name: 'TEXAS', abbreviation: 'TX'},
  {name: 'UTAH', abbreviation: 'UT'},
  {name: 'VERMONT', abbreviation: 'VT'},
  {name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
  {name: 'VIRGINIA', abbreviation: 'VA'},
  {name: 'WASHINGTON', abbreviation: 'WA'},
  {name: 'WEST VIRGINIA', abbreviation: 'WV'},
  {name: 'WISCONSIN', abbreviation: 'WI'},
  {name: 'WYOMING', abbreviation: 'WY'},
]

export const COUNTRIES_ISO_3166_ALPHA_3 = [
  {
    name: 'United States of America',
    alpha_3: 'USA',
    country_code: '840',
    divisions: [
      {
        code: 'AL',
        name: 'Alabama',
      },
      {
        code: 'AK',
        name: 'Alaska',
      },
      {
        code: 'AZ',
        name: 'Arizona',
      },
      {
        code: 'AR',
        name: 'Arkansas',
      },
      {
        code: 'CA',
        name: 'California',
      },
      {
        code: 'CO',
        name: 'Colorado',
      },
      {
        code: 'CT',
        name: 'Connecticut',
      },
      {
        code: 'DE',
        name: 'Delaware',
      },
      {
        code: 'FL',
        name: 'Florida',
      },
      {
        code: 'GA',
        name: 'Georgia',
      },
      {
        code: 'HI',
        name: 'Hawaii',
      },
      {
        code: 'ID',
        name: 'Idaho',
      },
      {
        code: 'IL',
        name: 'Illinois',
      },
      {
        code: 'IN',
        name: 'Indiana',
      },
      {
        code: 'IA',
        name: 'Iowa',
      },
      {
        code: 'KS',
        name: 'Kansas',
      },
      {
        code: 'KY',
        name: 'Kentucky',
      },
      {
        code: 'LA',
        name: 'Louisiana',
      },
      {
        code: 'ME',
        name: 'Maine',
      },
      {
        code: 'MD',
        name: 'Maryland',
      },
      {
        code: 'MA',
        name: 'Massachusetts',
      },
      {
        code: 'MI',
        name: 'Michigan',
      },
      {
        code: 'MN',
        name: 'Minnesota',
      },
      {
        code: 'MS',
        name: 'Mississippi',
      },
      {
        code: 'MO',
        name: 'Missouri',
      },
      {
        code: 'MT',
        name: 'Montana',
      },
      {
        code: 'NE',
        name: 'Nebraska',
      },
      {
        code: 'NV',
        name: 'Nevada',
      },
      {
        code: 'NH',
        name: 'New Hampshire',
      },
      {
        code: 'NJ',
        name: 'New Jersey',
      },
      {
        code: 'NM',
        name: 'New Mexico',
      },
      {
        code: 'NY',
        name: 'New York',
      },
      {
        code: 'NC',
        name: 'North Carolina',
      },
      {
        code: 'ND',
        name: 'North Dakota',
      },
      {
        code: 'OH',
        name: 'Ohio',
      },
      {
        code: 'OK',
        name: 'Oklahoma',
      },
      {
        code: 'OR',
        name: 'Oregon',
      },
      {
        code: 'PA',
        name: 'Pennsylvania',
      },
      {
        code: 'RI',
        name: 'Rhode Island',
      },
      {
        code: 'SC',
        name: 'South Carolina',
      },
      {
        code: 'SD',
        name: 'South Dakota',
      },
      {
        code: 'TN',
        name: 'Tennessee',
      },
      {
        code: 'TX',
        name: 'Texas',
      },
      {
        code: 'UT',
        name: 'Utah',
      },
      {
        code: 'VT',
        name: 'Vermont',
      },
      {
        code: 'VA',
        name: 'Virginia',
      },
      {
        code: 'WA',
        name: 'Washington',
      },
      {
        code: 'WV',
        name: 'West Virginia',
      },
      {
        code: 'WI',
        name: 'Wisconsin',
      },
      {
        code: 'WY',
        name: 'Wyoming',
      },
      {
        code: 'DC',
        name: 'District of Columbia',
      },
      {
        code: 'AS',
        name: 'American Samoa',
      },
      {
        code: 'GU',
        name: 'Guam',
      },
      {
        code: 'MP',
        name: 'Northern Mariana Islands',
      },
      {
        code: 'PR',
        name: 'Puerto Rico',
      },
      {
        code: 'UM',
        name: 'United States Minor Outlying Islands',
      },
      {
        code: 'VI',
        name: 'Virgin Islands, U.S.',
      },
    ],
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha_3: 'GBR',
    country_code: '826',
    divisions: [
      {
        code: 'GB-CHA',
        name: 'Channel Islands',
      },
      {
        code: 'GB-GSY',
        name: 'Guernsey [Guernesey]',
      },
      {
        code: 'GB-JSY',
        name: 'Jersey',
      },
      {
        code: 'GB-ENG',
        name: 'England',
      },
      {
        code: 'GB-BDG',
        name: 'Barking and Dagenham',
      },
      {
        code: 'GB-BNE',
        name: 'Barnet',
      },
      {
        code: 'GB-BNS',
        name: 'Barnsley',
      },
      {
        code: 'GB-BAS',
        name: 'Bath and North East Somerset',
      },
      {
        code: 'GB-BDF',
        name: 'Bedfordshire',
      },
      {
        code: 'GB-BEX',
        name: 'Bexley',
      },
      {
        code: 'GB-BIR',
        name: 'Birmingham (City of)',
      },
      {
        code: 'GB-BBD',
        name: 'Blackburn with Darwen',
      },
      {
        code: 'GB-BPL',
        name: 'Blackpool',
      },
      {
        code: 'GB-BOL',
        name: 'Bolton',
      },
      {
        code: 'GB-BMH',
        name: 'Bournemouth',
      },
      {
        code: 'GB-BRC',
        name: 'Bracknell Forest',
      },
      {
        code: 'GB-BRD',
        name: 'Bradford (City of)',
      },
      {
        code: 'GB-BEN',
        name: 'Brent',
      },
      {
        code: 'GB-BNH',
        name: 'Brighton and Hove',
      },
      {
        code: 'GB-BST',
        name: 'Bristol, City of',
      },
      {
        code: 'GB-BRY',
        name: 'Bromley',
      },
      {
        code: 'GB-BKM',
        name: 'Buckinghamshire',
      },
      {
        code: 'GB-BUR',
        name: 'Bury',
      },
      {
        code: 'GB-CLD',
        name: 'Calderdale',
      },
      {
        code: 'GB-CAM',
        name: 'Cambridgeshire',
      },
      {
        code: 'GB-CMD',
        name: 'Camden',
      },
      {
        code: 'GB-CHS',
        name: 'Cheshire',
      },
      {
        code: 'GB-CON',
        name: 'Cornwall',
      },
      {
        code: 'GB-COV',
        name: 'Coventry (City of)',
      },
      {
        code: 'GB-CRY',
        name: 'Croydon',
      },
      {
        code: 'GB-CMA',
        name: 'Cumbria',
      },
      {
        code: 'GB-DAL',
        name: 'Darlington',
      },
      {
        code: 'GB-DER',
        name: 'Derby (City of)',
      },
      {
        code: 'GB-DBY',
        name: 'Derbyshire',
      },
      {
        code: 'GB-DEV',
        name: 'Devon',
      },
      {
        code: 'GB-DNC',
        name: 'Doncaster',
      },
      {
        code: 'GB-DOR',
        name: 'Dorset',
      },
      {
        code: 'GB-DUD',
        name: 'Dudley',
      },
      {
        code: 'GB-DUR',
        name: 'Durharn',
      },
      {
        code: 'GB-EAL',
        name: 'Ealing',
      },
      {
        code: 'GB-ERY',
        name: 'East Riding of Yorkshire',
      },
      {
        code: 'GB-ESX',
        name: 'East Sussex',
      },
      {
        code: 'GB-ENF',
        name: 'Enfield',
      },
      {
        code: 'GB-ESS',
        name: 'Essex',
      },
      {
        code: 'GB-GAT',
        name: 'Gateshead',
      },
      {
        code: 'GB-GLS',
        name: 'Gloucestershire',
      },
      {
        code: 'GB-GRE',
        name: 'Greenwich',
      },
      {
        code: 'GB-HCK',
        name: 'Hackney',
      },
      {
        code: 'GB-HAL',
        name: 'Haiton',
      },
      {
        code: 'GB-HMF',
        name: 'Hammersmith and Fulham',
      },
      {
        code: 'GB-HAM',
        name: 'Hampshire',
      },
      {
        code: 'GB-HRY',
        name: 'Haringey',
      },
      {
        code: 'GB-HRW',
        name: 'Harrow',
      },
      {
        code: 'GB-HPL',
        name: 'Hartlepool',
      },
      {
        code: 'GB-HAV',
        name: 'Havering',
      },
      {
        code: 'GB-HEF',
        name: 'Herefordshire, County of',
      },
      {
        code: 'GB-HRT',
        name: 'Hertfordshire',
      },
      {
        code: 'GB-HIL',
        name: 'Hillingdon',
      },
      {
        code: 'GB-HNS',
        name: 'Hounslow',
      },
      {
        code: 'GB-IOW',
        name: 'Isle of Wight',
      },
      {
        code: 'GB-IOS',
        name: 'Isles of Scilly',
      },
      {
        code: 'GB-ISL',
        name: 'Islington',
      },
      {
        code: 'GB-KEC',
        name: 'Kensington and Chelsea',
      },
      {
        code: 'GB-KEN',
        name: 'Kent',
      },
      {
        code: 'GB-KHL',
        name: 'Kingston upon Hull, City of',
      },
      {
        code: 'GB-KTT',
        name: 'Kingston upon Thames',
      },
      {
        code: 'GB-KIR',
        name: 'Kirklees',
      },
      {
        code: 'GB-KWL',
        name: 'Knowsley',
      },
      {
        code: 'GB-LBH',
        name: 'Lambeth',
      },
      {
        code: 'GB-LAN',
        name: 'Lancashire',
      },
      {
        code: 'GB-LDS',
        name: 'Leeds (City of)',
      },
      {
        code: 'GB-LCE',
        name: 'Leitester (City of)',
      },
      {
        code: 'GB-LEC',
        name: 'Leicestershire',
      },
      {
        code: 'GB-LEW',
        name: 'Lewisham',
      },
      {
        code: 'GB-LIN',
        name: 'Lincolnshire',
      },
      {
        code: 'GB-LIV',
        name: 'Liverpool (City of)',
      },
      {
        code: 'GB-LND',
        name: 'London, City of',
      },
      {
        code: 'GB-LUT',
        name: 'Luton',
      },
      {
        code: 'GB-MAN',
        name: 'Manchester (City of)',
      },
      {
        code: 'GB-MDW',
        name: 'Medway',
      },
      {
        code: 'GB-MRT',
        name: 'Merton',
      },
      {
        code: 'GB-MDB',
        name: 'Middlesbrough',
      },
      {
        code: 'GB-MIK',
        name: 'Milton Keynes',
      },
      {
        code: 'GB-NET',
        name: 'Newcastle upon Tyne (City of)',
      },
      {
        code: 'GB-NWM',
        name: 'Newham',
      },
      {
        code: 'GB-NFK',
        name: 'Norfolk',
      },
      {
        code: 'GB-NEL',
        name: 'North East Lincolnshire',
      },
      {
        code: 'GB-NLN',
        name: 'North Lincolnshire',
      },
      {
        code: 'GB-NSM',
        name: 'North Somerset',
      },
      {
        code: 'GB-NTY',
        name: 'North Tyneside',
      },
      {
        code: 'GB-NYK',
        name: 'North Yorkshire',
      },
      {
        code: 'GB-NTH',
        name: 'Northamptonshire',
      },
      {
        code: 'GB-NBL',
        name: 'Northumberland',
      },
      {
        code: 'GB-NGM',
        name: 'Nottingham (City of)',
      },
      {
        code: 'GB-NTT',
        name: 'Nottinghamshire',
      },
      {
        code: 'GB-OLD',
        name: 'Oldham',
      },
      {
        code: 'GB-OXF',
        name: 'Oxfordshire',
      },
      {
        code: 'GB-PTE',
        name: 'Peterborough',
      },
      {
        code: 'GB-PLY',
        name: 'Plymouth (City of)',
      },
      {
        code: 'GB-POL',
        name: 'Poole',
      },
      {
        code: 'GB-POR',
        name: 'Portsmouth (City of)',
      },
      {
        code: 'GB-RDG',
        name: 'Reading',
      },
      {
        code: 'GB-RDB',
        name: 'Redbridge',
      },
      {
        code: 'GB-RCC',
        name: 'Redcar and Cleveland',
      },
      {
        code: 'GB-RIC',
        name: 'Richmond upon Thames',
      },
      {
        code: 'GB-RCH',
        name: 'Rochdale',
      },
      {
        code: 'GB-ROT',
        name: 'Rotherharn',
      },
      {
        code: 'GB-RUT',
        name: 'Rutland',
      },
      {
        code: 'GB-SHN',
        name: 'St. Helens',
      },
      {
        code: 'GB-SLF',
        name: 'Salford (City of)',
      },
      {
        code: 'GB-SAW',
        name: 'Sandweil',
      },
      {
        code: 'GB-SFT',
        name: 'Sefton',
      },
      {
        code: 'GB-SHF',
        name: 'Sheffield (City of)',
      },
      {
        code: 'GB-SHR',
        name: 'Shropshire',
      },
      {
        code: 'GB-SLG',
        name: 'Slough',
      },
      {
        code: 'GB-SOL',
        name: 'Solihull',
      },
      {
        code: 'GB-SOM',
        name: 'Somerset',
      },
      {
        code: 'GB-SGC',
        name: 'South Gloucestershire',
      },
      {
        code: 'GB-STY',
        name: 'South Tyneside',
      },
      {
        code: 'GB-STH',
        name: 'Southampton (City of)',
      },
      {
        code: 'GB-SOS',
        name: 'Southend-on-Sea',
      },
      {
        code: 'GB-SWK',
        name: 'Southwark',
      },
      {
        code: 'GB-STS',
        name: 'Staffordshire',
      },
      {
        code: 'GB-SKP',
        name: 'Stockport',
      },
      {
        code: 'GB-STT',
        name: 'Stockton-On-Tees',
      },
      {
        code: 'GB-STE',
        name: 'Stoke-on-Trent (City of)',
      },
      {
        code: 'GB-SFK',
        name: 'Suffolk',
      },
      {
        code: 'GB-SND',
        name: 'Sunderland (City of)',
      },
      {
        code: 'GB-SRY',
        name: 'Surrey',
      },
      {
        code: 'GB-STN',
        name: 'Sutton',
      },
      {
        code: 'GB-SWD',
        name: 'Swindon',
      },
      {
        code: 'GB-TAM',
        name: 'Tameside',
      },
      {
        code: 'GB-TFW',
        name: 'Telford and Wrekin',
      },
      {
        code: 'GB-THR',
        name: 'Thurrock',
      },
      {
        code: 'GB-TOB',
        name: 'Torbay',
      },
      {
        code: 'GB-TWH',
        name: 'Tower Hamlets',
      },
      {
        code: 'GB-TRF',
        name: 'Trafford',
      },
      {
        code: 'GB-WKF',
        name: 'Wakefield (City of)',
      },
      {
        code: 'GB-WLL',
        name: 'Walsall',
      },
      {
        code: 'GB-WFT',
        name: 'Waltham Forest',
      },
      {
        code: 'GB-WND',
        name: 'Wandsworth',
      },
      {
        code: 'GB-WRT',
        name: 'Warrington',
      },
      {
        code: 'GB-WAR',
        name: 'Warwickshire',
      },
      {
        code: 'GB-WBK',
        name: 'West Berkshire',
      },
      {
        code: 'GB-WSX',
        name: 'West Sussex',
      },
      {
        code: 'GB-WSM',
        name: 'Westminster (City of)',
      },
      {
        code: 'GB-WGN',
        name: 'Wigan',
      },
      {
        code: 'GB-WIL',
        name: 'Wiltshire',
      },
      {
        code: 'GB-WNM',
        name: 'Windsor and Maidenhead',
      },
      {
        code: 'GB-WRL',
        name: 'Wirral',
      },
      {
        code: 'GB-WOK',
        name: 'Wokingham',
      },
      {
        code: 'GB-WLV',
        name: 'Wolverhampton',
      },
      {
        code: 'GB-WOR',
        name: 'Worcestershire',
      },
      {
        code: 'GB-YOR',
        name: 'York (City of)',
      },
      {
        code: 'GB-IOM',
        name: 'Isle of Man',
      },
      {
        code: 'GB-NIR',
        name: 'Northern Ireland',
      },
      {
        code: 'GB-ANT',
        name: 'Antrim',
      },
      {
        code: 'GB-ARD',
        name: 'Ards',
      },
      {
        code: 'GB-ARM',
        name: 'Armagh',
      },
      {
        code: 'GB-BLA',
        name: 'Ballymena',
      },
      {
        code: 'GB-BLY',
        name: 'Ballymoney',
      },
      {
        code: 'GB-BNB',
        name: 'Banbridge',
      },
      {
        code: 'GB-BFS',
        name: 'Belfast (City of)',
      },
      {
        code: 'GB-CKF',
        name: 'Carrickfergus',
      },
      {
        code: 'GB-CSR',
        name: 'Castlereagh',
      },
      {
        code: 'GB-CLR',
        name: 'Coleraine',
      },
      {
        code: 'GB-CKT',
        name: 'Cookstown',
      },
      {
        code: 'GB-CGV',
        name: 'Craigavon',
      },
      {
        code: 'GB-DRY',
        name: 'Derry (City of)',
      },
      {
        code: 'GB-DOW',
        name: 'Down',
      },
      {
        code: 'GB-DGN',
        name: 'Dungannon',
      },
      {
        code: 'GB-FER',
        name: 'Fermanagh',
      },
      {
        code: 'GB-LRN',
        name: 'Larne',
      },
      {
        code: 'GB-LMV',
        name: 'Limavady',
      },
      {
        code: 'GB-LSB',
        name: 'Lisburn',
      },
      {
        code: 'GB-MFT',
        name: 'Magherafelt',
      },
      {
        code: 'GB-MYL',
        name: 'Moyle',
      },
      {
        code: 'GB-NYM',
        name: 'Newry and Mourne',
      },
      {
        code: 'GB-NTA',
        name: 'Newtownabbey',
      },
      {
        code: 'GB-NDN',
        name: 'North Down',
      },
      {
        code: 'GB-OMH',
        name: 'Omagh',
      },
      {
        code: 'GB-STB',
        name: 'Strabane',
      },
      {
        code: 'GB-SCT',
        name: 'Scotland',
      },
      {
        code: 'GB-ABE',
        name: 'Aberdeen City',
      },
      {
        code: 'GB-ABD',
        name: 'Aberdeenshire',
      },
      {
        code: 'GB-ANS',
        name: 'Angus',
      },
      {
        code: 'GB-AGB',
        name: 'Argyll and Bute',
      },
      {
        code: 'GB-CLK',
        name: 'Clackmannanshire',
      },
      {
        code: 'GB-DGY',
        name: 'Dumfries and Galloway',
      },
      {
        code: 'GB-DND',
        name: 'Dundee City',
      },
      {
        code: 'GB-EAY',
        name: 'East Ayrshire',
      },
      {
        code: 'GB-EDU',
        name: 'East Dunbartonshire',
      },
      {
        code: 'GB-ELN',
        name: 'East Lothian',
      },
      {
        code: 'GB-ERW',
        name: 'East Renfrewshire',
      },
      {
        code: 'GB-EDH',
        name: 'Edinburgh, City of',
      },
      {
        code: 'GB-ELS',
        name: 'Eilean Siar',
      },
      {
        code: 'GB-FAL',
        name: 'Falkirk',
      },
      {
        code: 'GB-FIF',
        name: 'Fife',
      },
      {
        code: 'GB-GLG',
        name: 'Glasgow City',
      },
      {
        code: 'GB-HLD',
        name: 'Highland',
      },
      {
        code: 'GB-IVC',
        name: 'Inverclyde',
      },
      {
        code: 'GB-MLN',
        name: 'Midlothian',
      },
      {
        code: 'GB-MRY',
        name: 'Moray',
      },
      {
        code: 'GB-NAY',
        name: 'North Ayrshire',
      },
      {
        code: 'GB-NLK',
        name: 'North Lanarkshire',
      },
      {
        code: 'GB-ORK',
        name: 'Orkney Islands',
      },
      {
        code: 'GB-PKN',
        name: 'Perth and Kinross',
      },
      {
        code: 'GB-RFW',
        name: 'Renfrewshire',
      },
      {
        code: 'GB-SCB',
        name: 'Scottish Borders, The',
      },
      {
        code: 'GB-ZET',
        name: 'Shetland Islands',
      },
      {
        code: 'GB-SAY',
        name: 'South Ayrshire',
      },
      {
        code: 'GB-SLK',
        name: 'South Lanarkshire',
      },
      {
        code: 'GB-STG',
        name: 'Stirling',
      },
      {
        code: 'GB-WDU',
        name: 'West Dunbartonshire',
      },
      {
        code: 'GB-WLN',
        name: 'West Lothian',
      },
      {
        code: 'GB-WLS',
        name: 'Wales [Cymru]',
      },
      {
        code: 'GB-BGW',
        name: 'Blaenau Gwent',
      },
      {
        code: 'GB-BGE',
        name: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]',
      },
      {
        code: 'GB-CAY',
        name: 'Caerphilly [Caerffili GB-CAF]',
      },
      {
        code: 'GB-CRF',
        name: 'Cardiff (City of) [Caerdydd GB-CRD]',
      },
      {
        code: 'GB-CMN',
        name: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]',
      },
      {
        code: 'GB-CGN',
        name: 'Ceredigion [Sir Ceredigion]',
      },
      {
        code: 'GB-CWY',
        name: 'Conwy',
      },
      {
        code: 'GB-DEN',
        name: 'Denbighshire [Sir Ddinbych GB-DDB]',
      },
      {
        code: 'GB-FLN',
        name: 'Flintshire [Sir y Fflint GB-FFL]',
      },
      {
        code: 'GB-GWN',
        name: 'Gwynedd',
      },
      {
        code: 'GB-AGY',
        name: 'Isle of Anglesey [Sir Ynys Man GB-YNM]',
      },
      {
        code: 'GB-MTY',
        name: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]',
      },
      {
        code: 'GB-MON',
        name: 'Monmouthshire [Sir Fynwy GB-FYN]',
      },
      {
        code: 'GB-NTL',
        name: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]',
      },
      {
        code: 'GB-NWP',
        name: 'Newport [Casnewydd GB-CNW]',
      },
      {
        code: 'GB-PEM',
        name: 'Pembrokeshire [Sir Benfro CB-BNF]',
      },
      {
        code: 'GB-POW',
        name: 'Powys',
      },
      {
        code: 'GB-RCT',
        name: 'Rhondda, Cynon, Taff [Rhondda, Cynon, Taf]',
      },
      {
        code: 'GB-SWA',
        name: 'Swansea (City of) [Abertawe GB-ATA]',
      },
      {
        code: 'GB-TOF',
        name: 'Torfaen [Tor-faen]',
      },
      {
        code: 'GB-VGL',
        name: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]',
      },
      {
        code: 'GB-WRX',
        name: 'Wrexham [Wrecsam GB-WRC]',
      },
    ],
  },
  {
    name: 'Canada',
    alpha_3: 'CAN',
    country_code: '124',
    divisions: [
      {
        code: 'CA-AB',
        name: 'Alberta',
      },
      {
        code: 'CA-BC',
        name: 'British Columbia (Colombie-Britannique)',
      },
      {
        code: 'CA-MB',
        name: 'Manitoba',
      },
      {
        code: 'CA-NB',
        name: 'New Brunswick (Nouveau-Brunswick)',
      },
      {
        code: 'CA-NL',
        name: 'Newfoundland and Labrador (Terre-Neuve)',
      },
      {
        code: 'CA-NS',
        name: 'Nova Scotia (Nouvelle-Écosse)',
      },
      {
        code: 'CA-ON',
        name: 'Ontario',
      },
      {
        code: 'CA-PE',
        name: 'Prince Edward Island (Île-du-Prince-Édouard)',
      },
      {
        code: 'CA-QC',
        name: 'Quebec (Québec)',
      },
      {
        code: 'CA-SK',
        name: 'Saskatchewan',
      },
      {
        code: 'CA-NT',
        name: 'Northwest Territories (Territoires du Nord-Ouest)',
      },
      {
        code: 'CA-NU',
        name: 'Nunavut',
      },
      {
        code: 'CA-YT',
        name: 'Yukon Territory (Teritoire du Yukon)',
      },
    ],
  },
  {
    name: 'Afghanistan',
    alpha_3: 'AFG',
    country_code: '004',
    divisions: [
      {
        code: 'AF-BDS',
        name: 'Badakhshān',
      },
      {
        code: 'AF-BDG',
        name: 'Bādghīs',
      },
      {
        code: 'AF-BGL',
        name: 'Baghlān',
      },
      {
        code: 'AF-BAL',
        name: 'Balkh',
      },
      {
        code: 'AF-BAM',
        name: 'Bāmīān',
      },
      {
        code: 'AF-FRA',
        name: 'Farāh',
      },
      {
        code: 'AF-FYB',
        name: 'Fāryāb',
      },
      {
        code: 'AF-GHA',
        name: 'Ghaznī',
      },
      {
        code: 'AF-GHO',
        name: 'Ghowr',
      },
      {
        code: 'AF-HEL',
        name: 'Helmand',
      },
      {
        code: 'AF-HER',
        name: 'Herāt',
      },
      {
        code: 'AF-JOW',
        name: 'Jowzjān',
      },
      {
        code: 'AF-KAB',
        name: 'Kabul (Kābol)',
      },
      {
        code: 'AF-KAN',
        name: 'Kandahār',
      },
      {
        code: 'AF-KAP',
        name: 'Kāpīsā',
      },
      {
        code: 'AF-KNR',
        name: 'Konar (Kunar)',
      },
      {
        code: 'AF-KDZ',
        name: 'Kondoz (Kunduz)',
      },
      {
        code: 'AF-LAG',
        name: 'Laghmān',
      },
      {
        code: 'AF-LOW',
        name: 'Lowgar',
      },
      {
        code: 'AF-NAN',
        name: 'Nangrahār (Nangarhār)',
      },
      {
        code: 'AF-NIM',
        name: 'Nīmrūz',
      },
      {
        code: 'AF-ORU',
        name: 'Orūzgān (Urūzgā',
      },
      {
        code: 'AF-PIA',
        name: 'Paktīā',
      },
      {
        code: 'AF-PKA',
        name: 'Paktīkā',
      },
      {
        code: 'AF-PAR',
        name: 'Parwān',
      },
      {
        code: 'AF-SAM',
        name: 'Samangān',
      },
      {
        code: 'AF-SAR',
        name: 'Sar-e Pol',
      },
      {
        code: 'AF-TAK',
        name: 'Takhār',
      },
      {
        code: 'AF-WAR',
        name: 'Wardak (Wardag)',
      },
      {
        code: 'AF-ZAB',
        name: 'Zābol (Zābul)',
      },
    ],
  },
  {
    name: 'Albania',
    alpha_3: 'ALB',
    country_code: '008',
    divisions: [
      {
        code: 'AL-BR',
        name: 'Berat',
      },
      {
        code: 'AL-BU',
        name: 'Bulqizë',
      },
      {
        code: 'AL-DL',
        name: 'Delvinë',
      },
      {
        code: 'AL-DV',
        name: 'Devoll',
      },
      {
        code: 'AL-DI',
        name: 'Dibër',
      },
      {
        code: 'AL-DR',
        name: 'Durrës',
      },
      {
        code: 'AL-EL',
        name: 'Elbasan',
      },
      {
        code: 'AL-FR',
        name: 'Fier',
      },
      {
        code: 'AL-GR',
        name: 'Gramsh',
      },
      {
        code: 'AL-GJ',
        name: 'Gjirokastër',
      },
      {
        code: 'AL-HA',
        name: 'Has',
      },
      {
        code: 'AL-KA',
        name: 'Kavajë',
      },
      {
        code: 'AL-ER',
        name: 'Kolonjë',
      },
      {
        code: 'AL-KO',
        name: 'Korcë',
      },
      {
        code: 'AL-KR',
        name: 'Krujë',
      },
      {
        code: 'AL-KC',
        name: 'Kucovë',
      },
      {
        code: 'AL-KU',
        name: 'Kukës',
      },
      {
        code: 'AL-LA',
        name: 'Laç',
      },
      {
        code: 'AL-LE',
        name: 'Lezhë',
      },
      {
        code: 'AL-LB',
        name: 'Librazhd',
      },
      {
        code: 'AL-LU',
        name: 'Lushnjë',
      },
      {
        code: 'AL-MM',
        name: 'Malësia e Madhe',
      },
      {
        code: 'AL-MK',
        name: 'Mallakastër',
      },
      {
        code: 'AL-MT',
        name: 'Mat',
      },
      {
        code: 'AL-MR',
        name: 'Mirditë',
      },
      {
        code: 'AL-PQ',
        name: 'Peqin',
      },
      {
        code: 'AL-PR',
        name: 'Përmet',
      },
      {
        code: 'AL-PG',
        name: 'Pogradec',
      },
      {
        code: 'AL-PU',
        name: 'Pukë',
      },
      {
        code: 'AL-SR',
        name: 'Sarandë',
      },
      {
        code: 'AL-SK',
        name: 'Skrapar',
      },
      {
        code: 'AL-SH',
        name: 'Shkodër',
      },
      {
        code: 'AL-TE',
        name: 'Tepelenë',
      },
      {
        code: 'AL-TR',
        name: 'Tiranë',
      },
      {
        code: 'AL-TP',
        name: 'Tropojë',
      },
      {
        code: 'AL-VL',
        name: 'Vlorë',
      },
    ],
  },
  {
    name: 'Algeria',
    alpha_3: 'DZA',
    country_code: '012',
    divisions: [
      {
        code: 'DZ-01',
        name: 'Adrar',
      },
      {
        code: 'DZ-44',
        name: 'Aïn Defla',
      },
      {
        code: 'DZ-46',
        name: 'Aïn Témouchent',
      },
      {
        code: 'DZ-16',
        name: 'Alger',
      },
      {
        code: 'DZ-05',
        name: 'Batna',
      },
      {
        code: 'DZ-08',
        name: 'Béchar',
      },
      {
        code: 'DZ-06',
        name: 'Béjaïa',
      },
      {
        code: 'DZ-07',
        name: 'Biskra',
      },
      {
        code: 'DZ-09',
        name: 'Blida',
      },
      {
        code: 'DZ-34',
        name: 'Bordj Bou Arréridj',
      },
      {
        code: 'DZ-10',
        name: 'Bouira',
      },
      {
        code: 'DZ-35',
        name: 'Boumerdès',
      },
      {
        code: 'DZ-02',
        name: 'Chlef',
      },
      {
        code: 'DZ-25',
        name: 'Constantine',
      },
      {
        code: 'DZ-17',
        name: 'Djelfa',
      },
      {
        code: 'DZ-32',
        name: 'El Bayadh',
      },
      {
        code: 'DZ-39',
        name: 'El Oued',
      },
      {
        code: 'DZ-36',
        name: 'El Tarf',
      },
      {
        code: 'DZ-47',
        name: 'Ghardaïa',
      },
      {
        code: 'DZ-24',
        name: 'Guelma',
      },
      {
        code: 'DZ-33',
        name: 'Illizi',
      },
      {
        code: 'DZ-18',
        name: 'Jijel',
      },
      {
        code: 'DZ-40',
        name: 'Khenchela',
      },
      {
        code: 'DZ-03',
        name: 'Laghouat',
      },
      {
        code: 'DZ-29',
        name: 'Mascara',
      },
      {
        code: 'DZ-26',
        name: 'Médéa',
      },
      {
        code: 'DZ-43',
        name: 'Mila',
      },
      {
        code: 'DZ-27',
        name: 'Mostaganem',
      },
      {
        code: 'DZ-28',
        name: 'Msila',
      },
      {
        code: 'DZ-45',
        name: 'Naama',
      },
      {
        code: 'DZ-31',
        name: 'Oran',
      },
      {
        code: 'DZ-30',
        name: 'Ouargla',
      },
      {
        code: 'DZ-04',
        name: 'Oum el Bouaghi',
      },
      {
        code: 'DZ-48',
        name: 'Relizane',
      },
      {
        code: 'DZ-20',
        name: 'Saïda',
      },
      {
        code: 'DZ-19',
        name: 'Sétif',
      },
      {
        code: 'DZ-22',
        name: 'Sidi Bel Abbès',
      },
      {
        code: 'DZ-21',
        name: 'Skikda',
      },
      {
        code: 'DZ-41',
        name: 'Souk Ahras',
      },
      {
        code: 'DZ-11',
        name: 'Tamanghasset',
      },
      {
        code: 'DZ-12',
        name: 'Tébessa',
      },
      {
        code: 'DZ-14',
        name: 'Tiaret',
      },
      {
        code: 'DZ-37',
        name: 'Tindouf',
      },
      {
        code: 'DZ-42',
        name: 'Tipaza',
      },
      {
        code: 'DZ-38',
        name: 'Tissemsilt',
      },
      {
        code: 'DZ-15',
        name: 'Tizi Ouzou',
      },
      {
        code: 'DZ-13',
        name: 'Tlemcen',
      },
    ],
  },
  {
    name: 'American Samoa',
    alpha_3: 'ASM',
    country_code: '016',
    divisions: [
      {
        code: 'AS-AS',
        name: 'American Samoa',
      },
    ],
  },
  {
    name: 'Andorra',
    alpha_3: 'AND',
    country_code: '020',
    divisions: [
      {
        code: 'AD-AD',
        name: 'Andorra',
      },
    ],
  },
  {
    name: 'Angola',
    alpha_3: 'AGO',
    country_code: '024',
    divisions: [
      {
        code: 'AO-BGO',
        name: 'Bengo',
      },
      {
        code: 'AO-BGU',
        name: 'Benguela',
      },
      {
        code: 'AO-BIE',
        name: 'Bié',
      },
      {
        code: 'AO-CAB',
        name: 'Cabinda',
      },
      {
        code: 'AO-CCU',
        name: 'Cuando-Cubango',
      },
      {
        code: 'AO-CNO',
        name: 'Cuanza Norte',
      },
      {
        code: 'AO-CUS',
        name: 'Cuanza Sul',
      },
      {
        code: 'AO-CNN',
        name: 'Cunene',
      },
      {
        code: 'AO-HUA',
        name: 'Huambo',
      },
      {
        code: 'AO-HUI',
        name: 'Huíla',
      },
      {
        code: 'AO-LUA',
        name: 'Luanda',
      },
      {
        code: 'AO-LNO',
        name: 'Lunda Norte',
      },
      {
        code: 'AO-LSU',
        name: 'Lunda Sul',
      },
      {
        code: 'AO-MAL',
        name: 'Malange',
      },
      {
        code: 'AO-MOX',
        name: 'Moxico',
      },
      {
        code: 'AO-NAM',
        name: 'Namibe',
      },
      {
        code: 'AO-UIG',
        name: 'Uíge',
      },
      {
        code: 'AO-ZAI',
        name: 'Zaïre',
      },
    ],
  },
  {
    name: 'Anguilla',
    alpha_3: 'AIA',
    country_code: '660',
    divisions: [
      {
        code: 'AI-AI',
        name: 'Anguilla',
      },
    ],
  },
  {
    name: 'Antarctica',
    alpha_3: 'ATA',
    country_code: '010',
    divisions: [
      {
        code: 'AQ-AQ',
        name: 'Antarctica',
      },
    ],
  },
  {
    name: 'Antigua and Barbuda',
    alpha_3: 'ATG',
    country_code: '028',
    divisions: [
      {
        code: 'AG-AG',
        name: 'Antigua & Barbuda',
      },
    ],
  },
  {
    name: 'Argentina',
    alpha_3: 'ARG',
    country_code: '032',
    divisions: [
      {
        code: 'AR-C',
        name: 'Capital federal',
      },
      {
        code: 'AR-B',
        name: 'Buenos Aires',
      },
      {
        code: 'AR-K',
        name: 'Catamarca',
      },
      {
        code: 'AR-X',
        name: 'Córdoba',
      },
      {
        code: 'AR-W',
        name: 'Corrientes',
      },
      {
        code: 'AR-H',
        name: 'Chaco',
      },
      {
        code: 'AR-U',
        name: 'Chubut',
      },
      {
        code: 'AR-E',
        name: 'Entre Ríos',
      },
      {
        code: 'AR-P',
        name: 'Formosa',
      },
      {
        code: 'AR-Y',
        name: 'Jujuy',
      },
      {
        code: 'AR-L',
        name: 'La Pampa',
      },
      {
        code: 'AR-F',
        name: 'La Rioja',
      },
      {
        code: 'AR-M',
        name: 'Mendoza',
      },
      {
        code: 'AR-N',
        name: 'Misiones',
      },
      {
        code: 'AR-Q',
        name: 'Neuquén',
      },
      {
        code: 'AR-R',
        name: 'Río Negro',
      },
      {
        code: 'AR-A',
        name: 'Salta',
      },
      {
        code: 'AR-J',
        name: 'San Juan',
      },
      {
        code: 'AR-D',
        name: 'San Luis',
      },
      {
        code: 'AR-Z',
        name: 'Santa Cruz',
      },
      {
        code: 'AR-S',
        name: 'Santa Fe',
      },
      {
        code: 'AR-G',
        name: 'Santiago del Estero',
      },
      {
        code: 'AR-V',
        name: 'Tierra del Fuego',
      },
      {
        code: 'AR-T',
        name: 'Tucumán',
      },
    ],
  },
  {
    name: 'Armenia',
    alpha_3: 'ARM',
    country_code: '051',
    divisions: [
      {
        code: 'AM-ER',
        name: 'Erevan',
      },
      {
        code: 'AM-AG',
        name: 'Aragacotn',
      },
      {
        code: 'AM-AR',
        name: 'Ararat',
      },
      {
        code: 'AM-AV',
        name: 'Armavir',
      },
      {
        code: 'AM-GR',
        name: "Geģark'unik'",
      },
      {
        code: 'AM-KT',
        name: "Kotayk'",
      },
      {
        code: 'AM-LO',
        name: 'Loŕy',
      },
      {
        code: 'AM-SH',
        name: 'Širak',
      },
      {
        code: 'AM-SU',
        name: "Syunik'",
      },
      {
        code: 'AM-TV',
        name: 'Tavuš',
      },
      {
        code: 'AM-VD',
        name: 'Vayoc Jor',
      },
    ],
  },
  {
    name: 'Aruba',
    alpha_3: 'ABW',
    country_code: '533',
    divisions: [
      {
        code: 'AW-AW',
        name: 'Aruba',
      },
    ],
  },
  {
    name: 'Australia',
    alpha_3: 'AUS',
    country_code: '036',
    divisions: [
      {
        code: 'AU-NSW',
        name: 'New South Wales',
      },
      {
        code: 'AU-QLD',
        name: 'Queensland',
      },
      {
        code: 'AU-SA',
        name: 'South Australia',
      },
      {
        code: 'AU-TAS',
        name: 'Tasmania',
      },
      {
        code: 'AU-VIC',
        name: 'Victoria',
      },
      {
        code: 'AU-WA',
        name: 'Western Australia',
      },
      {
        code: 'AU-ACT',
        name: 'Australian Capital Territory',
      },
      {
        code: 'AU-NT',
        name: 'Northern Territory',
      },
    ],
  },
  {
    name: 'Austria',
    alpha_3: 'AUT',
    country_code: '040',
    divisions: [
      {
        code: 'AT-1',
        name: 'Burgenland',
      },
      {
        code: 'AT-2',
        name: 'Kärnten',
      },
      {
        code: 'AT-3',
        name: 'Niederösterreich',
      },
      {
        code: 'AT-4',
        name: 'Oberösterreich',
      },
      {
        code: 'AT-5',
        name: 'Salzburg',
      },
      {
        code: 'AT-6',
        name: 'Steiermark',
      },
      {
        code: 'AT-7',
        name: 'Tirol',
      },
      {
        code: 'AT-8',
        name: 'Vorarlberg',
      },
      {
        code: 'AT-9',
        name: 'Wien',
      },
    ],
  },
  {
    name: 'Azerbaijan',
    alpha_3: 'AZE',
    country_code: '031',
    divisions: [
      {
        code: 'AZ-MM',
        name: 'Naxçivan',
      },
      {
        code: 'AZ-AB',
        name: 'Äli Bayramli',
      },
      {
        code: 'AZ-BA',
        name: 'Baki',
      },
      {
        code: 'AZ-GA',
        name: 'Gäncä',
      },
      {
        code: 'AZ-LA',
        name: 'Länkäran',
      },
      {
        code: 'AZ-MI',
        name: 'Mingäçevir',
      },
      {
        code: 'AZ-NA',
        name: 'Naftalan',
      },
      {
        code: 'AZ-SA',
        name: 'Şäki',
      },
      {
        code: 'AZ-SM',
        name: 'Sumqayit',
      },
      {
        code: 'AZ-SS',
        name: 'Şuşa',
      },
      {
        code: 'AZ-XA',
        name: 'Xankändi',
      },
      {
        code: 'AZ-YE',
        name: 'Yevlax',
      },
      {
        code: 'AZ-ABS',
        name: 'Abşeron',
      },
      {
        code: 'AZ-AGC',
        name: 'Ağcabädi',
      },
      {
        code: 'AZ-AGM',
        name: 'Ağdam',
      },
      {
        code: 'AZ-AGS',
        name: 'Ağdas',
      },
      {
        code: 'AZ-AGA',
        name: 'Ağstafa',
      },
      {
        code: 'AZ-AGU',
        name: 'Ağsu',
      },
      {
        code: 'AZ-AST',
        name: 'Astara',
      },
      {
        code: 'AZ-BAB',
        name: 'Babäk',
      },
      {
        code: 'AZ-BAL',
        name: 'Balakän',
      },
      {
        code: 'AZ-BAR',
        name: 'Bärdä',
      },
      {
        code: 'AZ-BEY',
        name: 'Beyläqan',
      },
      {
        code: 'AZ-BIL',
        name: 'Biläsuvar',
      },
      {
        code: 'AZ-CAB',
        name: 'Cäbrayil',
      },
      {
        code: 'AZ-CAL',
        name: 'Cälilabad',
      },
      {
        code: 'AZ-CUL',
        name: 'Culfa',
      },
      {
        code: 'AZ-DAS',
        name: 'Daşkäsän',
      },
      {
        code: 'AZ-DAV',
        name: 'Däväçi',
      },
      {
        code: 'AZ-FUZ',
        name: 'Füzuli',
      },
      {
        code: 'AZ-GAD',
        name: 'Gädäbäy',
      },
      {
        code: 'AZ-GOR',
        name: 'Goranboy',
      },
      {
        code: 'AZ-GOY',
        name: 'Göyçay',
      },
      {
        code: 'AZ-HAC',
        name: 'Haciqabul',
      },
      {
        code: 'AZ-IMI',
        name: 'Imişli',
      },
      {
        code: 'AZ-ISM',
        name: 'Ismayilli',
      },
      {
        code: 'AZ-KAL',
        name: 'Kälbäcär',
      },
      {
        code: 'AZ-KUR',
        name: 'Kürdämir',
      },
      {
        code: 'AZ-LAC',
        name: 'Laçin',
      },
      {
        code: 'AZ-LAN',
        name: 'Länkäran',
      },
      {
        code: 'AZ-LER',
        name: 'Lerik',
      },
      {
        code: 'AZ-MAS',
        name: 'Masalli',
      },
      {
        code: 'AZ-NEF',
        name: 'Neftçala',
      },
      {
        code: 'AZ-OGU',
        name: 'Oğuz',
      },
      {
        code: 'AZ-ORD',
        name: 'Ordubad',
      },
      {
        code: 'AZ-QAB',
        name: 'Qäbälä',
      },
      {
        code: 'AZ-QAX',
        name: 'Qax',
      },
      {
        code: 'AZ-QAZ',
        name: 'Qazax',
      },
      {
        code: 'AZ-QOB',
        name: 'Qobustan',
      },
      {
        code: 'AZ-QBA',
        name: 'Quba',
      },
      {
        code: 'AZ-QBI',
        name: 'Qubadlı',
      },
      {
        code: 'AZ-QUS',
        name: 'Qusar',
      },
      {
        code: 'AZ-SAT',
        name: 'Saatli',
      },
      {
        code: 'AZ-SAB',
        name: 'Sabirabad',
      },
      {
        code: 'AZ-SAD',
        name: 'Sädäräk',
      },
      {
        code: 'AZ-SAH',
        name: 'Şahbuz',
      },
      {
        code: 'AZ-SAK',
        name: 'Şäki',
      },
      {
        code: 'AZ-SAL',
        name: 'Salyan',
      },
      {
        code: 'AZ-SMI',
        name: 'Şamaxı',
      },
      {
        code: 'AZ-SKR',
        name: 'Şämkir',
      },
      {
        code: 'AZ-SMX',
        name: 'Samux',
      },
      {
        code: 'AZ-SAR',
        name: 'Şärur',
      },
      {
        code: 'AZ-SIY',
        name: 'Siyäzän',
      },
      {
        code: 'AZ-SUS',
        name: 'Şuşa',
      },
      {
        code: 'AZ-TAR',
        name: 'Tärtär',
      },
      {
        code: 'AZ-TOV',
        name: 'Tovuz',
      },
      {
        code: 'AZ-UCA',
        name: 'Ucar',
      },
      {
        code: 'AZ-XAC',
        name: 'Xaçmaz',
      },
      {
        code: 'AZ-XAN',
        name: 'Xanlar',
      },
      {
        code: 'AZ-XIZ',
        name: 'Xizi',
      },
      {
        code: 'AZ-XCI',
        name: 'Xocalı',
      },
      {
        code: 'AZ-XVD',
        name: 'Xocavänd',
      },
      {
        code: 'AZ-YAR',
        name: 'Yardimli',
      },
      {
        code: 'AZ-YEV',
        name: 'Yevlax',
      },
      {
        code: 'AZ-ZAN',
        name: 'Zängılan',
      },
      {
        code: 'AZ-ZAQ',
        name: 'Zaqatala',
      },
      {
        code: 'AZ-ZAR',
        name: 'Zärdab',
      },
    ],
  },
  {
    name: 'Bahamas',
    alpha_3: 'BHS',
    country_code: '044',
    divisions: [
      {
        code: 'BS-AC',
        name: 'Acklins and Crooked Islands',
      },
      {
        code: 'BS-BI',
        name: 'Bimini',
      },
      {
        code: 'BS-CI',
        name: 'Cat Island',
      },
      {
        code: 'BS-EX',
        name: 'Exuma',
      },
      {
        code: 'BS-FP',
        name: 'Freeport',
      },
      {
        code: 'BS-FC',
        name: 'Fresh Creek',
      },
      {
        code: 'BS-GH',
        name: "Governor's Harbour",
      },
      {
        code: 'BS-GT',
        name: 'Green Turtle Cay',
      },
      {
        code: 'BS-HI',
        name: 'Harbour Island',
      },
      {
        code: 'BS-HR',
        name: 'High Rock',
      },
      {
        code: 'BS-IN',
        name: 'Inagua',
      },
      {
        code: 'BS-KB',
        name: 'Kemps Bay',
      },
      {
        code: 'BS-LI',
        name: 'Long Island',
      },
      {
        code: 'BS-MH',
        name: 'Marsh Harbour',
      },
      {
        code: 'BS-MG',
        name: 'Mayaguana',
      },
      {
        code: 'BS-NP',
        name: 'New Providence',
      },
      {
        code: 'BS-NB',
        name: 'Nicholls Town and Berry Islands',
      },
      {
        code: 'BS-RI',
        name: 'Ragged Island',
      },
      {
        code: 'BS-RS',
        name: 'Rock Sound',
      },
      {
        code: 'BS-SP',
        name: 'Sandy Point',
      },
      {
        code: 'BS-SR',
        name: 'San Salvador and Rum Cay',
      },
    ],
  },
  {
    name: 'Bahrain',
    alpha_3: 'BHR',
    country_code: '048',
    divisions: [
      {
        code: 'BH-01',
        name: 'Al Ḩadd',
      },
      {
        code: 'BH-03',
        name: 'Al Manāmah',
      },
      {
        code: 'BH-10',
        name: 'Al Minţaqah al Gharbīyah',
      },
      {
        code: 'BH-07',
        name: 'Al Minţaqah al Wusţa',
      },
      {
        code: 'BH-05',
        name: 'Al Minţaqah ash Shamālīyah',
      },
      {
        code: 'BH-02',
        name: 'Al Muḩarraq',
      },
      {
        code: 'BH-09',
        name: 'Ar Rifā‘',
      },
      {
        code: 'BH-04',
        name: 'Jidd Ḩafş',
      },
      {
        code: 'BH-12',
        name: 'Madīnat Ḩamad',
      },
      {
        code: 'BH-08',
        name: 'Madīnat ‘Īsá',
      },
      {
        code: 'BH-11',
        name: 'Minţaqat Juzur Ḩawār',
      },
      {
        code: 'BH-06',
        name: 'Sitrah',
      },
    ],
  },
  {
    name: 'Bangladesh',
    alpha_3: 'BGD',
    country_code: '050',
    divisions: [
      {
        code: 'BD-1',
        name: 'Barisal bibhag',
      },
      {
        code: 'BD-1B',
        name: 'Barisal anchal',
      },
      {
        code: 'BD-1Q',
        name: 'Patuakhali anchal',
      },
      {
        code: 'BD-2',
        name: 'Chittagong bibhag',
      },
      {
        code: 'BD-2A',
        name: 'Bandarban anchal',
      },
      {
        code: 'BD-2D',
        name: 'Chittagong anchal',
      },
      {
        code: 'BD-2E',
        name: 'Chittagong Hill Tracts',
      },
      {
        code: 'BD-2F',
        name: 'Comilla anchal',
      },
      {
        code: 'BD-2O',
        name: 'Noakhali anchal',
      },
      {
        code: 'BD-2T',
        name: 'Sylhet anchal',
      },
      {
        code: 'BD-3',
        name: 'Dhaka bibhag',
      },
      {
        code: 'BD-3G',
        name: 'Dhaka anchal',
      },
      {
        code: 'BD-3I',
        name: 'Faridpur anchal',
      },
      {
        code: 'BD-3J',
        name: 'Jamalpur anchal',
      },
      {
        code: 'BD-3N',
        name: 'Mymensingh anchal',
      },
      {
        code: 'BD-3U',
        name: 'Tangail anchal',
      },
      {
        code: 'BD-4',
        name: 'Khulna bibhag',
      },
      {
        code: 'BD-4K',
        name: 'Jessore anchal',
      },
      {
        code: 'BD-4L',
        name: 'Khulna anchal',
      },
      {
        code: 'BD-4M',
        name: 'Khustia anchal',
      },
      {
        code: 'BD-5',
        name: 'Rajshahi bibhag',
      },
      {
        code: 'BD-5C',
        name: 'Bogra anchal',
      },
      {
        code: 'BD-5H',
        name: 'Dinajpur anchal',
      },
      {
        code: 'BD-5P',
        name: 'Pabna anchal',
      },
      {
        code: 'BD-5R',
        name: 'Rajshahi anchal',
      },
      {
        code: 'BD-5S',
        name: 'Rangpur anchal',
      },
    ],
  },
  {
    name: 'Barbados',
    alpha_3: 'BRB',
    country_code: '052',
    divisions: [
      {
        code: 'BB-BB',
        name: 'Barbados',
      },
    ],
  },
  {
    name: 'Belarus',
    alpha_3: 'BLR',
    country_code: '112',
    divisions: [
      {
        code: 'BY-BR',
        name: "Brestskaya voblasts'",
      },
      {
        code: 'BY-HO',
        name: "Homyel'skaya voblasts’",
      },
      {
        code: 'BY-HR',
        name: "Hrodnenskaya voblasts'",
      },
      {
        code: 'BY-MA',
        name: "Mahilyowskaya voblasts'",
      },
      {
        code: 'BY-MI',
        name: "Minskaya voblasts'",
      },
      {
        code: 'BY-VI',
        name: "Vitsyebskaya voblasts'",
      },
    ],
  },
  {
    name: 'Belgium',
    alpha_3: 'BEL',
    country_code: '056',
    divisions: [
      {
        code: 'BE-BRU',
        name: 'Bruxelles-Capitale, Region de (fr), Brussels Hoofdstedelijk Gewest (nl)',
      },
      {
        code: 'BE-VLG',
        name: 'Vlaamse Gewest (nl)',
      },
      {
        code: 'BE-VAN',
        name: 'Antwerpen (nl)',
      },
      {
        code: 'BE-VLI',
        name: 'Limburg (nl)',
      },
      {
        code: 'BE-VOV',
        name: 'Oost-Vlaanderen (nl)',
      },
      {
        code: 'BE-VBR',
        name: 'Vlaams Brabant (nl)',
      },
      {
        code: 'BE-VWV',
        name: 'West-Vlaanderen (nl)',
      },
      {
        code: 'BE-WAL',
        name: 'Wallonne, Region (fr)',
      },
      {
        code: 'BE-WBR',
        name: 'Brabant Wallon (fr)',
      },
      {
        code: 'BE-WHT',
        name: 'Hainaut (fr)',
      },
      {
        code: 'BE-WLG',
        name: 'Liège (fr)',
      },
      {
        code: 'BE-WLX',
        name: 'Luxembourg (fr)',
      },
      {
        code: 'BE-WNA',
        name: 'Namur (fr)',
      },
    ],
  },
  {
    name: 'Belize',
    alpha_3: 'BLZ',
    country_code: '084',
    divisions: [
      {
        code: 'BZ-BZ',
        name: 'Belize',
      },
      {
        code: 'BZ-CY',
        name: 'Cayo',
      },
      {
        code: 'BZ-CZL',
        name: 'Corozal',
      },
      {
        code: 'BZ-OW',
        name: 'Orange Walk',
      },
      {
        code: 'BZ-SC',
        name: 'Stann Creek',
      },
      {
        code: 'BZ-TOL',
        name: 'Toledo',
      },
    ],
  },
  {
    name: 'Benin',
    alpha_3: 'BEN',
    country_code: '204',
    divisions: [
      {
        code: 'BJ-AK',
        name: 'Atakora',
      },
      {
        code: 'BJ-AQ',
        name: 'Atlantique',
      },
      {
        code: 'BJ-BO',
        name: 'Borgou',
      },
      {
        code: 'BJ-MO',
        name: 'Mono',
      },
      {
        code: 'BJ-OU',
        name: 'Ouémé',
      },
      {
        code: 'BJ-ZO',
        name: 'Zou',
      },
    ],
  },
  {
    name: 'Bermuda',
    alpha_3: 'BMU',
    country_code: '060',
    divisions: [
      {
        code: 'BM-BM',
        name: 'Bermuda',
      },
    ],
  },
  {
    name: 'Bhutan',
    alpha_3: 'BTN',
    country_code: '064',
    divisions: [
      {
        code: 'BT-33',
        name: 'Bumthang',
      },
      {
        code: 'BT-12',
        name: 'Chhukha',
      },
      {
        code: 'BT-22',
        name: 'Dagana',
      },
      {
        code: 'BT-GA',
        name: 'Gasa',
      },
      {
        code: 'BT-13',
        name: 'Ha',
      },
      {
        code: 'BT-44',
        name: 'Lhuentse',
      },
      {
        code: 'BT-42',
        name: 'Monggar',
      },
      {
        code: 'BT-11',
        name: 'Paro',
      },
      {
        code: 'BT-43',
        name: 'Pemagatshel',
      },
      {
        code: 'BT-23',
        name: 'Punakha',
      },
      {
        code: 'BT-45',
        name: 'Samdrup Jongkha',
      },
      {
        code: 'BT-14',
        name: 'Samtse',
      },
      {
        code: 'BT-31',
        name: 'Sarpang',
      },
      {
        code: 'BT-15',
        name: 'Thimphu',
      },
      {
        code: 'BT-41',
        name: 'Trashigang',
      },
      {
        code: 'BT-TY',
        name: 'Trashi Yangtse',
      },
      {
        code: 'BT-32',
        name: 'Trongsa',
      },
      {
        code: 'BT-21',
        name: 'Tsirang',
      },
      {
        code: 'BT-24',
        name: 'Wangdue Phodrang',
      },
      {
        code: 'BT-34',
        name: 'Zhemgang',
      },
    ],
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha_3: 'BOL',
    country_code: '068',
    divisions: [
      {
        code: 'BO-C',
        name: 'Cochabamba',
      },
      {
        code: 'BO-H',
        name: 'Chuquisaca',
      },
      {
        code: 'BO-B',
        name: 'El Beni',
      },
      {
        code: 'BO-L',
        name: 'La Paz',
      },
      {
        code: 'BO-O',
        name: 'Oruro',
      },
      {
        code: 'BO-N',
        name: 'Pando',
      },
      {
        code: 'BO-P',
        name: 'Potosi',
      },
      {
        code: 'BO-S',
        name: 'Santa Cruz',
      },
      {
        code: 'BO-T',
        name: 'Tarija',
      },
    ],
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha_3: 'BES',
    country_code: '535',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha_3: 'BIH',
    country_code: '070',
    divisions: [
      {
        code: 'BA-BIH',
        name: 'Federacija Bosna i Hercegovina',
      },
      {
        code: 'BA-SRP',
        name: 'Republika Srpska',
      },
    ],
  },
  {
    name: 'Botswana',
    alpha_3: 'BWA',
    country_code: '072',
    divisions: [
      {
        code: 'BW-CE',
        name: 'Central [Serowe-Palapye]',
      },
      {
        code: 'BW-CH',
        name: 'Chobe',
      },
      {
        code: 'BW-GH',
        name: 'Ghanzi',
      },
      {
        code: 'BW-KG',
        name: 'Kgalagadi',
      },
      {
        code: 'BW-KL',
        name: 'Kgatleng',
      },
      {
        code: 'BW-KW',
        name: 'Kweneng',
      },
      {
        code: 'BW-NG',
        name: 'Ngamiland [North-West]',
      },
      {
        code: 'BW-NE',
        name: 'North-East',
      },
      {
        code: 'BW-SE',
        name: 'South-East',
      },
      {
        code: 'BW-SO',
        name: 'Southern [Ngwaketse]',
      },
    ],
  },
  {
    name: 'Bouvet Island',
    alpha_3: 'BVT',
    country_code: '074',
    divisions: [
      {
        code: 'BV-BV',
        name: 'Bouvet Island',
      },
    ],
  },
  {
    name: 'Brazil',
    alpha_3: 'BRA',
    country_code: '076',
    divisions: [
      {
        code: 'BR-DF',
        name: 'Distrito Federal',
      },
      {
        code: 'BR-AC',
        name: 'Acre',
      },
      {
        code: 'BR-AL',
        name: 'Alagoas',
      },
      {
        code: 'BR-AP',
        name: 'Amapá',
      },
      {
        code: 'BR-AM',
        name: 'Amazonas',
      },
      {
        code: 'BR-BA',
        name: 'Bahia',
      },
      {
        code: 'BR-CE',
        name: 'Ceará',
      },
      {
        code: 'BR-ES',
        name: 'Espírito Santo',
      },
      {
        code: 'BR-GO',
        name: 'Goiás',
      },
      {
        code: 'BR-MA',
        name: 'Maranhāo',
      },
      {
        code: 'BR-MT',
        name: 'Mato Grosso',
      },
      {
        code: 'BR-MS',
        name: 'Mato Grosso do Sul',
      },
      {
        code: 'BR-MG',
        name: 'Minas Gerais',
      },
      {
        code: 'BR-PA',
        name: 'Pará',
      },
      {
        code: 'BR-PB',
        name: 'Paraíba',
      },
      {
        code: 'BR-PR',
        name: 'Paraná',
      },
      {
        code: 'BR-PE',
        name: 'Pernambuco',
      },
      {
        code: 'BR-PI',
        name: 'Piauí',
      },
      {
        code: 'BR-RJ',
        name: 'Rio de Janeiro',
      },
      {
        code: 'BR-RN',
        name: 'Rio Grande do Norte',
      },
      {
        code: 'BR-RS',
        name: 'Rio Grande do Sul',
      },
      {
        code: 'BR-R0',
        name: 'Rondônia',
      },
      {
        code: 'BR-RR',
        name: 'Roraima',
      },
      {
        code: 'BR-SC',
        name: 'Santa Catarina',
      },
      {
        code: 'BR-SP',
        name: 'São Paulo',
      },
      {
        code: 'BR-SE',
        name: 'Sergipe',
      },
      {
        code: 'BR-TO',
        name: 'Tocantins',
      },
    ],
  },
  {
    name: 'British Indian Ocean Territory',
    alpha_3: 'IOT',
    country_code: '086',
    divisions: [
      {
        code: 'IO-IO',
        name: 'British Indian Ocean Territory',
      },
    ],
  },
  {
    name: 'Brunei Darussalam',
    alpha_3: 'BRN',
    country_code: '096',
    divisions: [
      {
        code: 'BN-BE',
        name: 'Belait',
      },
      {
        code: 'BN-BM',
        name: 'Brunei-Muara',
      },
      {
        code: 'BN-TE',
        name: 'Temburong',
      },
      {
        code: 'BN-TU',
        name: 'Tutong',
      },
    ],
  },
  {
    name: 'Bulgaria',
    alpha_3: 'BGR',
    country_code: '100',
    divisions: [
      {
        code: 'BG-02',
        name: 'Burgas',
      },
      {
        code: 'BG-09',
        name: 'Haskovo',
      },
      {
        code: 'BG-04',
        name: 'Loveč',
      },
      {
        code: 'BG-05',
        name: 'Montana',
      },
      {
        code: 'BG-06',
        name: 'Plovdiv',
      },
      {
        code: 'BG-07',
        name: 'Ruse',
      },
      {
        code: 'BG-08',
        name: 'Sofija',
      },
      {
        code: 'BG-01',
        name: 'Sofija-Grad',
      },
      {
        code: 'BG-03',
        name: 'Varna',
      },
    ],
  },
  {
    name: 'Burkina Faso',
    alpha_3: 'BFA',
    country_code: '854',
    divisions: [
      {
        code: 'BF-BAL',
        name: 'Balé',
      },
      {
        code: 'BF-BAM',
        name: 'Bam',
      },
      {
        code: 'BF-BAN',
        name: 'Banwa',
      },
      {
        code: 'BF-BAZ',
        name: 'Bazèga',
      },
      {
        code: 'BF-BGR',
        name: 'Bougouriba',
      },
      {
        code: 'BF-BLG',
        name: 'Boulgou',
      },
      {
        code: 'BF-BLK',
        name: 'Boulkiemdé',
      },
      {
        code: 'BF-COM',
        name: 'Comoé',
      },
      {
        code: 'BF-GAN',
        name: 'Ganzourgou',
      },
      {
        code: 'BF-GNA',
        name: 'Gnagna',
      },
      {
        code: 'BF-GOU',
        name: 'Gourma',
      },
      {
        code: 'BF-HOU',
        name: 'Houet',
      },
      {
        code: 'BF-IOB',
        name: 'Ioba',
      },
      {
        code: 'BF-KAD',
        name: 'Kadiogo',
      },
      {
        code: 'BF-KEN',
        name: 'Kénédougou',
      },
      {
        code: 'BF-KMD',
        name: 'Komondjari',
      },
      {
        code: 'BF-KMP',
        name: 'Kompienga',
      },
      {
        code: 'BF-KOS',
        name: 'Kossi',
      },
      {
        code: 'BF-KOP',
        name: 'Koulpélogo',
      },
      {
        code: 'BF-KOT',
        name: 'Kouritenga',
      },
      {
        code: 'BF-KOW',
        name: 'Kourwéogo',
      },
      {
        code: 'BF-LER',
        name: 'Léraba',
      },
      {
        code: 'BF-LOR',
        name: 'Loroum',
      },
      {
        code: 'BF-MOU',
        name: 'Mouhoun',
      },
      {
        code: 'BF-NAO',
        name: 'Nahouri',
      },
      {
        code: 'BF-NAM',
        name: 'Namentenga',
      },
      {
        code: 'BF-NAY',
        name: 'Nayala',
      },
      {
        code: 'BF-NOU',
        name: 'Noumbiel',
      },
      {
        code: 'BF-OUB',
        name: 'Oubritenga',
      },
      {
        code: 'BF-OUD',
        name: 'Oudalan',
      },
      {
        code: 'BF-PAS',
        name: 'Passoré',
      },
      {
        code: 'BF-PON',
        name: 'Poni',
      },
      {
        code: 'BF-SNG',
        name: 'Sanguié',
      },
      {
        code: 'BF-SMT',
        name: 'Sanmatenga',
      },
      {
        code: 'BF-SEN',
        name: 'Séno',
      },
      {
        code: 'BF-SIS',
        name: 'Sissili',
      },
      {
        code: 'BF-SOM',
        name: 'Soum',
      },
      {
        code: 'BF-SOR',
        name: 'Sourou',
      },
      {
        code: 'BF-TAP',
        name: 'Tapoa',
      },
      {
        code: 'BF-TUI',
        name: 'Tui',
      },
      {
        code: 'BF-YAG',
        name: 'Yagha',
      },
      {
        code: 'BF-YAT',
        name: 'Yatenga',
      },
      {
        code: 'BF-ZIR',
        name: 'Ziro',
      },
      {
        code: 'BF-ZON',
        name: 'Zondoma',
      },
      {
        code: 'BF-ZOU',
        name: 'Zoundwéogo',
      },
    ],
  },
  {
    name: 'Burundi',
    alpha_3: 'BDI',
    country_code: '108',
    divisions: [
      {
        code: 'BI-BB',
        name: 'Bubanza',
      },
      {
        code: 'BI-BJ',
        name: 'Bujumbura',
      },
      {
        code: 'BI-BR',
        name: 'Bururi',
      },
      {
        code: 'BI-CA',
        name: 'Cankuzo',
      },
      {
        code: 'BI-CI',
        name: 'Cibitoke',
      },
      {
        code: 'BI-GI',
        name: 'Gitega',
      },
      {
        code: 'BI-KR',
        name: 'Karuzi',
      },
      {
        code: 'BI-KY',
        name: 'Kayanza',
      },
      {
        code: 'BI-KI',
        name: 'Kirundo',
      },
      {
        code: 'BI-MA',
        name: 'Makamba',
      },
      {
        code: 'BI-MU',
        name: 'Muramvya',
      },
      {
        code: 'BI-MY',
        name: 'Muyinga',
      },
      {
        code: 'BI-NG',
        name: 'Ngozi',
      },
      {
        code: 'BI-RT',
        name: 'Rutana',
      },
      {
        code: 'BI-RY',
        name: 'Ruyigi',
      },
    ],
  },
  {
    name: 'Cabo Verde',
    alpha_3: 'CPV',
    country_code: '132',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Cambodia',
    alpha_3: 'KHM',
    country_code: '116',
    divisions: [
      {
        code: 'KH-23',
        name: 'Krong Kaeb [Krŏng Kêb]',
      },
      {
        code: 'KH-18',
        name: 'Krong Preah Sihanouk [Krŏng Preăh Sihanouk]',
      },
      {
        code: 'KH-12',
        name: 'Phnom Penh [Phnum Pénh]',
      },
      {
        code: 'KH-2',
        name: 'Baat Dambang [Bătdâmbâng]',
      },
      {
        code: 'KH-1',
        name: 'Banteay Mean Chey [Bântéay Méanchey]',
      },
      {
        code: 'KH-3',
        name: 'Kampong Chaam [Kâmpóng Cham]',
      },
      {
        code: 'KH-4',
        name: 'Kampong Chhnang [Kâmpóng Chhnăng]',
      },
      {
        code: 'KH-5',
        name: 'Kampong Spueu [Kâmpóng Spœ]',
      },
      {
        code: 'KH-6',
        name: 'Kampong Thum [Kâmpóng Thum]',
      },
      {
        code: 'KH-7',
        name: 'Kampot [Kâmpôt]',
      },
      {
        code: 'KH-8',
        name: 'Kandaal [Kândal]',
      },
      {
        code: 'KH-10',
        name: 'Kracheh [Krâchéh]',
      },
      {
        code: 'KH-11',
        name: 'Mond01 Kiri [Môndól Kiri]',
      },
      {
        code: 'KH-22',
        name: 'Otdar Mean Chey [Ŏtdâr Méanchey]',
      },
      {
        code: 'KH-15',
        name: 'Pousaat [Poŭthĭsăt]',
      },
      {
        code: 'KH-13',
        name: 'Preah Vihear [Preăh Vihéar]',
      },
      {
        code: 'KH-14',
        name: 'Prey Veaeng [Prey Vêng]',
      },
      {
        code: 'KH-16',
        name: 'Rotanak Kiri [Rôtânôkiri]',
      },
      {
        code: 'KH-17',
        name: 'Siem Reab [Siĕmréab]',
      },
      {
        code: 'KH-19',
        name: 'Stueng Traeng [Stœng Trêng]',
      },
      {
        code: 'KH-20',
        name: 'Svaay Rieng [Svay Riĕng]',
      },
      {
        code: 'KH-21',
        name: 'Taakaev [Takêv]',
      },
    ],
  },
  {
    name: 'Cameroon',
    alpha_3: 'CMR',
    country_code: '120',
    divisions: [
      {
        code: 'CM-AD',
        name: 'Adamaoua',
      },
      {
        code: 'CM-CE',
        name: 'Centre',
      },
      {
        code: 'CM-ES',
        name: 'Est',
      },
      {
        code: 'CM-EN',
        name: 'Far North',
      },
      {
        code: 'CM-LT',
        name: 'Littoral',
      },
      {
        code: 'CM-NO',
        name: 'North',
      },
      {
        code: 'CM-NW',
        name: 'North-West',
      },
      {
        code: 'CM-SU',
        name: 'South',
      },
      {
        code: 'CM-SW',
        name: 'South-West',
      },
      {
        code: 'CM-OU',
        name: 'West',
      },
    ],
  },
  {
    name: 'Cayman Islands',
    alpha_3: 'CYM',
    country_code: '136',
    divisions: [
      {
        code: 'KY-KY',
        name: 'Cayman Islands',
      },
    ],
  },
  {
    name: 'Central African Republic',
    alpha_3: 'CAF',
    country_code: '140',
    divisions: [
      {
        code: 'CF-BGF',
        name: 'Bangui',
      },
      {
        code: 'CF-BB',
        name: 'Bamingui-Bangoran',
      },
      {
        code: 'CF-BK',
        name: 'Basse-Kotto',
      },
      {
        code: 'CF-HK',
        name: 'Haute-Kotto',
      },
      {
        code: 'CF-HM',
        name: 'Haut-Mbomou',
      },
      {
        code: 'CF-KG',
        name: 'Kémo',
      },
      {
        code: 'CF-LB',
        name: 'Lobaye',
      },
      {
        code: 'CF-HS',
        name: 'Mambéré-Kadéï',
      },
      {
        code: 'CF-MB',
        name: 'Mbomou',
      },
      {
        code: 'CF-KB',
        name: 'Nana-Grébizi',
      },
      {
        code: 'CF-NM',
        name: 'Nana-Mambéré',
      },
      {
        code: 'CF-MP',
        name: 'Ombella-Mpoko',
      },
      {
        code: 'CF-UK',
        name: 'Ouaka',
      },
      {
        code: 'CF-AC',
        name: 'Ouham',
      },
      {
        code: 'CF-OP',
        name: 'Ouham-Pendé',
      },
      {
        code: 'CF-SE',
        name: 'Sangha-Mbaéré',
      },
      {
        code: 'CF-VK',
        name: 'Vakaga',
      },
    ],
  },
  {
    name: 'Chad',
    alpha_3: 'TCD',
    country_code: '148',
    divisions: [
      {
        code: 'TD-BA',
        name: 'Batha',
      },
      {
        code: 'TD-BI',
        name: 'Biltine',
      },
      {
        code: 'TD-BET',
        name: 'Borkou-Ennedi-Tibesti',
      },
      {
        code: 'TD-CB',
        name: 'Chari-Baguirmi',
      },
      {
        code: 'TD-GR',
        name: 'Guéra',
      },
      {
        code: 'TD-KA',
        name: 'Kanem',
      },
      {
        code: 'TD-LC',
        name: 'Lac',
      },
      {
        code: 'TD-LO',
        name: 'Logone-Occidental',
      },
      {
        code: 'TD-LR',
        name: 'Logone-Oriental',
      },
      {
        code: 'TD-MK',
        name: 'Mayo-Kébbi',
      },
      {
        code: 'TD-MC',
        name: 'Moyen-Chari',
      },
      {
        code: 'TD-OD',
        name: 'Ouaddaï',
      },
      {
        code: 'TD-SA',
        name: 'Salamat',
      },
      {
        code: 'TD-TA',
        name: 'Tandjilé',
      },
    ],
  },
  {
    name: 'Chile',
    alpha_3: 'CHL',
    country_code: '152',
    divisions: [
      {
        code: 'CL-AI',
        name: 'Aisén del General Carlos Ibáñiez del Campo',
      },
      {
        code: 'CL-AN',
        name: 'Antofagasta',
      },
      {
        code: 'CL-AR',
        name: 'Araucanía',
      },
      {
        code: 'CL-AT',
        name: 'Atacama',
      },
      {
        code: 'CL-BI',
        name: 'Bío-Bío',
      },
      {
        code: 'CL-CO',
        name: 'Coquimbo',
      },
      {
        code: 'CL-LI',
        name: "Libertador General Bernardo O'Higgins",
      },
      {
        code: 'CL-LL',
        name: 'Los Lagos',
      },
      {
        code: 'CL-MA',
        name: 'Magallanes',
      },
      {
        code: 'CL-ML',
        name: 'Maule',
      },
      {
        code: 'CL-RM',
        name: 'Regíon Metropolitana de Santiago',
      },
      {
        code: 'CL-TA',
        name: 'Tarapacá',
      },
      {
        code: 'CL-VS',
        name: 'Valparaiso',
      },
    ],
  },
  {
    name: 'China',
    alpha_3: 'CHN',
    country_code: '156',
    divisions: [
      {
        code: 'CN-11',
        name: 'Beijing',
      },
      {
        code: 'CN-50',
        name: 'Chongqing',
      },
      {
        code: 'CN-31',
        name: 'Shanghai',
      },
      {
        code: 'CN-12',
        name: 'Tianjin',
      },
      {
        code: 'CN-34',
        name: 'Anhui',
      },
      {
        code: 'CN-35',
        name: 'Fujian',
      },
      {
        code: 'CN-62',
        name: 'Gansu',
      },
      {
        code: 'CN-44',
        name: 'Guangdong',
      },
      {
        code: 'CN-52',
        name: 'Guizhou',
      },
      {
        code: 'CN-46',
        name: 'Hainan',
      },
      {
        code: 'CN-13',
        name: 'Hebei',
      },
      {
        code: 'CN-23',
        name: 'Heilongjiang',
      },
      {
        code: 'CN-41',
        name: 'Henan',
      },
      {
        code: 'CN-42',
        name: 'Hubei',
      },
      {
        code: 'CN-43',
        name: 'Hunan',
      },
      {
        code: 'CN-32',
        name: 'Jiangsu',
      },
      {
        code: 'CN-36',
        name: 'Jiangxi',
      },
      {
        code: 'CN-22',
        name: 'Jilin',
      },
      {
        code: 'CN-21',
        name: 'Liaoning',
      },
      {
        code: 'CN-63',
        name: 'Qinghai',
      },
      {
        code: 'CN-61',
        name: 'Shaanxi',
      },
      {
        code: 'CN-37',
        name: 'Shandong',
      },
      {
        code: 'CN-14',
        name: 'Shanxi',
      },
      {
        code: 'CN-51',
        name: 'Sichuan',
      },
      {
        code: 'CN-71',
        name: 'Taiwan',
      },
      {
        code: 'CN-53',
        name: 'Yunnan',
      },
      {
        code: 'CN-33',
        name: 'Zhejiang',
      },
      {
        code: 'CN-45',
        name: 'Guangxi',
      },
      {
        code: 'CN-15',
        name: 'Nei Monggol',
      },
      {
        code: 'CN-64',
        name: 'Ningxia',
      },
      {
        code: 'CN-65',
        name: 'Xinjiang',
      },
      {
        code: 'CN-54',
        name: 'Xizang',
      },
      {
        code: 'CN-91',
        name: 'Hong Kong',
      },
    ],
  },
  {
    name: 'Christmas Island',
    alpha_3: 'CXR',
    country_code: '162',
    divisions: [
      {
        code: 'CX-CX',
        name: 'Christmas Island',
      },
    ],
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha_3: 'CCK',
    country_code: '166',
    divisions: [
      {
        code: 'CC-CC',
        name: 'Cocos (Keeling) Islands',
      },
    ],
  },
  {
    name: 'Colombia',
    alpha_3: 'COL',
    country_code: '170',
    divisions: [
      {
        code: 'CO-DC',
        name: 'Distrito Capital de Santa Fe de Bogota',
      },
      {
        code: 'CO-AMA',
        name: 'Amazonas',
      },
      {
        code: 'CO-ANT',
        name: 'Antioguia',
      },
      {
        code: 'CO-ARA',
        name: 'Arauca',
      },
      {
        code: 'CO-ATL',
        name: 'Atlántico',
      },
      {
        code: 'CO-BOL',
        name: 'Bolívar',
      },
      {
        code: 'CO-BOY',
        name: 'Boyacá',
      },
      {
        code: 'CO-CAL',
        name: 'Caldas',
      },
      {
        code: 'CO-CAQ',
        name: 'Caquetá',
      },
      {
        code: 'CO-CAS',
        name: 'Casanare',
      },
      {
        code: 'CO-CAU',
        name: 'Cauca',
      },
      {
        code: 'CO-CES',
        name: 'Cesar',
      },
      {
        code: 'CO-COR',
        name: 'Córdoba',
      },
      {
        code: 'CO-CUN',
        name: 'Cundinamarca',
      },
      {
        code: 'CO-CHO',
        name: 'Chocó',
      },
      {
        code: 'CO-GUA',
        name: 'Guainía',
      },
      {
        code: 'CO-GUV',
        name: 'Guaviare',
      },
      {
        code: 'CO-HUI',
        name: 'Huila',
      },
      {
        code: 'CO-LAG',
        name: 'La Guajira',
      },
      {
        code: 'CO-MAG',
        name: 'Magdalena',
      },
      {
        code: 'CO-MET',
        name: 'Meta',
      },
      {
        code: 'CO-NAR',
        name: 'Nariño',
      },
      {
        code: 'CO-NSA',
        name: 'Norte de Santander',
      },
      {
        code: 'CO-PUT',
        name: 'Putumayo',
      },
      {
        code: 'CO-QUI',
        name: 'Quindío',
      },
      {
        code: 'CO-RIS',
        name: 'Risaralda',
      },
      {
        code: 'CO-SAP',
        name: 'San Andrés, Providencia y Santa Catalina',
      },
      {
        code: 'CO-SAN',
        name: 'Santander',
      },
      {
        code: 'CO-SUC',
        name: 'Sucre',
      },
      {
        code: 'CO-TOL',
        name: 'Tolima',
      },
      {
        code: 'CO-VAC',
        name: 'Valle del Cauca',
      },
      {
        code: 'CO-VAU',
        name: 'Vaupés',
      },
      {
        code: 'CO-VID',
        name: 'Vichada',
      },
    ],
  },
  {
    name: 'Comoros',
    alpha_3: 'COM',
    country_code: '174',
    divisions: [
      {
        code: 'KM-A',
        name: 'Anjouan (Ndzouani)',
      },
      {
        code: 'KM-G',
        name: 'Grande Comore (Ngazidja)',
      },
      {
        code: 'KM-M',
        name: 'Mohéli (Moili)',
      },
    ],
  },
  {
    name: 'Congo',
    alpha_3: 'COG',
    country_code: '178',
    divisions: [
      {
        code: 'CG-BZV',
        name: 'Brazzaville',
      },
      {
        code: 'CG-11',
        name: 'Bouenza',
      },
      {
        code: 'CG-8',
        name: 'Cuvette',
      },
      {
        code: 'CG-15',
        name: 'Cuvette-Ouest',
      },
      {
        code: 'CG-5',
        name: 'Kouilou',
      },
      {
        code: 'CG-2',
        name: 'Lékoumou',
      },
      {
        code: 'CG-7',
        name: 'Likouala',
      },
      {
        code: 'CG-9',
        name: 'Niari',
      },
      {
        code: 'CG-14',
        name: 'Plateaux',
      },
      {
        code: 'CG-12',
        name: 'Pool',
      },
      {
        code: 'CG-13',
        name: 'Sangha',
      },
    ],
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha_3: 'COD',
    country_code: '180',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Cook Islands',
    alpha_3: 'COK',
    country_code: '184',
    divisions: [
      {
        code: 'CK-CK',
        name: 'Cook Islands',
      },
    ],
  },
  {
    name: 'Costa Rica',
    alpha_3: 'CRI',
    country_code: '188',
    divisions: [
      {
        code: 'CR-A',
        name: 'Alajuela',
      },
      {
        code: 'CR-C',
        name: 'Cartago',
      },
      {
        code: 'CR-G',
        name: 'Guanacaste',
      },
      {
        code: 'CR-H',
        name: 'Heredia',
      },
      {
        code: 'CR-L',
        name: 'Limón',
      },
      {
        code: 'CR-P',
        name: 'Puntarenas',
      },
      {
        code: 'CR-SJ',
        name: 'San José',
      },
    ],
  },
  {
    name: "Côte d'Ivoire",
    alpha_3: 'CIV',
    country_code: '384',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Croatia',
    alpha_3: 'HRV',
    country_code: '191',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Cuba',
    alpha_3: 'CUB',
    country_code: '192',
    divisions: [
      {
        code: 'CU-09',
        name: 'Camagüey',
      },
      {
        code: 'CU-03',
        name: 'Ciudad de La Habana',
      },
      {
        code: 'CU-12',
        name: 'Granma',
      },
      {
        code: 'CU-14',
        name: 'Guantánamo',
      },
      {
        code: 'CU-11',
        name: 'Holguín',
      },
      {
        code: 'CU-02',
        name: 'La Habana',
      },
      {
        code: 'CU-10',
        name: 'Las Tunas',
      },
      {
        code: 'CU-04',
        name: 'Matanzas',
      },
      {
        code: 'CU-01',
        name: 'Pinar del Río',
      },
      {
        code: 'CU-07',
        name: 'Sancti Spíritus',
      },
      {
        code: 'CU-13',
        name: 'Santiago de Cuba',
      },
      {
        code: 'CU-05',
        name: 'Villa Clara',
      },
      {
        code: 'CU-99',
        name: 'Isla de la Juventud',
      },
    ],
  },
  {
    name: 'Curaçao',
    alpha_3: 'CUW',
    country_code: '531',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Cyprus',
    alpha_3: 'CYP',
    country_code: '196',
    divisions: [
      {
        code: 'CY-04',
        name: 'Ammochostos (Mağusa)',
      },
      {
        code: 'CY-06',
        name: 'Keryneia (Girne)',
      },
      {
        code: 'CY-03',
        name: 'Larnaka',
      },
      {
        code: 'CY-01',
        name: 'Lefkosia (Lefkoşa)',
      },
      {
        code: 'CY-02',
        name: 'Lemesos (Leymosun)',
      },
      {
        code: 'CY-05',
        name: 'Pafos (Baf)',
      },
    ],
  },
  {
    name: 'Czechia',
    alpha_3: 'CZE',
    country_code: '203',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Denmark',
    alpha_3: 'DNK',
    country_code: '208',
    divisions: [
      {
        code: 'DK-147',
        name: 'Frederiksberg',
      },
      {
        code: 'DK-101',
        name: 'Kǿbenhavn',
      },
      {
        code: 'DK-040',
        name: 'Bornholm',
      },
      {
        code: 'DK-020',
        name: 'Frederiksborg',
      },
      {
        code: 'DK-042',
        name: 'Fyn',
      },
      {
        code: 'DK-015',
        name: 'Kǿbenhavn',
      },
      {
        code: 'DK-080',
        name: 'Nordjylland',
      },
      {
        code: 'DK-055',
        name: 'Ribe',
      },
      {
        code: 'DK-065',
        name: 'Ringkǿbing',
      },
      {
        code: 'DK-025',
        name: 'Roskilde',
      },
      {
        code: 'DK-035',
        name: 'Storstrǿm',
      },
      {
        code: 'DK-050',
        name: 'Sǿnderjylland',
      },
      {
        code: 'DK-060',
        name: 'Vejle',
      },
      {
        code: 'DK-030',
        name: 'Vestsjælland',
      },
      {
        code: 'DK-076',
        name: 'Viborg',
      },
      {
        code: 'DK-070',
        name: 'Århus',
      },
    ],
  },
  {
    name: 'Djibouti',
    alpha_3: 'DJI',
    country_code: '262',
    divisions: [
      {
        code: 'DJ-AS',
        name: 'Ali Sabieh',
      },
      {
        code: 'DJ-DI',
        name: 'Dikhil',
      },
      {
        code: 'DJ-DJ',
        name: 'Djibouti',
      },
      {
        code: 'DJ-OB',
        name: 'Obock',
      },
      {
        code: 'DJ-TA',
        name: 'Tadjoura',
      },
    ],
  },
  {
    name: 'Dominica',
    alpha_3: 'DMA',
    country_code: '212',
    divisions: [
      {
        code: 'DM-DM',
        name: 'Dominica',
      },
    ],
  },
  {
    name: 'Dominican Republic',
    alpha_3: 'DOM',
    country_code: '214',
    divisions: [
      {
        code: 'DO-DN',
        name: 'Distrito National (Santo Domingo)',
      },
      {
        code: 'DO-AZ',
        name: 'Azua',
      },
      {
        code: 'DO-BR',
        name: 'Bahoruco',
      },
      {
        code: 'DO-BH',
        name: 'Barahona',
      },
      {
        code: 'DO-DA',
        name: 'Dajabón',
      },
      {
        code: 'DO-DU',
        name: 'Duarte',
      },
      {
        code: 'DO-SE',
        name: 'El Seibo',
      },
      {
        code: 'DO-EP',
        name: 'La Estrelleta [Elías Piña]',
      },
      {
        code: 'DO-HM',
        name: 'Hato Mayor',
      },
      {
        code: 'DO-IN',
        name: 'Independencia',
      },
      {
        code: 'DO-AL',
        name: 'La Altagracia',
      },
      {
        code: 'DO-RO',
        name: 'La Romana',
      },
      {
        code: 'DO-VE',
        name: 'La Vega',
      },
      {
        code: 'DO-MT',
        name: 'María Trinidad Sánchez',
      },
      {
        code: 'DO-MN',
        name: 'Monseñor Nouel',
      },
      {
        code: 'DO-MC',
        name: 'Monte Cristi',
      },
      {
        code: 'DO-MP',
        name: 'Monte Plata',
      },
      {
        code: 'DO-PN',
        name: 'Pedernales',
      },
      {
        code: 'DO-PR',
        name: 'Peravia',
      },
      {
        code: 'DO-PP',
        name: 'Puerto Plata',
      },
      {
        code: 'DO-SC',
        name: 'Salcedo',
      },
      {
        code: 'DO-SM',
        name: 'Samaná',
      },
      {
        code: 'DO-SZ',
        name: 'Sanchez Ramírez',
      },
      {
        code: 'DO-CR',
        name: 'San Cristóbal',
      },
      {
        code: 'DO-JU',
        name: 'San Juan',
      },
      {
        code: 'DO-PM',
        name: 'San Pedro de Macorís',
      },
      {
        code: 'DO-ST',
        name: 'Santiago',
      },
      {
        code: 'DO-SR',
        name: 'Santiago Rodríguez',
      },
      {
        code: 'DO-VA',
        name: 'Valverde',
      },
    ],
  },
  {
    name: 'Ecuador',
    alpha_3: 'ECU',
    country_code: '218',
    divisions: [
      {
        code: 'EC-A',
        name: 'Azuay',
      },
      {
        code: 'EC-B',
        name: 'Bolívar',
      },
      {
        code: 'EC-F',
        name: 'Cañar',
      },
      {
        code: 'EC-C',
        name: 'Carchi',
      },
      {
        code: 'EC-X',
        name: 'Cotopaxi',
      },
      {
        code: 'EC-H',
        name: 'Chimborazo',
      },
      {
        code: 'EC-O',
        name: 'El Oro',
      },
      {
        code: 'EC-E',
        name: 'Esmeraldas',
      },
      {
        code: 'EC-W',
        name: 'Galápagos',
      },
      {
        code: 'EC-G',
        name: 'Guayas',
      },
      {
        code: 'EC-I',
        name: 'Imbabura',
      },
      {
        code: 'EC-L',
        name: 'Loja',
      },
      {
        code: 'EC-R',
        name: 'Los Ríos',
      },
      {
        code: 'EC-M',
        name: 'Manabí',
      },
      {
        code: 'EC-S',
        name: 'Morona-Santiago',
      },
      {
        code: 'EC-N',
        name: 'Napo',
      },
      {
        code: 'EC-Y',
        name: 'Pastaza',
      },
      {
        code: 'EC-P',
        name: 'Pichincha',
      },
      {
        code: 'EC-U',
        name: 'Sucumbíos',
      },
      {
        code: 'EC-T',
        name: 'Tungurahua',
      },
      {
        code: 'EC-Z',
        name: 'Zarnora-Chinchipe',
      },
    ],
  },
  {
    name: 'Egypt',
    alpha_3: 'EGY',
    country_code: '818',
    divisions: [
      {
        code: 'EG-DK',
        name: 'Ad Daqahlīyah',
      },
      {
        code: 'EG-BA',
        name: 'Al Baḩr al Aḩmar',
      },
      {
        code: 'EG-BH',
        name: 'Al Buḩayrah',
      },
      {
        code: 'EG-FYM',
        name: 'Al Fayyūm',
      },
      {
        code: 'EG-GH',
        name: 'Al Gharbīyah',
      },
      {
        code: 'EG-ALX',
        name: 'Al Iskandarīyah',
      },
      {
        code: 'EG-IS',
        name: 'Al Ismā‘īlīyah',
      },
      {
        code: 'EG-GZ',
        name: 'Al Jīzah',
      },
      {
        code: 'EG-MNF',
        name: 'Al Minūfīyah',
      },
      {
        code: 'EG-MN',
        name: 'Al Minyā',
      },
      {
        code: 'EG-C',
        name: 'Al Qāhirah',
      },
      {
        code: 'EG-KB',
        name: 'Al Qalyūbīyah',
      },
      {
        code: 'EG-WAD',
        name: 'Al Wādī al Jadīd',
      },
      {
        code: 'EG-SHR',
        name: 'Ash Sharqīyah',
      },
      {
        code: 'EG-SUZ',
        name: 'As Suways',
      },
      {
        code: 'EG-ASN',
        name: 'Aswān',
      },
      {
        code: 'EG-AST',
        name: 'Asyūţ',
      },
      {
        code: 'EG-BNS',
        name: 'Banī Suwayf',
      },
      {
        code: 'EG-PTS',
        name: 'Būr Sa‘īd',
      },
      {
        code: 'EG-DT',
        name: 'Dumyāţ',
      },
      {
        code: 'EG-JS',
        name: "Janūb Sīnā'",
      },
      {
        code: 'EG-KFS',
        name: 'Kafr ash Shaykh',
      },
      {
        code: 'EG-MT',
        name: 'Maţrūḩ',
      },
      {
        code: 'EG-KN',
        name: 'Qinā',
      },
      {
        code: 'EG-SIN',
        name: "Shamāl Sīnā'",
      },
      {
        code: 'EG-SHG',
        name: 'Sūhāj',
      },
    ],
  },
  {
    name: 'El Salvador',
    alpha_3: 'SLV',
    country_code: '222',
    divisions: [
      {
        code: 'SV-AH',
        name: 'Ahuachapán',
      },
      {
        code: 'SV-CA',
        name: 'Cabañas',
      },
      {
        code: 'SV-CU',
        name: 'Cuscatlán',
      },
      {
        code: 'SV-CH',
        name: 'Chalatenango',
      },
      {
        code: 'SV-LI',
        name: 'La Libertad',
      },
      {
        code: 'SV-PA',
        name: 'La Paz',
      },
      {
        code: 'SV-UN',
        name: 'La Unión',
      },
      {
        code: 'SV-MO',
        name: 'Morazán',
      },
      {
        code: 'SV-SM',
        name: 'San Miguel',
      },
      {
        code: 'SV-SS',
        name: 'San Salvador',
      },
      {
        code: 'SV-SA',
        name: 'Santa Ana',
      },
      {
        code: 'SV-SV',
        name: 'San Vicente',
      },
      {
        code: 'SV-SO',
        name: 'Sonsonate',
      },
      {
        code: 'SV-SU',
        name: 'Usulután',
      },
    ],
  },
  {
    name: 'Equatorial Guinea',
    alpha_3: 'GNQ',
    country_code: '226',
    divisions: [
      {
        code: 'GQ-C',
        name: 'Región Continental',
      },
      {
        code: 'GQ-CS',
        name: 'Centro Sur',
      },
      {
        code: 'GQ-KN',
        name: 'Kie-Ntem',
      },
      {
        code: 'GQ-LI',
        name: 'Litoral',
      },
      {
        code: 'GQ-WN',
        name: 'Wele-Nzás',
      },
      {
        code: 'GQ-I',
        name: 'Región Insular',
      },
      {
        code: 'GQ-AN',
        name: 'Annobón',
      },
      {
        code: 'GQ-BN',
        name: 'Bioko Norte',
      },
      {
        code: 'GQ-BS',
        name: 'Bioko Sur',
      },
    ],
  },
  {
    name: 'Eritrea',
    alpha_3: 'ERI',
    country_code: '232',
    divisions: [
      {
        code: 'ER-AG',
        name: 'Akele Guzai [Akalä Guzay]',
      },
      {
        code: 'ER-AS',
        name: 'Asmara [Asmära]',
      },
      {
        code: 'ER-BA',
        name: 'Barka',
      },
      {
        code: 'ER-DE',
        name: 'Denkalia [Dänkali]',
      },
      {
        code: 'ER-GS',
        name: 'Gash-Setit [Gaš enna Sätit]',
      },
      {
        code: 'ER-HA',
        name: 'Hamasien [Hamasén]',
      },
      {
        code: 'ER-SA',
        name: 'Sahel',
      },
      {
        code: 'ER-SM',
        name: 'Semhar [Sämhar]',
      },
      {
        code: 'ER-SN',
        name: 'Senhit [Sänhet]',
      },
      {
        code: 'ER-SR',
        name: 'Seraye [Särayé]',
      },
    ],
  },
  {
    name: 'Estonia',
    alpha_3: 'EST',
    country_code: '233',
    divisions: [
      {
        code: 'EE-37',
        name: 'Harjumaa',
      },
      {
        code: 'EE-39',
        name: 'Hiiumaa',
      },
      {
        code: 'EE-44',
        name: 'Ida-Virumaa',
      },
      {
        code: 'EE-49',
        name: 'Jōgevamaa',
      },
      {
        code: 'EE-51',
        name: 'Järvamaa',
      },
      {
        code: 'EE-57',
        name: 'Läänemaa',
      },
      {
        code: 'EE-59',
        name: 'Lääne-Virumaa',
      },
      {
        code: 'EE-65',
        name: 'Pōlvamaa',
      },
      {
        code: 'EE-67',
        name: 'Pärnumaa',
      },
      {
        code: 'EE-70',
        name: 'Raplamaa',
      },
      {
        code: 'EE-74',
        name: 'Saaremaa',
      },
      {
        code: 'EE-78',
        name: 'Tartumaa',
      },
      {
        code: 'EE-82',
        name: 'Valgamaa',
      },
      {
        code: 'EE-84',
        name: 'Viljandimaa',
      },
      {
        code: 'EE-86',
        name: 'Vōrumaa',
      },
    ],
  },
  {
    name: 'Eswatini',
    alpha_3: 'SWZ',
    country_code: '748',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Ethiopia',
    alpha_3: 'ETH',
    country_code: '231',
    divisions: [
      {
        code: 'ET-AA',
        name: 'Addis Ababa [Addis Abeba]',
      },
      {
        code: 'ET-AF',
        name: 'Afar',
      },
      {
        code: 'ET-AM',
        name: 'Amara [Amhara]',
      },
      {
        code: 'ET-BE',
        name: 'Benshangul-Gumaz [Bénishangul]',
      },
      {
        code: 'ET-GA',
        name: 'Gambela Peoples [Gambéla]',
      },
      {
        code: 'ET-HA',
        name: 'Harari People [Harer]',
      },
      {
        code: 'ET-OR',
        name: 'Oromia [Oromo]',
      },
      {
        code: 'ET-SO',
        name: 'Somali',
      },
      {
        code: 'ET-SN',
        name: 'Southern Nations, Nationalities and Peoples',
      },
      {
        code: 'ET-TI',
        name: 'Tigrai [Tegré]',
      },
    ],
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha_3: 'FLK',
    country_code: '238',
    divisions: [
      {
        code: 'FK-FK',
        name: 'Falkland Islands (Malvinas)',
      },
    ],
  },
  {
    name: 'Faroe Islands',
    alpha_3: 'FRO',
    country_code: '234',
    divisions: [
      {
        code: 'FO-FO',
        name: 'Faroe Islands',
      },
    ],
  },
  {
    name: 'Fiji',
    alpha_3: 'FJI',
    country_code: '242',
    divisions: [
      {
        code: 'FJ-C',
        name: 'Central',
      },
      {
        code: 'FJ-E',
        name: 'Eastern',
      },
      {
        code: 'FJ-N',
        name: 'Northern',
      },
      {
        code: 'FJ-W',
        name: 'Western',
      },
      {
        code: 'FJ-R',
        name: 'Rotuma',
      },
    ],
  },
  {
    name: 'Finland',
    alpha_3: 'FIN',
    country_code: '246',
    divisions: [
      {
        code: 'FI-AL',
        name: 'Ahvenanmaan lääni',
      },
      {
        code: 'FI-ES',
        name: 'Etelä-Suomen lääni',
      },
      {
        code: 'FI-IS',
        name: 'Itä-Suomen lääni',
      },
      {
        code: 'FI-LL',
        name: 'Lapin lääni',
      },
      {
        code: 'FI-LS',
        name: 'Länsi-Suomen lääni',
      },
      {
        code: 'FI-OL',
        name: 'Oulun lääni',
      },
    ],
  },
  {
    name: 'France',
    alpha_3: 'FRA',
    country_code: '250',
    divisions: [
      {
        code: 'FR-A',
        name: 'Alsace',
      },
      {
        code: 'FR-67',
        name: 'Bas-Rhin',
      },
      {
        code: 'FR-68',
        name: 'Haut-Rhin',
      },
      {
        code: 'FR-B',
        name: 'Aquitaine',
      },
      {
        code: 'FR-79',
        name: 'Deux-Sèvres',
      },
      {
        code: 'FR-24',
        name: 'Dordogne',
      },
      {
        code: 'FR-33',
        name: 'Gironde',
      },
      {
        code: 'FR-40',
        name: 'Landes',
      },
      {
        code: 'FR-47',
        name: 'Lot-et-Garonne',
      },
      {
        code: 'FR-64',
        name: 'Pyrénées-Atlantiques',
      },
      {
        code: 'FR-C',
        name: 'Auvergne',
      },
      {
        code: 'FR-03',
        name: 'Allier',
      },
      {
        code: 'FR-15',
        name: 'Cantal',
      },
      {
        code: 'FR-43',
        name: 'Haute-Loire',
      },
      {
        code: 'FR-63',
        name: 'Puy-de-Dôme',
      },
      {
        code: 'FR-P',
        name: 'Basse-Normandie',
      },
      {
        code: 'FR-14',
        name: 'Calvados',
      },
      {
        code: 'FR-50',
        name: 'Manche',
      },
      {
        code: 'FR-61',
        name: 'Orne',
      },
      {
        code: 'FR-D',
        name: 'Bourgogne',
      },
      {
        code: 'FR-21',
        name: "Côte-d'Or",
      },
      {
        code: 'FR-58',
        name: 'Nièvre',
      },
      {
        code: 'FR-71',
        name: 'Saône-et-Loire',
      },
      {
        code: 'FR-89',
        name: 'Yonne',
      },
      {
        code: 'FR-E',
        name: 'Bretagne',
      },
      {
        code: 'FR-22',
        name: "Cotes-d'Armor",
      },
      {
        code: 'FR-29',
        name: 'Finistère',
      },
      {
        code: 'FR-35',
        name: 'Ille-et-Vilaine',
      },
      {
        code: 'FR-56',
        name: 'Morbihan',
      },
      {
        code: 'FR-F',
        name: 'Centre',
      },
      {
        code: 'FR-18',
        name: 'Cher',
      },
      {
        code: 'FR-28',
        name: 'Eure-et-Loir',
      },
      {
        code: 'FR-36',
        name: 'Indre',
      },
      {
        code: 'FR-37',
        name: 'Indre-et-Loire',
      },
      {
        code: 'FR-41',
        name: 'Loir-et-Cher',
      },
      {
        code: 'FR-45',
        name: 'Loiret',
      },
      {
        code: 'FR-G',
        name: 'Champagne-Ardenne',
      },
      {
        code: 'FR-08',
        name: 'Ardennes',
      },
      {
        code: 'FR-10',
        name: 'Aube',
      },
      {
        code: 'FR-52',
        name: 'Haute-Marne',
      },
      {
        code: 'FR-51',
        name: 'Marne',
      },
      {
        code: 'FR-H',
        name: 'Corse',
      },
      {
        code: 'FR-2A',
        name: 'Corse-du-Sud',
      },
      {
        code: 'FR-2B',
        name: 'Haute-Corse',
      },
      {
        code: 'FR-I',
        name: 'Franche-Comté',
      },
      {
        code: 'FR-25',
        name: 'Doubs',
      },
      {
        code: 'FR-70',
        name: 'Haute-Saône',
      },
      {
        code: 'FR-39',
        name: 'Jura',
      },
      {
        code: 'FR-90',
        name: 'Territoire de Belfort',
      },
      {
        code: 'FR-Q',
        name: 'Haute-Normandie',
      },
      {
        code: 'FR-27',
        name: 'Eure',
      },
      {
        code: 'FR-76',
        name: 'Seine-Maritime',
      },
      {
        code: 'FR-J',
        name: 'Île-de-France',
      },
      {
        code: 'FR-91',
        name: 'Essonne',
      },
      {
        code: 'FR-92',
        name: 'Hauts-de-Seine',
      },
      {
        code: 'FR-75',
        name: 'Paris',
      },
      {
        code: 'FR-77',
        name: 'Seine-et-Marne',
      },
      {
        code: 'FR-93',
        name: 'Seine-Saint-Denis',
      },
      {
        code: 'FR-94',
        name: 'Val-de-Marne',
      },
      {
        code: 'FR-95',
        name: "Val-d'Oise",
      },
      {
        code: 'FR-78',
        name: 'Yvelines',
      },
      {
        code: 'FR-K',
        name: 'Languedoc-Roussillon',
      },
      {
        code: 'FR-11',
        name: 'Aude',
      },
      {
        code: 'FR-30',
        name: 'Gard',
      },
      {
        code: 'FR-34',
        name: 'Hérault',
      },
      {
        code: 'FR-48',
        name: 'Lozère',
      },
      {
        code: 'FR-66',
        name: 'Pyrénées-Orientales',
      },
      {
        code: 'FR-L',
        name: 'Limousin',
      },
      {
        code: 'FR-19',
        name: 'Corrèze',
      },
      {
        code: 'FR-23',
        name: 'Creuse',
      },
      {
        code: 'FR-87',
        name: 'Haute-Vienne',
      },
      {
        code: 'FR-M',
        name: 'Lorraine',
      },
      {
        code: 'FR-54',
        name: 'Meurthe-et-Moselle',
      },
      {
        code: 'FR-55',
        name: 'Meuse',
      },
      {
        code: 'FR-57',
        name: 'Moselle',
      },
      {
        code: 'FR-88',
        name: 'Vosges',
      },
      {
        code: 'FR-N',
        name: 'Midi-Pyrénées',
      },
      {
        code: 'FR-09',
        name: 'Ariège',
      },
      {
        code: 'FR-12',
        name: 'Aveyron',
      },
      {
        code: 'FR-32',
        name: 'Gers',
      },
      {
        code: 'FR-31',
        name: 'Haute-Garonne',
      },
      {
        code: 'FR-65',
        name: 'Hautes-Pyrénées',
      },
      {
        code: 'FR-46',
        name: 'Lot',
      },
      {
        code: 'FR-81',
        name: 'Tarn',
      },
      {
        code: 'FR-82',
        name: 'Tarn-et-Garonne',
      },
      {
        code: 'FR-O',
        name: 'Nord-Pas-de-Calais',
      },
      {
        code: 'FR-59',
        name: 'Nord',
      },
      {
        code: 'FR-62',
        name: 'Pas-de-Calais',
      },
      {
        code: 'FR-R',
        name: 'Pays de la Loire',
      },
      {
        code: 'FR-44',
        name: 'Loire-Atlantique',
      },
      {
        code: 'FR-49',
        name: 'Maine-et-Loire',
      },
      {
        code: 'FR-53',
        name: 'Mayenne',
      },
      {
        code: 'FR-72',
        name: 'Sarthe',
      },
      {
        code: 'FR-85',
        name: 'Vendée',
      },
      {
        code: 'FR-S',
        name: 'Picardie',
      },
      {
        code: 'FR-02',
        name: 'Aisne',
      },
      {
        code: 'FR-60',
        name: 'Oise',
      },
      {
        code: 'FR-80',
        name: 'Somme',
      },
      {
        code: 'FR-T',
        name: 'Poitou-Charentes',
      },
      {
        code: 'FR-16',
        name: 'Charente',
      },
      {
        code: 'FR-17',
        name: 'Charente-Maritime',
      },
      {
        code: 'FR-86',
        name: 'Vienne',
      },
      {
        code: 'FR-U',
        name: "Provence-Alpes-Côte d'Azur",
      },
      {
        code: 'FR-04',
        name: 'Alpes-de-Haute-Provence',
      },
      {
        code: 'FR-06',
        name: 'Alpes-Maritimes',
      },
      {
        code: 'FR-13',
        name: 'Bauches-du-Rhône',
      },
      {
        code: 'FR-05',
        name: 'Hautes-Alpes',
      },
      {
        code: 'FR-83',
        name: 'Var',
      },
      {
        code: 'FR-84',
        name: 'Vaucluse',
      },
      {
        code: 'FR-V',
        name: 'Rhône-Alpes',
      },
      {
        code: 'FR-01',
        name: 'Ain',
      },
      {
        code: 'FR-07',
        name: 'Ardèche',
      },
      {
        code: 'FR-26',
        name: 'Drôme',
      },
      {
        code: 'FR-74',
        name: 'Haute-Savoie',
      },
      {
        code: 'FR-38',
        name: 'Isère',
      },
      {
        code: 'FR-42',
        name: 'Loire',
      },
      {
        code: 'FR-69',
        name: 'Rhône',
      },
      {
        code: 'FR-73',
        name: 'Savoie',
      },
      {
        code: 'FR-GP',
        name: 'Guadeloupe',
      },
      {
        code: 'FR-GF',
        name: 'Guyane (francaise)',
      },
      {
        code: 'FR-MQ',
        name: 'Martinique',
      },
      {
        code: 'FR-RE',
        name: 'Réunion',
      },
      {
        code: 'FR-YT',
        name: 'Mayotte',
      },
      {
        code: 'FR-PM',
        name: 'Saint-Pierre-et-Miquelon',
      },
      {
        code: 'FR-NC',
        name: 'Nouvelle-Calédonie',
      },
      {
        code: 'FR-PF',
        name: 'Polynésie française',
      },
      {
        code: 'FR-TF',
        name: 'Terres Australes',
      },
      {
        code: 'FR-WF',
        name: 'Wallis et Futuna',
      },
    ],
  },
  {
    name: 'French Guiana',
    alpha_3: 'GUF',
    country_code: '254',
    divisions: [
      {
        code: 'GF-GF',
        name: 'French Guiana',
      },
    ],
  },
  {
    name: 'French Polynesia',
    alpha_3: 'PYF',
    country_code: '258',
    divisions: [
      {
        code: 'PF-PF',
        name: 'French Polynesia',
      },
    ],
  },
  {
    name: 'French Southern Territories',
    alpha_3: 'ATF',
    country_code: '260',
    divisions: [
      {
        code: 'TF-TF',
        name: 'French Southern Territories',
      },
    ],
  },
  {
    name: 'Gabon',
    alpha_3: 'GAB',
    country_code: '266',
    divisions: [
      {
        code: 'GA-1',
        name: 'Estuaire',
      },
      {
        code: 'GA-2',
        name: 'Haut-Ogooué',
      },
      {
        code: 'GA-3',
        name: 'Moyen-Ogooué',
      },
      {
        code: 'GA-4',
        name: 'Ngounié',
      },
      {
        code: 'GA-5',
        name: 'Nyanga',
      },
      {
        code: 'GA-6',
        name: 'Ogooué-Ivindo',
      },
      {
        code: 'GA-7',
        name: 'Ogooué-Lolo',
      },
      {
        code: 'GA-8',
        name: 'Ogooué-Maritime',
      },
      {
        code: 'GA-9',
        name: 'Woleu-Ntem',
      },
    ],
  },
  {
    name: 'Gambia',
    alpha_3: 'GMB',
    country_code: '270',
    divisions: [
      {
        code: 'GM-B',
        name: 'Banjul',
      },
      {
        code: 'GM-L',
        name: 'Lower River',
      },
      {
        code: 'GM-M',
        name: 'MacCarthy Island',
      },
      {
        code: 'GM-N',
        name: 'North Bank',
      },
      {
        code: 'GM-U',
        name: 'Upper River',
      },
      {
        code: 'GM-W',
        name: 'Western',
      },
    ],
  },
  {
    name: 'Georgia',
    alpha_3: 'GEO',
    country_code: '268',
    divisions: [
      {
        code: 'GE-AB',
        name: "Ap'khazet'is Avtonomiuri Respublika [Abkhazia]",
      },
      {
        code: 'GE-AJ',
        name: 'Acharis Avtonomiuri Respublika [Ajaria]',
      },
      {
        code: 'GE-BUS',
        name: "Bat'umi",
      },
      {
        code: 'GE-CHI',
        name: "Chiat'ura",
      },
      {
        code: 'GE-GAG',
        name: 'Gagra',
      },
      {
        code: 'GE-GOR',
        name: 'Gori',
      },
      {
        code: 'GE-KUT',
        name: "K'ut'aisi",
      },
      {
        code: 'GE-PTI',
        name: "P'ot'i",
      },
      {
        code: 'GE-RUS',
        name: "Rust'avi",
      },
      {
        code: 'GE-SUI',
        name: 'Sokhumi',
      },
      {
        code: 'GE-TBS',
        name: "T'bilisi",
      },
      {
        code: 'GE-TQI',
        name: 'Tqibuli',
      },
      {
        code: 'GE-TQV',
        name: "Tqvarch'eli",
      },
      {
        code: 'GE-TSQ',
        name: 'Tsqalmbo',
      },
      {
        code: 'GE-ZUG',
        name: 'Zugdidi',
      },
      {
        code: 'GE-01',
        name: 'Abashis Raioni',
      },
      {
        code: 'GE-02',
        name: 'Adigenis Raioni',
      },
      {
        code: 'GE-03',
        name: 'Akhalgoris Raioni',
      },
      {
        code: 'GE-04',
        name: "Akhalk'alak'is Raioni",
      },
      {
        code: 'GE-05',
        name: "Akhalts'ikhis Raioni",
      },
      {
        code: 'GE-06',
        name: 'Akhmetis Raioni',
      },
      {
        code: 'GE-07',
        name: 'Ambrolauris Raioni',
      },
      {
        code: 'GE-08',
        name: 'Aspindzis Raioni',
      },
      {
        code: 'GE-09',
        name: "Baghdat'is Raioni",
      },
      {
        code: 'GE-10',
        name: 'Bolnisis Raioni',
      },
      {
        code: 'GE-11',
        name: 'Borjomis Raioni',
      },
      {
        code: 'GE-12',
        name: "Ch'khorotsqus Raioni",
      },
      {
        code: 'GE-13',
        name: "Ch'okhatauris Raioni",
      },
      {
        code: 'GE-14',
        name: "Dedop'listsqaros Raioni",
      },
      {
        code: 'GE-15',
        name: 'Dmanisis Raioni',
      },
      {
        code: 'GE-16',
        name: "Dushet'is Raioni",
      },
      {
        code: 'GE-17',
        name: 'Galis Raioni',
      },
      {
        code: 'GE-18',
        name: 'Gardabnis Raioni',
      },
      {
        code: 'GE-19',
        name: 'Goris Raioni',
      },
      {
        code: 'GE-20',
        name: "Gudaut'is Raioni",
      },
      {
        code: 'GE-21',
        name: "Gulrip'shis Raioni",
      },
      {
        code: 'GE-22',
        name: 'Gurjaanis Raioni',
      },
      {
        code: 'GE-23',
        name: 'Javis Raioni',
      },
      {
        code: 'GE-24',
        name: "K'arelis Raioni",
      },
      {
        code: 'GE-25',
        name: 'Kaspis Raioni',
      },
      {
        code: 'GE-26',
        name: "K'edis Raioni",
      },
      {
        code: 'GE-27',
        name: 'Kharagaulis Raioni',
      },
      {
        code: 'GE-28',
        name: 'Khashuris Raioni',
      },
      {
        code: 'GE-29',
        name: "Khelvach'auris Raioni",
      },
      {
        code: 'GE-30',
        name: 'Khobis Raioni',
      },
      {
        code: 'GE-31',
        name: 'Khonis Raioni',
      },
      {
        code: 'GE-32',
        name: 'Khulos Raioni',
      },
      {
        code: 'GE-33',
        name: "K'obuletis Raioni",
      },
      {
        code: 'GE-34',
        name: 'Lagodekhis Raioni',
      },
      {
        code: 'GE-35',
        name: "Lanch'khut'is Raioni",
      },
      {
        code: 'GE-36',
        name: 'Lentekhis Raioni',
      },
      {
        code: 'GE-37',
        name: 'Marneulis Raioni',
      },
      {
        code: 'GE-38',
        name: 'Martvilis Raioni',
      },
      {
        code: 'GE-39',
        name: 'Mestiis Raioni',
      },
      {
        code: 'GE-40',
        name: "Mts'khet'is Raioni",
      },
      {
        code: 'GE-41',
        name: 'Ninotsmindis Raioni',
      },
      {
        code: 'GE-42',
        name: "Och'amch'iris Raioni",
      },
      {
        code: 'GE-43',
        name: 'Onis Raioni',
      },
      {
        code: 'GE-44',
        name: "Ozurget'is Raioni",
      },
      {
        code: 'GE-45',
        name: 'Qazbegis Raioni',
      },
      {
        code: 'GE-46',
        name: 'Qvarlis Raioni',
      },
      {
        code: 'GE-47',
        name: "Sach'kheris Raioni",
      },
      {
        code: 'GE-48',
        name: 'Sagarejos Raioni',
      },
      {
        code: 'GE-49',
        name: 'Samtrediis Raioni',
      },
      {
        code: 'GE-50',
        name: 'Senakis Raioni',
      },
      {
        code: 'GE-51',
        name: 'Shuakhevis Raioni',
      },
      {
        code: 'GE-52',
        name: 'Sighnaghis Raioni',
      },
      {
        code: 'GE-53',
        name: 'Sokhumis Raioni',
      },
      {
        code: 'GE-54',
        name: "T'elavis Raioni",
      },
      {
        code: 'GE-55',
        name: "T'erjolis Raioni",
      },
      {
        code: 'GE-56',
        name: "T'et'ritsqaros Raioni",
      },
      {
        code: 'GE-57',
        name: "T'ianet'is Raioni",
      },
      {
        code: 'GE-58',
        name: "Ts'ageris Raioni",
      },
      {
        code: 'GE-59',
        name: 'Tsalenjikhis Raioni',
      },
      {
        code: 'GE-60',
        name: 'Tsalkis Raioni',
      },
      {
        code: 'GE-61',
        name: 'Vanis Raioni',
      },
      {
        code: 'GE-62',
        name: "Zestap'onis Raioni",
      },
      {
        code: 'GE-63',
        name: 'Zugdidis Raioni',
      },
    ],
  },
  {
    name: 'Germany',
    alpha_3: 'DEU',
    country_code: '276',
    divisions: [
      {
        code: 'DE-BW',
        name: 'Baden-Württemberg',
      },
      {
        code: 'DE-BY',
        name: 'Bayern',
      },
      {
        code: 'DE-BE',
        name: 'Berlin',
      },
      {
        code: 'DE-BB',
        name: 'Brandenburg',
      },
      {
        code: 'DE-HB',
        name: 'Bremen',
      },
      {
        code: 'DE-HH',
        name: 'Hamburg',
      },
      {
        code: 'DE-HE',
        name: 'Hessen',
      },
      {
        code: 'DE-MV',
        name: 'Mecklenburg-Vorpommern',
      },
      {
        code: 'DE-NI',
        name: 'Niedersachsen',
      },
      {
        code: 'DE-NW',
        name: 'Nordrhein-Westfalen',
      },
      {
        code: 'DE-RP',
        name: 'Rheinland-Pfalz',
      },
      {
        code: 'DE-SL',
        name: 'Saarland',
      },
      {
        code: 'DE-SN',
        name: 'Sachsen',
      },
      {
        code: 'DE-ST',
        name: 'Sachsen-Anhalt',
      },
      {
        code: 'DE-SH',
        name: 'Schleswig-Holstein',
      },
      {
        code: 'DE-TH',
        name: 'Thüringen',
      },
    ],
  },
  {
    name: 'Ghana',
    alpha_3: 'GHA',
    country_code: '288',
    divisions: [
      {
        code: 'GH-AH',
        name: 'Ashanti',
      },
      {
        code: 'GH-BA',
        name: 'Brong-Ahafo',
      },
      {
        code: 'GH-CP',
        name: 'Central',
      },
      {
        code: 'GH-EP',
        name: 'Eastern',
      },
      {
        code: 'GH-AA',
        name: 'Greater Accra',
      },
      {
        code: 'GH-NP',
        name: 'Northern',
      },
      {
        code: 'GH-UE',
        name: 'Upper East',
      },
      {
        code: 'GH-UW',
        name: 'Upper West',
      },
      {
        code: 'GH-TV',
        name: 'Volta',
      },
      {
        code: 'GH-WP',
        name: 'Western',
      },
    ],
  },
  {
    name: 'Gibraltar',
    alpha_3: 'GIB',
    country_code: '292',
    divisions: [
      {
        code: 'GI-GI',
        name: 'Gibraltar',
      },
    ],
  },
  {
    name: 'Greece',
    alpha_3: 'GRC',
    country_code: '300',
    divisions: [
      {
        code: 'GR-I',
        name: 'Anatoliki Makedonia kai Thraki',
      },
      {
        code: 'GR-52',
        name: 'Drama',
      },
      {
        code: 'GR-71',
        name: 'Evros',
      },
      {
        code: 'GR-55',
        name: 'Kavalla',
      },
      {
        code: 'GR-73',
        name: 'Rodopi',
      },
      {
        code: 'GR-72',
        name: 'Xanthi',
      },
      {
        code: 'GR-IX',
        name: 'Attiki',
      },
      {
        code: 'GR-A1',
        name: 'Attiki',
      },
      {
        code: 'GR-VII',
        name: 'Dytiki Ellada',
      },
      {
        code: 'GR-13',
        name: 'Achaïa',
      },
      {
        code: 'GR-01',
        name: 'Aitolia-Akarnania',
      },
      {
        code: 'GR-14',
        name: 'Ileia',
      },
      {
        code: 'GR-III',
        name: 'Dytiki Makedonia',
      },
      {
        code: 'GR-63',
        name: 'Florina',
      },
      {
        code: 'GR-51',
        name: 'Grevena',
      },
      {
        code: 'GR-56',
        name: 'Kastoria',
      },
      {
        code: 'GR-58',
        name: 'Kozani',
      },
      {
        code: 'GR-VI',
        name: 'Ionioi Nisoi',
      },
      {
        code: 'GR-23',
        name: 'Kefallinia',
      },
      {
        code: 'GR-22',
        name: 'Kerkyra',
      },
      {
        code: 'GR-24',
        name: 'Lefkas',
      },
      {
        code: 'GR-21',
        name: 'Zakynthos',
      },
      {
        code: 'GR-IV',
        name: 'Ipeiros',
      },
      {
        code: 'GR-31',
        name: 'Arta',
      },
      {
        code: 'GR-33',
        name: 'Ioannina',
      },
      {
        code: 'GR-34',
        name: 'Preveza',
      },
      {
        code: 'GR-32',
        name: 'Thesprotia',
      },
      {
        code: 'GR-II',
        name: 'Kentriki Makedonia',
      },
      {
        code: 'GR-64',
        name: 'Chalkidiki',
      },
      {
        code: 'GR-53',
        name: 'Imathia',
      },
      {
        code: 'GR-57',
        name: 'Kilkis',
      },
      {
        code: 'GR-59',
        name: 'Pella',
      },
      {
        code: 'GR-61',
        name: 'Pieria',
      },
      {
        code: 'GR-62',
        name: 'Serrai',
      },
      {
        code: 'GR-54',
        name: 'Thessaloniki',
      },
      {
        code: 'GR-XIII',
        name: 'Kriti',
      },
      {
        code: 'GR-94',
        name: 'Chania',
      },
      {
        code: 'GR-91',
        name: 'Irakleion',
      },
      {
        code: 'GR-92',
        name: 'Lasithion',
      },
      {
        code: 'GR-93',
        name: 'Rethymnon',
      },
      {
        code: 'GR-XII',
        name: 'Notio Aigaio',
      },
      {
        code: 'GR-81',
        name: 'Dodekanisos',
      },
      {
        code: 'GR-82',
        name: 'Kyklades',
      },
      {
        code: 'GR-X',
        name: 'Peloponnisos',
      },
      {
        code: 'GR-11',
        name: 'Argolis',
      },
      {
        code: 'GR-12',
        name: 'Arkadia',
      },
      {
        code: 'GR-15',
        name: 'Korinthia',
      },
      {
        code: 'GR-16',
        name: 'Lakonia',
      },
      {
        code: 'GR-17',
        name: 'Messinia',
      },
      {
        code: 'GR-VIII',
        name: 'Sterea Ellada',
      },
      {
        code: 'GR-05',
        name: 'Evrytania',
      },
      {
        code: 'GR-04',
        name: 'Evvoia',
      },
      {
        code: 'GR-07',
        name: 'Fokis',
      },
      {
        code: 'GR-06',
        name: 'Fthiotis',
      },
      {
        code: 'GR-03',
        name: 'Voiotia',
      },
      {
        code: 'GR-V',
        name: 'Thessalia',
      },
      {
        code: 'GR-41',
        name: 'Karditsa',
      },
      {
        code: 'GR-42',
        name: 'Larisa',
      },
      {
        code: 'GR-43',
        name: 'Magnisia',
      },
      {
        code: 'GR-44',
        name: 'Trikala',
      },
      {
        code: 'GR-XI',
        name: 'Voreio Aigaio',
      },
      {
        code: 'GR-85',
        name: 'Chios',
      },
      {
        code: 'GR-83',
        name: 'Lesvos',
      },
      {
        code: 'GR-84',
        name: 'Samos',
      },
    ],
  },
  {
    name: 'Greenland',
    alpha_3: 'GRL',
    country_code: '304',
    divisions: [
      {
        code: 'GL-GL',
        name: 'Greenland',
      },
    ],
  },
  {
    name: 'Grenada',
    alpha_3: 'GRD',
    country_code: '308',
    divisions: [
      {
        code: 'GD-GD',
        name: 'Grenada',
      },
    ],
  },
  {
    name: 'Guadeloupe',
    alpha_3: 'GLP',
    country_code: '312',
    divisions: [
      {
        code: 'GP-GP',
        name: 'Guadeloupe',
      },
    ],
  },
  {
    name: 'Guam',
    alpha_3: 'GUM',
    country_code: '316',
    divisions: [
      {
        code: 'GU-GU',
        name: 'Guam',
      },
    ],
  },
  {
    name: 'Guatemala',
    alpha_3: 'GTM',
    country_code: '320',
    divisions: [
      {
        code: 'GT-AV',
        name: 'Alta Verapaz',
      },
      {
        code: 'GT-BV',
        name: 'Baja Verapaz',
      },
      {
        code: 'GT-CM',
        name: 'Chimaltenango',
      },
      {
        code: 'GT-CQ',
        name: 'Chiquimula',
      },
      {
        code: 'GT-PR',
        name: 'El Progreso',
      },
      {
        code: 'GT-ES',
        name: 'Escuintla',
      },
      {
        code: 'GT-GU',
        name: 'Guatemala',
      },
      {
        code: 'GT-HU',
        name: 'Huehuetenango',
      },
      {
        code: 'GT-IZ',
        name: 'Izabal',
      },
      {
        code: 'GT-JA',
        name: 'Jalapa',
      },
      {
        code: 'GT-JU',
        name: 'Jutiapa',
      },
      {
        code: 'GT-PE',
        name: 'Petén',
      },
      {
        code: 'GT-QZ',
        name: 'Quezaltenango',
      },
      {
        code: 'GT-QC',
        name: 'Quiché',
      },
      {
        code: 'GT-RE',
        name: 'Retalhuleu',
      },
      {
        code: 'GT-SA',
        name: 'Sacatepéquez',
      },
      {
        code: 'GT-SM',
        name: 'San Marcos',
      },
      {
        code: 'GT-SR',
        name: 'Santa Rosa',
      },
      {
        code: 'GT-SO',
        name: 'Sololá',
      },
      {
        code: 'GT-SU',
        name: 'Suchitepéquez',
      },
      {
        code: 'GT-TO',
        name: 'Totonicapán',
      },
      {
        code: 'GT-ZA',
        name: 'Zacapa',
      },
    ],
  },
  {
    name: 'Guernsey',
    alpha_3: 'GGY',
    country_code: '831',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Guinea',
    alpha_3: 'GIN',
    country_code: '324',
    divisions: [
      {
        code: 'GN-B',
        name: 'Bake, Gouvernorat de',
      },
      {
        code: 'GN-BF',
        name: 'Boffa',
      },
      {
        code: 'GN-BK',
        name: 'Boké',
      },
      {
        code: 'GN-FR',
        name: 'Fria',
      },
      {
        code: 'GN-GA',
        name: 'Gaoual',
      },
      {
        code: 'GN-KD',
        name: 'Kindia',
      },
      {
        code: 'GN-C',
        name: 'Conakry, Gouvernorat de',
      },
      {
        code: 'GN-F',
        name: 'Faranah, Gouvernorat de',
      },
      {
        code: 'GN-DB',
        name: 'Dabola',
      },
      {
        code: 'GN-DI',
        name: 'Dinguiraye',
      },
      {
        code: 'GN-FA',
        name: 'Faranah',
      },
      {
        code: 'GN-KS',
        name: 'Kissidougou',
      },
      {
        code: 'GN-K',
        name: 'Kankan, Gouvernorat de',
      },
      {
        code: 'GN-KA',
        name: 'Kankan',
      },
      {
        code: 'GN-KE',
        name: 'Kérouané',
      },
      {
        code: 'GN-KO',
        name: 'Kouroussa',
      },
      {
        code: 'GN-MD',
        name: 'Mandiana',
      },
      {
        code: 'GN-SI',
        name: 'Siguiri',
      },
      {
        code: 'GN-D',
        name: 'Kindia, Gouvernorat de',
      },
      {
        code: 'GN-CO',
        name: 'Coyah',
      },
      {
        code: 'GN-DU',
        name: 'Dubréka',
      },
      {
        code: 'GN-FO',
        name: 'Forécariah',
      },
      {
        code: 'GN-TE',
        name: 'Télimélé',
      },
      {
        code: 'GN-L',
        name: 'Labé, Gouvernorat de',
      },
      {
        code: 'GN-KB',
        name: 'Koubia',
      },
      {
        code: 'GN-LA',
        name: 'Labé',
      },
      {
        code: 'GN-LE',
        name: 'Lélouma',
      },
      {
        code: 'GN-ML',
        name: 'Mali',
      },
      {
        code: 'GN-TO',
        name: 'Tougué',
      },
      {
        code: 'GN-M',
        name: 'Mamou, Gouvernorat de',
      },
      {
        code: 'GN-DL',
        name: 'Dalaba',
      },
      {
        code: 'GN-MM',
        name: 'Mamou',
      },
      {
        code: 'GN-PI',
        name: 'Pita',
      },
      {
        code: 'GN-N',
        name: 'Nzérékoré, Gouvernorat de',
      },
      {
        code: 'GN-BE',
        name: 'Beyla',
      },
      {
        code: 'GN-GU',
        name: 'Guékédou',
      },
      {
        code: 'GN-LO',
        name: 'Lola',
      },
      {
        code: 'GN-MC',
        name: 'Macenta',
      },
      {
        code: 'GN-NZ',
        name: 'Nzérékoré',
      },
      {
        code: 'GN-YO',
        name: 'Yomou',
      },
    ],
  },
  {
    name: 'Guinea-Bissau',
    alpha_3: 'GNB',
    country_code: '624',
    divisions: [
      {
        code: 'GW-BS',
        name: 'Bissau',
      },
      {
        code: 'GW-BA',
        name: 'Bafatá',
      },
      {
        code: 'GW-BM',
        name: 'Biombo',
      },
      {
        code: 'GW-BL',
        name: 'Bolama',
      },
      {
        code: 'GW-CA',
        name: 'Cacheu',
      },
      {
        code: 'GW-GA',
        name: 'Gabú',
      },
      {
        code: 'GW-OI',
        name: 'Oio',
      },
      {
        code: 'GW-QU',
        name: 'Quinara',
      },
    ],
  },
  {
    name: 'Guyana',
    alpha_3: 'GUY',
    country_code: '328',
    divisions: [
      {
        code: 'GY-BA',
        name: 'Barima-Waini',
      },
      {
        code: 'GY-CU',
        name: 'Cuyuni-Mazaruni',
      },
      {
        code: 'GY-DE',
        name: 'Demerara-Mahaica',
      },
      {
        code: 'GY-EB',
        name: 'East Berbice-Corentyne',
      },
      {
        code: 'GY-ES',
        name: 'Essequibo Islands-West Demerara',
      },
      {
        code: 'GY-MA',
        name: 'Mahaica-Berbice',
      },
      {
        code: 'GY-PM',
        name: 'Pomeroon-Supenaam',
      },
      {
        code: 'GY-PT',
        name: 'Potaro-Siparuni',
      },
      {
        code: 'GY-UD',
        name: 'Upper Demerara-Berbice',
      },
      {
        code: 'GY-UT',
        name: 'Upper Takutu-Upper Essequibo',
      },
    ],
  },
  {
    name: 'Haiti',
    alpha_3: 'HTI',
    country_code: '332',
    divisions: [
      {
        code: 'HT-AR',
        name: 'Artibonite',
      },
      {
        code: 'HT-CE',
        name: 'Centre',
      },
      {
        code: 'HT-GA',
        name: 'Grande-Anse',
      },
      {
        code: 'HT-ND',
        name: 'Nord',
      },
      {
        code: 'HT-NE',
        name: 'Nord-Est',
      },
      {
        code: 'HT-NO',
        name: 'Nord-Ouest',
      },
      {
        code: 'HT-OU',
        name: 'Ouest',
      },
      {
        code: 'HT-SD',
        name: 'Sud',
      },
      {
        code: 'HT-SE',
        name: 'Sud-Est',
      },
    ],
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha_3: 'HMD',
    country_code: '334',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Holy See',
    alpha_3: 'VAT',
    country_code: '336',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Honduras',
    alpha_3: 'HND',
    country_code: '340',
    divisions: [
      {
        code: 'HN-AT',
        name: 'Atlántida',
      },
      {
        code: 'HN-CL',
        name: 'Colón',
      },
      {
        code: 'HN-CM',
        name: 'Comayagua',
      },
      {
        code: 'HN-CP',
        name: 'Copán',
      },
      {
        code: 'HN-CR',
        name: 'Cortés',
      },
      {
        code: 'HN-CH',
        name: 'Choluteca',
      },
      {
        code: 'HN-EP',
        name: 'El Paraíso',
      },
      {
        code: 'HN-FM',
        name: 'Francisco Morazán',
      },
      {
        code: 'HN-GD',
        name: 'Gracias a Dios',
      },
      {
        code: 'HN-IN',
        name: 'Intibucá',
      },
      {
        code: 'HN-IB',
        name: 'Islas de la Bahía',
      },
      {
        code: 'HN-LP',
        name: 'La Paz',
      },
      {
        code: 'HN-LE',
        name: 'Lempira',
      },
      {
        code: 'HN-OC',
        name: 'Ocotepeque',
      },
      {
        code: 'HN-OL',
        name: 'Olancho',
      },
      {
        code: 'HN-SB',
        name: 'Santa Bárbara',
      },
      {
        code: 'HN-VA',
        name: 'Valle',
      },
      {
        code: 'HN-YO',
        name: 'Yoro',
      },
    ],
  },
  {
    name: 'Hong Kong',
    alpha_3: 'HKG',
    country_code: '344',
    divisions: [
      {
        code: 'HK-HK',
        name: 'Hong Kong',
      },
    ],
  },
  {
    name: 'Hungary',
    alpha_3: 'HUN',
    country_code: '348',
    divisions: [
      {
        code: 'HU-BU',
        name: 'Budapest',
      },
      {
        code: 'HU-BK',
        name: 'Bács-Kiskun',
      },
      {
        code: 'HU-BA',
        name: 'Baranya',
      },
      {
        code: 'HU-BE',
        name: 'Békés',
      },
      {
        code: 'HU-BZ',
        name: 'Borsod-Abaúj-Zemplén',
      },
      {
        code: 'HU-CS',
        name: 'Csongrád',
      },
      {
        code: 'HU-FE',
        name: 'Fejér',
      },
      {
        code: 'HU-GS',
        name: 'Gyór-Moson-Sopron',
      },
      {
        code: 'HU-HB',
        name: 'Hajdú-Bihar',
      },
      {
        code: 'HU-HE',
        name: 'Heves',
      },
      {
        code: 'HU-JN',
        name: 'Jasz-Nagykun-Szolnok',
      },
      {
        code: 'HU-KE',
        name: 'Komárom-Esztergom',
      },
      {
        code: 'HU-NO',
        name: 'Nógrád',
      },
      {
        code: 'HU-PE',
        name: 'Pest',
      },
      {
        code: 'HU-SO',
        name: 'Somogy',
      },
      {
        code: 'HU-SZ',
        name: 'Szabolcs-Szatmár-Bereg',
      },
      {
        code: 'HU-TO',
        name: 'Tolna',
      },
      {
        code: 'HU-VA',
        name: 'Vas',
      },
      {
        code: 'HU-VE',
        name: 'Veszprém',
      },
      {
        code: 'HU-ZA',
        name: 'Zala',
      },
      {
        code: 'HU-BC',
        name: 'Békéscsaba',
      },
      {
        code: 'HU-DE',
        name: 'Debrecen',
      },
      {
        code: 'HU-DU',
        name: 'Dunaújváros',
      },
      {
        code: 'HU-EG',
        name: 'Eger',
      },
      {
        code: 'HU-GY',
        name: 'Gyór',
      },
      {
        code: 'HU-HV',
        name: 'Hódmezóvásárhely',
      },
      {
        code: 'HU-KV',
        name: 'Kaposvár',
      },
      {
        code: 'HU-KM',
        name: 'Kecskemét',
      },
      {
        code: 'HU-MI',
        name: 'Miskolc',
      },
      {
        code: 'HU-NK',
        name: 'Nagykanizsa',
      },
      {
        code: 'HU-NY',
        name: 'Nyíregyháza',
      },
      {
        code: 'HU-PS',
        name: 'Pécs',
      },
      {
        code: 'HU-ST',
        name: 'Salgótarján',
      },
      {
        code: 'HU-SN',
        name: 'Sopron',
      },
      {
        code: 'HU-SD',
        name: 'Szeged',
      },
      {
        code: 'HU-SF',
        name: 'Székesfehérvár',
      },
      {
        code: 'HU-SS',
        name: 'Szekszárd',
      },
      {
        code: 'HU-SK',
        name: 'Szolnok',
      },
      {
        code: 'HU-SH',
        name: 'Szombathely',
      },
      {
        code: 'HU-TB',
        name: 'Tatabánya',
      },
      {
        code: 'HU-VM',
        name: 'Veszprém',
      },
      {
        code: 'HU-ZE',
        name: 'Zalaegerszeg',
      },
    ],
  },
  {
    name: 'Iceland',
    alpha_3: 'ISL',
    country_code: '352',
    divisions: [
      {
        code: 'IS-7',
        name: 'Austurland',
      },
      {
        code: 'IS-1',
        name: 'Höfudborgarsvædi utan Reykjavíkur',
      },
      {
        code: 'IS-6',
        name: 'Nordurland eystra',
      },
      {
        code: 'IS-5',
        name: 'Nordurland vestra',
      },
      {
        code: 'IS-0',
        name: 'Reykjavīk',
      },
      {
        code: 'IS-8',
        name: 'Sudurland',
      },
      {
        code: 'IS-2',
        name: 'Sudurnes',
      },
      {
        code: 'IS-4',
        name: 'Vestfirdir',
      },
      {
        code: 'IS-3',
        name: 'Vesturland',
      },
    ],
  },
  {
    name: 'India',
    alpha_3: 'IND',
    country_code: '356',
    divisions: [
      {
        code: 'IN-AP',
        name: 'Andhra Pradesh',
      },
      {
        code: 'IN-AR',
        name: 'Arunachal Pradesh',
      },
      {
        code: 'IN-AS',
        name: 'Assam',
      },
      {
        code: 'IN-BR',
        name: 'Bihar',
      },
      {
        code: 'IN-GA',
        name: 'Goa',
      },
      {
        code: 'IN-GJ',
        name: 'Gujarat',
      },
      {
        code: 'IN-HR',
        name: 'Haryana',
      },
      {
        code: 'IN-HP',
        name: 'Himachal Pradesh',
      },
      {
        code: 'IN-JK',
        name: 'Jammu and Kashmir',
      },
      {
        code: 'IN-KA',
        name: 'Karnataka',
      },
      {
        code: 'IN-KL',
        name: 'Kerala',
      },
      {
        code: 'IN-MP',
        name: 'Madhya Pradesh',
      },
      {
        code: 'IN-MH',
        name: 'Maharashtra',
      },
      {
        code: 'IN-MN',
        name: 'Manipur',
      },
      {
        code: 'IN-ML',
        name: 'Meghalaya',
      },
      {
        code: 'IN-MZ',
        name: 'Mizoram',
      },
      {
        code: 'IN-NL',
        name: 'Nagaland',
      },
      {
        code: 'IN-OR',
        name: 'Orissa',
      },
      {
        code: 'IN-PB',
        name: 'Punjab',
      },
      {
        code: 'IN-RJ',
        name: 'Rajasthan',
      },
      {
        code: 'IN-SK',
        name: 'Sikkim',
      },
      {
        code: 'IN-TN',
        name: 'Tamil Nadu',
      },
      {
        code: 'IN-TR',
        name: 'Tripura',
      },
      {
        code: 'IN-UP',
        name: 'Uttar Pradesh',
      },
      {
        code: 'IN-WB',
        name: 'West Bengal',
      },
      {
        code: 'IN-AN',
        name: 'Andaman and Nicobar Islands',
      },
      {
        code: 'IN-CH',
        name: 'Chandigarh',
      },
      {
        code: 'IN-DN',
        name: 'Dadra and Nagar Haveli',
      },
      {
        code: 'IN-DD',
        name: 'Daman and Diu',
      },
      {
        code: 'IN-DL',
        name: 'Delhi',
      },
      {
        code: 'IN-LD',
        name: 'Lakshadweep',
      },
      {
        code: 'IN-PY',
        name: 'Pondicherry',
      },
    ],
  },
  {
    name: 'Indonesia',
    alpha_3: 'IDN',
    country_code: '360',
    divisions: [
      {
        code: 'ID-IJU',
        name: 'Irian Jaya',
      },
      {
        code: 'ID-IJ',
        name: 'Irian Jaya',
      },
      {
        code: 'ID-JWU',
        name: 'Jawa',
      },
      {
        code: 'ID-JB',
        name: 'Jawa Barat',
      },
      {
        code: 'ID-JT',
        name: 'Jawa Tengah',
      },
      {
        code: 'ID-JI',
        name: 'Jawa Timur',
      },
      {
        code: 'ID-JK',
        name: 'Jakarta Raya',
      },
      {
        code: 'ID-YO',
        name: 'Yogyakarta',
      },
      {
        code: 'ID-KAU',
        name: 'Kalimantan',
      },
      {
        code: 'ID-KB',
        name: 'Kalimantan Barat',
      },
      {
        code: 'ID-KS',
        name: 'Kalimantan Selatan',
      },
      {
        code: 'ID-KT',
        name: 'Kalimantan Tengah',
      },
      {
        code: 'ID-KI',
        name: 'Kalimantan Timur',
      },
      {
        code: 'ID-MAU',
        name: 'Maluku',
      },
      {
        code: 'ID-MA',
        name: 'Maluku',
      },
      {
        code: 'ID-NUU',
        name: 'Nusa Tenggara',
      },
      {
        code: 'ID-BA',
        name: 'Bali',
      },
      {
        code: 'ID-NB',
        name: 'Nusa Tenggara Barat',
      },
      {
        code: 'ID-NT',
        name: 'Nusa Tenggara Timur',
      },
      {
        code: 'ID-TT',
        name: 'Timor Timur',
      },
      {
        code: 'ID-SLU',
        name: 'Sulawesi',
      },
      {
        code: 'ID-SN',
        name: 'Sulawesi Selatan',
      },
      {
        code: 'ID-ST',
        name: 'Sulawesi Tengah',
      },
      {
        code: 'ID-SG',
        name: 'Sulawesi Tenggara',
      },
      {
        code: 'ID-SA',
        name: 'Sulawesi Utara',
      },
      {
        code: 'ID-SMU',
        name: 'Sumatera',
      },
      {
        code: 'ID-BE',
        name: 'Bengkulu',
      },
      {
        code: 'ID-JA',
        name: 'Jambi',
      },
      {
        code: 'ID-LA',
        name: 'Lampung',
      },
      {
        code: 'ID-RI',
        name: 'Riau',
      },
      {
        code: 'ID-SB',
        name: 'Sumatera Barat',
      },
      {
        code: 'ID-SS',
        name: 'Sumatera Selatan',
      },
      {
        code: 'ID-SU',
        name: 'Sumatera Utara',
      },
      {
        code: 'ID-AC',
        name: 'Aceh',
      },
    ],
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha_3: 'IRN',
    country_code: '364',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Iraq',
    alpha_3: 'IRQ',
    country_code: '368',
    divisions: [
      {
        code: 'IQ-AN',
        name: 'Al Anbār',
      },
      {
        code: 'IQ-BA',
        name: 'Al Başrah',
      },
      {
        code: 'IQ-MU',
        name: 'Al Muthanná',
      },
      {
        code: 'IQ-QA',
        name: 'Al Qādisīyah',
      },
      {
        code: 'IQ-NA',
        name: 'An Najaf',
      },
      {
        code: 'IQ-AR',
        name: 'Arbīl',
      },
      {
        code: 'IQ-SU',
        name: 'As Sulaymānīyah',
      },
      {
        code: 'IQ-TS',
        name: "At Ta'mīm",
      },
      {
        code: 'IQ-BB',
        name: 'Bābil',
      },
      {
        code: 'IQ-BG',
        name: 'Baghdād',
      },
      {
        code: 'IQ-DA',
        name: 'Dahūk',
      },
      {
        code: 'IQ-DQ',
        name: 'Dhī Qār',
      },
      {
        code: 'IQ-DI',
        name: 'Diyālá',
      },
      {
        code: 'IQ-KA',
        name: "Karbalā'",
      },
      {
        code: 'IQ-MA',
        name: 'Maysān',
      },
      {
        code: 'IQ-NI',
        name: 'Nīnawá',
      },
      {
        code: 'IQ-SD',
        name: 'Şalāḩ ad Dīn',
      },
      {
        code: 'IQ-WA',
        name: 'Wāsiţ',
      },
    ],
  },
  {
    name: 'Ireland',
    alpha_3: 'IRL',
    country_code: '372',
    divisions: [
      {
        code: 'IE-CP',
        name: 'Connaught (Connacht)',
      },
      {
        code: 'IE-G',
        name: 'Galway (Gaillimh)',
      },
      {
        code: 'IE-LM',
        name: 'Leitrim (Liatroim)',
      },
      {
        code: 'IE-MO',
        name: 'Mayo (Maigh Eo)',
      },
      {
        code: 'IE-RN',
        name: 'Roscommon (Ros Comáin)',
      },
      {
        code: 'IE-SO',
        name: 'Sligo (Sligeach)',
      },
      {
        code: 'IE-LP',
        name: 'Leinster (Laighin)',
      },
      {
        code: 'IE-CW',
        name: 'Carlow (Ceatharlach)',
      },
      {
        code: 'IE-D',
        name: 'Dublin (Baile Átha Cliath)',
      },
      {
        code: 'IE-KE',
        name: 'Kildare (Cill Dara)',
      },
      {
        code: 'IE-KK',
        name: 'Kilkenny (Cill Chainnigh)',
      },
      {
        code: 'IE-LS',
        name: 'Laois (Laois)',
      },
      {
        code: 'IE-LD',
        name: 'Longford (An Longfort)',
      },
      {
        code: 'IE-LH',
        name: 'Louth (Lú)',
      },
      {
        code: 'IE-MH',
        name: 'Meath (An Mhí)',
      },
      {
        code: 'IE-OY',
        name: 'Offaly (Uíbh Fhailí)',
      },
      {
        code: 'IE-WH',
        name: 'Westmeath (An Iarmhí)',
      },
      {
        code: 'IE-WX',
        name: 'Wexford (Loch Garman)',
      },
      {
        code: 'IE-WW',
        name: 'Wicklow (Cill Mhantáin)',
      },
      {
        code: 'IE-M',
        name: 'Munster (An Mhumhain)',
      },
      {
        code: 'IE-UP',
        name: 'Ulster (Ulaidh)',
      },
      {
        code: 'IE-CN',
        name: 'Cavan (An Cabhán)',
      },
      {
        code: 'IE-DL',
        name: 'Donegal (Dún na nGall)',
      },
      {
        code: 'IE-MN',
        name: 'Monaghan (Muineachán)',
      },
    ],
  },
  {
    name: 'Isle of Man',
    alpha_3: 'IMN',
    country_code: '833',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Israel',
    alpha_3: 'ISR',
    country_code: '376',
    divisions: [
      {
        code: 'IL-D',
        name: 'HaDarom (El Janūbī)',
      },
      {
        code: 'IL-M',
        name: 'HaMerkaz (El Awsat)',
      },
      {
        code: 'IL-2',
        name: 'HaZafon (Esh Shamālī)',
      },
      {
        code: 'IL-HA',
        name: 'Hefa (Heifā)',
      },
      {
        code: 'IL-TA',
        name: 'Tel-Aviv (Tell Abīb)',
      },
      {
        code: 'IL-JM',
        name: 'Yerushalayim (Al Quds)',
      },
    ],
  },
  {
    name: 'Italy',
    alpha_3: 'ITA',
    country_code: '380',
    divisions: [
      {
        code: 'IT-65',
        name: 'Abruzzo',
      },
      {
        code: 'IT-CH',
        name: 'Chieti',
      },
      {
        code: 'IT-AQ',
        name: "L'Aquila",
      },
      {
        code: 'IT-PE',
        name: 'Pescara',
      },
      {
        code: 'IT-TE',
        name: 'Teramo',
      },
      {
        code: 'IT-77',
        name: 'Basilicata',
      },
      {
        code: 'IT-MT',
        name: 'Matera',
      },
      {
        code: 'IT-PZ',
        name: 'Potenza',
      },
      {
        code: 'IT-78',
        name: 'Calabria',
      },
      {
        code: 'IT-CZ',
        name: 'Catanzaro',
      },
      {
        code: 'IT-CS',
        name: 'Cosenza',
      },
      {
        code: 'IT-KR',
        name: 'Crotone',
      },
      {
        code: 'IT-RC',
        name: 'Reggio Calabria',
      },
      {
        code: 'IT-W',
        name: 'Vibo Valentia',
      },
      {
        code: 'IT-72',
        name: 'Campania',
      },
      {
        code: 'IT-AV',
        name: 'Avellino',
      },
      {
        code: 'IT-BN',
        name: 'Benevento',
      },
      {
        code: 'IT-CE',
        name: 'Caserta',
      },
      {
        code: 'IT-NA',
        name: 'Napoli',
      },
      {
        code: 'IT-SA',
        name: 'Salerno',
      },
      {
        code: 'IT-45',
        name: 'Emilia-Romagna',
      },
      {
        code: 'IT-BO',
        name: 'Bologna',
      },
      {
        code: 'IT-FE',
        name: 'Ferrara',
      },
      {
        code: 'IT-FO',
        name: 'Forlì',
      },
      {
        code: 'IT-MO',
        name: 'Modena',
      },
      {
        code: 'IT-PR',
        name: 'Parma',
      },
      {
        code: 'IT-PC',
        name: 'Piacenza',
      },
      {
        code: 'IT-RA',
        name: 'Ravenna',
      },
      {
        code: 'IT-RE',
        name: 'Reggio Emilia',
      },
      {
        code: 'IT-RN',
        name: 'Rimini',
      },
      {
        code: 'IT-36',
        name: 'Friuli-Venezia Giulia',
      },
      {
        code: 'IT-GO',
        name: 'Gorizia',
      },
      {
        code: 'IT-PN',
        name: 'Pordenone',
      },
      {
        code: 'IT-TS',
        name: 'Trieste',
      },
      {
        code: 'IT-UD',
        name: 'Udine',
      },
      {
        code: 'IT-62',
        name: 'Lazio',
      },
      {
        code: 'IT-FR',
        name: 'Frosinone',
      },
      {
        code: 'IT-LT',
        name: 'Latina',
      },
      {
        code: 'IT-RI',
        name: 'Rieti',
      },
      {
        code: 'IT-RM',
        name: 'Roma',
      },
      {
        code: 'IT-VT',
        name: 'Viterbo',
      },
      {
        code: 'IT-42',
        name: 'Liguria',
      },
      {
        code: 'IT-GE',
        name: 'Genova',
      },
      {
        code: 'IT-IM',
        name: 'Imperia',
      },
      {
        code: 'IT-SP',
        name: 'La Spezia',
      },
      {
        code: 'IT-SV',
        name: 'Savona',
      },
      {
        code: 'IT-25',
        name: 'Lombardia',
      },
      {
        code: 'IT-BG',
        name: 'Bergamo',
      },
      {
        code: 'IT-BS',
        name: 'Brescia',
      },
      {
        code: 'IT-CO',
        name: 'Como',
      },
      {
        code: 'IT-CR',
        name: 'Cremona',
      },
      {
        code: 'IT-LC',
        name: 'Lecco',
      },
      {
        code: 'IT-LO',
        name: 'Lodi',
      },
      {
        code: 'IT-MN',
        name: 'Mantova',
      },
      {
        code: 'IT-MI',
        name: 'Milano',
      },
      {
        code: 'IT-PV',
        name: 'Pavia',
      },
      {
        code: 'IT-SO',
        name: 'Sondrio',
      },
      {
        code: 'IT-VA',
        name: 'Varese',
      },
      {
        code: 'IT-57',
        name: 'Marche',
      },
      {
        code: 'IT-AN',
        name: 'Ancona',
      },
      {
        code: 'IT-AP',
        name: 'Ascoli Piceno',
      },
      {
        code: 'IT-MC',
        name: 'Macerata',
      },
      {
        code: 'IT-PS',
        name: 'Pesaro',
      },
      {
        code: 'IT-67',
        name: 'Molise',
      },
      {
        code: 'IT-CB',
        name: 'Campobasso',
      },
      {
        code: 'IT-IS',
        name: 'Isernia',
      },
      {
        code: 'IT-21',
        name: 'Piemonte',
      },
      {
        code: 'IT-AL',
        name: 'Alessandria',
      },
      {
        code: 'IT-AT',
        name: 'Asti',
      },
      {
        code: 'IT-BI',
        name: 'Biella',
      },
      {
        code: 'IT-CN',
        name: 'Cuneo',
      },
      {
        code: 'IT-NO',
        name: 'Novara',
      },
      {
        code: 'IT-TO',
        name: 'Torino',
      },
      {
        code: 'IT-VB',
        name: 'Verbano-Cusio-Ossola',
      },
      {
        code: 'IT-VC',
        name: 'Vercelli',
      },
      {
        code: 'IT-75',
        name: 'Puglia',
      },
      {
        code: 'IT-BA',
        name: 'Bari',
      },
      {
        code: 'IT-BR',
        name: 'Brindisi',
      },
      {
        code: 'IT-FG',
        name: 'Foggia',
      },
      {
        code: 'IT-LE',
        name: 'Lecce',
      },
      {
        code: 'IT-TA',
        name: 'Taranto',
      },
      {
        code: 'IT-88',
        name: 'Sardegna',
      },
      {
        code: 'IT-CA',
        name: 'Cagliari',
      },
      {
        code: 'IT-NU',
        name: 'Nuoro',
      },
      {
        code: 'IT-OR',
        name: 'Oristano',
      },
      {
        code: 'IT-SS',
        name: 'Sassari',
      },
      {
        code: 'IT-82',
        name: 'Sicilia',
      },
      {
        code: 'IT-AG',
        name: 'Agrigento',
      },
      {
        code: 'IT-CL',
        name: 'Caltanissetta',
      },
      {
        code: 'IT-CT',
        name: 'Catania',
      },
      {
        code: 'IT-EN',
        name: 'Enna',
      },
      {
        code: 'IT-ME',
        name: 'Mesaina',
      },
      {
        code: 'IT-PA',
        name: 'Palermo',
      },
      {
        code: 'IT-RG',
        name: 'Ragusa',
      },
      {
        code: 'IT-SR',
        name: 'Siracusa',
      },
      {
        code: 'IT-TP',
        name: 'Trapani',
      },
      {
        code: 'IT-52',
        name: 'Toscana',
      },
      {
        code: 'IT-AR',
        name: 'Arezzo',
      },
      {
        code: 'IT-FI',
        name: 'Firenze',
      },
      {
        code: 'IT-GR',
        name: 'Grosseto',
      },
      {
        code: 'IT-LI',
        name: 'Livorno',
      },
      {
        code: 'IT-LU',
        name: 'Lucca',
      },
      {
        code: 'IT-MS',
        name: 'Massa',
      },
      {
        code: 'IT-PI',
        name: 'Pisa',
      },
      {
        code: 'IT-PT',
        name: 'Pistoia',
      },
      {
        code: 'IT-PO',
        name: 'Prato',
      },
      {
        code: 'IT-SI',
        name: 'Siena',
      },
      {
        code: 'IT-32',
        name: 'Trentino-Alte Adige (Trentino-Südtirol)',
      },
      {
        code: 'IT-BZ',
        name: 'Bolzano (Bozen)',
      },
      {
        code: 'IT-TN',
        name: 'Trento',
      },
      {
        code: 'IT-55',
        name: 'Umbria',
      },
      {
        code: 'IT-PG',
        name: 'Perugia',
      },
      {
        code: 'IT-TR',
        name: 'Terni',
      },
      {
        code: 'IT-23',
        name: "Valle d'Aosta (Vallée d'Aoste)",
      },
      {
        code: 'IT-AO',
        name: 'Aosta (Aoste)',
      },
      {
        code: 'IT-34',
        name: 'Veneto',
      },
      {
        code: 'IT-BL',
        name: 'Belluno',
      },
      {
        code: 'IT-PD',
        name: 'Padova',
      },
      {
        code: 'IT-RO',
        name: 'Rovigo',
      },
      {
        code: 'IT-TV',
        name: 'Treviso',
      },
      {
        code: 'IT-VE',
        name: 'Venezia',
      },
      {
        code: 'IT-VR',
        name: 'Verona',
      },
      {
        code: 'IT-VI',
        name: 'Vicenza',
      },
    ],
  },
  {
    name: 'Jamaica',
    alpha_3: 'JAM',
    country_code: '388',
    divisions: [
      {
        code: 'JM-13',
        name: 'Clarendon',
      },
      {
        code: 'JM-09',
        name: 'Hanover',
      },
      {
        code: 'JM-01',
        name: 'Kingston',
      },
      {
        code: 'JM-12',
        name: 'Manchester',
      },
      {
        code: 'JM-04',
        name: 'Portland',
      },
      {
        code: 'JM-02',
        name: 'Saint Andrew',
      },
      {
        code: 'JM-06',
        name: 'Saint Ann',
      },
      {
        code: 'JM-14',
        name: 'Saint Catherine',
      },
      {
        code: 'JM-11',
        name: 'Saint Elizabeth',
      },
      {
        code: 'JM-08',
        name: 'Saint James',
      },
      {
        code: 'JM-05',
        name: 'Saint Mary',
      },
      {
        code: 'JM-03',
        name: 'Saint Thomas',
      },
      {
        code: 'JM-07',
        name: 'Trelawny',
      },
      {
        code: 'JM-10',
        name: 'Westmoreland',
      },
    ],
  },
  {
    name: 'Japan',
    alpha_3: 'JPN',
    country_code: '392',
    divisions: [
      {
        code: 'JP-23',
        name: 'Aiti [Aichi]',
      },
      {
        code: 'JP-05',
        name: 'Akita',
      },
      {
        code: 'JP-02',
        name: 'Aomori',
      },
      {
        code: 'JP-38',
        name: 'Ehime',
      },
      {
        code: 'JP-21',
        name: 'Gihu [Gifu]',
      },
      {
        code: 'JP-10',
        name: 'Gunma',
      },
      {
        code: 'JP-34',
        name: 'Hirosima [Hiroshima]',
      },
      {
        code: 'JP-01',
        name: 'Hokkaidô [Hokkaido]',
      },
      {
        code: 'JP-18',
        name: 'Hukui [Fukui]',
      },
      {
        code: 'JP-40',
        name: 'Hukuoka [Fukuoka]',
      },
      {
        code: 'JP-07',
        name: 'Hukusima [Fukushima]',
      },
      {
        code: 'JP-28',
        name: 'Hyôgo [Hyogo]',
      },
      {
        code: 'JP-08',
        name: 'Ibaraki',
      },
      {
        code: 'JP-17',
        name: 'Isikawa [Ishikawa]',
      },
      {
        code: 'JP-03',
        name: 'Iwate',
      },
      {
        code: 'JP-37',
        name: 'Kagawa',
      },
      {
        code: 'JP-46',
        name: 'Kagosima [Kagoshima]',
      },
      {
        code: 'JP-14',
        name: 'Kanagawa',
      },
      {
        code: 'JP-39',
        name: 'Kôti [Kochi]',
      },
      {
        code: 'JP-43',
        name: 'Kumamoto',
      },
      {
        code: 'JP-26',
        name: 'Kyôto [Kyoto]',
      },
      {
        code: 'JP-24',
        name: 'Mie',
      },
      {
        code: 'JP-04',
        name: 'Miyagi',
      },
      {
        code: 'JP-45',
        name: 'Miyazaki',
      },
      {
        code: 'JP-20',
        name: 'Nagano',
      },
      {
        code: 'JP-42',
        name: 'Nagasaki',
      },
      {
        code: 'JP-29',
        name: 'Nara',
      },
      {
        code: 'JP-15',
        name: 'Niigata',
      },
      {
        code: 'JP-44',
        name: 'Ôita [Oita]',
      },
      {
        code: 'JP-33',
        name: 'Okayama',
      },
      {
        code: 'JP-47',
        name: 'Okinawa',
      },
      {
        code: 'JP-27',
        name: 'Ôsaka [Osaka]',
      },
      {
        code: 'JP-41',
        name: 'Saga',
      },
      {
        code: 'JP-11',
        name: 'Saitama',
      },
      {
        code: 'JP-25',
        name: 'Siga [Shiga]',
      },
      {
        code: 'JP-22',
        name: 'Sizuoka [Shizuoka]',
      },
      {
        code: 'JP-12',
        name: 'Tiba [Chiba]',
      },
      {
        code: 'JP-09',
        name: 'Totigi [Tochigi]',
      },
      {
        code: 'JP-36',
        name: 'Tokusima [Tokushima]',
      },
      {
        code: 'JP-13',
        name: 'Tôkyô [Tokyo]',
      },
      {
        code: 'JP-31',
        name: 'Tottori',
      },
      {
        code: 'JP-16',
        name: 'Toyama',
      },
      {
        code: 'JP-30',
        name: 'Wakayama',
      },
      {
        code: 'JP-06',
        name: 'Yamagata',
      },
      {
        code: 'JP-35',
        name: 'Yamaguti [Yamaguchi]',
      },
      {
        code: 'JP-19',
        name: 'Yamanasi [Yamanashi]',
      },
    ],
  },
  {
    name: 'Jersey',
    alpha_3: 'JEY',
    country_code: '832',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Jordan',
    alpha_3: 'JOR',
    country_code: '400',
    divisions: [
      {
        code: 'JO-AJ',
        name: '‘Ajlūn',
      },
      {
        code: 'JO-AQ',
        name: "Al 'Aqaba",
      },
      {
        code: 'JO-BA',
        name: "Al Balqā'",
      },
      {
        code: 'JO-KA',
        name: 'Al Karak',
      },
      {
        code: 'JO-MA',
        name: 'Al Mafraq',
      },
      {
        code: 'JO-AM',
        name: '‘Ammān',
      },
      {
        code: 'JO-AT',
        name: 'Aţ Ţafīlah',
      },
      {
        code: 'JO-AZ',
        name: "Az Zarqā'",
      },
      {
        code: 'JO-IR',
        name: 'Irbid',
      },
      {
        code: 'JO-JA',
        name: 'Jarash',
      },
      {
        code: 'JO-MN',
        name: 'Ma‘ān',
      },
      {
        code: 'JO-MD',
        name: 'Mādaba',
      },
    ],
  },
  {
    name: 'Kazakhstan',
    alpha_3: 'KAZ',
    country_code: '398',
    divisions: [
      {
        code: 'KZ-ALA',
        name: 'Almaty',
      },
      {
        code: 'KZ-BAY',
        name: 'Bayqonyr (Baykonyr)',
      },
      {
        code: 'KZ-ALM',
        name: "Almaty oblysy (Almatinskaya oblast')",
      },
      {
        code: 'KZ-AKM',
        name: "Aqmola oblysy (Akmolinskaya oblast')",
      },
      {
        code: 'KZ-AKT',
        name: "Aqtöbe oblysy (Aktyubinskaya oblast')",
      },
      {
        code: 'KZ-ATY',
        name: "Atyraü oblysy (Atyrauskaya oblast')",
      },
      {
        code: 'KZ-ZAP',
        name: "Batys Kazakstan oblysy (Zapadno-Kazakhstanskaya oblast')",
      },
      {
        code: 'KZ-MAN',
        name: "Mangghystaū oblysy (Mangystauskaya oblast')",
      },
      {
        code: 'KZ-YUZ',
        name: "Ongtüstik Kazakstan oblysy (Yuzhno-Kazakhstanskaya oblast')",
      },
      {
        code: 'KZ-PAV',
        name: "Pavlodar oblysy (Pavlodarskaya oblast')",
      },
      {
        code: 'KZ-KAR',
        name: "Qaraghandy oblysy (Karagandinskaya oblast')",
      },
      {
        code: 'KZ-KUS',
        name: "Qostanay oblysy (Kustanayskaya oblast')",
      },
      {
        code: 'KZ-KZY',
        name: "Qyzylorda oblysy (Kzylordinskaya oblast')",
      },
      {
        code: 'KZ-VOS',
        name: "Shyghys Kazakstan oblysy (Vostochno-Kazakhstanskaya oblast')",
      },
      {
        code: 'KZ-SEV',
        name: "Soltüstik Kazakstan oblysy (Severo-Kazakhstanskaya oblast')",
      },
      {
        code: 'KZ-ZHA',
        name: "Zhambyl oblysy (Zhambylskaya Oblast')",
      },
    ],
  },
  {
    name: 'Kenya',
    alpha_3: 'KEN',
    country_code: '404',
    divisions: [
      {
        code: 'KE-110',
        name: 'Nairobi Municipality',
      },
      {
        code: 'KE-200',
        name: 'Central (Kati)',
      },
      {
        code: 'KE-300',
        name: 'Coast (Pwani)',
      },
      {
        code: 'KE-400',
        name: 'Eastern (Mashariki)',
      },
      {
        code: 'KE-500',
        name: 'North-Eastern (Kaskazini Mashariki)',
      },
      {
        code: 'KE-600',
        name: 'Nyanza',
      },
      {
        code: 'KE-700',
        name: 'Rift Valley',
      },
      {
        code: 'KE-900',
        name: 'Western (Magharibi)',
      },
    ],
  },
  {
    name: 'Kiribati',
    alpha_3: 'KIR',
    country_code: '296',
    divisions: [
      {
        code: 'KI-G',
        name: 'Gilbert Islands',
      },
      {
        code: 'KI-L',
        name: 'Line Islands',
      },
      {
        code: 'KI-P',
        name: 'Phoenix Islands',
      },
    ],
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha_3: 'PRK',
    country_code: '408',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Korea, Republic of',
    alpha_3: 'KOR',
    country_code: '410',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Kuwait',
    alpha_3: 'KWT',
    country_code: '414',
    divisions: [
      {
        code: 'KW-AH',
        name: 'Al Aḩmadi',
      },
      {
        code: 'KW-FA',
        name: 'Al Farwānīyah',
      },
      {
        code: 'KW-JA',
        name: 'Al Jahrah',
      },
      {
        code: 'KW-KU',
        name: 'Al Kuwayt',
      },
      {
        code: 'KW-HA',
        name: 'Ḩawallī',
      },
    ],
  },
  {
    name: 'Kyrgyzstan',
    alpha_3: 'KGZ',
    country_code: '417',
    divisions: [
      {
        code: 'KG-C',
        name: "Chu (Chuyskaya oblast')",
      },
      {
        code: 'KG-J',
        name: "Jalal-Abad (Dzhalal-Abadskaya oblast')",
      },
      {
        code: 'KG-N',
        name: 'Naryn (Narynskaya Oblast’)',
      },
      {
        code: 'KG-O',
        name: "Osh (Oshskaya oblast')",
      },
      {
        code: 'KG-T',
        name: "Talas (Talasskaya oblast')",
      },
      {
        code: 'KG-Y',
        name: "Ysyk-Köl (Issyk-Kul'skaya oblast')",
      },
    ],
  },
  {
    name: "Lao People's Democratic Republic",
    alpha_3: 'LAO',
    country_code: '418',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Latvia',
    alpha_3: 'LVA',
    country_code: '428',
    divisions: [
      {
        code: 'LV-AI',
        name: 'Aizkraukles Aprinkis',
      },
      {
        code: 'LV-AL',
        name: 'Alūksnes Aprinkis',
      },
      {
        code: 'LV-BL',
        name: 'Balvu Aprinkis',
      },
      {
        code: 'LV-BU',
        name: 'Bauskas Aprinkis',
      },
      {
        code: 'LV-CE',
        name: 'Cēsu Aprinkis',
      },
      {
        code: 'LV-DA',
        name: 'Daugavpils Aprinkis',
      },
      {
        code: 'LV-DO',
        name: 'Dobeles Aprinkis',
      },
      {
        code: 'LV-GU',
        name: 'Gulbenes Aprinkis',
      },
      {
        code: 'LV-JL',
        name: 'Jelgavas Aprinkis',
      },
      {
        code: 'LV-JK',
        name: 'Jēkabpils Aprinkis',
      },
      {
        code: 'LV-KR',
        name: 'Krāslavas Aprinkis',
      },
      {
        code: 'LV-KU',
        name: 'Kuldīgas Aprinkis',
      },
      {
        code: 'LV-LM',
        name: 'Limbažu Aprinkis',
      },
      {
        code: 'LV-LE',
        name: 'Liepājas Aprinkis',
      },
      {
        code: 'LV-LU',
        name: 'Ludzas Aprinkis',
      },
      {
        code: 'LV-MA',
        name: 'Madonas Aprinkis',
      },
      {
        code: 'LV-OG',
        name: 'Ogres Aprinkis',
      },
      {
        code: 'LV-PR',
        name: 'Preilu Aprinkis',
      },
      {
        code: 'LV-RE',
        name: 'Rēzeknes Aprinkis',
      },
      {
        code: 'LV-RI',
        name: 'Rīgas Aprinkis',
      },
      {
        code: 'LV-SA',
        name: 'Saldus Aprinkis',
      },
      {
        code: 'LV-TA',
        name: 'Talsu Aprinkis',
      },
      {
        code: 'LV-TU',
        name: 'Tukuma Aprinkis',
      },
      {
        code: 'LV-VK',
        name: 'Valkas Aprinkis',
      },
      {
        code: 'LV-VM',
        name: 'Valmieras Aprinkis',
      },
      {
        code: 'LV-VE',
        name: 'Ventspils Aprinkis',
      },
      {
        code: 'LV-DGV',
        name: 'Daugavpils',
      },
      {
        code: 'LV-JEL',
        name: 'Jelgava',
      },
      {
        code: 'LV-JUR',
        name: 'Jūrmala',
      },
      {
        code: 'LV-LPX',
        name: 'Liepāja',
      },
      {
        code: 'LV-REZ',
        name: 'Rēzekne',
      },
      {
        code: 'LV-RIX',
        name: 'Rīga',
      },
      {
        code: 'LV-VEN',
        name: 'Ventspils',
      },
    ],
  },
  {
    name: 'Lebanon',
    alpha_3: 'LBN',
    country_code: '422',
    divisions: [
      {
        code: 'LB-BA',
        name: 'Beiroût (Bayrūt)',
      },
      {
        code: 'LB-BI',
        name: "El Béqaa (Al Biqā')",
      },
      {
        code: 'LB-JL',
        name: 'Jabal Loubnâne (Jabal Lubnān)',
      },
      {
        code: 'LB-AS',
        name: 'Loubnâne ech Chemâli (Ash Shamāl)',
      },
      {
        code: 'LB-JA',
        name: 'Loubnâne ej Jnoûbi (Al Janūb)',
      },
      {
        code: 'LB-NA',
        name: 'Nabatîyé (An Nabaţīyah',
      },
    ],
  },
  {
    name: 'Lesotho',
    alpha_3: 'LSO',
    country_code: '426',
    divisions: [
      {
        code: 'LS-D',
        name: 'Berea',
      },
      {
        code: 'LS-B',
        name: 'Butha-Buthe',
      },
      {
        code: 'LS-C',
        name: 'Leribe',
      },
      {
        code: 'LS-E',
        name: 'Mafeteng',
      },
      {
        code: 'LS-A',
        name: 'Maseru',
      },
      {
        code: 'LS-F',
        name: "Mohale's Hoek",
      },
      {
        code: 'LS-J',
        name: 'Mokhotlong',
      },
      {
        code: 'LS-H',
        name: "Qacha's Nek",
      },
      {
        code: 'LS-G',
        name: 'Quthing',
      },
      {
        code: 'LS-K',
        name: 'Thaba-Tseka',
      },
    ],
  },
  {
    name: 'Liberia',
    alpha_3: 'LBR',
    country_code: '430',
    divisions: [
      {
        code: 'LR-BM',
        name: 'Bomi',
      },
      {
        code: 'LR-BG',
        name: 'Bong',
      },
      {
        code: 'LR-GB',
        name: 'Grand Bassa',
      },
      {
        code: 'LR-CM',
        name: 'Grand Cape Mount',
      },
      {
        code: 'LR-GG',
        name: 'Grand Gedeh',
      },
      {
        code: 'LR-GK',
        name: 'Grand Kru',
      },
      {
        code: 'LR-LO',
        name: 'Lofa',
      },
      {
        code: 'LR-MG',
        name: 'Margibi',
      },
      {
        code: 'LR-MY',
        name: 'Maryland',
      },
      {
        code: 'LR-MO',
        name: 'Montserrado',
      },
      {
        code: 'LR-NI',
        name: 'Nimba',
      },
      {
        code: 'LR-RI',
        name: 'Rivercess',
      },
      {
        code: 'LR-SI',
        name: 'Sinoe',
      },
    ],
  },
  {
    name: 'Libya',
    alpha_3: 'LBY',
    country_code: '434',
    divisions: [
      {
        code: 'LY-BU',
        name: 'Al Buţnān',
      },
      {
        code: 'LY-JA',
        name: 'Al Jabal al Akhḑar',
      },
      {
        code: 'LY-JG',
        name: 'Al Jabal al Gharbī',
      },
      {
        code: 'LY-Ju',
        name: 'Al Jufrah',
      },
      {
        code: 'LY-WA',
        name: 'Al Wāḩah',
      },
      {
        code: 'LY-Wu',
        name: 'Al Wusţá',
      },
      {
        code: 'LY-ZA',
        name: 'Az Zāwiyah',
      },
      {
        code: 'LY-BA',
        name: 'Banghāzī',
      },
      {
        code: 'LY-FA',
        name: 'Fazzān',
      },
      {
        code: 'LY-MI',
        name: 'Mişrātah',
      },
      {
        code: 'LY-NA',
        name: 'Naggaza',
      },
      {
        code: 'LY-SF',
        name: 'Sawfajjin',
      },
      {
        code: 'LY-TB',
        name: 'Ţarābulus',
      },
    ],
  },
  {
    name: 'Liechtenstein',
    alpha_3: 'LIE',
    country_code: '438',
    divisions: [
      {
        code: 'LI-LI',
        name: 'Liechtenstein',
      },
    ],
  },
  {
    name: 'Lithuania',
    alpha_3: 'LTU',
    country_code: '440',
    divisions: [
      {
        code: 'LT-AL',
        name: 'Alytaus Apskritis',
      },
      {
        code: 'LT-KU',
        name: 'Kauno Apskritis',
      },
      {
        code: 'LT-KL',
        name: 'Klaipėdos Apskritis',
      },
      {
        code: 'LT-MR',
        name: 'Marijampolės Apskritis',
      },
      {
        code: 'LT-PN',
        name: 'Panevėžio Apskritis',
      },
      {
        code: 'LT-SA',
        name: 'Šiauliu Apskritis',
      },
      {
        code: 'LT-TA',
        name: 'Tauragės Apskritis',
      },
      {
        code: 'LT-TE',
        name: 'Telšiu Apskritis',
      },
      {
        code: 'LT-UT',
        name: 'Utenos Apskritis',
      },
      {
        code: 'LT-VL',
        name: 'Vilniaus Apskritis',
      },
    ],
  },
  {
    name: 'Luxembourg',
    alpha_3: 'LUX',
    country_code: '442',
    divisions: [
      {
        code: 'LU-D',
        name: 'Diekirch',
      },
      {
        code: 'LU-G',
        name: 'Grevenmacher',
      },
      {
        code: 'LU-L',
        name: 'Luxembourg (Luxemburg)',
      },
    ],
  },
  {
    name: 'Macao',
    alpha_3: 'MAC',
    country_code: '446',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Madagascar',
    alpha_3: 'MDG',
    country_code: '450',
    divisions: [
      {
        code: 'MG-T',
        name: 'Antananarivo',
      },
      {
        code: 'MG-D',
        name: 'Antsiranana',
      },
      {
        code: 'MG-F',
        name: 'Fianarantsoa',
      },
      {
        code: 'MG-M',
        name: 'Mahajanga',
      },
      {
        code: 'MG-A',
        name: 'Toamasina',
      },
      {
        code: 'MG-U',
        name: 'Toliara',
      },
    ],
  },
  {
    name: 'Malawi',
    alpha_3: 'MWI',
    country_code: '454',
    divisions: [
      {
        code: 'MW-C',
        name: 'Central',
      },
      {
        code: 'MW-DE',
        name: 'Dedza',
      },
      {
        code: 'MW-DO',
        name: 'Dowa',
      },
      {
        code: 'MW-KS',
        name: 'Kasungu',
      },
      {
        code: 'MW-LI',
        name: 'Lilongwe',
      },
      {
        code: 'MW-MC',
        name: 'Mchinji',
      },
      {
        code: 'MW-NK',
        name: 'Nkhotakota',
      },
      {
        code: 'MW-NU',
        name: 'Ntcheu',
      },
      {
        code: 'MW-NI',
        name: 'Ntchisi',
      },
      {
        code: 'MW-SA',
        name: 'Salima',
      },
      {
        code: 'MW-N',
        name: 'Northern',
      },
      {
        code: 'MW-CT',
        name: 'Chitipa',
      },
      {
        code: 'MW-KR',
        name: 'Karonga',
      },
      {
        code: 'MW-MZ',
        name: 'Mzimba',
      },
      {
        code: 'MW-NB',
        name: 'Nkhata Bay',
      },
      {
        code: 'MW-RU',
        name: 'Rumphi',
      },
      {
        code: 'MW-S',
        name: 'Southern',
      },
      {
        code: 'MW-BL',
        name: 'Blantyre',
      },
      {
        code: 'MW-CK',
        name: 'Chikwawa',
      },
      {
        code: 'MW-CR',
        name: 'Chiradzulu',
      },
      {
        code: 'MW-MH',
        name: 'Machinga',
      },
      {
        code: 'MW-MG',
        name: 'Mangochi',
      },
      {
        code: 'MW-MU',
        name: 'Mulanje',
      },
      {
        code: 'MW-MW',
        name: 'Mwanza',
      },
      {
        code: 'MW-NS',
        name: 'Nsanje',
      },
      {
        code: 'MW-TH',
        name: 'Thyolo',
      },
      {
        code: 'MW-ZO',
        name: 'Zomba',
      },
    ],
  },
  {
    name: 'Malaysia',
    alpha_3: 'MYS',
    country_code: '458',
    divisions: [
      {
        code: 'MY-W',
        name: 'Wilayah Persekutuan Kuala Lumpur',
      },
      {
        code: 'MY-L',
        name: 'Wilayah Persekutuan Labuan',
      },
      {
        code: 'MY-J',
        name: 'Johor',
      },
      {
        code: 'MY-K',
        name: 'Kedah',
      },
      {
        code: 'MY-D',
        name: 'Kelantan',
      },
      {
        code: 'MY-M',
        name: 'Melaka',
      },
      {
        code: 'MY-N',
        name: 'Negeri Sembilan',
      },
      {
        code: 'MY-C',
        name: 'Pahang',
      },
      {
        code: 'MY-A',
        name: 'Perak',
      },
      {
        code: 'MY-R',
        name: 'Perlis',
      },
      {
        code: 'MY-P',
        name: 'Pulau Pinang',
      },
      {
        code: 'MY-SA',
        name: 'Sabah',
      },
      {
        code: 'MY-SK',
        name: 'Sarawak',
      },
      {
        code: 'MY-B',
        name: 'Selangor',
      },
      {
        code: 'MY-T',
        name: 'Terengganu',
      },
    ],
  },
  {
    name: 'Maldives',
    alpha_3: 'MDV',
    country_code: '462',
    divisions: [
      {
        code: 'MV-MLE',
        name: 'Male',
      },
      {
        code: 'MV-02',
        name: 'Alif',
      },
      {
        code: 'MV-20',
        name: 'Baa',
      },
      {
        code: 'MV-17',
        name: 'Dhaalu',
      },
      {
        code: 'MV-14',
        name: 'Faafu',
      },
      {
        code: 'MV-27',
        name: 'Gaaf Alif',
      },
      {
        code: 'MV-28',
        name: 'Gaafu Dhaalu',
      },
      {
        code: 'MV-29',
        name: 'Gnaviyani',
      },
      {
        code: 'MV-07',
        name: 'Haa Alif',
      },
      {
        code: 'MV-23',
        name: 'Haa Dhaalu',
      },
      {
        code: 'MV-26',
        name: 'Kaafu',
      },
      {
        code: 'MV-05',
        name: 'Laamu',
      },
      {
        code: 'MV-03',
        name: 'Lhaviyani',
      },
      {
        code: 'MV-12',
        name: 'Meemu',
      },
      {
        code: 'MV-25',
        name: 'Noonu',
      },
      {
        code: 'MV-13',
        name: 'Raa',
      },
      {
        code: 'MV-01',
        name: 'Seenu',
      },
      {
        code: 'MV-24',
        name: 'Shaviyani',
      },
      {
        code: 'MV-08',
        name: 'Thaa',
      },
      {
        code: 'MV-04',
        name: 'Vaavu',
      },
    ],
  },
  {
    name: 'Mali',
    alpha_3: 'MLI',
    country_code: '466',
    divisions: [
      {
        code: 'ML-BKO',
        name: 'Bamako',
      },
      {
        code: 'ML-7',
        name: 'Gao',
      },
      {
        code: 'ML-1',
        name: 'Kayes',
      },
      {
        code: 'ML-8',
        name: 'Kidal',
      },
      {
        code: 'ML-2',
        name: 'Koulikoro',
      },
      {
        code: 'ML-5',
        name: 'Mopti',
      },
      {
        code: 'ML-4',
        name: 'Ségou',
      },
      {
        code: 'ML-3',
        name: 'Sikasso',
      },
      {
        code: 'ML-6',
        name: 'Tombouctou',
      },
    ],
  },
  {
    name: 'Malta',
    alpha_3: 'MLT',
    country_code: '470',
    divisions: [
      {
        code: 'MT-MT',
        name: 'Malta',
      },
    ],
  },
  {
    name: 'Marshall Islands',
    alpha_3: 'MHL',
    country_code: '584',
    divisions: [
      {
        code: 'MH-L',
        name: 'Ralik chain',
      },
      {
        code: 'MH-ALL',
        name: 'Ailinglapalap',
      },
      {
        code: 'MH-EBO',
        name: 'Ebon',
      },
      {
        code: 'MH-ENI',
        name: 'Eniwetok',
      },
      {
        code: 'MH-JAL',
        name: 'Jaluit',
      },
      {
        code: 'MH-KIL',
        name: 'Kili',
      },
      {
        code: 'MH-KWA',
        name: 'Kwajalein',
      },
      {
        code: 'MH-LAE',
        name: 'Lae',
      },
      {
        code: 'MH-LIB',
        name: 'Lib',
      },
      {
        code: 'MH-NMK',
        name: 'Namorik',
      },
      {
        code: 'MH-NMU',
        name: 'Namu',
      },
      {
        code: 'MH-RON',
        name: 'Rongelap',
      },
      {
        code: 'MH-UJA',
        name: 'Ujae',
      },
      {
        code: 'MH-UJL',
        name: 'Ujelang',
      },
      {
        code: 'MH-WTH',
        name: 'Wotho',
      },
      {
        code: 'MH-T',
        name: 'Ratak chain',
      },
      {
        code: 'MH-ALK',
        name: 'Ailuk',
      },
      {
        code: 'MH-ARN',
        name: 'Arno',
      },
      {
        code: 'MH-AUR',
        name: 'Aur',
      },
      {
        code: 'MH-LIK',
        name: 'Likiep',
      },
      {
        code: 'MH-MAJ',
        name: 'Majuro',
      },
      {
        code: 'MH-MAL',
        name: 'Maloelap',
      },
      {
        code: 'MH-MEJ',
        name: 'Mejit',
      },
      {
        code: 'MH-MIL',
        name: 'Mili',
      },
      {
        code: 'MH-UTI',
        name: 'Utirik',
      },
      {
        code: 'MH-WTJ',
        name: 'Wotje',
      },
    ],
  },
  {
    name: 'Martinique',
    alpha_3: 'MTQ',
    country_code: '474',
    divisions: [
      {
        code: 'MQ-MQ',
        name: 'Martinique',
      },
    ],
  },
  {
    name: 'Mauritania',
    alpha_3: 'MRT',
    country_code: '478',
    divisions: [
      {
        code: 'MR-NKC',
        name: 'Nouakchott',
      },
      {
        code: 'MR-07',
        name: 'Adrar',
      },
      {
        code: 'MR-03',
        name: 'Assaba',
      },
      {
        code: 'MR-05',
        name: 'Brakna',
      },
      {
        code: 'MR-08',
        name: 'Dakhlet Nouādhibou',
      },
      {
        code: 'MR-04',
        name: 'Gorgol',
      },
      {
        code: 'MR-10',
        name: 'Guidimaka',
      },
      {
        code: 'MR-01',
        name: 'Hodh ech Chargui',
      },
      {
        code: 'MR-02',
        name: 'Hodh el Gharbi',
      },
      {
        code: 'MR-12',
        name: 'Inchiri',
      },
      {
        code: 'MR-09',
        name: 'Tagant',
      },
      {
        code: 'MR-11',
        name: 'Tiris Zemmour',
      },
      {
        code: 'MR-06',
        name: 'Trarza',
      },
    ],
  },
  {
    name: 'Mauritius',
    alpha_3: 'MUS',
    country_code: '480',
    divisions: [
      {
        code: 'MU-BR',
        name: 'Beau Bassin-Rose Hill',
      },
      {
        code: 'MU-CU',
        name: 'Curepipe',
      },
      {
        code: 'MU-PL',
        name: 'Port Louis',
      },
      {
        code: 'MU-QB',
        name: 'Quatre Bornes',
      },
      {
        code: 'MU-VP',
        name: 'Vacoas-Phoenix',
      },
      {
        code: 'MU-BL',
        name: 'Black River',
      },
      {
        code: 'MU-FL',
        name: 'Flacq',
      },
      {
        code: 'MU-GP',
        name: 'Grand Port',
      },
      {
        code: 'MU-MO',
        name: 'Moka',
      },
      {
        code: 'MU-PA',
        name: 'Pamplemousses',
      },
      {
        code: 'MU-PW',
        name: 'Plaines Wilhems',
      },
      {
        code: 'MU-RR',
        name: 'Rivière du Rempart',
      },
      {
        code: 'MU-SA',
        name: 'Savanne',
      },
      {
        code: 'MU-AG',
        name: 'Agalega Islands',
      },
      {
        code: 'MU-CC',
        name: 'Cargados Carajos Shoals [Saint Brandon Islands]',
      },
      {
        code: 'MU-RO',
        name: 'Rodrigues Island',
      },
    ],
  },
  {
    name: 'Mayotte',
    alpha_3: 'MYT',
    country_code: '175',
    divisions: [
      {
        code: 'YT-YT',
        name: 'Mayotte',
      },
    ],
  },
  {
    name: 'Mexico',
    alpha_3: 'MEX',
    country_code: '484',
    divisions: [
      {
        code: 'MX-DIF',
        name: 'Distrito Federal',
      },
      {
        code: 'MX-AGU',
        name: 'Aguascalientes',
      },
      {
        code: 'MX-BCN',
        name: 'Baja California',
      },
      {
        code: 'MX-BCS',
        name: 'Baja California Sur',
      },
      {
        code: 'MX-CAM',
        name: 'Campeche',
      },
      {
        code: 'MX-COA',
        name: 'Coahuila',
      },
      {
        code: 'MX-COL',
        name: 'Colima',
      },
      {
        code: 'MX-CHP',
        name: 'Chiapas',
      },
      {
        code: 'MX-CHH',
        name: 'Chihuahua',
      },
      {
        code: 'MX-DUR',
        name: 'Durango',
      },
      {
        code: 'MX-GRO',
        name: 'Guerrero',
      },
      {
        code: 'MX-GUA',
        name: 'Guanajuato',
      },
      {
        code: 'MX-HID',
        name: 'Hidalgo',
      },
      {
        code: 'MX-JAL',
        name: 'Jalisco',
      },
      {
        code: 'MX-MEX',
        name: 'México',
      },
      {
        code: 'MX-MIC',
        name: 'Michoacán',
      },
      {
        code: 'MX-MOR',
        name: 'Morelos',
      },
      {
        code: 'MX-NAY',
        name: 'Nayarit',
      },
      {
        code: 'MX-NLE',
        name: 'Nuevo León',
      },
      {
        code: 'MX-OAX',
        name: 'Oaxaca',
      },
      {
        code: 'MX-PUE',
        name: 'Puebla',
      },
      {
        code: 'MX-QUE',
        name: 'Queretaro',
      },
      {
        code: 'MX-ROO',
        name: 'Quintana Roo',
      },
      {
        code: 'MX-SLP',
        name: 'San Luis Potosí',
      },
      {
        code: 'MX-SIN',
        name: 'Sinaloa',
      },
      {
        code: 'MX-SON',
        name: 'Sonora',
      },
      {
        code: 'MX-TAB',
        name: 'Tabasco',
      },
      {
        code: 'MX-TAM',
        name: 'Tamaulipas',
      },
      {
        code: 'MX-TLA',
        name: 'Tlaxcala',
      },
      {
        code: 'MX-VER',
        name: 'Veracruz',
      },
      {
        code: 'MX-YUC',
        name: 'Yucatán',
      },
      {
        code: 'MX-ZAC',
        name: 'Zacatecas',
      },
    ],
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha_3: 'FSM',
    country_code: '583',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Moldova, Republic of',
    alpha_3: 'MDA',
    country_code: '498',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Monaco',
    alpha_3: 'MCO',
    country_code: '492',
    divisions: [
      {
        code: 'MC-MC',
        name: 'Monaco',
      },
    ],
  },
  {
    name: 'Mongolia',
    alpha_3: 'MNG',
    country_code: '496',
    divisions: [
      {
        code: 'MN-1',
        name: 'Ulaanbaatar',
      },
      {
        code: 'MN-073',
        name: 'Arhangay',
      },
      {
        code: 'MN-069',
        name: 'Bayanhongor',
      },
      {
        code: 'MN-071',
        name: 'Bayan-Ölgiy',
      },
      {
        code: 'MN-067',
        name: 'Bulgan',
      },
      {
        code: 'MN-037',
        name: 'Darhan uul',
      },
      {
        code: 'MN-061',
        name: 'Dornod',
      },
      {
        code: 'MN-063',
        name: 'Dornogovĭ',
      },
      {
        code: 'MN-059',
        name: 'Dundgovĭ',
      },
      {
        code: 'MN-057',
        name: 'Dzavhan',
      },
      {
        code: 'MN-065',
        name: 'Govĭ-Altay',
      },
      {
        code: 'MN-064',
        name: 'Govĭ-Sümber',
      },
      {
        code: 'MN-039',
        name: 'Hentiy',
      },
      {
        code: 'MN-043',
        name: 'Hovd',
      },
      {
        code: 'MN-041',
        name: 'Hövsgöl',
      },
      {
        code: 'MN-053',
        name: 'Ömnögovĭ',
      },
      {
        code: 'MN-035',
        name: 'Orhon',
      },
      {
        code: 'MN-055',
        name: 'Övörhangay',
      },
      {
        code: 'MN-049',
        name: 'Selenge',
      },
      {
        code: 'MN-051',
        name: 'Sühbaatar',
      },
      {
        code: 'MN-047',
        name: 'Töv',
      },
      {
        code: 'MN-046',
        name: 'Uvs',
      },
    ],
  },
  {
    name: 'Montenegro',
    alpha_3: 'MNE',
    country_code: '499',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Montserrat',
    alpha_3: 'MSR',
    country_code: '500',
    divisions: [
      {
        code: 'MS-MS',
        name: 'Montserrat',
      },
    ],
  },
  {
    name: 'Morocco',
    alpha_3: 'MAR',
    country_code: '504',
    divisions: [
      {
        code: 'MA-CE',
        name: 'Centre',
      },
      {
        code: 'MA-AZI',
        name: 'Azilal',
      },
      {
        code: 'MA-BEM',
        name: 'Beni Mellal',
      },
      {
        code: 'MA-BES',
        name: 'Ben Slimane',
      },
      {
        code: 'MA-BOM',
        name: 'Boulemane',
      },
      {
        code: 'MA-CAS',
        name: 'Casablanca [Dar el Beïda]',
      },
      {
        code: 'MA-JDI',
        name: 'El Jadida',
      },
      {
        code: 'MA-KHO',
        name: 'Khouribga',
      },
      {
        code: 'MA-SET',
        name: 'Settat',
      },
      {
        code: 'MA-CN',
        name: 'Centre-Nord',
      },
      {
        code: 'MA-HOC',
        name: 'Al Hoceïma',
      },
      {
        code: 'MA-FES',
        name: 'Fès',
      },
      {
        code: 'MA-SEF',
        name: 'Sefrou',
      },
      {
        code: 'MA-TAO',
        name: 'Taounate',
      },
      {
        code: 'MA-TAZ',
        name: 'Taza',
      },
      {
        code: 'MA-CS',
        name: 'Centre-Sud',
      },
      {
        code: 'MA-HAJ',
        name: 'El Hajeb',
      },
      {
        code: 'MA-ERR',
        name: 'Errachidia',
      },
      {
        code: 'MA-IFR',
        name: 'Ifrane',
      },
      {
        code: 'MA-KHN',
        name: 'Khenifra',
      },
      {
        code: 'MA-MEK',
        name: 'Meknès',
      },
      {
        code: 'MA-ES',
        name: 'Est',
      },
      {
        code: 'MA-BER',
        name: 'Berkane',
      },
      {
        code: 'MA-FIG',
        name: 'Figuig',
      },
      {
        code: 'MA-IRA',
        name: 'Jrada',
      },
      {
        code: 'MA-NAD',
        name: 'Nador',
      },
      {
        code: 'MA-OUJ',
        name: 'Oujda',
      },
      {
        code: 'MA-NO',
        name: 'Nord-Ouest',
      },
      {
        code: 'MA-CHE',
        name: 'Chefchaouene',
      },
      {
        code: 'MA-KEN',
        name: 'Kénitra',
      },
      {
        code: 'MA-KHE',
        name: 'Khemisset',
      },
      {
        code: 'MA-LAR',
        name: 'Larache',
      },
      {
        code: 'MA-RBA',
        name: 'Rabat-Salé',
      },
      {
        code: 'MA-SIK',
        name: 'Sidi Kacem',
      },
      {
        code: 'MA-TNG',
        name: 'Tanger',
      },
      {
        code: 'MA-TET',
        name: 'Tétouan',
      },
      {
        code: 'MA-SU',
        name: 'Sud',
      },
      {
        code: 'MA-AGD',
        name: 'Agadir',
      },
      {
        code: 'MA-BAH',
        name: 'Aït Baha',
      },
      {
        code: 'MA-MEL',
        name: 'Aït Melloul',
      },
      {
        code: 'MA-ASZ',
        name: 'Assa-Zag',
      },
      {
        code: 'MA-BOD',
        name: 'Boujdour (EH)',
      },
      {
        code: 'MA-ESM',
        name: 'Es Semara (EH)',
      },
      {
        code: 'MA-GUE',
        name: 'Guelmim',
      },
      {
        code: 'MA-LAA',
        name: 'Laayoune (EH)',
      },
      {
        code: 'MA-OUA',
        name: 'Ouarzazate',
      },
      {
        code: 'MA-OUD',
        name: 'Oued ed Dahab (EH)',
      },
      {
        code: 'MA-TNT',
        name: 'Tan-Tan',
      },
      {
        code: 'MA-TAR',
        name: 'Taroudannt',
      },
      {
        code: 'MA-TAT',
        name: 'Tata',
      },
      {
        code: 'MA-TIZ',
        name: 'Tiznit',
      },
      {
        code: 'MA-TS',
        name: 'Tensift',
      },
      {
        code: 'MA-HAO',
        name: 'Al Haouz',
      },
      {
        code: 'MA-CHI',
        name: 'Chichaoua',
      },
      {
        code: 'MA-ESI',
        name: 'Essaouira',
      },
      {
        code: 'MA-KES',
        name: 'Kelaat Sraghna',
      },
      {
        code: 'MA-MAR',
        name: 'Marrakech',
      },
      {
        code: 'MA-SAF',
        name: 'Safi',
      },
    ],
  },
  {
    name: 'Mozambique',
    alpha_3: 'MOZ',
    country_code: '508',
    divisions: [
      {
        code: 'MZ-MPM',
        name: 'Maputo',
      },
      {
        code: 'MZ-P',
        name: 'Cabo Delgado',
      },
      {
        code: 'MZ-G',
        name: 'Gaza',
      },
      {
        code: 'MZ-I',
        name: 'Inhambane',
      },
      {
        code: 'MZ-B',
        name: 'Manica',
      },
      {
        code: 'MZ-L',
        name: 'Maputo',
      },
      {
        code: 'MZ-N',
        name: 'Nampula',
      },
      {
        code: 'MZ-A',
        name: 'Niassa',
      },
      {
        code: 'MZ-S',
        name: 'Sofala',
      },
      {
        code: 'MZ-T',
        name: 'Tete',
      },
      {
        code: 'MZ-Q',
        name: 'Zambézia',
      },
    ],
  },
  {
    name: 'Myanmar',
    alpha_3: 'MMR',
    country_code: '104',
    divisions: [
      {
        code: 'MM-07',
        name: 'Ayeyarwady',
      },
      {
        code: 'MM-02',
        name: 'Bago',
      },
      {
        code: 'MM-03',
        name: 'Magway',
      },
      {
        code: 'MM-04',
        name: 'Mandalay',
      },
      {
        code: 'MM-01',
        name: 'Sagaing',
      },
      {
        code: 'MM-05',
        name: 'Tanintharyi',
      },
      {
        code: 'MM-06',
        name: 'Yangon',
      },
      {
        code: 'MM-14',
        name: 'Chin',
      },
      {
        code: 'MM-11',
        name: 'Kachin',
      },
      {
        code: 'MM-12',
        name: 'Kayah',
      },
      {
        code: 'MM-13',
        name: 'Kayin',
      },
      {
        code: 'MM-15',
        name: 'Mon',
      },
      {
        code: 'MM-16',
        name: 'Rakhine',
      },
      {
        code: 'MM-17',
        name: 'Shan',
      },
    ],
  },
  {
    name: 'Namibia',
    alpha_3: 'NAM',
    country_code: '516',
    divisions: [
      {
        code: 'NA-CA',
        name: 'Caprivi',
      },
      {
        code: 'NA-ER',
        name: 'Erongo',
      },
      {
        code: 'NA-HA',
        name: 'Hardap',
      },
      {
        code: 'NA-KA',
        name: 'Karas',
      },
      {
        code: 'NA-KH',
        name: 'Khomas',
      },
      {
        code: 'NA-KU',
        name: 'Kunene',
      },
      {
        code: 'NA-OW',
        name: 'Ohangwena',
      },
      {
        code: 'NA-OK',
        name: 'Okavango',
      },
      {
        code: 'NA-OH',
        name: 'Omaheke',
      },
      {
        code: 'NA-OS',
        name: 'Omusati',
      },
      {
        code: 'NA-ON',
        name: 'Oshana',
      },
      {
        code: 'NA-OT',
        name: 'Oshikoto',
      },
      {
        code: 'NA-OD',
        name: 'Otjozondjupa',
      },
    ],
  },
  {
    name: 'Nauru',
    alpha_3: 'NRU',
    country_code: '520',
    divisions: [
      {
        code: 'NR-NR',
        name: 'Nauru',
      },
    ],
  },
  {
    name: 'Nepal',
    alpha_3: 'NPL',
    country_code: '524',
    divisions: [
      {
        code: 'NP-1',
        name: 'Madhyamanchal',
      },
      {
        code: 'NP-BA',
        name: 'Bagmati',
      },
      {
        code: 'NP-JA',
        name: 'Janakpur',
      },
      {
        code: 'NP-NA',
        name: 'Narayani',
      },
      {
        code: 'NP-2',
        name: 'Madhya Pashchimanchal',
      },
      {
        code: 'NP-BH',
        name: 'Bheri',
      },
      {
        code: 'NP-KA',
        name: 'Karnali',
      },
      {
        code: 'NP-RA',
        name: 'Rapti',
      },
      {
        code: 'NP-3',
        name: 'Pashchimanchal',
      },
      {
        code: 'NP-DH',
        name: 'Dhawalagiri',
      },
      {
        code: 'NP-GA',
        name: 'Gandaki',
      },
      {
        code: 'NP-LU',
        name: 'Lumbini',
      },
      {
        code: 'NP-4',
        name: 'Purwanchal',
      },
      {
        code: 'NP-KO',
        name: 'Kosi [Koshi]',
      },
      {
        code: 'NP-ME',
        name: 'Mechi',
      },
      {
        code: 'NP-SA',
        name: 'Sagarmatha',
      },
      {
        code: 'NP-5',
        name: 'Sudur Pashchimanchal',
      },
      {
        code: 'NP-MA',
        name: 'Mahakali',
      },
      {
        code: 'NP-SE',
        name: 'Seti',
      },
    ],
  },
  {
    name: 'Netherlands',
    alpha_3: 'NLD',
    country_code: '528',
    divisions: [
      {
        code: 'NL-DR',
        name: 'Drenthe',
      },
      {
        code: 'NL-FL',
        name: 'Flevoland',
      },
      {
        code: 'NL-FR',
        name: 'Friesland',
      },
      {
        code: 'NL-GE',
        name: 'Gelderland',
      },
      {
        code: 'NL-GR',
        name: 'Groningen',
      },
      {
        code: 'NL-LI',
        name: 'Limburg',
      },
      {
        code: 'NL-NB',
        name: 'Noord-Brabant',
      },
      {
        code: 'NL-NH',
        name: 'Noord-Holland',
      },
      {
        code: 'NL-OV',
        name: 'Overijssel',
      },
      {
        code: 'NL-UT',
        name: 'Utrecht',
      },
      {
        code: 'NL-ZE',
        name: 'Zeeland',
      },
      {
        code: 'NL-ZH',
        name: 'Zuid-Holland',
      },
    ],
  },
  {
    name: 'New Caledonia',
    alpha_3: 'NCL',
    country_code: '540',
    divisions: [
      {
        code: 'NC-NC',
        name: 'New Caledonia',
      },
    ],
  },
  {
    name: 'New Zealand',
    alpha_3: 'NZL',
    country_code: '554',
    divisions: [
      {
        code: 'NZ-N',
        name: 'North Island',
      },
      {
        code: 'NZ-AUK',
        name: 'Auckland',
      },
      {
        code: 'NZ-BOP',
        name: 'Bay of Plenty',
      },
      {
        code: 'NZ-GIS',
        name: 'Gisborne',
      },
      {
        code: 'NZ-HKB',
        name: "Hawkes's Bay",
      },
      {
        code: 'NZ-MWT',
        name: 'Manawatu-Wanganui',
      },
      {
        code: 'NZ-NTL',
        name: 'Northland',
      },
      {
        code: 'NZ-TKI',
        name: 'Taranaki',
      },
      {
        code: 'NZ-WKO',
        name: 'Waikato',
      },
      {
        code: 'NZ-WGN',
        name: 'Wellington',
      },
      {
        code: 'NZ-S',
        name: 'South Island',
      },
      {
        code: 'NZ-CAN',
        name: 'Canterbury',
      },
      {
        code: 'NZ-MBH',
        name: 'Marlborough',
      },
      {
        code: 'NZ-NSN',
        name: 'Nelson',
      },
      {
        code: 'NZ-OTA',
        name: 'Otago',
      },
      {
        code: 'NZ-STL',
        name: 'Southland',
      },
      {
        code: 'NZ-TAS',
        name: 'Tasman',
      },
      {
        code: 'NZ-WTC',
        name: 'West Coast',
      },
    ],
  },
  {
    name: 'Nicaragua',
    alpha_3: 'NIC',
    country_code: '558',
    divisions: [
      {
        code: 'NI-BO',
        name: 'Boaco',
      },
      {
        code: 'NI-CA',
        name: 'Carazo',
      },
      {
        code: 'NI-CI',
        name: 'Chinandega',
      },
      {
        code: 'NI-CO',
        name: 'Chontales',
      },
      {
        code: 'NI-ES',
        name: 'Estelí',
      },
      {
        code: 'NI-GR',
        name: 'Granada',
      },
      {
        code: 'NI-JI',
        name: 'Jinotega',
      },
      {
        code: 'NI-LE',
        name: 'León',
      },
      {
        code: 'NI-MD',
        name: 'Madriz',
      },
      {
        code: 'NI-MN',
        name: 'Manaqua',
      },
      {
        code: 'NI-MS',
        name: 'Masaya',
      },
      {
        code: 'NI-MT',
        name: 'Matagalpa',
      },
      {
        code: 'NI-NS',
        name: 'Nueva Segovia',
      },
      {
        code: 'NI-SJ',
        name: 'Río San Juan',
      },
      {
        code: 'NI-RI',
        name: 'Rivas',
      },
      {
        code: 'NI-ZE',
        name: 'Zelaya',
      },
    ],
  },
  {
    name: 'Niger',
    alpha_3: 'NER',
    country_code: '562',
    divisions: [
      {
        code: 'NE-8',
        name: 'Niamey',
      },
      {
        code: 'NE-1',
        name: 'Agadez',
      },
      {
        code: 'NE-2',
        name: 'Diffa',
      },
      {
        code: 'NE-3',
        name: 'Dosso',
      },
      {
        code: 'NE-4',
        name: 'Maradi',
      },
      {
        code: 'NE-5',
        name: 'Tahoua',
      },
      {
        code: 'NE-6',
        name: 'Tillaberi',
      },
      {
        code: 'NE-7',
        name: 'Zinder',
      },
    ],
  },
  {
    name: 'Nigeria',
    alpha_3: 'NGA',
    country_code: '566',
    divisions: [
      {
        code: 'NG-FC',
        name: 'Abuja Capital Territory',
      },
      {
        code: 'NG-AB',
        name: 'Abia',
      },
      {
        code: 'NG-AD',
        name: 'Adamawa',
      },
      {
        code: 'NG-AK',
        name: 'Akwa Ibom',
      },
      {
        code: 'NG-AN',
        name: 'Anambra',
      },
      {
        code: 'NG-BA',
        name: 'Bauchi',
      },
      {
        code: 'NG-BE',
        name: 'Benue',
      },
      {
        code: 'NG-BO',
        name: 'Borno',
      },
      {
        code: 'NG-CR',
        name: 'Cross River',
      },
      {
        code: 'NG-DE',
        name: 'Delta',
      },
      {
        code: 'NG-ED',
        name: 'Edo',
      },
      {
        code: 'NG-EN',
        name: 'Enugu',
      },
      {
        code: 'NG-IM',
        name: 'Imo',
      },
      {
        code: 'NG-JI',
        name: 'Jigawa',
      },
      {
        code: 'NG-KD',
        name: 'Kaduna',
      },
      {
        code: 'NG-KN',
        name: 'Kano',
      },
      {
        code: 'NG-KT',
        name: 'Katsina',
      },
      {
        code: 'NG-KE',
        name: 'Kebbi',
      },
      {
        code: 'NG-KO',
        name: 'Kogi',
      },
      {
        code: 'NG-KW',
        name: 'Kwara',
      },
      {
        code: 'NG-LA',
        name: 'Lagos',
      },
      {
        code: 'NG-NI',
        name: 'Niger',
      },
      {
        code: 'NG-OG',
        name: 'Ogun',
      },
      {
        code: 'NG-ON',
        name: 'Ondo',
      },
      {
        code: 'NG-OS',
        name: 'Osun',
      },
      {
        code: 'NG-OY',
        name: 'Oyo',
      },
      {
        code: 'NG-PL',
        name: 'Plateau',
      },
      {
        code: 'NG-RI',
        name: 'Rivers',
      },
      {
        code: 'NG-SO',
        name: 'Sokoto',
      },
      {
        code: 'NG-TA',
        name: 'Taraba',
      },
      {
        code: 'NG-YO',
        name: 'Yobe',
      },
    ],
  },
  {
    name: 'Niue',
    alpha_3: 'NIU',
    country_code: '570',
    divisions: [
      {
        code: 'NU-NU',
        name: 'Niue',
      },
    ],
  },
  {
    name: 'Norfolk Island',
    alpha_3: 'NFK',
    country_code: '574',
    divisions: [
      {
        code: 'NF-NF',
        name: 'Norfolk Island',
      },
    ],
  },
  {
    name: 'North Macedonia',
    alpha_3: 'MKD',
    country_code: '807',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Northern Mariana Islands',
    alpha_3: 'MNP',
    country_code: '580',
    divisions: [
      {
        code: 'MP-MP',
        name: 'Northern Mariana Islands',
      },
    ],
  },
  {
    name: 'Norway',
    alpha_3: 'NOR',
    country_code: '578',
    divisions: [
      {
        code: 'NO-02',
        name: 'Akershus',
      },
      {
        code: 'NO-09',
        name: 'Aust-Agder',
      },
      {
        code: 'NO-06',
        name: 'Buskerud',
      },
      {
        code: 'NO-20',
        name: 'Finnmark',
      },
      {
        code: 'NO-04',
        name: 'Hedmark',
      },
      {
        code: 'NO-12',
        name: 'Hordaland',
      },
      {
        code: 'NO-15',
        name: 'Møre og Romsdal',
      },
      {
        code: 'NO-18',
        name: 'Nordland',
      },
      {
        code: 'NO-17',
        name: 'Nord-Trøndelag',
      },
      {
        code: 'NO-05',
        name: 'Oppland',
      },
      {
        code: 'NO-03',
        name: 'Oslo',
      },
      {
        code: 'NO-11',
        name: 'Rogaland',
      },
      {
        code: 'NO-14',
        name: 'Sogn og Fjordane',
      },
      {
        code: 'NO-16',
        name: 'Sør-Trøndelag',
      },
      {
        code: 'NO-08',
        name: 'Telemark',
      },
      {
        code: 'NO-19',
        name: 'Troms',
      },
      {
        code: 'NO-10',
        name: 'Vest-Agder',
      },
      {
        code: 'NO-07',
        name: 'Vestfold',
      },
      {
        code: 'NO-01',
        name: 'Østfold',
      },
      {
        code: 'NO-22',
        name: 'Jan Mayen (Arctic Region)',
      },
      {
        code: 'NO-21',
        name: 'Svalbard (Arctic Region)',
      },
    ],
  },
  {
    name: 'Oman',
    alpha_3: 'OMN',
    country_code: '512',
    divisions: [
      {
        code: 'OM-DA',
        name: 'Ad Dākhilīyah',
      },
      {
        code: 'OM-BA',
        name: 'Al Bāţinah',
      },
      {
        code: 'OM-JA',
        name: 'Al Janūbīyah [Zufār]',
      },
      {
        code: 'OM-WU',
        name: 'Al Wusţā',
      },
      {
        code: 'OM-SH',
        name: 'Ash Sharqīyah',
      },
      {
        code: 'OM-ZA',
        name: 'Az Zāhirah',
      },
      {
        code: 'OM-MA',
        name: 'Masqaţ',
      },
      {
        code: 'OM-MU',
        name: 'Musandam',
      },
    ],
  },
  {
    name: 'Pakistan',
    alpha_3: 'PAK',
    country_code: '586',
    divisions: [
      {
        code: 'PK-IS',
        name: 'Islamabad',
      },
      {
        code: 'PK-BA',
        name: 'Baluchistan (Balochistān)',
      },
      {
        code: 'PK-NW',
        name: 'North-West Frontier',
      },
      {
        code: 'PK-PB',
        name: 'Punjab',
      },
      {
        code: 'PK-SD',
        name: 'Sind (Sindh)',
      },
      {
        code: 'PK-TA',
        name: 'Federally Administered Tribal Areas',
      },
      {
        code: 'PK-JK',
        name: 'Azad Kashmir',
      },
      {
        code: 'PK-NA',
        name: 'Northern Areas',
      },
    ],
  },
  {
    name: 'Palau',
    alpha_3: 'PLW',
    country_code: '585',
    divisions: [
      {
        code: 'PW-PW',
        name: 'Palau',
      },
    ],
  },
  {
    name: 'Palestine, State of',
    alpha_3: 'PSE',
    country_code: '275',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Panama',
    alpha_3: 'PAN',
    country_code: '591',
    divisions: [
      {
        code: 'PA-1',
        name: 'Botas del Toro',
      },
      {
        code: 'PA-2',
        name: 'Coclé',
      },
      {
        code: 'PA-3',
        name: 'Colón',
      },
      {
        code: 'PA-4',
        name: 'Chiriquī',
      },
      {
        code: 'PA-5',
        name: 'Darién',
      },
      {
        code: 'PA-6',
        name: 'Herrera',
      },
      {
        code: 'PA-7',
        name: 'Los Santos',
      },
      {
        code: 'PA-8',
        name: 'Panamá',
      },
      {
        code: 'PA-9',
        name: 'Veraguas',
      },
      {
        code: 'PA-0',
        name: 'Comarca de San Blas',
      },
    ],
  },
  {
    name: 'Papua New Guinea',
    alpha_3: 'PNG',
    country_code: '598',
    divisions: [
      {
        code: 'PG-NCD',
        name: 'National Capital District (Port Moresby)',
      },
      {
        code: 'PG-CPM',
        name: 'Central',
      },
      {
        code: 'PG-CPK',
        name: 'Chimbu',
      },
      {
        code: 'PG-EHG',
        name: 'Eastern Highlands',
      },
      {
        code: 'PG-EBR',
        name: 'East New Britain',
      },
      {
        code: 'PG-ESW',
        name: 'East Sepik',
      },
      {
        code: 'PG-EPW',
        name: 'Enga',
      },
      {
        code: 'PG-GPK',
        name: 'Gulf',
      },
      {
        code: 'PG-MPM',
        name: 'Madang',
      },
      {
        code: 'PG-MRL',
        name: 'Manus',
      },
      {
        code: 'PG-MBA',
        name: 'Milne Bay',
      },
      {
        code: 'PG-MPL',
        name: 'Morobe',
      },
      {
        code: 'PG-NIK',
        name: 'New Ireland',
      },
      {
        code: 'PG-NPP',
        name: 'Northern',
      },
      {
        code: 'PG-NSA',
        name: 'North Solomons',
      },
      {
        code: 'PG-SAN',
        name: 'Sandaun [West Sepik]',
      },
      {
        code: 'PG-SHM',
        name: 'Southern Highlands',
      },
      {
        code: 'PG-WPD',
        name: 'Western',
      },
      {
        code: 'PG-WHM',
        name: 'Western Highlands',
      },
      {
        code: 'PG-WBK',
        name: 'West New Britain',
      },
    ],
  },
  {
    name: 'Paraguay',
    alpha_3: 'PRY',
    country_code: '600',
    divisions: [
      {
        code: 'PY-ASU',
        name: 'Asunción',
      },
      {
        code: 'PY-16',
        name: 'Alto Paraguay',
      },
      {
        code: 'PY-10',
        name: 'Alto Parang',
      },
      {
        code: 'PY-13',
        name: 'Amambay',
      },
      {
        code: 'PY-19',
        name: 'Boquerón',
      },
      {
        code: 'PY-5',
        name: 'Caaguazú',
      },
      {
        code: 'PY-6',
        name: 'Caazapá',
      },
      {
        code: 'PY-14',
        name: 'Canindeyú',
      },
      {
        code: 'PY-11',
        name: 'Central',
      },
      {
        code: 'PY-1',
        name: 'Concepción',
      },
      {
        code: 'PY-3',
        name: 'Cordillera',
      },
      {
        code: 'PY-4',
        name: 'Guairá',
      },
      {
        code: 'PY-7',
        name: 'Itapúa',
      },
      {
        code: 'PY-8',
        name: 'Misiones',
      },
      {
        code: 'PY-12',
        name: 'Neembucú',
      },
      {
        code: 'PY-9',
        name: 'Paraguarī',
      },
      {
        code: 'PY-15',
        name: 'Presidente Hayes',
      },
      {
        code: 'PY-2',
        name: 'San Pedro',
      },
    ],
  },
  {
    name: 'Peru',
    alpha_3: 'PER',
    country_code: '604',
    divisions: [
      {
        code: 'PE-CAL',
        name: 'El Callao',
      },
      {
        code: 'PE-AMA',
        name: 'Amazonas',
      },
      {
        code: 'PE-ANC',
        name: 'Ancash',
      },
      {
        code: 'PE-APU',
        name: 'Apurímac',
      },
      {
        code: 'PE-ARE',
        name: 'Arequipa',
      },
      {
        code: 'PE-AYA',
        name: 'Ayacucho',
      },
      {
        code: 'PE-CAJ',
        name: 'Cajamarca',
      },
      {
        code: 'PE-CUS',
        name: 'Cuzco [Cusco]',
      },
      {
        code: 'PE-HUV',
        name: 'Huancavelica',
      },
      {
        code: 'PE-HUC',
        name: 'Huánuco',
      },
      {
        code: 'PE-ICA',
        name: 'Ica',
      },
      {
        code: 'PE-JUN',
        name: 'Junín',
      },
      {
        code: 'PE-LAL',
        name: 'La Libertad',
      },
      {
        code: 'PE-LAM',
        name: 'Lambayeque',
      },
      {
        code: 'PE-LIM',
        name: 'Lima',
      },
      {
        code: 'PE-LOR',
        name: 'Loreto',
      },
      {
        code: 'PE-MDD',
        name: 'Madre de Dios',
      },
      {
        code: 'PE-MOQ',
        name: 'Moquegua',
      },
      {
        code: 'PE-PAS',
        name: 'Pasco',
      },
      {
        code: 'PE-PIU',
        name: 'Piura',
      },
      {
        code: 'PE-PUN',
        name: 'Puno',
      },
      {
        code: 'PE-SAM',
        name: 'San Martín',
      },
      {
        code: 'PE-TAC',
        name: 'Tacna',
      },
      {
        code: 'PE-TUM',
        name: 'Tumbes',
      },
      {
        code: 'PE-UCA',
        name: 'Ucayali',
      },
    ],
  },
  {
    name: 'Philippines',
    alpha_3: 'PHL',
    country_code: '608',
    divisions: [
      {
        code: 'PH-PH',
        name: 'Philippines',
      },
    ],
  },
  {
    name: 'Pitcairn',
    alpha_3: 'PCN',
    country_code: '612',
    divisions: [
      {
        code: 'PN-PN',
        name: 'Pitcairn',
      },
    ],
  },
  {
    name: 'Poland',
    alpha_3: 'POL',
    country_code: '616',
    divisions: [
      {
        code: 'PL-BP',
        name: 'Biała Podlaska',
      },
      {
        code: 'PL-BK',
        name: 'Białystok',
      },
      {
        code: 'PL-BB',
        name: 'Bielsko',
      },
      {
        code: 'PL-BY',
        name: 'Bydgoszcz',
      },
      {
        code: 'PL-CH',
        name: 'Chełm',
      },
      {
        code: 'PL-CI',
        name: 'Ciechanów',
      },
      {
        code: 'PL-CZ',
        name: 'Czestochowa',
      },
      {
        code: 'PL-EL',
        name: 'Elblag',
      },
      {
        code: 'PL-GD',
        name: 'Gdańsk',
      },
      {
        code: 'PL-GO',
        name: 'Gorzów',
      },
      {
        code: 'PL-JG',
        name: 'Jelenia Gera',
      },
      {
        code: 'PL-KL',
        name: 'Kalisz',
      },
      {
        code: 'PL-KA',
        name: 'Katowice',
      },
      {
        code: 'PL-KI',
        name: 'Kielce',
      },
      {
        code: 'PL-KN',
        name: 'Konin',
      },
      {
        code: 'PL-KO',
        name: 'Koszalin',
      },
      {
        code: 'PL-KR',
        name: 'Kraków',
      },
      {
        code: 'PL-KS',
        name: 'Krosno',
      },
      {
        code: 'PL-LG',
        name: 'Legnica',
      },
      {
        code: 'PL-LE',
        name: 'Leszno',
      },
      {
        code: 'PL-LU',
        name: 'Lublin',
      },
      {
        code: 'PL-LO',
        name: 'Łomia',
      },
      {
        code: 'PL-LD',
        name: 'Łódź',
      },
      {
        code: 'PL-NS',
        name: 'Nowy Sacz',
      },
      {
        code: 'PL-OL',
        name: 'Olsztyn',
      },
      {
        code: 'PL-OP',
        name: 'Opole',
      },
      {
        code: 'PL-OS',
        name: 'Ostrołeka',
      },
      {
        code: 'PL-PI',
        name: 'Piła',
      },
      {
        code: 'PL-PT',
        name: 'Piotrków',
      },
      {
        code: 'PL-PL',
        name: 'Płock',
      },
      {
        code: 'PL-PO',
        name: 'Poznań',
      },
      {
        code: 'PL-PR',
        name: 'Przemyśl',
      },
      {
        code: 'PL-RA',
        name: 'Radom',
      },
      {
        code: 'PL-RZ',
        name: 'Rzeszów',
      },
      {
        code: 'PL-SE',
        name: 'Siedlce',
      },
      {
        code: 'PL-SI',
        name: 'Sieradz',
      },
      {
        code: 'PL-SK',
        name: 'Skierniewice',
      },
      {
        code: 'PL-SL',
        name: 'Słupsk',
      },
      {
        code: 'PL-SU',
        name: 'Suwałki',
      },
      {
        code: 'PL-SZ',
        name: 'Szczecin',
      },
      {
        code: 'PL-TG',
        name: 'Tarnobrzeg',
      },
      {
        code: 'PL-TA',
        name: 'Tarnów',
      },
      {
        code: 'PL-TO',
        name: 'Toruń',
      },
      {
        code: 'PL-WB',
        name: 'Wałbrzych',
      },
      {
        code: 'PL-WA',
        name: 'Warszawa',
      },
      {
        code: 'PL-WL',
        name: 'Włocławek',
      },
      {
        code: 'PL-WR',
        name: 'Wrocław',
      },
      {
        code: 'PL-ZA',
        name: 'Zamość',
      },
      {
        code: 'PL-ZG',
        name: 'Zielona Góra',
      },
    ],
  },
  {
    name: 'Portugal',
    alpha_3: 'PRT',
    country_code: '620',
    divisions: [
      {
        code: 'PT-01',
        name: 'Aveiro',
      },
      {
        code: 'PT-02',
        name: 'Beja',
      },
      {
        code: 'PT-03',
        name: 'Braga',
      },
      {
        code: 'PT-04',
        name: 'Bragança',
      },
      {
        code: 'PT-05',
        name: 'Castelo Branco',
      },
      {
        code: 'PT-06',
        name: 'Coimbra',
      },
      {
        code: 'PT-07',
        name: 'Évora',
      },
      {
        code: 'PT-08',
        name: 'Faro',
      },
      {
        code: 'PT-09',
        name: 'Guarda',
      },
      {
        code: 'PT-10',
        name: 'Leiria',
      },
      {
        code: 'PT-11',
        name: 'Lisboa',
      },
      {
        code: 'PT-12',
        name: 'Portalegre',
      },
      {
        code: 'PT-13',
        name: 'Porto',
      },
      {
        code: 'PT-14',
        name: 'Santarém',
      },
      {
        code: 'PT-15',
        name: 'Setúbal',
      },
      {
        code: 'PT-16',
        name: 'Viana do Castelo',
      },
      {
        code: 'PT-17',
        name: 'Vila Real',
      },
      {
        code: 'PT-18',
        name: 'Viseu',
      },
      {
        code: 'PT-20',
        name: 'Regiāo Autónoma dos Açores',
      },
      {
        code: 'PT-30',
        name: 'Regiāo Autónoma da Madeira',
      },
    ],
  },
  {
    name: 'Puerto Rico',
    alpha_3: 'PRI',
    country_code: '630',
    divisions: [
      {
        code: 'PR-PR',
        name: 'Puerto Rico',
      },
    ],
  },
  {
    name: 'Qatar',
    alpha_3: 'QAT',
    country_code: '634',
    divisions: [
      {
        code: 'QA-DA',
        name: 'Ad Dawḩah',
      },
      {
        code: 'QA-GH',
        name: 'Al Ghuwayrīyah',
      },
      {
        code: 'QA-JU',
        name: 'Al Jumaylīyah',
      },
      {
        code: 'QA-KH',
        name: 'Al Khawr',
      },
      {
        code: 'QA-WA',
        name: 'Al Wakrah',
      },
      {
        code: 'QA-RA',
        name: 'Ar Rayyān',
      },
      {
        code: 'QA-JB',
        name: 'Jarīyān al Bāţnah',
      },
      {
        code: 'QA-MS',
        name: 'Madīnat ash Shamāl',
      },
      {
        code: 'QA-US',
        name: 'Umm Şalāl',
      },
    ],
  },
  {
    name: 'Réunion',
    alpha_3: 'REU',
    country_code: '638',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Romania',
    alpha_3: 'ROU',
    country_code: '642',
    divisions: [
      {
        code: 'RO-B',
        name: 'Bucureşti',
      },
      {
        code: 'RO-AB',
        name: 'Alba',
      },
      {
        code: 'RO-AR',
        name: 'Arad',
      },
      {
        code: 'RO-AG',
        name: 'Argeş',
      },
      {
        code: 'RO-BC',
        name: 'Bacău',
      },
      {
        code: 'RO-BH',
        name: 'Bihor',
      },
      {
        code: 'RO-BN',
        name: 'Bistriţa-Năsăud',
      },
      {
        code: 'RO-BT',
        name: 'Botoşani',
      },
      {
        code: 'RO-BV',
        name: 'Braşov',
      },
      {
        code: 'RO-BR',
        name: 'Brăila',
      },
      {
        code: 'RO-BZ',
        name: 'Buzău',
      },
      {
        code: 'RO-CS',
        name: 'Caraş-Severin',
      },
      {
        code: 'RO-CL',
        name: 'Călăraşi',
      },
      {
        code: 'RO-CJ',
        name: 'Cluj',
      },
      {
        code: 'RO-CT',
        name: 'Constanţa',
      },
      {
        code: 'RO-CV',
        name: 'Covasna',
      },
      {
        code: 'RO-DB',
        name: 'Dâmboviţa',
      },
      {
        code: 'RO-DJ',
        name: 'Dolj',
      },
      {
        code: 'RO-GL',
        name: 'Galaţi',
      },
      {
        code: 'RO-GR',
        name: 'Giurgiu',
      },
      {
        code: 'RO-GJ',
        name: 'Gorj',
      },
      {
        code: 'RO-HR',
        name: 'Harghita',
      },
      {
        code: 'RO-HD',
        name: 'Hunedoara',
      },
      {
        code: 'RO-IL',
        name: 'Ialomiţa',
      },
      {
        code: 'RO-IS',
        name: 'Iaşi',
      },
      {
        code: 'RO-MM',
        name: 'Maramureş',
      },
      {
        code: 'RO-MH',
        name: 'Mehedinţi',
      },
      {
        code: 'RO-MS',
        name: 'Mureş',
      },
      {
        code: 'RO-NT',
        name: 'Neamţ',
      },
      {
        code: 'RO-OT',
        name: 'Olt',
      },
      {
        code: 'RO-PH',
        name: 'Prahova',
      },
      {
        code: 'RO-SM',
        name: 'Satu Mare',
      },
      {
        code: 'RO-SJ',
        name: 'Sălaj',
      },
      {
        code: 'RO-SB',
        name: 'Sibiu',
      },
      {
        code: 'RO-SV',
        name: 'Suceava',
      },
      {
        code: 'RO-TR',
        name: 'Teleorman',
      },
      {
        code: 'RO-TM',
        name: 'Timiş',
      },
      {
        code: 'RO-TL',
        name: 'Tulcea',
      },
      {
        code: 'RO-VS',
        name: 'Vaslui',
      },
      {
        code: 'RO-VL',
        name: 'Vâlcea',
      },
      {
        code: 'RO-VN',
        name: 'Vrancea',
      },
    ],
  },
  {
    name: 'Russian Federation',
    alpha_3: 'RUS',
    country_code: '643',
    divisions: [
      {
        code: 'RU-AD',
        name: 'Adygeya, Respublika',
      },
      {
        code: 'RU-AL',
        name: 'Altay, Respublika',
      },
      {
        code: 'RU-BA',
        name: 'Bashkortostan, Respublika',
      },
      {
        code: 'RU-BU',
        name: 'Buryatiya, Respublika',
      },
      {
        code: 'RU-CE',
        name: 'Chechenskaya Respublika',
      },
      {
        code: 'RU-CU',
        name: 'Chuvashskaya Respublika',
      },
      {
        code: 'RU-DA',
        name: 'Dagestan, Respublika',
      },
      {
        code: 'RU-IN',
        name: 'Ingushskaya Respublika',
      },
      {
        code: 'RU-KB',
        name: 'Kabardino-Balkarskaya Respublika',
      },
      {
        code: 'RU-KL',
        name: 'Kalmykiya, Respublika',
      },
      {
        code: 'RU-KC',
        name: 'Karachayevo-Cherkesskaya Respublika',
      },
      {
        code: 'RU-KR',
        name: 'Kareliya, Respublika',
      },
      {
        code: 'RU-KK',
        name: 'Khakasiya, Respublika',
      },
      {
        code: 'RU-KO',
        name: 'Komi, Respublika',
      },
      {
        code: 'RU-ME',
        name: 'Mariy El, Respublika',
      },
      {
        code: 'RU-MO',
        name: 'Mordoviya, Respublika',
      },
      {
        code: 'RU-SA',
        name: 'Sakha, Respublika [Yakutiya]',
      },
      {
        code: 'RU-SE',
        name: 'Severnaya Osetiya, Respublika [Alaniya]',
      },
      {
        code: 'RU-TA',
        name: 'Tatarstan, Respublika',
      },
      {
        code: 'RU-TY',
        name: 'Tyva, Respublika [Tuva]',
      },
      {
        code: 'RU-UD',
        name: 'Udmurtskaya Respublika',
      },
      {
        code: 'RU-ALT',
        name: 'Altayskiy kray',
      },
      {
        code: 'RU-KHA',
        name: 'Khabarovskiy kray',
      },
      {
        code: 'RU-KDA',
        name: 'Krasnodarskiy kray',
      },
      {
        code: 'RU-KYA',
        name: 'Krasnoyarskiy kray',
      },
      {
        code: 'RU-PRI',
        name: 'Primorskiy kray',
      },
      {
        code: 'RU-STA',
        name: "Stavropol 'skiy kray",
      },
      {
        code: 'RU-AMU',
        name: "Amurskaya Oblast'",
      },
      {
        code: 'RU-ARK',
        name: "Arkhangel'skaya Oblast'",
      },
      {
        code: 'RU-AST',
        name: "Astrakhanskaya Oblast'",
      },
      {
        code: 'RU-BEL',
        name: "Belgorodskaya Oblast'",
      },
      {
        code: 'RU-BRY',
        name: "Bryanskaya Oblast'",
      },
      {
        code: 'RU-CHE',
        name: "Chelyabinskaya Oblast'",
      },
      {
        code: 'RU-CHI',
        name: "Chitinskaya Oblast'",
      },
      {
        code: 'RU-IRK',
        name: "Irkutskaya Oblast'",
      },
      {
        code: 'RU-IVA',
        name: "Ivanovskaya Oblast'",
      },
      {
        code: 'RU-KGD',
        name: "Kaliningradskaya Oblast'",
      },
      {
        code: 'RU-KLU',
        name: "Kaluzhskaya Oblast'",
      },
      {
        code: 'RU-KAM',
        name: "Kamchatskaya Oblast'",
      },
      {
        code: 'RU-KEM',
        name: "Kemerovskaya Oblast'",
      },
      {
        code: 'RU-KIR',
        name: "Kirovskaya Oblast'",
      },
      {
        code: 'RU-KOS',
        name: "Kostromskaya Oblast'",
      },
      {
        code: 'RU-KGN',
        name: "Kurganskaya Oblast'",
      },
      {
        code: 'RU-KRS',
        name: "Kurskaya Oblast'",
      },
      {
        code: 'RU-LEN',
        name: "Leningradskaya Oblast'",
      },
      {
        code: 'RU-LIP',
        name: "Lipetskaya Oblast'",
      },
      {
        code: 'RU-MAG',
        name: "Magadanskaya Oblast'",
      },
      {
        code: 'RU-MOS',
        name: "Moskovskaya Oblast'",
      },
      {
        code: 'RU-MUR',
        name: "Murmanskaya Oblast'",
      },
      {
        code: 'RU-NIZ',
        name: "Nizhegorodskaya Oblast'",
      },
      {
        code: 'RU-NGR',
        name: "Novgorodskaya Oblast'",
      },
      {
        code: 'RU-NVS',
        name: "Novosibirskaya Oblast'",
      },
      {
        code: 'RU-OMS',
        name: "Omskaya Oblast'",
      },
      {
        code: 'RU-ORE',
        name: "Orenburgskaya Oblast'",
      },
      {
        code: 'RU-ORL',
        name: "Orlovskaya Oblast'",
      },
      {
        code: 'RU-PNZ',
        name: "Penzenskaya Oblast'",
      },
      {
        code: 'RU-PER',
        name: "Permskaya Oblast'",
      },
      {
        code: 'RU-PSK',
        name: "Pskovskaya Oblast'",
      },
      {
        code: 'RU-ROS',
        name: "Rostovskaya Oblast'",
      },
      {
        code: 'RU-RYA',
        name: "Ryazanskaya Oblast'",
      },
      {
        code: 'RU-SAK',
        name: "Sakhalinskaya Oblast'",
      },
      {
        code: 'RU-SAM',
        name: 'Samarskaya Oblast’',
      },
      {
        code: 'RU-SAR',
        name: "Saratovskaya Oblast'",
      },
      {
        code: 'RU-SMO',
        name: "Smolenskaya Oblast'",
      },
      {
        code: 'RU-SVE',
        name: "Sverdlovskaya Oblast'",
      },
      {
        code: 'RU-TAM',
        name: "Tambovskaya Oblast'",
      },
      {
        code: 'RU-TOM',
        name: 'Tomskaya Oblast’',
      },
      {
        code: 'RU-TUL',
        name: "Tul'skaya Oblast'",
      },
      {
        code: 'RU-TVE',
        name: "Tverskaya Oblast'",
      },
      {
        code: 'RU-TYU',
        name: "Tyumenskaya Oblast'",
      },
      {
        code: 'RU-ULY',
        name: "Ul'yanovskaya Oblast'",
      },
      {
        code: 'RU-VLA',
        name: "Vladimirskaya Oblast'",
      },
      {
        code: 'RU-VGG',
        name: "Volgogradskaya Oblast'",
      },
      {
        code: 'RU-VLG',
        name: "Vologodskaya Oblast'",
      },
      {
        code: 'RU-VOR',
        name: "Voronezhskaya Oblast'",
      },
      {
        code: 'RU-YAR',
        name: "Yaroslavskaya Oblast'",
      },
      {
        code: 'RU-MOW',
        name: 'Moskva',
      },
      {
        code: 'RU-SPE',
        name: 'Sankt-Peterburg',
      },
      {
        code: 'RU-YEV',
        name: "Yevreyskaya avtonomnaya oblast'",
      },
      {
        code: 'RU-AGB',
        name: 'Aginskiy Buryatskiy avtonomnyy okrug',
      },
      {
        code: 'RU-CHU',
        name: 'Chukotskiy avtonomnyy okrug',
      },
      {
        code: 'RU-EVE',
        name: 'Evenkiyskiy avtonomnyy okrug',
      },
      {
        code: 'RU-KHM',
        name: 'Khanty-Mansiyskiy avtonomnyy okrug',
      },
      {
        code: 'RU-KOP',
        name: 'Komi-Permyatskiy avtonomnyy okrug',
      },
      {
        code: 'RU-KOR',
        name: 'Koryakskiy avtonomnyy okrug',
      },
      {
        code: 'RU-NEN',
        name: 'Nenetskiy avtonomnyy okrug',
      },
      {
        code: 'RU-TAY',
        name: 'Taymyrskiy (Dolgano-Nenetskiy) avtonomnyy okrug',
      },
      {
        code: 'RU-UOB',
        name: 'Ust’-Ordynskiy Buryatskiy avtonomnyy okrug',
      },
      {
        code: 'RU-YAN',
        name: 'Yamalo-Nenetskiy avtonomnyy okrug',
      },
    ],
  },
  {
    name: 'Rwanda',
    alpha_3: 'RWA',
    country_code: '646',
    divisions: [
      {
        code: 'RW-C',
        name: 'Butare',
      },
      {
        code: 'RW-I',
        name: 'Byumba',
      },
      {
        code: 'RW-E',
        name: 'Cyangugu',
      },
      {
        code: 'RW-D',
        name: 'Gikongoro',
      },
      {
        code: 'RW-G',
        name: 'Gisenyi',
      },
      {
        code: 'RW-B',
        name: 'Gitarama',
      },
      {
        code: 'RW-J',
        name: 'Kibungo',
      },
      {
        code: 'RW-F',
        name: 'Kibuye',
      },
      {
        code: 'RW-K',
        name: 'Kigali-Rural (Kigali y’ Icyaro)',
      },
      {
        code: 'RW-L',
        name: 'Kigali-Ville (Kilgali Ngari)',
      },
      {
        code: 'RW-M',
        name: 'Mutara',
      },
      {
        code: 'RW-H',
        name: 'Ruhengeri',
      },
    ],
  },
  {
    name: 'Saint Barthélemy',
    alpha_3: 'BLM',
    country_code: '652',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha_3: 'SHN',
    country_code: '654',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha_3: 'KNA',
    country_code: '659',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Saint Lucia',
    alpha_3: 'LCA',
    country_code: '662',
    divisions: [
      {
        code: 'LC-LC',
        name: 'Saint Lucia',
      },
    ],
  },
  {
    name: 'Saint Martin (French part)',
    alpha_3: 'MAF',
    country_code: '663',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha_3: 'SPM',
    country_code: '666',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha_3: 'VCT',
    country_code: '670',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Samoa',
    alpha_3: 'WSM',
    country_code: '882',
    divisions: [
      {
        code: 'WS-AA',
        name: "A'ana",
      },
      {
        code: 'WS-AL',
        name: 'Aiga-i-le-Tai',
      },
      {
        code: 'WS-AT',
        name: 'Atua',
      },
      {
        code: 'WS-FA',
        name: "Fa'asaleleaga",
      },
      {
        code: 'WS-GE',
        name: "Gaga'emauga",
      },
      {
        code: 'WS-GI',
        name: 'Gagaifomauga',
      },
      {
        code: 'WS-PA',
        name: 'Palauli',
      },
      {
        code: 'WS-SA',
        name: "Satupa'itea",
      },
      {
        code: 'WS-TU',
        name: 'Tuamasaga',
      },
      {
        code: 'WS-VF',
        name: "Va'a-o-Fonoti",
      },
      {
        code: 'WS-VS',
        name: 'Vaisigano',
      },
    ],
  },
  {
    name: 'San Marino',
    alpha_3: 'SMR',
    country_code: '674',
    divisions: [
      {
        code: 'SM-SM',
        name: 'San Marino',
      },
    ],
  },
  {
    name: 'Sao Tome and Principe',
    alpha_3: 'STP',
    country_code: '678',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Saudi Arabia',
    alpha_3: 'SAU',
    country_code: '682',
    divisions: [
      {
        code: 'SA-11',
        name: 'Al Bāḩah',
      },
      {
        code: 'SA-08',
        name: 'Al Ḩudūd ash Shamālīyah',
      },
      {
        code: 'SA-12',
        name: 'Al Jawf',
      },
      {
        code: 'SA-03',
        name: 'Al Madīnah',
      },
      {
        code: 'SA-05',
        name: 'Al Qaşim',
      },
      {
        code: 'SA-O1',
        name: 'Ar Riyāḑ',
      },
      {
        code: 'SA-04',
        name: 'Ash Sharqīyah',
      },
      {
        code: 'SA-14',
        name: '‘Asīr',
      },
      {
        code: 'SA-06',
        name: "Ḩā'il",
      },
      {
        code: 'SA-09',
        name: 'Jīzān',
      },
      {
        code: 'SA-02',
        name: 'Makkah',
      },
      {
        code: 'SA-10',
        name: 'Najrān',
      },
      {
        code: 'SA-07',
        name: 'Tabūk',
      },
    ],
  },
  {
    name: 'Senegal',
    alpha_3: 'SEN',
    country_code: '686',
    divisions: [
      {
        code: 'SN-DK',
        name: 'Dakar',
      },
      {
        code: 'SN-DB',
        name: 'Diourbel',
      },
      {
        code: 'SN-FK',
        name: 'Fatick',
      },
      {
        code: 'SN-KL',
        name: 'Kaolack',
      },
      {
        code: 'SN-KD',
        name: 'Kolda',
      },
      {
        code: 'SN-LG',
        name: 'Louga',
      },
      {
        code: 'SN-SL',
        name: 'Saint-Louis',
      },
      {
        code: 'SN-TC',
        name: 'Tambacounda',
      },
      {
        code: 'SN-TH',
        name: 'Thiès',
      },
      {
        code: 'SN-ZG',
        name: 'Ziguinchor',
      },
    ],
  },
  {
    name: 'Serbia',
    alpha_3: 'SRB',
    country_code: '688',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Seychelles',
    alpha_3: 'SYC',
    country_code: '690',
    divisions: [
      {
        code: 'SC-SC',
        name: 'Seychelles',
      },
    ],
  },
  {
    name: 'Sierra Leone',
    alpha_3: 'SLE',
    country_code: '694',
    divisions: [
      {
        code: 'SL-W',
        name: 'Western Area (Freetown)',
      },
      {
        code: 'SL-E',
        name: 'Eastern',
      },
      {
        code: 'SL-N',
        name: 'Northern',
      },
      {
        code: 'SL-S',
        name: 'Southern',
      },
    ],
  },
  {
    name: 'Singapore',
    alpha_3: 'SGP',
    country_code: '702',
    divisions: [
      {
        code: 'SG-SG',
        name: 'Singapore',
      },
    ],
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha_3: 'SXM',
    country_code: '534',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Slovakia',
    alpha_3: 'SVK',
    country_code: '703',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Slovenia',
    alpha_3: 'SVN',
    country_code: '705',
    divisions: [
      {
        code: 'SI-07',
        name: 'Dolenjska',
      },
      {
        code: 'SI-09',
        name: 'Gorenjska',
      },
      {
        code: 'SI-11',
        name: 'Goriška',
      },
      {
        code: 'SI-03',
        name: 'Koroška',
      },
      {
        code: 'SI-10',
        name: 'Notranjsko-kraška',
      },
      {
        code: 'SI-12',
        name: 'Obalno-kraška',
      },
      {
        code: 'SI-08',
        name: 'Osrednjeslovenska',
      },
      {
        code: 'SI-02',
        name: 'Podravska',
      },
      {
        code: 'SI-01',
        name: 'Pomurska',
      },
      {
        code: 'SI-04',
        name: 'Savinjska',
      },
      {
        code: 'SI-06',
        name: 'Spodnjeposavska',
      },
      {
        code: 'SI-05',
        name: 'Zasavska',
      },
    ],
  },
  {
    name: 'Solomon Islands',
    alpha_3: 'SLB',
    country_code: '090',
    divisions: [
      {
        code: 'SB-CT',
        name: 'Capital Territory (Honiara)',
      },
      {
        code: 'SB-CE',
        name: 'Central',
      },
      {
        code: 'SB-GU',
        name: 'Guadalcanal',
      },
      {
        code: 'SB-IS',
        name: 'Isabel',
      },
      {
        code: 'SB-MK',
        name: 'Makira',
      },
      {
        code: 'SB-ML',
        name: 'Malaita',
      },
      {
        code: 'SB-TE',
        name: 'Temotu',
      },
      {
        code: 'SB-WE',
        name: 'Western',
      },
    ],
  },
  {
    name: 'Somalia',
    alpha_3: 'SOM',
    country_code: '706',
    divisions: [
      {
        code: 'SO-AW',
        name: 'Awdal',
      },
      {
        code: 'SO-BK',
        name: 'Bakool',
      },
      {
        code: 'SO-BN',
        name: 'Banaadir',
      },
      {
        code: 'SO-BR',
        name: 'Bari',
      },
      {
        code: 'SO-BY',
        name: 'BaY',
      },
      {
        code: 'SO-GA',
        name: 'Galguduud',
      },
      {
        code: 'SO-GE',
        name: 'Gedo',
      },
      {
        code: 'SO-HI',
        name: 'Hiiraan',
      },
      {
        code: 'SO-JD',
        name: 'Jubbada Dhexe',
      },
      {
        code: 'SO-JH',
        name: 'Jubbada Hoose',
      },
      {
        code: 'SO-MU',
        name: 'Mudug',
      },
      {
        code: 'SO-NU',
        name: 'Nugaal',
      },
      {
        code: 'SO-SA',
        name: 'Sanaag',
      },
      {
        code: 'SO-SD',
        name: 'Shabeellaha Dhexe',
      },
      {
        code: 'SO-SH',
        name: 'Shabeellaha Hoose',
      },
      {
        code: 'SO-SO',
        name: 'Sool',
      },
      {
        code: 'SO-TO',
        name: 'Togdheer',
      },
      {
        code: 'SO-WO',
        name: 'Woqooyi Galbeed',
      },
    ],
  },
  {
    name: 'South Africa',
    alpha_3: 'ZAF',
    country_code: '710',
    divisions: [
      {
        code: 'ZA-EC',
        name: 'Eastern Cape (Oos-Kaap)',
      },
      {
        code: 'ZA-FS',
        name: 'Free State (Vrystaat)',
      },
      {
        code: 'ZA-GT',
        name: 'Gauteng',
      },
      {
        code: 'ZA-NL',
        name: 'Kwazulu-Natal',
      },
      {
        code: 'ZA-MP',
        name: 'Mpumalanga',
      },
      {
        code: 'ZA-NC',
        name: 'Northern Cape (Noord-Kaap)',
      },
      {
        code: 'ZA-NP',
        name: 'Northern Province (Noordelike Provinsie)',
      },
      {
        code: 'ZA-NW',
        name: 'North-West (Noord-Wes)',
      },
      {
        code: 'ZA-WC',
        name: 'Western Cape (Wes-Kaap)',
      },
    ],
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha_3: 'SGS',
    country_code: '239',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'South Sudan',
    alpha_3: 'SSD',
    country_code: '728',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Spain',
    alpha_3: 'ESP',
    country_code: '724',
    divisions: [
      {
        code: 'ES-AN',
        name: 'Andalucía',
      },
      {
        code: 'ES-AL',
        name: 'Almería',
      },
      {
        code: 'ES-CA',
        name: 'Cádiz',
      },
      {
        code: 'ES-CO',
        name: 'Córdoba',
      },
      {
        code: 'ES-GR',
        name: 'Granada',
      },
      {
        code: 'ES-H',
        name: 'Huelva',
      },
      {
        code: 'ES-J',
        name: 'Jaén',
      },
      {
        code: 'ES-MA',
        name: 'Málaga',
      },
      {
        code: 'ES-SE',
        name: 'Sevilla',
      },
      {
        code: 'ES-AR',
        name: 'Aragón',
      },
      {
        code: 'ES-HU',
        name: 'Huesca',
      },
      {
        code: 'ES-TE',
        name: 'Teruel',
      },
      {
        code: 'ES-Z',
        name: 'Zaragoza',
      },
      {
        code: 'ES-O',
        name: 'Asturias',
      },
      {
        code: 'ES-CN',
        name: 'Canarias',
      },
      {
        code: 'ES-GC',
        name: 'Las Palmas',
      },
      {
        code: 'ES-TF',
        name: 'Santa Cruz De Tenerife',
      },
      {
        code: 'ES-S',
        name: 'Cantabria',
      },
      {
        code: 'ES-CM',
        name: 'Castilla-La Mancha',
      },
      {
        code: 'ES-AB',
        name: 'Albacete',
      },
      {
        code: 'ES-CR',
        name: 'Ciudad Real',
      },
      {
        code: 'ES-CU',
        name: 'Cuenca',
      },
      {
        code: 'ES-GU',
        name: 'Guadalajara',
      },
      {
        code: 'ES-TO',
        name: 'Toledo',
      },
      {
        code: 'ES-CL',
        name: 'Castilla y León',
      },
      {
        code: 'ES-AV',
        name: 'Ávila',
      },
      {
        code: 'ES-BU',
        name: 'Burgos',
      },
      {
        code: 'ES-LE',
        name: 'León',
      },
      {
        code: 'ES-P',
        name: 'Palencia',
      },
      {
        code: 'ES-SA',
        name: 'Salamanca',
      },
      {
        code: 'ES-SG',
        name: 'Segovia',
      },
      {
        code: 'ES-SO',
        name: 'Soria',
      },
      {
        code: 'ES-VA',
        name: 'Valladolid',
      },
      {
        code: 'ES-ZA',
        name: 'Zamora',
      },
      {
        code: 'ES-CT',
        name: 'Cataluña',
      },
      {
        code: 'ES-B',
        name: 'Barcelona',
      },
      {
        code: 'ES-GE',
        name: 'Gerona',
      },
      {
        code: 'ES-L',
        name: 'Lérida',
      },
      {
        code: 'ES-T',
        name: 'Tarragona',
      },
      {
        code: 'ES-EX',
        name: 'Extremadura',
      },
      {
        code: 'ES-BA',
        name: 'Badajoz',
      },
      {
        code: 'ES-CC',
        name: 'Cáceres',
      },
      {
        code: 'ES-GA',
        name: 'Galicia',
      },
      {
        code: 'ES-C',
        name: 'La Coruña',
      },
      {
        code: 'ES-LU',
        name: 'Lugo',
      },
      {
        code: 'ES-OR',
        name: 'Orense',
      },
      {
        code: 'ES-PO',
        name: 'Pontevedra',
      },
      {
        code: 'ES-PM',
        name: 'Baleares',
      },
      {
        code: 'ES-LO',
        name: 'La Rioja',
      },
      {
        code: 'ES-M',
        name: 'Madrid',
      },
      {
        code: 'ES-MU',
        name: 'Murcia',
      },
      {
        code: 'ES-NA',
        name: 'Navarra',
      },
      {
        code: 'ES-PV',
        name: 'País Vasco',
      },
      {
        code: 'ES-VI',
        name: 'Álava',
      },
      {
        code: 'ES-SS',
        name: 'Guipúzcoa',
      },
      {
        code: 'ES-BI',
        name: 'Vizcaya',
      },
      {
        code: 'ES-VC',
        name: 'Valenciana, Comunidad',
      },
      {
        code: 'ES-A',
        name: 'Alicante',
      },
      {
        code: 'ES-CS',
        name: 'Castellón',
      },
      {
        code: 'ES-V',
        name: 'Valencia',
      },
    ],
  },
  {
    name: 'Sri Lanka',
    alpha_3: 'LKA',
    country_code: '144',
    divisions: [
      {
        code: 'LK-1',
        name: 'Basnahira Palata (Western Province)',
      },
      {
        code: 'LK-11',
        name: 'Colombo',
      },
      {
        code: 'LK-12',
        name: 'Gampaha',
      },
      {
        code: 'LK-13',
        name: 'Kalutara',
      },
      {
        code: 'LK-3',
        name: 'Dakunu Palata (Southern Province)',
      },
      {
        code: 'LK-31',
        name: 'Galle',
      },
      {
        code: 'LK-33',
        name: 'Hambantota',
      },
      {
        code: 'LK-32',
        name: 'Matara',
      },
      {
        code: 'LK-2',
        name: 'Madhyama Palata (Central Province)',
      },
      {
        code: 'LK-21',
        name: 'Kandy',
      },
      {
        code: 'LK-22',
        name: 'Matale',
      },
      {
        code: 'LK-23',
        name: 'Nuwara Eliya',
      },
      {
        code: 'LK-5',
        name: 'Negenahira Palata (Eastern Province)',
      },
      {
        code: 'LK-52',
        name: 'Arnpara',
      },
      {
        code: 'LK-51',
        name: 'Batticaloa',
      },
      {
        code: 'LK-53',
        name: 'Trincomalee',
      },
      {
        code: 'LK-9',
        name: 'Sabaragamuwa Palata',
      },
      {
        code: 'LK-92',
        name: 'Kegalla',
      },
      {
        code: 'LK-91',
        name: 'Ratnapura',
      },
      {
        code: 'LK-7',
        name: 'Uturumeda Palata (North Central Province)',
      },
      {
        code: 'LK-71',
        name: 'Anuradhapura',
      },
      {
        code: 'LK-72',
        name: 'Polonnaruwa',
      },
      {
        code: 'LK-4',
        name: 'Uturu Palata (Northern Province)',
      },
      {
        code: 'LK-41',
        name: 'Jaffna',
      },
      {
        code: 'LK-42',
        name: 'Kilinochchi',
      },
      {
        code: 'LK-43',
        name: 'Mannar',
      },
      {
        code: 'LK-45',
        name: 'Mullaittivu',
      },
      {
        code: 'LK-44',
        name: 'Vavuniya',
      },
      {
        code: 'LK-8',
        name: 'Uva Palata',
      },
      {
        code: 'LK-81',
        name: 'Badulla',
      },
      {
        code: 'LK-82',
        name: 'Monaragala',
      },
      {
        code: 'LK-6',
        name: 'Wayamba Palata (North Western Province)',
      },
      {
        code: 'LK-61',
        name: 'Kurunegala',
      },
      {
        code: 'LK-62',
        name: 'Puttalam',
      },
    ],
  },
  {
    name: 'Sudan',
    alpha_3: 'SDN',
    country_code: '729',
    divisions: [
      {
        code: 'SD-23',
        name: 'A‘ālī an Nīl',
      },
      {
        code: 'SD-26',
        name: 'Al Baḩr al Aḩmar',
      },
      {
        code: 'SD-18',
        name: 'Al Buḩayrāt',
      },
      {
        code: 'SD-07',
        name: 'Al Jazīrah',
      },
      {
        code: 'SD-03',
        name: 'Al Kharţūm',
      },
      {
        code: 'SD-06',
        name: 'Al Qaḑārif',
      },
      {
        code: 'SD-22',
        name: 'Al Waḩdah',
      },
      {
        code: 'SD-04',
        name: 'An Nīl',
      },
      {
        code: 'SD-08',
        name: 'An Nīl al Abyaḑ',
      },
      {
        code: 'SD-24',
        name: 'An Nīl al Azraq',
      },
      {
        code: 'SD-01',
        name: 'Ash Shamālīyah',
      },
      {
        code: 'SD-17',
        name: 'Baḩr al Jabal',
      },
      {
        code: 'SD-16',
        name: "Gharb al Istiwā'īyah",
      },
      {
        code: 'SD-14',
        name: 'Gharb Baḩr al Ghazāl',
      },
      {
        code: 'SD-12',
        name: 'Gharb Dārfūr',
      },
      {
        code: 'SD-10',
        name: 'Gharb Kurdufān',
      },
      {
        code: 'SD-11',
        name: 'Janūb Dārfūr',
      },
      {
        code: 'SD-13',
        name: 'Janūb Kurdufān',
      },
      {
        code: 'SD-20',
        name: 'Jūnqalī',
      },
      {
        code: 'SD-05',
        name: 'Kassalā',
      },
      {
        code: 'SD-15',
        name: 'Shamāl Baḩr al Ghazāl',
      },
      {
        code: 'SD-02',
        name: 'Shamāl Dārfūr',
      },
      {
        code: 'SD-09',
        name: 'Shamāl Kurdufān',
      },
      {
        code: 'SD-19',
        name: "Sharq al Istiwā'iyah",
      },
      {
        code: 'SD-25',
        name: 'Sinnār',
      },
      {
        code: 'SD-21',
        name: 'Wārāb',
      },
    ],
  },
  {
    name: 'Suriname',
    alpha_3: 'SUR',
    country_code: '740',
    divisions: [
      {
        code: 'SR-BR',
        name: 'Brokopondo',
      },
      {
        code: 'SR-CM',
        name: 'Commewijne',
      },
      {
        code: 'SR-CR',
        name: 'Coronie',
      },
      {
        code: 'SR-MA',
        name: 'Marowijne',
      },
      {
        code: 'SR-NI',
        name: 'Nickerie',
      },
      {
        code: 'SR-PR',
        name: 'Para',
      },
      {
        code: 'SR-PM',
        name: 'Paramaribo',
      },
      {
        code: 'SR-SA',
        name: 'Saramacca',
      },
      {
        code: 'SR-SI',
        name: 'Sipaliwini',
      },
      {
        code: 'SR-WA',
        name: 'Wanica',
      },
    ],
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha_3: 'SJM',
    country_code: '744',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Sweden',
    alpha_3: 'SWE',
    country_code: '752',
    divisions: [
      {
        code: 'SE-K',
        name: 'Blekinge län',
      },
      {
        code: 'SE-W',
        name: 'Dalarnas län',
      },
      {
        code: 'SE-I',
        name: 'Gotlands län',
      },
      {
        code: 'SE-X',
        name: 'Gävleborgs län',
      },
      {
        code: 'SE-N',
        name: 'Hallands län',
      },
      {
        code: 'SE-Z',
        name: 'Jämtlands län',
      },
      {
        code: 'SE-F',
        name: 'Jönköpings län',
      },
      {
        code: 'SE-H',
        name: 'Kalmar län',
      },
      {
        code: 'SE-G',
        name: 'Kronobergs län',
      },
      {
        code: 'SE-BD',
        name: 'Norrbottens län',
      },
      {
        code: 'SE-M',
        name: 'Skåne län',
      },
      {
        code: 'SE-AB',
        name: 'Stockholms län',
      },
      {
        code: 'SE-D',
        name: 'Södermanlands län',
      },
      {
        code: 'SE-C',
        name: 'Uppsala län',
      },
      {
        code: 'SE-S',
        name: 'Värmlands län',
      },
      {
        code: 'SE-AC',
        name: 'Västerbottens län',
      },
      {
        code: 'SE-Y',
        name: 'Västernorrlands län',
      },
      {
        code: 'SE-U',
        name: 'Västmanlands län',
      },
      {
        code: 'SE-O',
        name: 'Västra Götalands län',
      },
      {
        code: 'SE-T',
        name: 'Örebro län',
      },
      {
        code: 'SE-E',
        name: 'Östergötlands län',
      },
    ],
  },
  {
    name: 'Switzerland',
    alpha_3: 'CHE',
    country_code: '756',
    divisions: [
      {
        code: 'CH-AG',
        name: 'Aargau',
      },
      {
        code: 'CH-AR',
        name: 'Appenzell Ausser-Rhoden',
      },
      {
        code: 'CH-AI',
        name: 'Appenzell Inner-Rhoden',
      },
      {
        code: 'CH-BL',
        name: 'Basel-Landschaft',
      },
      {
        code: 'CH-BS',
        name: 'Basel-Stadt',
      },
      {
        code: 'CH-BE',
        name: 'Bern',
      },
      {
        code: 'CH-FR',
        name: 'Freiburg',
      },
      {
        code: 'CH-GE',
        name: 'Geneve',
      },
      {
        code: 'CH-GL',
        name: 'Glarus',
      },
      {
        code: 'CH-GR',
        name: 'Graubünden',
      },
      {
        code: 'CH-JU',
        name: 'Jura',
      },
      {
        code: 'CH-LU',
        name: 'Luzern',
      },
      {
        code: 'CH-NE',
        name: 'Neuchatel',
      },
      {
        code: 'CH-NW',
        name: 'Nidwalden',
      },
      {
        code: 'CH-OW',
        name: 'Obwalden',
      },
      {
        code: 'CH-SG',
        name: 'Sankt Gallen',
      },
      {
        code: 'CH-SH',
        name: 'Schaffhausen',
      },
      {
        code: 'CH-SZ',
        name: 'Schwyz',
      },
      {
        code: 'CH-SO',
        name: 'Solothurn',
      },
      {
        code: 'CH-TG',
        name: 'Thurgau',
      },
      {
        code: 'CH-TI',
        name: 'Ticino',
      },
      {
        code: 'CH-UR',
        name: 'Uri',
      },
      {
        code: 'CH-VS',
        name: 'Wallis',
      },
      {
        code: 'CH-VD',
        name: 'Vaud',
      },
      {
        code: 'CH-ZG',
        name: 'Zug',
      },
      {
        code: 'CH-ZH',
        name: 'Zürich',
      },
    ],
  },
  {
    name: 'Syrian Arab Republic',
    alpha_3: 'SYR',
    country_code: '760',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Taiwan, Province of China',
    alpha_3: 'TWN',
    country_code: '158',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Tajikistan',
    alpha_3: 'TJK',
    country_code: '762',
    divisions: [
      {
        code: 'TJ-KR',
        name: 'Karategin',
      },
      {
        code: 'TJ-KT',
        name: 'Khatlon',
      },
      {
        code: 'TJ-LN',
        name: 'Leninabad',
      },
      {
        code: 'TJ-GB',
        name: 'Gorno-Badakhshan',
      },
    ],
  },
  {
    name: 'Tanzania, United Republic of',
    alpha_3: 'TZA',
    country_code: '834',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Thailand',
    alpha_3: 'THA',
    country_code: '764',
    divisions: [
      {
        code: 'TH-10',
        name: 'Krung Thep Maha Nakhon [Bangkok]',
      },
      {
        code: 'TH-S',
        name: 'Phatthaya',
      },
      {
        code: 'TH-37',
        name: 'Amnat Charoen',
      },
      {
        code: 'TH-15',
        name: 'Ang Thong',
      },
      {
        code: 'TH-31',
        name: 'Buri Ram',
      },
      {
        code: 'TH-24',
        name: 'Chachoengsao',
      },
      {
        code: 'TH-18',
        name: 'Chai Nat',
      },
      {
        code: 'TH-36',
        name: 'Chaiyaphum',
      },
      {
        code: 'TH-22',
        name: 'Chanthaburi',
      },
      {
        code: 'TH-50',
        name: 'Chiang Mai',
      },
      {
        code: 'TH-57',
        name: 'Chiang Rai',
      },
      {
        code: 'TH-20',
        name: 'Chon Buri',
      },
      {
        code: 'TH-86',
        name: 'Chumphon',
      },
      {
        code: 'TH-46',
        name: 'Kalasin',
      },
      {
        code: 'TH-62',
        name: 'Kamphaeng Phet',
      },
      {
        code: 'TH-71',
        name: 'Kanchanaburi',
      },
      {
        code: 'TH-40',
        name: 'Khon Kaen',
      },
      {
        code: 'TH-81',
        name: 'Krabi',
      },
      {
        code: 'TH-52',
        name: 'Lampang',
      },
      {
        code: 'TH-51',
        name: 'Lamphun',
      },
      {
        code: 'TH-42',
        name: 'Loei',
      },
      {
        code: 'TH-16',
        name: 'Lop Buri',
      },
      {
        code: 'TH-58',
        name: 'Mae Hong Son',
      },
      {
        code: 'TH-44',
        name: 'Maha Sarakham',
      },
      {
        code: 'TH-49',
        name: 'Mukdahan',
      },
      {
        code: 'TH-26',
        name: 'Nakhon Nayok',
      },
      {
        code: 'TH-73',
        name: 'Nakhon Pathom',
      },
      {
        code: 'TH-48',
        name: 'Nakhon Phanom',
      },
      {
        code: 'TH-30',
        name: 'Nakhon Ratchasima',
      },
      {
        code: 'TH-60',
        name: 'Nakhon Sawan',
      },
      {
        code: 'TH-80',
        name: 'Nakhon Si Thammarat',
      },
      {
        code: 'TH-55',
        name: 'Nan',
      },
      {
        code: 'TH-96',
        name: 'Narathiwat',
      },
      {
        code: 'TH-39',
        name: 'Nong Bua Lam Phu',
      },
      {
        code: 'TH-43',
        name: 'Nong Khai',
      },
      {
        code: 'TH-12',
        name: 'Nonthaburi',
      },
      {
        code: 'TH-13',
        name: 'Pathum Thani',
      },
      {
        code: 'TH-94',
        name: 'Pattani',
      },
      {
        code: 'TH-82',
        name: 'Phangnga',
      },
      {
        code: 'TH-93',
        name: 'Phatthalung',
      },
      {
        code: 'TH-56',
        name: 'Phayao',
      },
      {
        code: 'TH-67',
        name: 'Phetchabun',
      },
      {
        code: 'TH-76',
        name: 'Phetchaburi',
      },
      {
        code: 'TH-66',
        name: 'Phichit',
      },
      {
        code: 'TH-65',
        name: 'Phitsanulok',
      },
      {
        code: 'TH-54',
        name: 'Phrae',
      },
      {
        code: 'TH-14',
        name: 'Phra Nakhon Si Ayutthaya',
      },
      {
        code: 'TH-83',
        name: 'Phuket',
      },
      {
        code: 'TH-25',
        name: 'Prachin Buri',
      },
      {
        code: 'TH-77',
        name: 'Prachuap Khiri Khan',
      },
      {
        code: 'TH-85',
        name: 'Ranong',
      },
      {
        code: 'TH-70',
        name: 'Ratchaburi',
      },
      {
        code: 'TH-21',
        name: 'Rayong',
      },
      {
        code: 'TH-45',
        name: 'Roi Et',
      },
      {
        code: 'TH-27',
        name: 'Sa Kaeo',
      },
      {
        code: 'TH-47',
        name: 'Sakon Nakhon',
      },
      {
        code: 'TH-11',
        name: 'Samut Prakan',
      },
      {
        code: 'TH-74',
        name: 'Samut Sakhon',
      },
      {
        code: 'TH-75',
        name: 'Samut Songkhram',
      },
      {
        code: 'TH-19',
        name: 'Saraburi',
      },
      {
        code: 'TH-91',
        name: 'Satun',
      },
      {
        code: 'TH-17',
        name: 'Sing Buri',
      },
      {
        code: 'TH-33',
        name: 'Si Sa Ket',
      },
      {
        code: 'TH-90',
        name: 'Songkhla',
      },
      {
        code: 'TH-64',
        name: 'Sukhothai',
      },
      {
        code: 'TH-72',
        name: 'Suphan Buri',
      },
      {
        code: 'TH-84',
        name: 'Surat Thani',
      },
      {
        code: 'TH-32',
        name: 'Surin',
      },
      {
        code: 'TH-63',
        name: 'Tak',
      },
      {
        code: 'TH-92',
        name: 'Trang',
      },
      {
        code: 'TH-23',
        name: 'Trat',
      },
      {
        code: 'TH-34',
        name: 'Ubon Ratchathani',
      },
      {
        code: 'TH-41',
        name: 'Udon Thani',
      },
      {
        code: 'TH-61',
        name: 'Uthai Thani',
      },
      {
        code: 'TH-53',
        name: 'Uttaradit',
      },
      {
        code: 'TH-95',
        name: 'Yala',
      },
      {
        code: 'TH-35',
        name: 'Yasothon',
      },
    ],
  },
  {
    name: 'Timor-Leste',
    alpha_3: 'TLS',
    country_code: '626',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Togo',
    alpha_3: 'TGO',
    country_code: '768',
    divisions: [
      {
        code: 'TG-C',
        name: 'Centre',
      },
      {
        code: 'TG-K',
        name: 'Kara',
      },
      {
        code: 'TG-M',
        name: 'Maritime (Région)',
      },
      {
        code: 'TG-P',
        name: 'Plateaux',
      },
      {
        code: 'TG-S',
        name: 'Savannes',
      },
    ],
  },
  {
    name: 'Tokelau',
    alpha_3: 'TKL',
    country_code: '772',
    divisions: [
      {
        code: 'TK-TK',
        name: 'Tokelau',
      },
    ],
  },
  {
    name: 'Tonga',
    alpha_3: 'TON',
    country_code: '776',
    divisions: [
      {
        code: 'TO-TO',
        name: 'Tonga',
      },
    ],
  },
  {
    name: 'Trinidad and Tobago',
    alpha_3: 'TTO',
    country_code: '780',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Tunisia',
    alpha_3: 'TUN',
    country_code: '788',
    divisions: [
      {
        code: 'TN-31',
        name: 'Béja',
      },
      {
        code: 'TN-13',
        name: 'Ben Arous',
      },
      {
        code: 'TN-23',
        name: 'Bizerte',
      },
      {
        code: 'TN-81',
        name: 'Gabès',
      },
      {
        code: 'TN-71',
        name: 'Gafsa',
      },
      {
        code: 'TN-32',
        name: 'Jendouba',
      },
      {
        code: 'TN-41',
        name: 'Kairouan',
      },
      {
        code: 'TN-42',
        name: 'Kasserine',
      },
      {
        code: 'TN-73',
        name: 'Kebili',
      },
      {
        code: 'TN-12',
        name: "L'Ariana",
      },
      {
        code: 'TN-33',
        name: 'Le Kef',
      },
      {
        code: 'TN-53',
        name: 'Mahdia',
      },
      {
        code: 'TN-82',
        name: 'Medenine',
      },
      {
        code: 'TN-52',
        name: 'Monastir',
      },
      {
        code: 'TN-21',
        name: 'Nabeul',
      },
      {
        code: 'TN-61',
        name: 'Sfax',
      },
      {
        code: 'TN-43',
        name: 'Sidi Bouzid',
      },
      {
        code: 'TN-34',
        name: 'Siliana',
      },
      {
        code: 'TN-51',
        name: 'Sousse',
      },
      {
        code: 'TN-83',
        name: 'Tataouine',
      },
      {
        code: 'TN-72',
        name: 'Tozeur',
      },
      {
        code: 'TN-11',
        name: 'Tunis',
      },
      {
        code: 'TN-22',
        name: 'Zaghouan',
      },
    ],
  },
  {
    name: 'Turkey',
    alpha_3: 'TUR',
    country_code: '792',
    divisions: [
      {
        code: 'TR-01',
        name: 'Adana',
      },
      {
        code: 'TR-02',
        name: 'Adiyaman',
      },
      {
        code: 'TR-03',
        name: 'Afyon',
      },
      {
        code: 'TR-04',
        name: 'Ağrı',
      },
      {
        code: 'TR-68',
        name: 'Aksaray',
      },
      {
        code: 'TR-05',
        name: 'Amasya',
      },
      {
        code: 'TR-06',
        name: 'Ankara',
      },
      {
        code: 'TR-07',
        name: 'Antalya',
      },
      {
        code: 'TR-75',
        name: 'Ardahan',
      },
      {
        code: 'TR-08',
        name: 'Artvin',
      },
      {
        code: 'TR-09',
        name: 'Aydin',
      },
      {
        code: 'TR-10',
        name: 'Balıkesir',
      },
      {
        code: 'TR-74',
        name: 'Bartın',
      },
      {
        code: 'TR-72',
        name: 'Batman',
      },
      {
        code: 'TR-69',
        name: 'Bayburt',
      },
      {
        code: 'TR-11',
        name: 'Bilecik',
      },
      {
        code: 'TR-12',
        name: 'Bingöl',
      },
      {
        code: 'TR-13',
        name: 'Bitlis',
      },
      {
        code: 'TR-14',
        name: 'Bolu',
      },
      {
        code: 'TR-15',
        name: 'Burdur',
      },
      {
        code: 'TR-16',
        name: 'Bursa',
      },
      {
        code: 'TR-17',
        name: 'Çanakkale',
      },
      {
        code: 'TR-18',
        name: 'Çankırı',
      },
      {
        code: 'TR-19',
        name: 'Çorum',
      },
      {
        code: 'TR-20',
        name: 'Denizli',
      },
      {
        code: 'TR-21',
        name: 'Diyarbakır',
      },
      {
        code: 'TR-22',
        name: 'Edirne',
      },
      {
        code: 'TR-23',
        name: 'Elaziğ',
      },
      {
        code: 'TR-24',
        name: 'Erzincan',
      },
      {
        code: 'TR-25',
        name: 'Erzurum',
      },
      {
        code: 'TR-26',
        name: 'Eskişehir',
      },
      {
        code: 'TR-27',
        name: 'Gaziantep',
      },
      {
        code: 'TR-28',
        name: 'Giresun',
      },
      {
        code: 'TR-29',
        name: 'Gümüşhane',
      },
      {
        code: 'TR-30',
        name: 'Hakkari',
      },
      {
        code: 'TR-31',
        name: 'Hatay',
      },
      {
        code: 'TR-76',
        name: 'Iğdir',
      },
      {
        code: 'TR-32',
        name: 'Isparta',
      },
      {
        code: 'TR-33',
        name: 'İçel',
      },
      {
        code: 'TR-34',
        name: 'İstanbul',
      },
      {
        code: 'TR-35',
        name: 'İzmir',
      },
      {
        code: 'TR-46',
        name: 'Kahramanmaraş',
      },
      {
        code: 'TR-78',
        name: 'Karabük',
      },
      {
        code: 'TR-70',
        name: 'Karaman',
      },
      {
        code: 'TR-36',
        name: 'Kars',
      },
      {
        code: 'TR-37',
        name: 'Kastamonu',
      },
      {
        code: 'TR-38',
        name: 'Kayseri',
      },
      {
        code: 'TR-71',
        name: 'Kırıkkale',
      },
      {
        code: 'TR-39',
        name: 'Kırklareli',
      },
      {
        code: 'TR-40',
        name: 'Kırşehir',
      },
      {
        code: 'TR-79',
        name: 'Kilis',
      },
      {
        code: 'TR-41',
        name: 'Kocaeli',
      },
      {
        code: 'TR-42',
        name: 'Konya',
      },
      {
        code: 'TR-43',
        name: 'Kütahya',
      },
      {
        code: 'TR-44',
        name: 'Malatya',
      },
      {
        code: 'TR-4S',
        name: 'Manisa',
      },
      {
        code: 'TR-47',
        name: 'Mardin',
      },
      {
        code: 'TR-48',
        name: 'Muğla',
      },
      {
        code: 'TR-49',
        name: 'Muş',
      },
      {
        code: 'TR-SO',
        name: 'Nevşehir',
      },
      {
        code: 'TR-51',
        name: 'Niğde',
      },
      {
        code: 'TR-52',
        name: 'Ordu',
      },
      {
        code: 'TR-53',
        name: 'Rize',
      },
      {
        code: 'TR-54',
        name: 'Sakarya',
      },
      {
        code: 'TR-SS',
        name: 'Samsun',
      },
      {
        code: 'TR-56',
        name: 'Siirt',
      },
      {
        code: 'TR-57',
        name: 'Sinop',
      },
      {
        code: 'TR-S8',
        name: 'Sivas',
      },
      {
        code: 'TR-63',
        name: 'Şanlıurfa',
      },
      {
        code: 'TR-73',
        name: 'Şirnak',
      },
      {
        code: 'TR-59',
        name: 'Tekirdağ',
      },
      {
        code: 'TR-60',
        name: 'Tokat',
      },
      {
        code: 'TR-61',
        name: 'Trabzon',
      },
      {
        code: 'TR-62',
        name: 'Tunceli',
      },
      {
        code: 'TR-64',
        name: 'Uşak',
      },
      {
        code: 'TR-65',
        name: 'Van',
      },
      {
        code: 'TR-77',
        name: 'Yalova',
      },
      {
        code: 'TR-66',
        name: 'Yozgat',
      },
      {
        code: 'TR-67',
        name: 'Zonguldak',
      },
    ],
  },
  {
    name: 'Turkmenistan',
    alpha_3: 'TKM',
    country_code: '795',
    divisions: [
      {
        code: 'TM-A',
        name: 'Ahal',
      },
      {
        code: 'TM-B',
        name: 'Balkan',
      },
      {
        code: 'TM-D',
        name: 'Daşhowuz',
      },
      {
        code: 'TM-L',
        name: 'Lebap',
      },
      {
        code: 'TM-M',
        name: 'Mary',
      },
    ],
  },
  {
    name: 'Turks and Caicos Islands',
    alpha_3: 'TCA',
    country_code: '796',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Tuvalu',
    alpha_3: 'TUV',
    country_code: '798',
    divisions: [
      {
        code: 'TV-TV',
        name: 'Tuvalu',
      },
    ],
  },
  {
    name: 'Uganda',
    alpha_3: 'UGA',
    country_code: '800',
    divisions: [
      {
        code: 'UG-APA',
        name: 'Apac',
      },
      {
        code: 'UG-ARU',
        name: 'Arua',
      },
      {
        code: 'UG-BUN',
        name: 'Bundibugyo',
      },
      {
        code: 'UG-BUS',
        name: 'Bushenyi',
      },
      {
        code: 'UG-GUL',
        name: 'Gulu',
      },
      {
        code: 'UG-HOI',
        name: 'Hoima',
      },
      {
        code: 'UG-IGA',
        name: 'Iganga',
      },
      {
        code: 'UG-JIN',
        name: 'Jinja',
      },
      {
        code: 'UG-KBL',
        name: 'Kabale',
      },
      {
        code: 'UG-KBR',
        name: 'Kabarole',
      },
      {
        code: 'UG-KLG',
        name: 'Kalangala',
      },
      {
        code: 'UG-KLA',
        name: 'Kampala',
      },
      {
        code: 'UG-KLI',
        name: 'Kamuli',
      },
      {
        code: 'UG-KAP',
        name: 'Kapchorwa',
      },
      {
        code: 'UG-KAS',
        name: 'Kasese',
      },
      {
        code: 'UG-KLE',
        name: 'Kibaale',
      },
      {
        code: 'UG-KIB',
        name: 'Kiboga',
      },
      {
        code: 'UG-KIS',
        name: 'Kisoro',
      },
      {
        code: 'UG-KIT',
        name: 'Kitgum',
      },
      {
        code: 'UG-KOT',
        name: 'Kotido',
      },
      {
        code: 'UG-KUM',
        name: 'Kumi',
      },
      {
        code: 'UG-LIR',
        name: 'Lira',
      },
      {
        code: 'UG-LUW',
        name: 'Luwero',
      },
      {
        code: 'UG-MSK',
        name: 'Masaka',
      },
      {
        code: 'UG-MSI',
        name: 'Masindi',
      },
      {
        code: 'UG-MBL',
        name: 'Mbale',
      },
      {
        code: 'UG-MBR',
        name: 'Mbarara',
      },
      {
        code: 'UG-MOR',
        name: 'Moroto',
      },
      {
        code: 'UG-MOY',
        name: 'Moyo',
      },
      {
        code: 'UG-MPI',
        name: 'Mpigi',
      },
      {
        code: 'UG-MUB',
        name: 'Mubende',
      },
      {
        code: 'UG-MUK',
        name: 'Mukono',
      },
      {
        code: 'UG-NEB',
        name: 'Nebbi',
      },
      {
        code: 'UG-NTU',
        name: 'Ntungamo',
      },
      {
        code: 'UG-PAL',
        name: 'Pallisa',
      },
      {
        code: 'UG-RAK',
        name: 'Rakai',
      },
      {
        code: 'UG-RUK',
        name: 'Rukungiri',
      },
      {
        code: 'UG-SOR',
        name: 'Soroti',
      },
      {
        code: 'UG-TOR',
        name: 'Tororo',
      },
    ],
  },
  {
    name: 'Ukraine',
    alpha_3: 'UKR',
    country_code: '804',
    divisions: [
      {
        code: 'UA-71',
        name: "Cherkas'ka Oblast'",
      },
      {
        code: 'UA-74',
        name: "Chernihivs'ka Oblast'",
      },
      {
        code: 'UA-77',
        name: "Chernivets'ka Oblast'",
      },
      {
        code: 'UA-12',
        name: "Dnipropetrovs'ka Oblast'",
      },
      {
        code: 'UA-14',
        name: "Donets'ka Oblast'",
      },
      {
        code: 'UA-26',
        name: "Ivano-Frankivs'ka Oblast'",
      },
      {
        code: 'UA-63',
        name: "Kharkivs'ka Oblast'",
      },
      {
        code: 'UA-65',
        name: "Khersons'ka Oblast'",
      },
      {
        code: 'UA-68',
        name: "Khmel'nyts'ka Oblast'",
      },
      {
        code: 'UA-35',
        name: "Kirovohrads'ka Oblast'",
      },
      {
        code: 'UA-32',
        name: "Kyïvs'ka Oblast'",
      },
      {
        code: 'UA-09',
        name: "Luhans'ka Oblast'",
      },
      {
        code: 'UA-46',
        name: "L'vivs'ka Oblast'",
      },
      {
        code: 'UA-48',
        name: "Mykolaïvs'ka Oblast'",
      },
      {
        code: 'UA-51',
        name: "Odes'ka Oblast'",
      },
      {
        code: 'UA-53',
        name: "Poltavs'ka Oblast'",
      },
      {
        code: 'UA-56',
        name: "Rivnens'ka Oblast'",
      },
      {
        code: 'UA-59',
        name: "Sums'ka Oblast'",
      },
      {
        code: 'UA-61',
        name: "Ternopil's'ka Oblast'",
      },
      {
        code: 'UA-05',
        name: "Vinnyts'ka Oblast'",
      },
      {
        code: 'UA-07',
        name: "Volyns'ka Oblast'",
      },
      {
        code: 'UA-21',
        name: "Zakarpats'ka Oblast'",
      },
      {
        code: 'UA-23',
        name: "Zaporiz'ka Oblast'",
      },
      {
        code: 'UA-18',
        name: "Zhytomyrs'ka Oblast'",
      },
      {
        code: 'UA-43',
        name: 'Respublika Krym',
      },
      {
        code: 'UA-30',
        name: 'Kyïv',
      },
      {
        code: 'UA-40',
        name: "Sevastopol'",
      },
    ],
  },
  {
    name: 'United Arab Emirates',
    alpha_3: 'ARE',
    country_code: '784',
    divisions: [
      {
        code: 'AE-AZ',
        name: 'Abū Zaby (Abu Dhabi)',
      },
      {
        code: 'AE-AJ',
        name: '‘Ajmān',
      },
      {
        code: 'AE-FU',
        name: 'Al Fujayrah',
      },
      {
        code: 'AE-SH',
        name: 'Ash Shāriqah (Sharjah)',
      },
      {
        code: 'AE-DU',
        name: 'Dubayy (Dubai)',
      },
      {
        code: 'AE-RK',
        name: "R'as al Khaymah",
      },
      {
        code: 'AE-UQ',
        name: 'Umm al Qaywayn',
      },
    ],
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha_3: 'UMI',
    country_code: '581',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Uruguay',
    alpha_3: 'URY',
    country_code: '858',
    divisions: [
      {
        code: 'UY-AR',
        name: 'Artigas',
      },
      {
        code: 'UY-CA',
        name: 'Canelones',
      },
      {
        code: 'UY-CL',
        name: 'Cerro Largo',
      },
      {
        code: 'UY-CO',
        name: 'Colonia',
      },
      {
        code: 'UY-DU',
        name: 'Durazno',
      },
      {
        code: 'UY-FS',
        name: 'Flores',
      },
      {
        code: 'UY-FD',
        name: 'Florida',
      },
      {
        code: 'UY-LA',
        name: 'Lavalleja',
      },
      {
        code: 'UY-MA',
        name: 'Maldonado',
      },
      {
        code: 'UY-MO',
        name: 'Montevideo',
      },
      {
        code: 'UY-PA',
        name: 'Paysandú',
      },
      {
        code: 'UY-RN',
        name: 'Río Negro',
      },
      {
        code: 'UY-RV',
        name: 'Rivera',
      },
      {
        code: 'UY-RO',
        name: 'Rocha',
      },
      {
        code: 'UY-SA',
        name: 'Salto',
      },
      {
        code: 'UY-SJ',
        name: 'San José',
      },
      {
        code: 'UY-SO',
        name: 'Soriano',
      },
      {
        code: 'UY-TA',
        name: 'Tacuarembó',
      },
      {
        code: 'UY-TT',
        name: 'Treinta y Tres',
      },
    ],
  },
  {
    name: 'Uzbekistan',
    alpha_3: 'UZB',
    country_code: '860',
    divisions: [
      {
        code: 'UZ-QR',
        name: 'Qoraqalpoghiston Respublikasi (Karakalpakstan, Respublika)',
      },
      {
        code: 'UZ-AN',
        name: 'Andijon (Andizhan)',
      },
      {
        code: 'UZ-BU',
        name: 'Bukhoro (Bukhara)',
      },
      {
        code: 'UZ-FA',
        name: 'Farghona (Fergana)',
      },
      {
        code: 'UZ-JI',
        name: 'Jizzakh (Dzhizak)',
      },
      {
        code: 'UZ-KH',
        name: 'Khorazm (Khorezm)',
      },
      {
        code: 'UZ-NG',
        name: 'Namangan',
      },
      {
        code: 'UZ-NW',
        name: 'Nawoiy (Navoi)',
      },
      {
        code: 'UZ-QA',
        name: "Qashqadaryo (Kashkadar'ya)",
      },
      {
        code: 'UZ-SA',
        name: 'Samarqand (Samarkand)',
      },
      {
        code: 'UZ-SI',
        name: "Sirdaryo (Syrdar'ya)",
      },
      {
        code: 'UZ-SU',
        name: "Surkhondaryo (Surkhandar'ya)",
      },
      {
        code: 'UZ-TO',
        name: 'Toshkent (Tashkent)',
      },
    ],
  },
  {
    name: 'Vanuatu',
    alpha_3: 'VUT',
    country_code: '548',
    divisions: [
      {
        code: 'VU-MAP',
        name: 'Malampa',
      },
      {
        code: 'VU-PAM',
        name: 'Pénama',
      },
      {
        code: 'VU-SAM',
        name: 'Sanma',
      },
      {
        code: 'VU-SEE',
        name: 'Shéfa',
      },
      {
        code: 'VU-TAE',
        name: 'Taféa',
      },
      {
        code: 'VU-TOB',
        name: 'Torba',
      },
    ],
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha_3: 'VEN',
    country_code: '862',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Viet Nam',
    alpha_3: 'VNM',
    country_code: '704',
    divisions: [
      {
        code: 'VN-44',
        name: 'An Giang',
      },
      {
        code: 'VN-53',
        name: 'Bat Can',
      },
      {
        code: 'VN-54',
        name: 'Bat Giang',
      },
      {
        code: 'VN-55',
        name: 'Bat Lieu',
      },
      {
        code: 'VN-56',
        name: 'Bat Ninh',
      },
      {
        code: 'VN-43',
        name: 'Ba Ria - Vung Tau',
      },
      {
        code: 'VN-50',
        name: 'Ben Tre',
      },
      {
        code: 'VN-31',
        name: 'Binh Dinh',
      },
      {
        code: 'VN-57',
        name: 'Binh Duong',
      },
      {
        code: 'VN-58',
        name: 'Binh Phuoc',
      },
      {
        code: 'VN-40',
        name: 'Binh Thuan',
      },
      {
        code: 'VN-59',
        name: 'Ca Mau',
      },
      {
        code: 'VN-48',
        name: 'Can Tho',
      },
      {
        code: 'VN-04',
        name: 'Cao Bang',
      },
      {
        code: 'VN-33',
        name: 'Dac Lac',
      },
      {
        code: 'VN-60',
        name: 'Da Nang, thanh pho',
      },
      {
        code: 'VN-39',
        name: 'Dong Nai',
      },
      {
        code: 'VN-45',
        name: 'Dong Thap',
      },
      {
        code: 'VN-30',
        name: 'Gia Lai',
      },
      {
        code: 'VN-03',
        name: 'Ha Giang',
      },
      {
        code: 'VN-61',
        name: 'Hai Duong',
      },
      {
        code: 'VN-62',
        name: 'Hai Phong, thanh pho',
      },
      {
        code: 'VN-63',
        name: 'Ha Nam',
      },
      {
        code: 'VN-64',
        name: 'Ha Noi, thu do',
      },
      {
        code: 'VN-15',
        name: 'Ha Tay',
      },
      {
        code: 'VN-23',
        name: 'Ha Tinh',
      },
      {
        code: 'VN-14',
        name: 'Hoa Binh',
      },
      {
        code: 'VN-65',
        name: 'Ho Chi Minh, thanh po [Sai Gon]',
      },
      {
        code: 'VN-66',
        name: 'Hung Yen',
      },
      {
        code: 'VN-34',
        name: 'Khanh Hoa',
      },
      {
        code: 'VN-47',
        name: 'Kien Giang',
      },
      {
        code: 'VN-28',
        name: 'Kon Turn',
      },
      {
        code: 'VN-01',
        name: 'Lai Chau',
      },
      {
        code: 'VN-35',
        name: 'Lam Dong',
      },
      {
        code: 'VN-09',
        name: 'Lang Son',
      },
      {
        code: 'VN-02',
        name: 'Lao Cai',
      },
      {
        code: 'VN-41',
        name: 'Long An',
      },
      {
        code: 'VN-67',
        name: 'Nam Dinh',
      },
      {
        code: 'VN-22',
        name: 'Nghe An',
      },
      {
        code: 'VN-18',
        name: 'Ninh Binh',
      },
      {
        code: 'VN-36',
        name: 'Ninh Thuan',
      },
      {
        code: 'VN-68',
        name: 'Phu Tho',
      },
      {
        code: 'VN-32',
        name: 'Phu Yen',
      },
      {
        code: 'VN-24',
        name: 'Quang Ninh',
      },
      {
        code: 'VN-27',
        name: 'Quang Nam',
      },
      {
        code: 'VN-29',
        name: 'Quang Ngai',
      },
      {
        code: 'VN-25',
        name: 'Quang Tri',
      },
      {
        code: 'VN-52',
        name: 'Sec Trang',
      },
      {
        code: 'VN-05',
        name: 'Son La',
      },
      {
        code: 'VN-37',
        name: 'Tay Ninh',
      },
      {
        code: 'VN-20',
        name: 'Thai Binh',
      },
      {
        code: 'VN-69',
        name: 'Thai Nguyen',
      },
      {
        code: 'VN-21',
        name: 'Thanh Hoa',
      },
      {
        code: 'VN-26',
        name: 'Thua Thien-Hue',
      },
      {
        code: 'VN-46',
        name: 'Tien Giang',
      },
      {
        code: 'VN-51',
        name: 'Tra Vinh',
      },
      {
        code: 'VN-07',
        name: 'Tuyen Quang',
      },
      {
        code: 'VN-49',
        name: 'Vinh Long',
      },
      {
        code: 'VN-70',
        name: 'Vinh Yen',
      },
      {
        code: 'VN-06',
        name: 'Yen Bai',
      },
    ],
  },
  {
    name: 'Virgin Islands (British)',
    alpha_3: 'VGB',
    country_code: '092',
    divisions: [
      {
        code: 'VG-VG',
        name: 'Virgin Islands (British)',
      },
    ],
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha_3: 'VIR',
    country_code: '850',
    divisions: [
      {
        code: 'VI-VI',
        name: 'Virgin Islands (U.S.)',
      },
    ],
  },
  {
    name: 'Wallis and Futuna',
    alpha_3: 'WLF',
    country_code: '876',
    divisions: [
      {
        code: 'NA',
        name: 'N/A',
      },
    ],
  },
  {
    name: 'Western Sahara',
    alpha_3: 'ESH',
    country_code: '732',
    divisions: [
      {
        code: 'EH-EH',
        name: 'Western Sahara',
      },
    ],
  },
  {
    name: 'Yemen',
    alpha_3: 'YEM',
    country_code: '887',
    divisions: [
      {
        code: 'YE-AB',
        name: 'Abyān',
      },
      {
        code: 'YE-AD',
        name: '‘Adan',
      },
      {
        code: 'YE-BA',
        name: "Al Bayḑā'",
      },
      {
        code: 'YE-HU',
        name: 'Al Ḩudaydah',
      },
      {
        code: 'YE-JA',
        name: 'Al Jawf',
      },
      {
        code: 'YE-MR',
        name: 'Al Mahrah',
      },
      {
        code: 'YE-MW',
        name: 'Al Maḩwit',
      },
      {
        code: 'YE-DH',
        name: 'Dhamār',
      },
      {
        code: 'YE-HD',
        name: 'Ḩaḑramawt',
      },
      {
        code: 'YE-HJ',
        name: 'Ḩajjah',
      },
      {
        code: 'YE-IB',
        name: 'Ibb',
      },
      {
        code: 'YE-LA',
        name: 'Laḩij',
      },
      {
        code: 'YE-MA',
        name: "Ma'rib",
      },
      {
        code: 'YE-SD',
        name: "Şa'dah",
      },
      {
        code: 'YE-SN',
        name: "Şan‘ā'",
      },
      {
        code: 'YE-SH',
        name: 'Shabwah',
      },
      {
        code: 'YE-TA',
        name: 'Ta‘izz',
      },
    ],
  },
  {
    name: 'Zambia',
    alpha_3: 'ZMB',
    country_code: '894',
    divisions: [
      {
        code: 'ZM-02',
        name: 'Central',
      },
      {
        code: 'ZM-08',
        name: 'Copperbelt',
      },
      {
        code: 'ZM-03',
        name: 'Eastern',
      },
      {
        code: 'ZM-04',
        name: 'Luapula',
      },
      {
        code: 'ZM-09',
        name: 'Lusaka',
      },
      {
        code: 'ZM-05',
        name: 'Northern',
      },
      {
        code: 'ZM-06',
        name: 'North-Western',
      },
      {
        code: 'ZM-07',
        name: 'Southern',
      },
      {
        code: 'ZM-01',
        name: 'Western',
      },
    ],
  },
  {
    name: 'Zimbabwe',
    alpha_3: 'ZWE',
    country_code: '716',
    divisions: [
      {
        code: 'ZW-BU',
        name: 'Bulawayo',
      },
      {
        code: 'ZW-HA',
        name: 'Harare',
      },
      {
        code: 'ZW-MA',
        name: 'Manicaland',
      },
      {
        code: 'ZW-MC',
        name: 'Mashonaland Central',
      },
      {
        code: 'ZW-ME',
        name: 'Mashonaland East',
      },
      {
        code: 'ZW-MW',
        name: 'Mashonaland West',
      },
      {
        code: 'ZW-MV',
        name: 'Masvingo',
      },
      {
        code: 'ZW-MN',
        name: 'Matabeleland North',
      },
      {
        code: 'ZW-MS',
        name: 'Matabeleland South',
      },
      {
        code: 'ZW-MI',
        name: 'Midlands',
      },
    ],
  },
]

export const COUNTRIES = [
  {
    name: 'United States',
    code: 'USA',
    timezone: 'Pacific Standard Time',
    utc: 'UTC-08:00',
    mobileCode: '+1',
  },
  {
    name: 'United Kingdom',
    code: 'GBR',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44',
  },
  {
    name: 'Canada',
    code: 'CAN',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1',
  },
  {
    name: 'Afghanistan',
    code: 'AF',
    timezone: 'Afghanistan Standard Time',
    utc: 'UTC+04:30',
    mobileCode: '+93',
  },
  {
    name: 'Åland Islands',
    code: 'AX',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+358-18',
  },
  {
    name: 'Albania',
    code: 'AL',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+355',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+213',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+1-684',
  },
  {
    name: 'Andorra',
    code: 'AD',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+376',
  },
  {
    name: 'Angola',
    code: 'AO',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+244',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-264',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    timezone: 'Pacific SA Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-268',
  },
  {
    name: 'Argentina',
    code: 'AR',
    timezone: 'Argentina Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+54',
  },
  {
    name: 'Armenia',
    code: 'AM',
    timezone: 'Caucasus Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+374',
  },
  {
    name: 'Aruba',
    code: 'AW',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+297',
  },
  {
    name: 'Australia',
    code: 'AU',
    timezone: 'AUS Eastern Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+61',
  },
  {
    name: 'Austria',
    code: 'AT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+43',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    timezone: 'Azerbaijan Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+994',
  },
  {
    name: 'Bahamas, The',
    code: 'BS',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-242',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+973',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    timezone: 'Bangladesh Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+880',
  },
  {
    name: 'Barbados',
    code: 'BB',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-246',
  },
  {
    name: 'Belarus',
    code: 'BY',
    timezone: 'Belarus Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+375',
  },
  {
    name: 'Belgium',
    code: 'BE',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+32',
  },
  {
    name: 'Belize',
    code: 'BZ',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+501',
  },
  {
    name: 'Benin',
    code: 'BJ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+229',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    timezone: 'Atlantic Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-441',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    timezone: 'Bangladesh Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+975',
  },
  {
    name: 'Bolivarian Republic of Venezuela',
    code: 'VE',
    timezone: 'Venezuela Standard Time',
    utc: 'UTC-04:30',
    mobileCode: '+58',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+591',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+387',
  },
  {
    name: 'Botswana',
    code: 'BW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+267',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    timezone: 'UTC',
    utc: 'UTC',
    mobileCode: '+',
  },
  {
    name: 'Brazil',
    code: 'BR',
    timezone: 'E. South America Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+246',
  },
  {
    name: 'Brunei',
    code: 'BN',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+673',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+359',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+226',
  },
  {
    name: 'Burundi',
    code: 'BI',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+257',
  },
  {
    name: 'Cabo Verde',
    code: 'CV',
    timezone: 'Cape Verde Standard Time',
    utc: 'UTC-01:00',
    mobileCode: '+238',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+855',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+237',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-345',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+236',
  },
  {
    name: 'Chad',
    code: 'TD',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+235',
  },
  {
    name: 'Chile',
    code: 'CL',
    timezone: 'Pacific SA Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+56',
  },
  {
    name: 'China',
    code: 'CN',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+86',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    timezone: 'Myanmar Standard Time',
    utc: 'UTC+06:30',
    mobileCode: '+61',
  },
  {
    name: 'Colombia',
    code: 'CO',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+57',
  },
  {
    name: 'Comoros',
    code: 'KM',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+269',
  },
  {
    name: 'Congo',
    code: 'CG',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+242',
  },
  {
    name: 'Congo (DRC)',
    code: 'CD',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+243',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    timezone: 'Hawaiian Standard Time',
    utc: 'UTC-10:00',
    mobileCode: '+682',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+506',
  },
  {
    name: "Côte d'Ivoire",
    code: 'CI',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+225',
  },
  {
    name: 'Croatia',
    code: 'HR',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+385',
  },
  {
    name: 'Cuba',
    code: 'CU',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+53',
  },
  {
    name: 'Curaçao',
    code: 'CW',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    timezone: 'E. Europe Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+357',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+420',
  },
  {
    name: 'Democratic Republic of Timor-Leste',
    code: 'TL',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+670',
  },
  {
    name: 'Denmark',
    code: 'DK',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+45',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+253',
  },
  {
    name: 'Dominica',
    code: 'DM',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-767',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-809 and 1-829',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+593',
  },
  {
    name: 'Egypt',
    code: 'EG',
    timezone: 'Egypt Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+20',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+503',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+240',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+291',
  },
  {
    name: 'Estonia',
    code: 'EE',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+372',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+251',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'FK',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+500',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+298',
  },
  {
    name: 'Fiji Islands',
    code: 'FJ',
    timezone: 'Fiji Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+679',
  },
  {
    name: 'Finland',
    code: 'FI',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+358',
  },
  {
    name: 'France',
    code: 'FR',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+33',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+594',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    timezone: 'Hawaiian Standard Time',
    utc: 'UTC-10:00',
    mobileCode: '+689',
  },
  {
    name: 'French Southern and Antarctic Lands',
    code: 'TF',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+',
  },
  {
    name: 'Gabon',
    code: 'GA',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+241',
  },
  {
    name: 'Gambia, The',
    code: 'GM',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+220',
  },
  {
    name: 'Georgia',
    code: 'GE',
    timezone: 'Georgian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+995',
  },
  {
    name: 'Germany',
    code: 'DE',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+49',
  },
  {
    name: 'Ghana',
    code: 'GH',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+233',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+350',
  },
  {
    name: 'Greece',
    code: 'GR',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+30',
  },
  {
    name: 'Greenland',
    code: 'GL',
    timezone: 'Greenland Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+299',
  },
  {
    name: 'Grenada',
    code: 'GD',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-473',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590',
  },
  {
    name: 'Guam',
    code: 'GU',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+1-671',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+502',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1481',
  },
  {
    name: 'Guinea',
    code: 'GN',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+224',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+245',
  },
  {
    name: 'Guyana',
    code: 'GY',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+592',
  },
  {
    name: 'Haiti',
    code: 'HT',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+ ',
  },
  {
    name: 'Honduras',
    code: 'HN',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+504',
  },
  {
    name: 'Hong Kong SAR',
    code: 'HK',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+852',
  },
  {
    name: 'Hungary',
    code: 'HU',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+36',
  },
  {
    name: 'Iceland',
    code: 'IS',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+354',
  },
  {
    name: 'India',
    code: 'IN',
    timezone: 'India Standard Time',
    utc: 'UTC+05:30',
    mobileCode: '+91',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+62',
  },
  {
    name: 'Iran',
    code: 'IR',
    timezone: 'Iran Standard Time',
    utc: 'UTC+03:30',
    mobileCode: '+98',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    timezone: 'Arabic Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+964',
  },
  {
    name: 'Ireland',
    code: 'IE',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+353',
  },
  {
    name: 'Israel',
    code: 'IL',
    timezone: 'Israel Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+972',
  },
  {
    name: 'Italy',
    code: 'IT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+39',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-876',
  },
  {
    name: 'Jan Mayen',
    code: 'SJ',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47',
  },
  {
    name: 'Japan',
    code: 'JP',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+81',
  },
  {
    name: 'Jersey',
    code: 'JE',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1534',
  },
  {
    name: 'Jordan',
    code: 'JO',
    timezone: 'Jordan Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+962',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+7',
  },
  {
    name: 'Kenya',
    code: 'KE',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+254',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+686',
  },
  {
    name: 'Korea',
    code: 'KR',
    timezone: 'Korea Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+82',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+965',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+996',
  },
  {
    name: 'Laos',
    code: 'LA',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+856',
  },
  {
    name: 'Latvia',
    code: 'LV',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+371',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    timezone: 'Middle East Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+961',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+266',
  },
  {
    name: 'Liberia',
    code: 'LR',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+231',
  },
  {
    name: 'Libya',
    code: 'LY',
    timezone: 'E. Europe Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+218',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+423',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+370',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+352',
  },
  {
    name: 'Macao SAR',
    code: 'MO',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+853',
  },
  {
    name: 'Macedonia, Former Yugoslav Republic of',
    code: 'MK',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+389',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+261',
  },
  {
    name: 'Malawi',
    code: 'MW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+265',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+60',
  },
  {
    name: 'Maldives',
    code: 'MV',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+960',
  },
  {
    name: 'Mali',
    code: 'ML',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+223',
  },
  {
    name: 'Malta',
    code: 'MT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+356',
  },
  {
    name: 'Man, Isle of',
    code: 'IM',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1624',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+692',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+596',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+222',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+230',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+262',
  },
  {
    name: 'Mexico',
    code: 'MX',
    timezone: 'Central Standard Time (Mexico)',
    utc: 'UTC-06:00',
    mobileCode: '+52',
  },
  {
    name: 'Micronesia',
    code: 'FM',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+691',
  },
  {
    name: 'Moldova',
    code: 'MD',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+373',
  },
  {
    name: 'Monaco',
    code: 'MC',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+377',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    timezone: 'Ulaanbaatar Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+976',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+382',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-664',
  },
  {
    name: 'Morocco',
    code: 'MA',
    timezone: 'Morocco Standard Time',
    utc: 'UTC',
    mobileCode: '+212',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+258',
  },
  {
    name: 'Myanmar',
    code: 'MM',
    timezone: 'Myanmar Standard Time',
    utc: 'UTC+06:30',
    mobileCode: '+95',
  },
  {
    name: 'Namibia',
    code: 'NA',
    timezone: 'Namibia Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+264',
  },
  {
    name: 'Nauru',
    code: 'NR',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+674',
  },
  {
    name: 'Nepal',
    code: 'NP',
    timezone: 'Nepal Standard Time',
    utc: 'UTC+05:45',
    mobileCode: '+977',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+31',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+687',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    timezone: 'New Zealand Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+64',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+505',
  },
  {
    name: 'Niger',
    code: 'NE',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+227',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+234',
  },
  {
    name: 'Niue',
    code: 'NU',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+683',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+672',
  },
  {
    name: 'North Korea',
    code: 'KP',
    timezone: 'Korea Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+850',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+1-670',
  },
  {
    name: 'Norway',
    code: 'NO',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47',
  },
  {
    name: 'Oman',
    code: 'OM',
    timezone: 'Arabian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+968',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    timezone: 'Pakistan Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+92',
  },
  {
    name: 'Palau',
    code: 'PW',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+680',
  },
  {
    name: 'Palestinian Authority',
    code: 'PS',
    timezone: 'Egypt Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+970',
  },
  {
    name: 'Panama',
    code: 'PA',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+507',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+675',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    timezone: 'Paraguay Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+595',
  },
  {
    name: 'Peru',
    code: 'PE',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+51',
  },
  {
    name: 'Philippines',
    code: 'PH',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+63',
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    timezone: 'Pacific Standard Time',
    utc: 'UTC-08:00',
    mobileCode: '+870',
  },
  {
    name: 'Poland',
    code: 'PL',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+48',
  },
  {
    name: 'Portugal',
    code: 'PT',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+351',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-787 and 1-939',
  },
  {
    name: 'Qatar',
    code: 'QA',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+974',
  },
  {
    name: 'Reunion',
    code: 'RE',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+262',
  },
  {
    name: 'Romania',
    code: 'RO',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+40',
  },
  {
    name: 'Russia',
    code: 'RU',
    timezone: 'Russian Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+7',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+250',
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-869',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-758',
  },
  {
    name: 'Saint Martin (French part)',
    code: 'MF',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    timezone: 'Greenland Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-784',
  },
  {
    name: 'Samoa',
    code: 'WS',
    timezone: 'Samoa Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+685',
  },
  {
    name: 'San Marino',
    code: 'SM',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+378',
  },
  {
    name: 'São Tomé and Príncipe',
    code: 'ST',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+239',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+966',
  },
  {
    name: 'Senegal',
    code: 'SN',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+221',
  },
  {
    name: 'Serbia',
    code: 'RS',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+381',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+248',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+232',
  },
  {
    name: 'Singapore',
    code: 'SG',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+65',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+421',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+386',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+677',
  },
  {
    name: 'Somalia',
    code: 'SO',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+252',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    timezone: 'UTC-02',
    utc: 'UTC-02:00',
    mobileCode: '+',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+211',
  },
  {
    name: 'Spain',
    code: 'ES',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+34',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    timezone: 'Sri Lanka Standard Time',
    utc: 'UTC+05:30',
    mobileCode: '+94',
  },
  {
    name: 'Sudan',
    code: 'SD',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+249',
  },
  {
    name: 'Suriname',
    code: 'SR',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+597',
  },
  {
    name: 'Svalbard',
    code: 'SJ',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+268',
  },
  {
    name: 'Sweden',
    code: 'SE',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+46',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+41',
  },
  {
    name: 'Syria',
    code: 'SY',
    timezone: 'Syria Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+963',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    timezone: 'Taipei Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+886',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+992',
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+255',
  },
  {
    name: 'Thailand',
    code: 'TH',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+66',
  },
  {
    name: 'Togo',
    code: 'TG',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+228',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    timezone: 'Tonga Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+690',
  },
  {
    name: 'Tonga',
    code: 'TO',
    timezone: 'Tonga Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-868',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+216',
  },
  {
    name: 'Turkey',
    code: 'TR',
    timezone: 'Turkey Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+90',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-649',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+688',
  },
  {
    name: 'U.S. Minor Outlying Islands',
    code: 'UM',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+1',
  },
  {
    name: 'Uganda',
    code: 'UG',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+256',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+380',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    timezone: 'Arabian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+971',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    timezone: 'Montevideo Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+598',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+998',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+678',
  },
  {
    name: 'Vatican City',
    code: 'VA',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+379',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+84',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-340',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-284',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+681',
  },
  {
    name: 'Yemen',
    code: 'YE',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+967',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+260',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+263',
  },
]
