/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useMemo, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {useQuery} from 'react-query'
import useAppRequests from '../../modules/application/Hooks/useAppRequests'
import _queryKeys from '../../modules/application/core/_queryKeys'
import YourPrograms from '../../modules/application/components/Dashboard/YourPrograms'

import {ROLES} from '../../routing/core/_models'
import ConfirmPayBanner from '../../modules/application/components/ConfirmPay/ConfirmPayBanner'
import useRole from '../../modules/auth/hooks/useRole'
import {useProductRequests} from '../../../app/modules/apps/products-management/Hooks/useProductRequests'
import {QUERIES, stringifyRequestQuery, KTSVG as KtSvg} from '../../../_metronic/helpers'
import {useQueryRequest} from '../../../app/modules/Shared/core/QueryRequestProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { IEnrollmentSummary } from '../../modules/application/core/_models'
import EnrolledDashboard from '../../NewTerra/EnrolledDashboard'
import { Alert, Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../axiosInstance'
import '../../../app/NewTerra/DashboardWrapper.scss'
import { downloadFile } from '../../../network/api'
import SavedProgress from '../../NewTerra/SavedProgress'
const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  const {getAllEnrollmentSummariesReq} = useAppRequests()

  const {
    isLoading,
    isError,
    data: getEnrollments,
    refetch,
  } = useQuery(_queryKeys.getAllEnrollmentSummariesReq, getAllEnrollmentSummariesReq, {
    refetchInterval: 300000,
  })

  const oppLenght: any = getEnrollments || []

  if (isLoading) {
    return (
      <>
      <div className='w-100 my-10'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <output className='spinner-border text-primary'>
            <span className='visually-hidden'>Loading...</span>
          </output>
        </div>
      </div>
      </>
    )
  } else {
    if (oppLenght.length !== 0) {
      return (
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-lg-12'>
              <EnrolledDashboard data={getEnrollments} refetch = {refetch}/>
            </div>
          </div>
        </>
      )
    }
    return null;
  }
}

const DashboardAdmin: FC = () => {
  const {getAllProductsReq} = useProductRequests()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PRODUCTS_LIST}-${query}`,
    () => {
      return getAllProductsReq(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const HandleClickSyncProducts = () => {
    refetch()
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div>Hello Admin</div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {isAdminOrSuperAdmin} = useRole()
  const {client} = useParams()
  const [isProfileComplete, setIsProfileComplete] = useState(false)
  const [showIncompleteProfileModal, setShowIncompleteProfileModal] = useState(false)
  const [isProfileCheckLoading, setIsProfileCheckLoading] = useState(true) // Add load

  const navigate = useNavigate()

  const {data: enrollmentSummary} = useQuery<IEnrollmentSummary[]>(
    _queryKeys.getAllEnrollmentSummariesReq
  )

  const checkProfileCompletion = async () => {
    try {
      const response = await axiosPrivate.get('/auth/check-prerequisite')
      return response.data.statusCode === 10 // Assuming this means profile is complete
    } catch (error) {
      console.error('Error checking profile:', error)
      return false
    }
  }


  useEffect(() => {
    if (currentUser?.role === ROLES.parent) { // Check if the user is a parent
      const initialProfileCheck = async () => {
        setIsProfileCheckLoading(true) // Set loading state to true before starting the check
        const profileComplete = await checkProfileCompletion()
        setIsProfileComplete(profileComplete)
        setIsProfileCheckLoading(false) // Set loading state to false after check completes

        if (!profileComplete) {
          setShowIncompleteProfileModal(true)
        }
      }

      initialProfileCheck()
    }
  }, [currentUser?.role])

  const subheader = () => {
    if (!enrollmentSummary) {
      return ''
    }
    // if (enrollmentSummary[0].opp_status.name === 'Incomplete - Deposit Due'
    // || enrollmentSummary[0].opp_status.name === 'Incomplete - Deposit Paid') {
    //   return 'YOUR APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE'
    // }
    // if (enrollmentSummary[0].opp_status.name === 'Applied - Pending') {
    //   return 'APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE'
    // }
    if (enrollmentSummary[0].opp_status.name === 'Enrolled - Paid') {
      return 'YOUR APPLICATION IS COMPLETE, PENDING RETURN OF PRE-DEPARTURE FORMS AND PAYING TUITION BALANCE.'
    }
    return `YOUR APPLICATION IS ${enrollmentSummary[0].opp_status.name}.`
  }
  
  const clientRoutes = client === '47821104' ? 'Click to view the SSB Travel Portal to access additional resources'
  : client === '88562775' ? 'Click to view the TTN Travel Portal to access additional resources' 
  : 'Click to view the GLA Travel Portal to access additional resources'

  const clientRoutesNavigation = client === '47821104' ? 'https://summerspringboard.com'
  : client === '88562775' ? 'https://sites.google.com/teentravelnetwork.com/travel-portal' 
  : 'https://sites.google.com/teentravelnetwork.com/travel-portal'

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const monthIndex = new Date().getMonth();
  const monthName = months[monthIndex];
  const SSB_Note = client === '47821104' ? `PLEASE NOTE: We will follow up with an email in ${monthName} when the required forms for your program are available.`: 
  'PLEASE NOTE: Your program has extra requirements that are not listed below. Please see the Travel Portal or your Welcome Email for additional forms!'

  const clientTelNumberRoute = client === '47821104' ? '858-780-5660' : '858-771-4886'

  const closeModal = () => {
    setShowIncompleteProfileModal(false);
  };

  const navigateToUpdateProfile = () => {
    navigate(`/profile/${currentUser?.currentClient}`);
    closeModal();
  };

  const downloadPdfHandler = async () => {
    downloadFile()
  };

  const [savedProgress, setSavedProgress] = useState<any>(null)
  useEffect(() => {
    const fetchSavedApplications =  async () => {
      try {
        const response = await axiosPrivate.get('/save-chages/get')
        setSavedProgress(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    
    fetchSavedApplications()
  }, [])


  return (
    <div>
      {!isAdminOrSuperAdmin && (
        <>
          <div className='d-flex align-items-center position-relative'>
            <ConfirmPayBanner title={`${currentUser?.lastName} Family Dashboard`}/>
          </div>
          <Alert variant="info" className="d-flex align-items-center dashboard-container">
            <i className="fas fa-info-circle me-2" style={{ fontSize: '2rem', color: 'lightskyblue' }}></i>
            <p className="mb-0">
              Hello and welcome to your Summer Springboard Dashboard! Here you’ll find all the
              information you need to manage your account, track your progress, and stay updated on
              upcoming events and opportunities. Click the Create Application Button on the left Menu
              to start your application.
            </p>
          </Alert>
        </>
      )}
      <div className='alert alert-link d-flex align-items-center p-5 w-100'>
        <div className='d-flex flex-column w-100'>
          {currentUser?.role === ROLES.superAdmin ? <DashboardAdmin /> : (
            <>
            <SavedProgress savedProgress={savedProgress} />
            <DashboardPage />
            </>
          )}
        </div>
        {currentUser?.role === ROLES.parent && !isProfileCheckLoading && (
        <Modal show={showIncompleteProfileModal} keyboard={false} backdrop='static' onHide={closeModal}>
          <Modal.Header>
            <Modal.Title>Incomplete Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your profile is not yet completed. Would you like to update it now?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={navigateToUpdateProfile}>
              Update Profile
            </Button>
          </Modal.Footer>
        </Modal>
        )}
      </div>
    </div>
  )
}

export {DashboardWrapper}
