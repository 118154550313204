import React, { useEffect, useState, useRef } from 'react';
import './StudentType.scss';

interface Perks {
  includes: string;
  excludes: string;
}

interface StudentTypeProps {
  formData: any;
  setFormData: (data: any) => void;
}

const StudentType: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
  const [selectedStudentType, setSelectedStudentType] = useState<string | null>(formData.student_Type || null);
  
  // Ref to track the first render
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!selectedStudentType) return; // Do nothing if no student type selected

    if (!isFirstRender.current) {
      // Only run this block after the first render
      if (formData.student_Type && formData.student_Type !== selectedStudentType) {
        sessionStorage.removeItem('selectedExtras'); // Clear extras if user changes the type
        setFormData((prevData: any) => ({
          ...prevData,
          student_Type: selectedStudentType,
          enrollment_extras: null, // Clear extras only if student type changes
        }));
      }
    }

    // Set formData on student_Type change
    setFormData((prevData: any) => ({
      ...prevData,
      student_Type: selectedStudentType,
    }));

    // Mark that first render is complete
    isFirstRender.current = false;
  }, [selectedStudentType, setFormData, formData.student_Type]);

  const studentTypeHandler = (type: string) => {
    const newType = selectedStudentType === type ? null : type;
    setSelectedStudentType(newType);
  };
  const residentialPricing = formData.selectedProgram.pricing.find(
    (pricing: any) => pricing.tuitionName.toLowerCase() === 'residential'
  );
  const commuterPricing = formData.selectedProgram.pricing.find(
    (pricing: any) => pricing.tuitionName.toLowerCase() === 'commuter'
  );
  const onlinePricing = formData.selectedProgram.pricing.find(
    (pricing: any) => pricing.tuitionName.toLowerCase() === 'online'
  );

  return (
    <div className='student-type-container' style={{ marginTop: 50 }}>
      {residentialPricing && (
        <div 
          className={`student-card card full-rounded ${selectedStudentType === 'residential' ? 'active' : ''}`} 
          onClick={() => studentTypeHandler('residential')}
        >
          <h2 className='text-capitalize'>Residential</h2>
          <div className='d-flex perks-label'>
            Price: <div className='pricing' style={{ marginBottom: '10px'}}>${residentialPricing?.price || 'N/A'}</div>
          </div>
          <div className=''>
            <p className='perks-text'  style={{ fontSize: '15px'}}>{residentialPricing.description}</p>
          </div>
        </div>
      )}

      {commuterPricing && (
      <div 
        className={`student-card card full-rounded ${selectedStudentType === 'commuter' ? 'active' : ''}`} 
        onClick={() => studentTypeHandler('commuter')}
      >
        <h2 className='text-capitalize'>Commuter</h2>
        <div className='d-flex perks-label'>
          Price: <div className='pricing' style={{ marginBottom: '10px'}}>${commuterPricing?.price || 'N/A'}</div>
        </div>
        <div className=''>
          <div className='perks-text' style={{ fontSize: '15px'}}>{commuterPricing?.description}</div>
        </div>
      </div>
      )}

      {onlinePricing && (
      <div 
        className={`student-card full-rounded ${selectedStudentType === 'online' ? 'active' : ''}`} 
        onClick={() => studentTypeHandler('online')}
      >
        <h2 className='text-capitalize'>Online</h2>
        <div className='d-flex perks-label'>
          Price: <div className='pricing'>${onlinePricing?.price || 'N/A'}</div>
        </div>
        <div className=''>
          <div className='perks-label'>Description: </div>
          <div className='perks-text'>{onlinePricing?.description}</div>
        </div>
      </div>
      )}
    </div>
  );
};

export default StudentType;
