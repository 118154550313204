import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Button, Col, Row, Alert } from 'react-bootstrap';
import './newHomePage.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { axiosInstance } from '../../axiosInstance';
import { Link } from 'react-router-dom';

interface IMessage {
  error: string;
  success: string;
}

const Register = () => {
  const [message, setMessage] = useState<IMessage>({ error: '', success: '' });

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    birth_date: '',
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required('First Name is required')
      .min(2, 'First Name is too short'),
    last_name: Yup.string()
      .required('Last Name is required')
      .min(2, 'Last Name is too short'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    birth_date: Yup.date()
      .required('Birthdate is required')
      .max(new Date(), 'Birthdate cannot be in the future'),
  });

  const onSubmit = async (values: any, action: any) => {
    try {
      const res = await axiosInstance.post('/account-holder/register', values);
      setMessage({ error: '', success: res.data.message || 'Registration successful! Please check your email for verification.' });
      action.resetForm();
    } catch (error: any) {
      setMessage({ error: error.response.data.message || 'Not in valid age for registration', success: '' });
      setTimeout(() => {
        setMessage({ error: '', success: '' });
      }, 5000);
    }
  };

  return (
    <div>
      <img className='logo' src={'https://newterraedu.s3.us-west-1.amazonaws.com/static-images/SSB_LOGO.png'} alt='SSB Logo' height={60} />
      <div className='register-background'>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="6">
              <div className="border p-4">
                {!message.success ? (
                  <>
                    <h2 className="my-4 text-center">Account Registration</h2>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting, isValid }) => (
                        <Form>
                          <div className="mb-3">
                            <label style={{ fontSize: '20px' }} className='fw-bolder' htmlFor="first_name">First Name</label>
                            <Field
                              type="text"
                              name="first_name"
                              className="form-control form-control-lg"
                            />
                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label style={{ fontSize: '20px' }} className='fw-bolder' htmlFor="last_name">Last Name</label>
                            <Field
                              type="text"
                              name="last_name"
                              className="form-control form-control-lg"
                            />
                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label style={{ fontSize: '20px' }} className='fw-bolder' htmlFor="email">Email</label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control form-control-lg"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label style={{ fontSize: '20px' }} className='fw-bolder' htmlFor="birth_date">Date of Birth</label>
                            <Field
                              type="date"
                              name="birth_date"
                              className="form-control form-control-lg"
                            />
                            <ErrorMessage
                              name="birth_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div>
                            <Button
                              style={{ fontSize: '20px' }}
                              className='w-100'
                              type="submit"
                              variant="primary"
                              disabled={isSubmitting || !isValid}
                            >
                              Submit
                            </Button>
                            {message.error && <Alert className='mt-2' variant='danger'>{message.error}</Alert>}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : (
                  <Alert className="mt-4" variant="success">
                    {message.success}
                  </Alert>
                )}
                <p style={{ fontSize: '20px' }} className="mt-3 text-center fw-bolder">
                  Already Registered? <Link to={'/auth'}>Login</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Register;
