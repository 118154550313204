import React, { useEffect, useState } from 'react';
import './ApplicationType.scss';

const ApplicationType = ({ formData, setFormData }: any) => {
  const [selectedApp, setSelectedApp] = useState<string | null>(null);

  useEffect(() => {
    const storedAppType = sessionStorage.getItem('selectedAppType');
    if (storedAppType) {
      setSelectedApp(storedAppType);
      setFormData((prevData: any) => ({ ...prevData, enrollment_track: storedAppType }));
    }
  }, [setFormData]);

  const handleSelect = (track: string) => {
    if (selectedApp === track) {
      setSelectedApp(null);
      setFormData({ ...formData, enrollment_track: null });
      sessionStorage.removeItem('selectedAppType');
    } else {
      setSelectedApp(track);
      setFormData({ ...formData, enrollment_track: track });
      sessionStorage.setItem('selectedAppType', track); 
    }
  };

  return (
    <div className='main-container-app mx-3 mt-5'>
      <div>
        <div  className={`card app-type ${selectedApp === 'express' ? 'selected' : ''}`} onClick={() => handleSelect('express')} >
          <div className='app-title' style={{ marginBottom: '5px' }}>
            Save your spot now (~5 minutes)
          </div>
          <div>
            Pay your application fee and deposit now to reserve your spot.
            Provide additional application information within the next two weeks (medical details, student-answered application questions).
          </div>
        </div>
        <div className="variant-info">
          In all cases, deposit is refundable in case of denied applications
        </div>
      </div>
      <div>
        <div className={`card app-type ${selectedApp === 'finish' ? 'selected' : ''}`} onClick={() => handleSelect('finish')} >
          <div className='app-title' style={{ marginBottom: '5px' }}>
            Complete full application now (NOTE: Student must be available to answer student questions, ~30 minutes)
          </div>
          <div style={{marginBottom: '10px'}}>
            Complete the full application including medical details and student-answered application questions now.
            Only once the application is complete will you pay your application fee and deposit, and we will then hold your spot.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationType;
