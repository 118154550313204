import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import CongratulationBanner from './CongratulationBanner';

interface CongratulationsModalProps {
  show: boolean;
  handleClose: () => void;
}

const CongratulationsModal: React.FC<CongratulationsModalProps> = ({ show, handleClose }) => {
  return (
    <Modal
      className='modal-lg'
      show={show}
      onHide={handleClose}
      centered
      style={{ width: '100%', overflowY: 'auto', margin: 'auto' }}
    >
      <Modal.Header>
        <div className="w-100 d-flex justify-content-center">
          <CongratulationBanner title="Thank you for Applying!" />
        </div>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div style={{ fontSize: '1.25rem', maxWidth: '430px', margin: 'auto' }}>
          <b>
            You have successfully submitted your initial application. A confirmation email will be sent to you shortly.
          </b>
        </div>
        <p className="mt-2 mb-0" style={{ fontSize: '1.25rem', padding: '10px', maxWidth: '500px', margin: 'auto' }}>
          Click below to proceed to your Dashboard to complete your Application.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" onClick={handleClose}>
          <b>Click Here to view your Dashboard</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CongratulationsModal;