import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Traveler } from './BillingInvoice';
import moment from 'moment';
import { currencyFormat } from '../../utils/funcHelpers';
import './PayLedger.scss'
import { axiosPrivate } from '../../axiosInstance';
import { Alert, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import useAppRequests from '../modules/application/Hooks/useAppRequests';
import PaymentMethodInputs from './PaymentMethodInput';

export const methodType = [
  {name: 'Credit Card', type: 'creditCard', label: 'credit_card'},
  {name: 'Debit Card', type: 'debitCard', label: 'debit_card'},
  {name: 'Bank Account', type: 'bankAccount', label: 'bank_account'},
]

interface LocationState {
  selectedPrograms: Traveler[];
}

interface Address {
  address: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
}

export interface Payment {
  id: string;
  orbipay_id: string;
  paymentType: PaymentType 
  subType: string; // This could also be more specific, e.g., 'checking' | 'savings'
  routingNumber: string;
  cardNumber: string; // Consider changing to optional if it's not always a credit card
  cardType?: string | null; // Optional, as it can be null
  expireDates?: string | null; // Optional, as it can be null
  isActive: boolean;
  address: Address;
  account_name: string;
}

type CreditCardInfo = {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
};

type BankAccountInfo = {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: 'checking' | 'savings';
};

type PaymentType = 'creditCard' | 'bankAccount' | 'debitCard';

type Message = {
  success: string;
  error: string;
}

const validationSchema = Yup.object().shape({
  payment_info: Yup.object().shape({
    payment_type: Yup.string().required('Payment type is required'),

    // Shared validation for both credit and debit cards
    creditCardInfo: Yup.object().when('payment_type', {
      is: (method: string) => method === 'creditCard' || method === 'debitCard', // Apply validation only if a new credit card is selected
      then: Yup.object({
        nameOnCard: Yup.string().required('Name on Card is required'),
        cardNumber: Yup.string().required('Card Number is required'),
        cardExpiryMonth: Yup.string().required('Expiry Month is required'),
        cardExpiryYear: Yup.string().required('Expiry Year is required'),
        cardCvv: Yup.string().required('CVV is required'),
        billingAddress: Yup.string().required('Billing Address is required'),
        billingCountry: Yup.string().required('Country is required'),
        billingStateProvince: Yup.string().required('State/Province is required'),
        billingPostalCode: Yup.string().required('Postal Code is required'),
        billingCity: Yup.string().required('City is required'),
      }),
      otherwise: Yup.object().notRequired(), // Do not validate if existing account is selected 
    }),

    // Bank Account Validation
    bankAccountInfo: Yup.object().when('payment_type', {
      is: 'bankAccount',
      then: Yup.object({
        nameOnCard: Yup.string().required('Name on Account is required'),
        routingNumber: Yup.string().required('Routing Number is required'),
        accountNumber: Yup.string().required('Account Number is required'),
        bankType: Yup.string().required('Account Type is required'),
      }),
      otherwise: Yup.object().notRequired(), // Skip validation for existing accounts
    }),
  }),

  // Programs to Pay Validation
  programs_to_pay: Yup.array().of(
    Yup.object().shape({
      pay_amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
    })
  ).min(1, 'You must select at least one program to pay for'),
});


const PayLedger = () => {
  const location = useLocation();
  const { client } = useParams()
  const state = location.state as LocationState;
  const selectedPrograms = state?.selectedPrograms || [];
  const { getAllPaymentMethods } = useAppRequests();
  const {
    data: paymentMethod,
    isLoading: isPaymentMethodLoading
  } = useQuery<any>("getAllPaymentMethods", getAllPaymentMethods);
  const navigate = useNavigate()  
  const [pMethod, setPMethod] = useState<Payment[]>([])
  const [paymentType, setPaymentType] = useState<PaymentType>('bankAccount');
  const [totalAmount, setTotalAmount] = useState(0)
  const [selectPMethod, setSelectPMethod] = useState<string>('');
  const [selectedFunding, setSelectedFunding] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(isPaymentMethodLoading);
  const [message, setMessage] = useState<Message>({
    success: '', 
    error: ''
  })
  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfo>({
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: ''
  });
  
  const [bankAccountInfo, setBankAccountInfo] = useState<BankAccountInfo>({
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: 'checking'
  });

  const [paymentAmounts, setPaymentAmounts] = useState<{ program_uuid: string; pay_amount: string }[]>(
    selectedPrograms.map((program: Traveler) => ({ program_uuid: program.program_uuid, pay_amount: '' })) 
  );

  const handleAmountChange = (program_uuid: string, value: string) => {
    setPaymentAmounts((prevAmounts) =>
      prevAmounts.map((program) =>
        program.program_uuid === program_uuid ? { ...program, pay_amount: value } : program
      )
    );
  };

  const handlePaymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentType(e.target.id as PaymentType);
  };

  const handlePMethodSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPMethod(e.target.value);
    setSelectedFunding(e.target.value);
  };

  const handleInputChange = <
    T extends CreditCardInfo | BankAccountInfo
  >(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    setInfo: React.Dispatch<React.SetStateAction<T>>
  ) => {
    const { name, value } = e.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  
  useEffect(() => {
    if (paymentMethod && paymentMethod?.status === 200) {
      setPMethod(paymentMethod.data?.payment_methods);
      setIsLoading(false);
    }
  }, [paymentMethod])

  const handlePrevStep = () => {
    navigate('/billing-and-invoice/47821104')
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const newFormData = {
      payment_info: {
        isActive: false,
        bankAccountInfo: {
          ...bankAccountInfo,
        },
        creditCardInfo: {
          ...creditCardInfo,
        },
        payment_type: paymentType,
      },
      programs_to_pay: [...paymentAmounts],
      existingPaymentMethod: selectedFunding.includes('funding_') ? selectedFunding : '',
    };
    if (!selectedFunding.includes('funding')) {
      try {
        await validationSchema.validate(newFormData, { abortEarly: false });
      } catch (error: any) {
        setIsLoading(false);
        if (error.name === 'ValidationError') {
          setMessage({ error: error.errors.join(', '), success: '' });
          return;
        }
      }
    }
  
    try {
      const response = await axiosPrivate.post('/pay-program', newFormData);
      if (response.status === 200) {
        setMessage({ success: response.data.message, error: '' });
        setTimeout(() => {
          setMessage({ success: '', error: '' });
          navigate(`/dashboard/${client}`);
        }, 5000);
      }
    } catch (error: any) {
      setMessage({ error: error.message || 'An error occurred', success: '' });
      setTimeout(() => {
        setIsLoading(false)
        setMessage({ success: '', error: '' });
      }, 5000);
    } finally {
      setTimeout(() => {
        setIsLoading(false)
        setMessage({ success: '', error: '' });
      }, 5000);
    }
  };
  
  const calculateTotalAmount = () => {
    const total = paymentAmounts.reduce((acc, program) => acc + (parseFloat(program.pay_amount) || 0), 0);
    setTotalAmount(total);
  };

  useEffect(() => {
    calculateTotalAmount(); 
  }, [paymentAmounts]);

  const pMethodSaved = (!pMethod) ? null : pMethod?.filter((pm) => pm.isActive) 
  const pMethodSavedCredit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'bankAccount')
  const pMethodSavedDebit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'debitCard')

  let ccCount = 0
  let ACHCount = 0
  let dcCount = 0
  if (pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if (pMethodSavedACH) ACHCount = pMethodSavedACH.length
  if (pMethodSavedDebit) dcCount = pMethodSavedDebit.length

  return (
    <>
    <div style={{ background: 'white', padding: '50px 0 20px', position: 'fixed', zIndex: 1000 }} className='d-flex w-100 align-items-center px-10'>
      <div style={{ width: 150 }}>
        <Button className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button" onClick={handlePrevStep}>
            Back
        </Button>
      </div>
      <div
      className='d-flex justify-content-center rounded bg-primary w-50 mx-auto col-md-12'>
        <div className='w-xl-700px  text-center py-4 text-light border-radius-20'>
          <h1 className='fw-bolder fs-2x text-light'>Pay the Remaining Amount</h1>
        </div>
      </div>
    </div>

    <div className="container mx-auto " style={{ position: 'relative', margin: '200px 0 0' }}>
      <h4 className="mb-3">
        <strong>
          Please select a payment method: Credit Card (includes a 2.75% service fee), Debit Card or ACH.
        </strong>
      </h4>
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs">
        {methodType.map((method) => (
          <li className='nav-item col-lg-4' key={method.type}>
            <a
              id={method.type}
              className={paymentType === method.type ? 'nav-link active' : 'nav-link'}
              data-bs-toggle='tab'
              href={`#pmt_${method.label}`}
              onClick={(e: any) => handlePaymentTypeChange(e)}
            >
              {method.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="col-md-6">
        {paymentType === "creditCard" && (<i className='text-muted'>A service fee of 2.75% will be applied to all credit card transactions.</i>)}
      </div>  
      <PaymentMethodInputs
        paymentType={paymentType}
        selectPMethod={selectPMethod}
        pMethodSaved={pMethodSaved}
        pMethodSavedCredit={pMethodSavedCredit}
        pMethodSavedACH={pMethodSavedACH}
        pMethodSavedDebit={pMethodSavedDebit}
        ccCount={ccCount}
        ACHCount={ACHCount}
        dcCount={dcCount}
        handlePMethodSelect={handlePMethodSelect}
        handleInputChange={handleInputChange}
        creditCardInfo={creditCardInfo}
        bankAccountInfo={bankAccountInfo}
        setCreditCardInfo={setCreditCardInfo}
        setBankAccountInfo={setBankAccountInfo}
      />
      {selectedPrograms.length === 0 ? (
        <p>No programs selected for payment.</p>
      ) : (
      <form className="payment-form" onSubmit={onSubmit}>
        <div className='d-flex justify-content-center rounded bg-primary w-25 mx-auto my-5 col-md-12'>
          <div className='w-xl-700px text-center py-2 text-light border-radius-20'>
            <h1 className='fw-bolder fs-2x text-light'>Summary</h1>
          </div>
        </div>
        <div className='selected-programs'>
            {selectedPrograms.map((program: any, index: number) => {
              const campusName = program?.selected_program?.programData.campus;
              const tuitionName = program?.student_type;
              const sessionName = `${program?.selected_session?.session_name} (${moment.utc(program.selected_session.start_date).format('MM/DD/YYYY')} - ${moment.utc(program.selected_session.end_date).format('MM/DD/YYYY')})`;
              const programName = program.selected_program?.name;
              const latestBalance = program?.ledger_transaction ? program?.ledger_transaction[0]?.balance : 0;
              const latestDue = latestBalance !== 0 && program?.due_schedules ? program?.due_schedules : null;  

              return (
                <div key={program.id}>
                  <div className="card p-3">
                    <div className='text-capitalize fs-4'>{campusName}</div>
                    <div className='text-capitalize fs-4'>{tuitionName} Student</div>
                    <div className='text-capitalize fs-4'> {sessionName}</div>
                    <div className='text-capitalize fs-4'>{programName}</div>
                    <p className="card-text fs-4">Amount Due: <strong>{currencyFormat.format(latestBalance)}</strong></p>

                    <div className="form-group fs-4">
                      <input
                        type="number"
                        className="form-control fs-4"
                        id={`amount-${program.id}`}
                        onChange={(e) => handleAmountChange(program.program_uuid, e.target.value)}
                        min="0"
                        max={latestBalance}
                        required
                      />
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        {message.error && <Alert className='' variant="danger">{message.error}</Alert>}
        {message.success && <Alert className='' variant="success">{message.success}</Alert>}
        <div className='d-flex align-items-center justify-content-between'>
          <div className="d-flex my-3 justify-content-end">
            <button type="submit"
            disabled={isLoading}
            className="btn btn-primary fs-3">
              Proceed to Payment
            </button>
          </div>
        <h3 className='mt-4'>Total Amount: {currencyFormat.format(totalAmount)}</h3> 
        </div>
      </form>
      )}
    </div>
    </>
  );
};

export default PayLedger;
