import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/auth';

const SavedProgress = ({ savedProgress }: any) => {

  console.log(savedProgress, 'savedProgress')

  const navigate = useNavigate()
  const { currentUser } = useAuth()

    const handleContinue = (data: any) => {
      const { form_data, current_step, program_uuid, selected_campus, selected_student } = data
        localStorage.setItem('multiStepFormData', JSON.stringify(form_data));
        localStorage.setItem('scholarshipCodes', JSON.stringify(form_data?.discounts))
        localStorage.setItem('currentStep', current_step);
        localStorage.setItem('selectedCampus', selected_campus);
        sessionStorage.setItem('selectedPaymentType', form_data?.payment_info?.paymentType || '');
        sessionStorage.setItem('savedCreditCardInfo', JSON.stringify(form_data?.payment_info?.creditCardInfo) || '');
        sessionStorage.setItem('savedBankAccountInfo', JSON.stringify(form_data?.payment_info?.bankAccountInfo) || '');
        sessionStorage.setItem('selectedStudent', selected_student);
        sessionStorage.setItem('selectedAppType', form_data?.enrollment_track || '');
        sessionStorage.setItem('program_uuid', program_uuid);
        sessionStorage.setItem('tuition_plan', form_data?.tuition_plan);
        sessionStorage.setItem('tnc', form_data?.tnc);
        sessionStorage.setItem('selectedExtras', JSON.stringify(form_data?.enrollment_extras))

        navigate(`/application/${currentUser?.currentClient}/add-program`);
    };

    if (savedProgress?.length === 0) return null;

    return (
      <>
        <div className='card p-2'>
          <h3 className='text-center'>Incomplete - Started Applications</h3>
        </div>
        {savedProgress?.map((item: any, index: number) => {
          if (item.status === "enrolled") return null
          return (
            <div className='d-flex flex-row justify-content-between align-items-center gap-3 card my-3 p-3' key={index}> 
              <div className='d-flex align-items-center gap-2'>You have an incomplete application for <div className=''>{`${item?.student_info?.first_name} ${item?.student_info?.last_name}`}</div> at {item?.selected_campus}!</div>
              <Button onClick={() => handleContinue(item)} variant='primary'>Click here to continue your application.</Button>
            </div>
          )
        })}
      </>
    );
};

export default SavedProgress;