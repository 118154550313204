import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import './SelectStudentProfile.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import { getAllProgram, getAllTypeForm, getAllTypeFormData } from '../../network/api';
import moment from 'moment';

interface StudentTypeProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

interface TypeformData {
    id: number;
    form_id: string;
    response_type: string;
    name: string;
    overview: string;
  }

const FormsPage: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate()
    const {currentUser} = useAuth()

    const [getContactPrograms, setContactPrograms] = useState<number[]>([]);
    const [getFormsByProgram, setFormsByProgram] = useState<{ [key: number]: number[] }>({});
    const [getTypeForm, setTypeform] = useState<TypeformData[]>([]);
    const [getTypeFormData, setTypeFormData] = useState<any[]>([]);
    const [getBridgeData, setBridgeData] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    const [selectedStatus, setSelectedStatus] = useState<string>()
    const [getCurrentStatus, setCurrentStatus] = useState<string>()
    const [getStudentUUID, setStudentUUID] = useState<any | null>(null);
    const [getProgramUUID, setProgramUUID] = useState<any | null>(null);
    const [getFormID, setFormID] = useState<any | null>(null);

    const [hasAdmin, setHasAdmin] = useState<any>(null)

    useEffect(() => {
        const getCurrentAuth = async () => {
        const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (PrevAuth) {
            setHasAdmin(true)
        } else {
            setHasAdmin(false)
        }
        }
        getCurrentAuth()
    }, []);

    useEffect(() => {
        const fetchData = async () => {
        try {
            // Fetch typeform data
            const { data: typeformData } = await getAllTypeForm();

            // Fetch prev data
            setFormData((prevFormData: any) => ({
                ...prevFormData,
            }));

            setTypeform(typeformData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true)
        const fetchBridgeData = async () => {
            const selectedProgramId = formData?.selectedProgram?.id;
            if (!selectedProgramId) {
                console.error('selectedProgram.id is not defined in formData');
                return;
            }
            const allProgramsResponse = await getAllProgram();
            const allPrograms = allProgramsResponse.data;
    
            // Filter bridge data based on selectedProgramId
            const bridgeData = allPrograms.filter((program: { program_id: any; }) => program.program_id === selectedProgramId);
    
            setBridgeData(bridgeData)
        }

        const fetchTypeFormData = async () => {
            try {
                const allTypeForm = await getAllTypeFormData();
                const allTypeFormData = allTypeForm.data;
    
                // Filter typeform data based on student_uuid & program_uuid
                if (Array.isArray(allTypeFormData)) {
                    const typeFormData = allTypeFormData.filter((typeform: { student_uuid: string; program_uuid: string; }) => 
                        typeform.student_uuid === formData.student_uuid && 
                        typeform.program_uuid === formData.program_uuid
                    );
            
                    setTypeFormData(typeFormData);
                } else {
                    console.error("allTypeForm is not an array:", allTypeFormData);
                }
            } catch (error) {
                console.error("Error fetching TypeForm data:", error);
            }
        }
        fetchBridgeData()
        fetchTypeFormData()
        setIsLoading(false)
    }, []);

    useEffect(() => {
        const fetchContactProgramsAndActiveForms = async () => {
          setIsLoading(true);
      
          try {
            const selectedProgramId = formData?.selectedProgram?.id;
            if (!selectedProgramId) {
                console.error('selectedProgram.id is not defined in formData');
                return;
            }
            // Fetch contact programs
            const contactProgramsPromise = Promise.resolve([selectedProgramId]);
            // Fetch active forms
            const activeFormsPromise = getAllProgram().then(response => response.data.filter((form: any) => form.isActive));
            const [contactPrograms, activeForms] = await Promise.all([contactProgramsPromise, activeFormsPromise]);

            // Process active forms based on fetched contact programs
            const contactProgramsSet = new Set(contactPrograms);
            const filteredActiveForms = activeForms.filter((form: { program_id: number; }) => contactProgramsSet.has(form.program_id));
      
            const formsByProgram = filteredActiveForms.reduce((acc: { [x: string]: any[] }, form: { program_id: string | number, form_id: any }) => {
              if (!acc[form.program_id]) {
                acc[form.program_id] = [];
              }
              acc[form.program_id].push(form.form_id);
              return acc;
            }, {});
    
            setContactPrograms(contactPrograms);
            setFormsByProgram(formsByProgram);
      
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
        };
      
        fetchContactProgramsAndActiveForms();
      }, []);



    const handleShowModal = async (student_uuid: any, program_uuid: any, form_id: any, status: string) => {
        setSelectedStatus(status);
        setCurrentStatus(status)
        setShowModal(true)
        {setStudentUUID(student_uuid)}
        {setProgramUUID(program_uuid)}
        {setFormID(form_id)}
    };
    const handleCloseModal = () => setShowModal(false);
    
    const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.target.value);
    };

    const handleSubmitStatusChange = async (student_uuid: any, program_uuid: any, form_id: any) => {
        if (selectedStatus === getCurrentStatus) {
        setIsError(`The form status is already set to '${getCurrentStatus}'. No changes will be made.`)

        setTimeout(() => {
            setIsError('')
        }, 3000)

        return;
        }

        try {
            await axiosPrivate.get('/api/typeform-updateStatus', {
                params: { student_uuid, program_uuid, form_id, selectedStatus},
            });
        } catch (error) {
        setIsError('An error occurred while submitting the form: ' + error)
        }

        handleCloseModal()
    };

    const navigateForms = async (student_uuid: string, program_id: any, program_uuid: string, form_id: string, completedForms: string[]) => {
        try {
        setIsLoading(true);
        const data  = getTypeForm

        const formIdToFormId = data.reduce((acc: { [key: number]: string }, form: any) => {
            acc[form.id] = form.form_id;
            return acc;
        }, {});

        const matchedForm = data.find((form: any) => form.id === form_id);

        if (!matchedForm) {
            console.error('No matching form_id found.')
            return;
        }

        const formsForProgram = getFormsByProgram[program_id] || []
        const completedFormsSet = new Set(completedForms.map(id => Number(id)));
        const filterIncompleteForms = formsForProgram.filter((formId: number) => !completedFormsSet.has(formId));
        const currentForm = matchedForm.form_id
        const remainingForms = filterIncompleteForms
            .map(formId => formIdToFormId[formId])
            .filter(form => form && form !== currentForm)

        navigate(`/dashboard/${currentUser?.currentClient}/forms/${currentForm}`, { replace: true, state: {
            student_uuid: student_uuid,
            program_uuid: program_uuid,
            form_id: currentForm,
            remaining_forms: remainingForms,
            auth: currentUser?.currentClient,
            rawTypeformData: data,
            formsPage: true
        }})
        } catch (error) {
        console.error("Error handling navigation:", error);
        } finally {
        setIsLoading(false);
        }
    }

    const getCompletedForms = getTypeFormData
        .filter((item: any) => item.response_type === 'Submitted - Needs Review')
        .map((item: any) => item.form_id)

    return (
        <div className='flex-grow-1' style={{ alignSelf: 'flex-start'}}>
            {isLoading ? (
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : getBridgeData.length === 0 ? (
                <div style={{ textAlign: 'center', background: '#f8d7da', color: '#721c24', padding: '10px'}}>
                    Forms missing, Please contact the administrator.
                </div>
            ) : (
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Section</th>
                            <th>Task Name</th>
                            <th>Overview</th>
                            <th>Status</th>
                            <th style={{ width: 70 }}>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ border: '1px solid #dee2e6' }}>
                        {isLoading ? (
                            <tr>
                                <td colSpan={6} style={{ textAlign: 'center' }}>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                        <>
                            {getBridgeData.map((item: any, index: number) => {
                                const student_uuid = formData.student_uuid
                                const form_id = item.form_id; 
                                const correspondingTypeform = getTypeFormData.find((typeform: { form_id: any; }) => typeform.form_id === form_id);
                                const displayNameOverview = getTypeForm.find((typeform: { id: any; }) => typeform.id === form_id);
                                const correspondingBridgeData = getBridgeData.find((bridgeItem: { form_id: any; isActive: boolean; }) => bridgeItem.form_id === form_id);
                                if (!correspondingBridgeData || !correspondingBridgeData.isActive) {
                                    return null;
                                }
                                const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                                const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;
                                const isFormIdMatched = !!correspondingTypeform;

                                let status = 'Incomplete';
                                if (isFormIdMatched) {
                                    if (isCompleted) {
                                    status = 'Completed';
                                    } else if (isSubmitted) {
                                    status = 'Submitted - Needs Review';
                                    }
                                }

                                return (
                                    <tr key={index}>
                                        {index === 0 && (
                                            <td rowSpan={getBridgeData.length} style={{ verticalAlign: "middle", background: '#ccc', textAlign: 'center' }}>
                                                Application Form
                                            </td>
                                        )}
                                        <td className='px-1'>{displayNameOverview?.name || 'N/A'}</td>
                                        <td>{displayNameOverview?.overview || 'N/A'}</td>
                                        <td 
                                            className={`${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                            onClick={status === 'Incomplete' ? () => navigateForms(
                                            student_uuid, 
                                            formData.selectedProgram.id, 
                                            formData.program_uuid, 
                                            form_id!,
                                            getCompletedForms,
                                            ) : undefined}
                                        >
                                            {status}
                                        </td>
                                        <td>
                                            {status === 'Incomplete' && (
                                            <Button 
                                                variant="primary"
                                                onClick={() => navigateForms(
                                                student_uuid, 
                                                formData.selectedProgram.id, 
                                                formData.program_uuid, 
                                                form_id!,
                                                getCompletedForms,
                                            )}
                                            >
                                                Submit
                                            </Button>
                                            )}
                                            {status !== 'Incomplete' && hasAdmin && (
                                            <>
                                                <Button variant="secondary" onClick={() =>{handleShowModal(student_uuid, formData.program_uuid, form_id, status)}}>
                                                Change Form Status
                                                </Button>
                                            </>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </>
                        )}
                    </tbody>
                </table>
            )}

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop='static'
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Change Form Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Group controlId="formStatus">
                    <Form.Label>Select Form Status</Form.Label>
                    <Form.Select value={selectedStatus} onChange={handleChangeStatus}>
                    <option value="Submitted - Needs Review">Submitted - Needs Review</option>
                    <option value="Completed">Completed</option>
                    </Form.Select>
                </Form.Group>
                {isError && <div className="text-danger">{isError}</div>}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                {hasAdmin && (
                    <Button variant="primary" onClick={() => { handleSubmitStatusChange(getStudentUUID, getProgramUUID, getFormID)}}>
                    Save Changes
                    </Button>
                )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FormsPage;
