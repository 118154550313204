import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import './AddProgramPage.scss';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';

type Campus = {
  id: number;
  name: string;
  segmentId: number;
  createdDate: string;
  modifiedDate: string;
  residentialCapacity: number | null;
  commuterCapacity: number | null;
  region: string | null;
  imageUrl: string | null;
  s3Key: string | null;
  school_level: string | null
};

const AddProgramPage: React.FC = () => {
  const [campus, setCampus] = useState<Campus[]>([]);
  const [activeCampus, setActiveCampus] = useState<string | null>(() => {
    return localStorage.getItem('selectedCampus') || null;
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampuses = async () => {
      try {
        const { data } = await axiosPrivate.get('/terra-programs/api/campus-list');
        const sortedPrograms = data.data.map((item: any) => item);
        setCampus(sortedPrograms);
      } catch (error) {
        console.error('Error fetching campuses:', error);
      }
    };

    fetchCampuses();
  }, []);

  const handleCampusSelection = (name: string) => {
    if (name !== activeCampus) {
      localStorage.removeItem('multiStepFormData');
      localStorage.removeItem('scholarshipCodes')
      localStorage.removeItem('currentStep');
      localStorage.removeItem('selectedCampus');
      sessionStorage.removeItem('selectedPaymentType');
      sessionStorage.removeItem('savedCreditCardInfo');
      sessionStorage.removeItem('savedBankAccountInfo');
      sessionStorage.removeItem('selectedStudent');
      sessionStorage.removeItem('selectedAppType');
      sessionStorage.removeItem('selectedExtras')
      sessionStorage.removeItem('tnc')
      sessionStorage.removeItem('tuition_plan')
    }

    setActiveCampus(name);
    localStorage.setItem('selectedCampus', name);
  };

  const handleNextClick = () => {
    navigate(`/application/47821104/program/${activeCampus}`);
  };

  const middleSchoolCampuses = campus.filter((item) =>
    item.school_level?.toLowerCase() === 'middle school'
  );
  
  const highSchoolCampuses = campus.filter((item) =>
    item.school_level?.toLowerCase() === 'high school'
  );

  const groupedHighSchoolCampuses = highSchoolCampuses.reduce((acc: Record<string, Campus[]>, item) => {
    const region = item.region || 'Other';
    if (!acc[region]) {
      acc[region] = [];
    }
    acc[region].push(item);
    acc[region].sort((a, b) => a.name.localeCompare(b.name));
    return acc;
  }, {});

  const regions = ['East Coast', 'West Coast', 'International', 'Online'];

  return (
    <div className='main-container'>
      <div className='header-program'>
        <h2
          style={{ background: '#f7942a' }}
          className='d-flex justify-content-center align-items-center rounded w-50 mx-auto col-md-12 mb-4'
        >
          <div className='bg-opacity-50 w-xl-700px text-center py-4 text-light border-radius-20'>
            <h1 className='fw-bolder fs-2x text-light'>Program Locations</h1>
          </div>
          <div className='button-ctn'>
          <button disabled={!activeCampus} className='btn btn-primary' onClick={handleNextClick}>
            Next
          </button>
        </div>
        </h2>
      </div>
      <div style={{ paddingTop: 100 }} className='campus-container mb-4'>
        {campus.length === 0 ? (
          <p>No campuses available</p>
        ) : (
          <>
            <h3
              style={{ background: '#e6e6e6' }}
              className='border text-center w-75 rounded p-2 mx-auto'
            >
              High School Campuses
            </h3>
            {regions.map((region, index) => {
              const campusesInRegion = groupedHighSchoolCampuses[region];

              // Only render the region if there are campuses in it
              if (campusesInRegion && campusesInRegion.length > 0) {
                return (
                  <div key={region}>
                    <h4 style={{ background: '#e6e6e6' }} className='text-center border w-50 rounded p-2 mx-auto my-3'>
                      {region}
                    </h4>
                    <div className='hs-container'>
                      {campusesInRegion.map((item, x) => {
                        const imageUrls = item?.imageUrl ? item.imageUrl.split(',') : [];

                        return (
                          <div
                            key={x}
                            className={`campus-item ${item.name === activeCampus ? 'active' : ''}`}
                            onClick={() => handleCampusSelection(item.name)}
                          >
                            {imageUrls.length > 0 && (
                              <Carousel interval={2000} controls={false} indicators={false}>
                                {imageUrls.map((url, index) => (
                                  <Carousel.Item key={index}>
                                    <img src={url.trim()} alt='' />
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            )}
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }

            return null;
            })}
            <h3
              style={{ background: '#e6e6e6' }}
              className='border text-center w-75 rounded p-2 mx-auto'
            >
              Middle School Campuses
            </h3>
            <div className={`${middleSchoolCampuses.length > 0 ? 'ms-container' : ''}`}>
              {middleSchoolCampuses.length > 0 ? (
                <>
                  {middleSchoolCampuses.map((item, index) => {
                    const imageUrls = item?.imageUrl ? item.imageUrl.split(',') : [];

                    return (
                      <div
                        key={index}
                        className={`campus-item ${item.name === activeCampus ? 'active' : ''}`}
                        onClick={() => handleCampusSelection(item.name)}
                      >
                        {imageUrls.length > 0 && (
                          <Carousel interval={2000} controls={false} indicators={false}>
                            {imageUrls.map((url, index) => (
                              <Carousel.Item key={index}>
                                <img src={url.trim()} alt='' />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        )}
                        {item.name}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div>No Middle school campus available</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddProgramPage;
