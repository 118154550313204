import React, { useState, useEffect, FormEvent } from 'react';
import './MultiStepForm.scss';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Campus from './Campus';
import SessionPage from './SessionPage';
import StudentType from './StudentType';
import ApplicationType from './ApplicationType';
import PaymentSummary from './PaymentSummary';
import PaymentOptions from './PaymentOptions';
import SelectProfile from './SelectProfile';
import SelectParentProfile from './SelectParentProfile';
import SelectExtras from './SelectExtras';
import FormsPage from './FormsPage';
import { axiosPrivate } from '../../axiosInstance';
import CongratulationsModal from '../modules/application/components/modal/CongratulationsModal';
import { getAllTypeFormData } from '../../network/api';

interface CreditCardInfo {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
}

interface BankAccountInfo {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: "checking" | "savings";
}

interface PaymentInfo {
  paymentType: "bankAccount" | "creditCard" | "debitCard";
  isActive: boolean;
  creditCardInfo?: CreditCardInfo;
  bankAccountInfo?: BankAccountInfo;
}

interface DiscountInfo {
  description: string
  due: number
  price: number
  transaction_type: string
}
interface FormData {
  selectedProgram: any;
  selectedSession: any;
  student_Type: string;
  student_uuid: string;
  account_holder_uuid: string;
  enrollment_extras: any;
  enrollment_track: string;
  payment_info: PaymentInfo;
  payAmount: number;
  existingPaymentMethod: string;
  tnc?: boolean;
  payment_due?: string;
  payment_full?: string;
  payment_schedule?: string
  tuition_plan?: boolean
  discounts?: DiscountInfo[]
  program_uuid: string
}

interface RouteParams {
  client: string;
  campus: string;
  step: string;
}

const stepsConfig = [
  {
    title: "Select your Course",
    component: Campus,
    validate: (formData: FormData) => !!formData.selectedProgram,
  },
  {
    title: "Select Session",
    component: SessionPage,
    validate: (formData: FormData) => !!formData.selectedSession,
  },
  {
    title: "Please Select Your Tuition Type",
    component: StudentType,
    validate: (formData: FormData) => !!formData.student_Type,
  },
  {
    title: "Please Select Extras",
    component: SelectExtras,
    validate: () => true,
  },
  {
    title: "Please select student to enroll",
    component: SelectProfile,
    validate: (formData: FormData) => !!formData.student_uuid,
  },
  {
    title: "Please select Primary Point of Contact Guardian",
    component: SelectParentProfile,
    validate: (formData: FormData) => !!formData.account_holder_uuid,
  },
  {
    title: "Please select Application Type",
    component: ApplicationType,
    validate: (formData: FormData) => !!formData.enrollment_track,
  },
  {
    title: 'Please complete the forms to proceed',
    component: FormsPage,
    validate: (formData: FormData) => {
      return formData.enrollment_track === 'finish' &&
        !!formData.student_uuid && 
        !!formData.selectedProgram;
    },
  },
  {
    title: "Billing Summary",
    component: PaymentSummary,
    validate: (formData: FormData) => !!formData.tnc
  },
  {
    title: 'Payments',
    component: PaymentOptions,
    validate: (formData: FormData) => {
      if (formData.existingPaymentMethod && formData.payAmount) {
        return true;
      }
      if (formData.payment_info?.paymentType === "creditCard" || formData.payment_info?.paymentType === "debitCard") {
        const { creditCardInfo } = formData.payment_info;
        return !!creditCardInfo?.cardNumber &&
               !!creditCardInfo?.cardExpiryMonth &&
               !!creditCardInfo?.cardExpiryYear &&
               !!creditCardInfo?.cardCvv;
      }
      if (formData.payment_info?.paymentType === "bankAccount") {
        const { bankAccountInfo } = formData.payment_info;
        return !!bankAccountInfo?.routingNumber &&
               !!bankAccountInfo?.accountNumber &&
               !!bankAccountInfo?.bankType;
      }
      return false;
    }
  }
];

const MultiStepForm: React.FC = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const parsedStep = step ? parseInt(step) - 1 : 0; // Convert step to a zero-based index
  const maxStep = stepsConfig.length - 1; // Max valid step index
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? JSON.parse(savedStep) : 0;
  });
  const [formData, setFormData] = useState<FormData>(() => {
    const savedData = localStorage.getItem('multiStepFormData');
    return savedData
      ? JSON.parse(savedData)
      : {
          selectedProgram: null,
          selectedSession: null,
          account_holder_uuid: '',
          student_Type: '',
          student_uuid: '',
          enrollment_track: '',
          payment_info: null,
          enrollment_extras: null,
          tuition_plan: false,
          discounts: [],
          program_uuid: ''
        };
  });
  const [stepCompleted, setStepCompleted] = useState<boolean[]>(() => {
    const savedSteps = localStorage.getItem('multiStepStepsCompleted');
    return savedSteps ? JSON.parse(savedSteps) : Array(stepsConfig.length).fill(false);
  });
  const [message, setMessage] = useState({ success: '', error: '' });
  const [showCongratulationsModal, setShowCongratulationsModal] = useState(false); 
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateStep = (): boolean => stepsConfig[currentStep].validate(formData);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const [getTypeFormData, setTypeFormData] = useState<any[]>([]);
  const [lastSavedData, setLastSavedData] = useState(formData);

  useEffect(() => {
    localStorage.setItem('multiStepFormData', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem('currentStep', JSON.stringify(currentStep));
  }, [currentStep]);

  const saveApplicationProgress = async (formData: FormData) => {
    const selectedCampus = localStorage.getItem('selectedCampus') || '';
    const currentStep = localStorage.getItem('currentStep') || '';
    const selectedStudent = sessionStorage.getItem('selectedStudent') || '';
    const program_uuid = sessionStorage.getItem('program_uuid') || '';

    const newData = {
      multiStepFormData: formData, 
      selectedCampus,
      currentStep: Number(currentStep),
      selectedStudent,
      program_uuid
    }

    try {
      const response = await axiosPrivate.post('/save-changes', newData);
      console.log(response, 'response')
      if (response.status === 200) {
        console.log('Progress saved successfully');
      }
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  useEffect(() => {
    const shouldSaveProgress = currentStep >= 5 || currentStep === stepsConfig.length - 1;

    if (shouldSaveProgress && JSON.stringify(formData) !== JSON.stringify(lastSavedData)) {
      const saveProgress = async () => {
        await saveApplicationProgress(formData);
        setLastSavedData(formData);
      };
      saveProgress();
    }
  }, [formData, currentStep]);


  useEffect(() => {
    const fetchTypeFormData = async () => {
      try {
          const allTypeForm = await getAllTypeFormData();
          const allTypeFormData = allTypeForm.data;

          if (Array.isArray(allTypeFormData)) {
              const typeFormData = allTypeFormData.filter((typeform: { student_uuid: string; program_uuid: string; }) => 
                  typeform.student_uuid === formData.student_uuid && 
                  typeform.program_uuid === formData.program_uuid 
              );
      
              setTypeFormData(typeFormData);
          } else {
              console.error("allTypeForm is not an array:", allTypeForm);
          }
      } catch (error) {
          console.error("Error fetching TypeForm data:", error);
      }
    }
    fetchTypeFormData()
  }, []);

  useEffect(() => {
    const removeDisableButton = async () => {
      setIsNextButtonDisabled(false)
    }
    if (getTypeFormData.length === 3) {
      removeDisableButton()
    }
  }, [getTypeFormData]);

  const handleNextStep = () => {
    if (!validateStep()) {
      alert('Please complete the current step before proceeding.');
      return;
    }
    setStepCompleted((prev) => {
      const updatedSteps = [...prev];
      updatedSteps[currentStep] = true;
      return updatedSteps;
    });
    let nextStep = currentStep + 1;
    if (
      stepsConfig[nextStep]?.component === FormsPage &&
      formData.enrollment_track === 'express'
    ) {
      nextStep += 1; 
    }
    if (nextStep <= maxStep) {
      setCurrentStep(nextStep); // Move to the next step internally
    }
  };

  const handlePrevStep = () => {
    let prevStep = currentStep - 1;
    if (
      stepsConfig[prevStep]?.component === FormsPage &&
      formData.enrollment_track === 'express'
    ) {
      prevStep -= 1; 
    }
    if (prevStep >= 0) {
      setCurrentStep(prevStep); // Move to the previous step internally
    }
  };

  console.log(formData, 'formData')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => { 
    event.preventDefault();
    if (!validateStep()) return alert('Please select a payment method.');
    setIsSubmitting(true);
    setMessage({ success: 'Payment is being processed.', error: '' });

    const newFormData = {
      traveler_uuid: formData.student_uuid,
      account_holder_uuid: formData?.account_holder_uuid,
      student_type: formData.student_Type,
      selected_session: formData.selectedSession,
      enrollment_track: formData.enrollment_track,
      selected_program: formData.selectedProgram,
      payment_info: formData.payment_info,
      payAmount: formData.payAmount,
      payment_schedule: formData.payment_schedule,
      enrollment_extras: formData.enrollment_extras,
      existingPaymentMethod: formData.existingPaymentMethod,
      tuition_plan: formData.tuition_plan,
      discounts: formData.discounts,
      program_uuid: formData.program_uuid,
    };

    try {
      const response = await axiosPrivate.post('/enroll-program', newFormData);
      
      if (response.status === 200) {
        setShowCongratulationsModal(true);
        localStorage.removeItem('currentStep');
        localStorage.removeItem('selectedCampus');
        localStorage.removeItem('scholarshipCodes');
        localStorage.removeItem('multiStepFormData');
        sessionStorage.removeItem('selectedAppType');
        sessionStorage.removeItem('selectedParent');
        sessionStorage.removeItem('selectedStudent');
        sessionStorage.removeItem('program_uuid');
        sessionStorage.removeItem('tnc')
        sessionStorage.removeItem('tuition_plan')
        sessionStorage.removeItem('selectedPaymentType');
        sessionStorage.removeItem('savedCreditCardInfo');
        sessionStorage.removeItem('savedBankAccountInfo');
      } else if (response.status === 201) {
        console.log(response.data.message, 'response')
        setMessage({ success: '' , error: response.data.message });
      }
    } catch (error: any) {
      console.log(error, 'error')
      setMessage({ success: '', error: error.message });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setMessage({ success: '', error: '' });
      }, 3000)
    }
  };

  const StepComponent = stepsConfig[currentStep].component;

  return (
    <div className="form-container">
      <form onSubmit={currentStep === maxStep ? handleSubmit : undefined}>
      <div className='fixed-buttons-container d-flex align-items-center'>
      <div style={{ width: 150 }}>
        {currentStep > 0 && (
          <Button className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button" onClick={handlePrevStep}>
            Previous
          </Button>
        )}
        {currentStep === 0 && (
          <Button className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button" onClick={() => navigate(-1)}>
            Previous
          </Button>
        )}
      </div>
      <span style={{ background: '#f7942a' }} className='d-flex justify-content-center rounded w-50 mx-auto col-md-12'>
        <div className='bg-opacity-50 w-xl-700px text-center py-4 text-light border-radius-20'>
          <h1 className='fw-bolder fs-2x text-light'>{stepsConfig[currentStep].title}</h1>
        </div>
      </span>

      <div style={{  width: 150, height: 50 }} className={`${currentStep > 0 ? 'form-nav' : 'form-navigation'}`}>
        {currentStep < stepsConfig.length - 1 && (
          <Button className='w-100' style={{ fontSize: 20 }} variant="primary" type="button" onClick={handleNextStep} 
            disabled={currentStep === stepsConfig.findIndex(step => step.component === FormsPage) && isNextButtonDisabled}>
            Next
          </Button>
        )}
      </div>
      </div>
        <div className='content-main'>
          {message.error && <Alert className='mx-3' variant="danger">{message.error}</Alert>}
          {message.success && <Alert className='mx-3' variant="success">{message.success}</Alert>}
          <StepComponent formData={formData} setFormData={setFormData} />
          {currentStep === maxStep && (
          <div style={{  width: 150, height: 50 }}>
            <Button style={{ fontSize: 20 }} className='w-100 m-3' variant="primary" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </div>
          )}
        </div>
      </form>
      
      {/* Render the Congratulations Modal */}
      <CongratulationsModal
        show={showCongratulationsModal}
        handleClose={() => {
          setShowCongratulationsModal(false);
          navigate('/dashboard/47821104');
        }}
      />
    </div>
  );
}

export default MultiStepForm;