import React from 'react'
import { parse_payment } from '../../utils/funcHelpers';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../utils';

interface PaymentMethodInputsProps {
  paymentType: string;
  selectPMethod: string;
  pMethodSaved: any;
  pMethodSavedCredit: any;
  pMethodSavedACH: any;
  pMethodSavedDebit: any;
  ccCount: number;
  ACHCount: number;
  dcCount: number;
  handlePMethodSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, setInfo: React.Dispatch<React.SetStateAction<any>>) => void;
  creditCardInfo: any;
  bankAccountInfo: any;
  setCreditCardInfo: React.Dispatch<React.SetStateAction<any>>;
  setBankAccountInfo: React.Dispatch<React.SetStateAction<any>>;
}

const PaymentMethodInputs: React.FC<PaymentMethodInputsProps> = ({
  paymentType,
  selectPMethod,
  pMethodSaved,
  pMethodSavedCredit,
  pMethodSavedACH,
  pMethodSavedDebit,
  ccCount,
  ACHCount,
  dcCount,
  handlePMethodSelect,
  handleInputChange,
  creditCardInfo,
  bankAccountInfo,
  setCreditCardInfo,
  setBankAccountInfo,
}) => {
  if (paymentType === 'creditCard' || paymentType === 'debitCard') {

    const isCreditCard = paymentType === 'creditCard';
    const count = isCreditCard ? ccCount : dcCount;
    const pMethodSavedData = isCreditCard ? pMethodSavedCredit : pMethodSavedDebit;

    return (
      <>
        {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === paymentType})  && count > 0 ? (
        <>
        <div className='d-flex flex-column my-5'>
          {pMethodSavedData?.map((data: any, index: number) => {
            const dataSaveText = data ? parse_payment(data) : null
              return (
                <div key={index} className='mb-7 me-5'>
                <input
                  type='radio'
                  className='form-check-input me-2'
                  value={`funding_${data?.id}`}
                  name={'selectPMethod'}
                  id={`funding_${data?.id}`}
                  onChange={handlePMethodSelect}
                />
                <label
                  className='form-check-label'
                  htmlFor={`funding_${data?.id}`}
                >
                  <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                  Ending in {data?.cardNumber?.replaceAll('x', '')}
                </label>
              </div>
              )
          })}
          <div className='mb-7 me-5'>
            <input
              type='radio'
              className='form-check-input me-2'
              value={'creditCard'}
              name={'selectPMethod'}
              id={'creditCard_selectPMethod'}
              onChange={handlePMethodSelect}
            />
            <label className='form-check-label' htmlFor={'creditCard'}>
              Use a new payment method
            </label>
          </div>
        </div>
        {selectPMethod === 'creditCard' && (
        <div>
          <h4>Card Information</h4>
            <div className="row mb-3">
            <div className="col-md-6">
            <label className="form-label">Name on Card</label>
            <input
              type="text"
              name="nameOnCard"
              className="form-control form-control-solid form-control-lg"
              placeholder="Name on Card"
              value={creditCardInfo.nameOnCard}
              onChange={(e) => handleInputChange(e, setCreditCardInfo)}
            />
            </div>

          <div className='d-flex flex-column mb-7 fv-row col-md-6'>
            <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>
            <div className='position-relative'>
              <input
                type='text'
                className='form-control form-control-solid form-control-lg'
                placeholder='Enter card number'
                name='cardNumber'
                value={creditCardInfo.cardNumber}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />

              <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                  alt=''
                  className='h-25px'
                />
                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                  alt=''
                  className='h-25px'
                />
                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                  alt=''
                  className='h-25px'
                />
              </div>
            </div>
          </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label className="form-label">Expiry Month</label>
              <select
                name="cardExpiryMonth"
                className="form-control form-control-solid form-control-lg"
                placeholder="MM"
                value={creditCardInfo.cardExpiryMonth}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              >
                <option value='' disabled>Select Month</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
              </select>
            </div>
            <div className="col-md-4">
              <label className="form-label">Expiry Year</label>
              <select
                name="cardExpiryYear"
                className="form-control form-control-solid form-control-lg"
                placeholder="YYYY"
                value={creditCardInfo.cardExpiryYear}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              >
                <option value='' disabled>Select expiry year</option>
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
                <option value='2027'>2027</option>
                <option value='2028'>2028</option>
                <option value='2029'>2029</option>
                <option value='2030'>2030</option>
                <option value='2031'>2031</option>
              </select>
            </div>
            <div className="col-md-4">
              <label className="form-label">CVV</label>
              <input
                type="text"
                name="cardCvv"
                className="form-control form-control-solid form-control-lg"
                placeholder="CVV"
                value={creditCardInfo.cardCvv}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Billing Address</label>
            <input
              type="text"
              name="billingAddress"
              className="form-control form-control-solid form-control-lg"
              placeholder="Billing Address"
              value={creditCardInfo.billingAddress}
              onChange={(e) => handleInputChange(e, setCreditCardInfo)}
            />
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
                <label className="form-label">Country</label>
                <select
                  name="billingCountry"
                  className="form-select form-select-lg form-select-solid"
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                >
                  <option value="">Select Country</option>
                  {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => (
                    <option key={c.alpha_3 + '_' + idx} value={c.alpha_3}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            <div className="col-md-6">
              <label className="form-label">State/Province</label>
              <select
                name="billingStateProvince"
                className="form-select form-select-lg form-select-solid"
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                disabled={!creditCardInfo.billingCountry}
              >
                <option value="">Select State/Province</option>
                {COUNTRIES_ISO_3166_ALPHA_3.find((c) => c.alpha_3 === creditCardInfo.billingCountry)?.divisions?.map((d) => (
                  <option key={d.code} value={d.code}>
                    {d.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="form-label">Postal Code</label>
              <input
                type="text"
                name="billingPostalCode"
                className="form-control form-control-solid form-control-lg"
                placeholder="Postal Code"
                value={creditCardInfo.billingPostalCode}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">City</label>
              <input
                type="text"
                name="billingCity"
                className="form-control form-control-solid form-control-lg"
                placeholder="City"
                value={creditCardInfo.billingCity}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>
          </div>
        </div>
        )}
        </> 
        ) : (
          <div>
            <h4>Card Information</h4>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Name on Card</label>
                <input
                  type="text"
                  name="nameOnCard"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Name on Card"
                  value={creditCardInfo.nameOnCard}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>

              <div className='d-flex flex-column mb-7 fv-row col-md-6'>
                <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>
                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control form-control-solid form-control-lg'
                    placeholder='Enter card number'
                    name='cardNumber'
                    value={creditCardInfo.cardNumber}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  />

                  <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                      alt=''
                      className='h-25px'
                    />
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                      alt=''
                      className='h-25px'
                    />
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                      alt=''
                      className='h-25px'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
            <div className="col-md-4">
              <label className="form-label">Expiry Month</label>
              <select
                name="cardExpiryMonth"
                className="form-control form-control-solid form-control-lg"
                placeholder="MM"
                value={creditCardInfo.cardExpiryMonth}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              >
                <option value='' disabled>Select Month</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
              </select>
            </div>
            <div className="col-md-4">
              <label className="form-label">Expiry Year</label>
              <select
                name="cardExpiryYear"
                className="form-control form-control-solid form-control-lg"
                placeholder="YYYY"
                value={creditCardInfo.cardExpiryYear}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              >
                <option value='' disabled>Select expiry year</option>
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
                <option value='2027'>2027</option>
                <option value='2028'>2028</option>
                <option value='2029'>2029</option>
                <option value='2030'>2030</option>
                <option value='2031'>2031</option>
              </select>
            </div>
            <div className="col-md-4">
              <label className="form-label">CVV</label>
              <input
                type="text"
                name="cardCvv"
                className="form-control form-control-solid form-control-lg"
                placeholder="CVV"
                value={creditCardInfo.cardCvv}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>
          </div>

            <div className="mb-3">
              <label className="form-label">Billing Address</label>
              <input
                type="text"
                name="billingAddress"
                className="form-control form-control-solid form-control-lg"
                placeholder="Billing Address"
                value={creditCardInfo.billingAddress}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                  <label className="form-label">Country</label>
                  <select
                    name="billingCountry"
                    className="form-select form-select-lg form-select-solid"
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  >
                    <option value="">Select Country</option>
                    {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => (
                      <option key={c.alpha_3 + '_' + idx} value={c.alpha_3}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              <div className="col-md-6">
                <label className="form-label">State/Province</label>
                <select
                  name="billingStateProvince"
                  className="form-select form-select-lg form-select-solid"
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  disabled={!creditCardInfo.billingCountry}
                >
                  <option value="">Select State/Province</option>
                  {COUNTRIES_ISO_3166_ALPHA_3.find((c) => c.alpha_3 === creditCardInfo.billingCountry)?.divisions?.map((d) => (
                    <option key={d.code} value={d.code}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Postal Code</label>
                <input
                  type="text"
                  name="billingPostalCode"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Postal Code"
                  value={creditCardInfo.billingPostalCode}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">City</label>
                <input
                  type="text"
                  name="billingCity"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="City"
                  value={creditCardInfo.billingCity}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  } else if (paymentType === 'bankAccount') {
    return (
      <>
      {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === 'bankAccount'}) && ACHCount > 0 ? (
        <>
          {pMethodSavedACH?.map((data: any, index: number) => {
            const dataSaveText = data ? parse_payment(data) : null
            return (
                <div key={index} className='mb-7 me-5'>
                <input
                  type='radio'
                  className='form-check-input me-2'
                  value={`funding_${data?.id}`}
                  name={'selectPMethod'}
                  id={`funding_${data?.id}`}
                  onChange={handlePMethodSelect}
                />
                <label
                  className='form-check-label'
                  htmlFor={`funding_${data?.id}`}
                >
                  <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                  Ending in {data?.cardNumber?.replaceAll('x', '')}
                </label>
              </div>
            )
          })}
          <div className='mb-7 me-5'>
            <input
              type='radio'
              className='form-check-input me-2'
              value={'bankAccount'}
              name={'selectPMethod'}
              onChange={handlePMethodSelect}
            />
            <label
              className='form-check-label'
              htmlFor={'bankAccount_selectPMethod'}
            >
              Use a new payment method
            </label>
          </div>
          {selectPMethod === 'bankAccount' && (
           <div className='col-md-6'>
             <h4>Bank Account Information:</h4>
             <div className='mb-3'>
               <label className='form-label'>Name on Account</label>
               <input
                 type='text'
                 name='nameOnCard'
                 className='form-control form-control-solid form-control-lg'
                 placeholder='Name on Account'
                 value={bankAccountInfo.nameOnCard}
                 onChange={(e) =>
                   handleInputChange(e, setBankAccountInfo)
                 }
               />
             </div>
             <div className='mb-3'>
               <label className='form-label'>Routing Number</label>
               <input
                 type='text'
                 name='routingNumber'
                 className='form-control form-control-solid form-control-lg'
                 placeholder='Routing Number'
                 value={bankAccountInfo.routingNumber}
                 onChange={(e) =>
                   handleInputChange(e, setBankAccountInfo)
                 }
               />
             </div>
             <div className='mb-3'>
               <label className='form-label'>Account Number</label>
               <input
                 type='text'
                 name='accountNumber'
                 className='form-control form-control-solid form-control-lg'
                 placeholder='Account Number'
                 value={bankAccountInfo.accountNumber}
                 onChange={(e) =>
                   handleInputChange(e, setBankAccountInfo)
                 }
               />
             </div>
             <div className='mb-3'>
               <label className='form-label'>Account Type</label>
               <select
                 name='bankType'
                 className='form-control form-control-solid form-control-lg'
                 value={bankAccountInfo.bankType}
                 onChange={(e) => handleInputChange(e, setBankAccountInfo)}
               >
                 <option value='checking'>Checking</option>
                 <option value='savings'>Savings</option>
               </select>
             </div>
           </div>
          )}
        </>
      ) : (
      <>
        <div className='col-md-6'>
          <h4>Bank Account Information:</h4>
          <div className='mb-3'>
            <label className='form-label'>Name on Account</label>
            <input
              type='text'
              name='nameOnCard'
              className='form-control form-control-solid form-control-lg'
              placeholder='Name on Account'
              value={bankAccountInfo.nameOnCard}
              onChange={(e) =>
                handleInputChange(e, setBankAccountInfo)
              }
            />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Routing Number</label>
            <input
              type='text'
              name='routingNumber'
              className='form-control form-control-solid form-control-lg'
              placeholder='Routing Number'
              value={bankAccountInfo.routingNumber}
              onChange={(e) =>
                handleInputChange(e, setBankAccountInfo)
              }
            />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Account Number</label>
            <input
              type='text'
              name='accountNumber'
              className='form-control form-control-solid form-control-lg'
              placeholder='Account Number'
              value={bankAccountInfo.accountNumber}
              onChange={(e) =>
                handleInputChange(e, setBankAccountInfo)
              }
            />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Account Type</label>
            <select
              name='bankType'
              className='form-control form-control-solid form-control-lg'
              value={bankAccountInfo.bankType}
              onChange={(e) => handleInputChange(e, setBankAccountInfo)}
            >
              <option value='checking'>Checking</option>
              <option value='savings'>Savings</option>
            </select>
          </div>
        </div>
      </>
      )}
    </>    
    )
  } else {
    return null;
  }
};

export default PaymentMethodInputs;