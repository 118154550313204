import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import './SelectStudentProfile.scss';
import { Link, useNavigate } from 'react-router-dom';

interface StudentTypeProps {
  formData: any;
  setFormData: (data: any) => void;
}

const SelectProfile: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
  const [linkContacts, setLinkContacts] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const fetchLinkContacts = async () => {
    try {
      const response = await axiosPrivate.get(`/contact/linked-accounts/child`);
      console.log(response, 'response')
      setLinkContacts(response.data.data);
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error fetching contacts');
    }
  };

  useEffect(() => {
    fetchLinkContacts();
    
    const storedStudent = sessionStorage.getItem('selectedStudent');
    if (storedStudent) {
      setSelectedStudent(storedStudent);
      setFormData((prevData: any) => ({ ...prevData, student_uuid: storedStudent }));
    }
  }, []);

  const selectContactHandler = (contact_id: string) => {
    const newSelectedStudent = selectedStudent === contact_id ? null : contact_id;
    setSelectedStudent(newSelectedStudent);
    setFormData({ ...formData, student_uuid: newSelectedStudent });
    
    if (newSelectedStudent) {
      sessionStorage.setItem('selectedStudent', newSelectedStudent);
    } else {
      sessionStorage.removeItem('selectedStudent');
    }
  };

  const getInitials = (firstName: string | undefined | null, lastName: string | undefined | null) => {
    const firstInitial = firstName?.charAt(0).toUpperCase() || '';
    const lastInitial = lastName?.charAt(0).toUpperCase() || '';
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <div className='my-5 mx-1'>
      {linkContacts.length === 0 ? (
        <div className="contact-list">
          <div className='add-contact-btn' onClick={() => navigate('/add-contact/47821104', { state: { page: 'enrollment' }})}>
            <div style={{ height: '220px'}} className='inner-contact-btn'>
              Add Contact
            </div>
          </div>
        </div>
      ) : (
        <div className="contact-list">
          {linkContacts.map((contact: any) => {
            const isActiveStudent = selectedStudent === contact.contact_id;
            const initials = getInitials(contact.student_contacts.first_name, contact.student_contacts.last_name);

            return (
              <div
                key={contact.contact_id}
                className={`student-selection mt-3 ${isActiveStudent ? 'selected' : ''}`}
                onClick={() => selectContactHandler(contact.contact_id)}
              >
                {contact.student_contacts.profile_url ? (
                  <img
                    src={`${contact.student_contacts.profile_url}`}
                    alt='profile'
                    className='rounded-circle'
                  />
                ) : (
                  <div className='no-pic' 
                  style={{ 
                    height: '100px', 
                    width: '100px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    fontSize: '40px', 
                    fontWeight: 'bold' }}
                    >
                    {initials}
                  </div>
                )}
                <div className='contact-information'>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Name:</div>
                    <div className='card-text'>{contact.student_contacts.first_name}</div>
                  </div>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Email:</div>
                    <div className='card-text'>{contact.student_contacts.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Relationship:</div>
                    <div className='text-capitalize card-text'>{contact.relationship}</div>
                  </div>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Phone:</div>
                    <div className='text-capitalize card-text'>{contact.student_contacts.phone}</div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className='add-contact-btn' onClick={() => navigate('/add-contact/47821104', { state: { page: 'enrollment' }})}>
            <div className='inner-contact-btn'>
              Add Contact
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectProfile;
