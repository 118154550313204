type Props = {
  handleTPPCheckboxChange: any;
  isChecked?: boolean;
};

const PaymentProtectionPlan = ({ handleTPPCheckboxChange, isChecked }: Props) => {
  return (
    <div style={{ padding: '1rem' }}>
      <div
        style={{ background: 'rgb(247, 148, 42)' }}
        className="d-flex justify-content-center rounded w-50 mx-auto col-md-12"
      >
        <div className="w-xl-700px text-center py-4 text-light border-radius-20">
          <h1 className="fw-bolder fs-2x text-light">Tuition Protection Plan</h1>
        </div>
      </div>
      <form className="container">
        <fieldset id="ppp">
          <div
            className="mx-auto d-flex gap-3 p-4 my-4 rounded"
            style={{ border: '1px black solid' }}
          >
            <input
              style={{ transform: 'scale(1.5)' }}
              checked={!isChecked}
              type="radio"
              name="protectionPlan"
              onChange={handleTPPCheckboxChange}
            />
            <h6>No.</h6>
          </div>
          <div
            className="mx-auto p-4 d-flex gap-10 mb-4 rounded"
            style={{ border: '1px black solid', flexDirection: 'column' }}
          >
            <div className="d-flex gap-3">
              <input
                style={{ transform: 'scale(1.5)' }}
                checked={isChecked}
                type="radio"
                name="protectionPlan"
                onChange={handleTPPCheckboxChange}
              />
              <h6>
                Yes, I would like to add Tuition Protection Plan of $499.00 total.
              </h6>
            </div>
            <p>
              The Tuition Protection Plan allows cancellation with full refund, for any
              reason up to the day the program starts.
            </p>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default PaymentProtectionPlan;