import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IPaymentMethod, IPaymentMethods } from '../modules/application/core/_models';
import {useQuery } from 'react-query';
import _queryKeys from '../modules/application/core/_queryKeys';
import useAppRequests from '../../../src/app/modules/application/Hooks/useAppRequests';
import { methodType, Payment } from './PayLedger';
import PaymentMethodInputs from './PaymentMethodInput';

type CreditCardInfo = {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
};

type BankAccountInfo = {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: 'checking' | 'savings';
};

type PaymentType = 'creditCard' | 'bankAccount' | 'debitCard' | '';

interface Props { 
  paymentData: IPaymentMethods | undefined
  formData: any
  setFormData: any
  id: string | undefined
  close: any
  isPaymentLoading: boolean
  payment_method: IPaymentMethod | undefined
  setMessage: Dispatch<SetStateAction<object>>
}

const PaymentOptions: React.FC<Props> = ({ formData, setFormData }) => {
  const [paymentType, setPaymentType] = useState<PaymentType>(() => {
    const selectedPaymentM = sessionStorage.getItem('selectedPaymentType');
    return selectedPaymentM ? selectedPaymentM as PaymentType : '';
});

  const [selectPMethod, setSelectPMethod] = useState<string>('');
  const [selectedFunding, setSelectedFunding] = useState<string>('');
  const [payAmount, setPayAmount] = useState<'payInFull' | 'payDue' | null>(null);
  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfo>(() => {
    const savedCreditCardInfo = sessionStorage.getItem('savedCreditCardInfo')
    return savedCreditCardInfo ? JSON.parse(savedCreditCardInfo) as CreditCardInfo : {
      nameOnCard: '',
      cardNumber: '',
      cardExpiryMonth: '',
      cardExpiryYear: '',
      cardCvv: '',
      billingAddress: '',
      billingCity: '',
      billingStateProvince: '',
      billingPostalCode: '',
      billingCountry: ''
    }
  });
  const [bankAccountInfo, setBankAccountInfo] = useState<BankAccountInfo>(() => {
    const savedBankAccountInfo = sessionStorage.getItem('savedBankAccountInfo')
    return savedBankAccountInfo ? JSON.parse(savedBankAccountInfo) as BankAccountInfo : {
      nameOnCard: '',
      routingNumber: '',
      accountNumber: '',
      bankType: 'checking'
  }});

  const { getAllPaymentMethods } = useAppRequests();
  const {
    data: paymentMethod,
    isLoading: isPaymentMethodLoading
  } = useQuery<any>("getAllPaymentMethods", getAllPaymentMethods);

  const [selectedMethod, setSelectedMethod] = useState('');
  const [pMethod, setPMethod] = useState<Payment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(isPaymentMethodLoading);

  const handlePMethodSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPMethod(e.target.value);
    setSelectedFunding(e.target.value);
  };

  useEffect(() => {
    const selectedPaymentAmount =
    payAmount === 'payInFull'
    ? formData.payment_full
    : payAmount === 'payDue'
    ? formData.payment_due
    : null;
    setFormData((prevData: any) => ({
      ...prevData,
      payAmount: selectedPaymentAmount,
      payment_info: {
        paymentType,
        isActive: false,
        ...(paymentType === 'creditCard' || paymentType === 'debitCard' ? { creditCardInfo } : { bankAccountInfo }),
      },
      payment_schedule: payAmount === 'payDue' ? ["03/10/2025","04/10/2025","05/10/2025"] : [],
      existingPaymentMethod: selectedFunding.includes('funding_') ? selectedFunding : '',
    }));
  }, [paymentType, creditCardInfo, bankAccountInfo, payAmount, setFormData, selectedMethod]);

  useEffect(() => {
    if (paymentMethod && paymentMethod?.status === 200) {
      setPMethod(paymentMethod.data?.payment_methods);
      setIsLoading(false);
    }
  }, [paymentMethod])


  const handlePaymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentType(e.target.id as PaymentType);
  };

  const handleInputChange = <
    T extends CreditCardInfo | BankAccountInfo
  >(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    setInfo: React.Dispatch<React.SetStateAction<T>>
  ) => {
    const { name, value } = e.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePayAmountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayAmount(e.target.value as 'payInFull' | 'payDue');
  };

  const renderTooltip = (text: string) => (
    <Tooltip id="tooltip">{text}</Tooltip>
  );

  const pMethodSaved = (!pMethod) ? null : pMethod?.filter((pm) => pm.isActive) 
  const pMethodSavedCredit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'bankAccount')
  const pMethodSavedDebit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'debitCard')

  let ccCount = 0
  let ACHCount = 0
  let dcCount = 0
  if (pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if (pMethodSavedACH) ACHCount = pMethodSavedACH.length
  if (pMethodSavedDebit) dcCount = pMethodSavedDebit.length

  return (
    <div className="mt-5 mx-3">
      <h2 style={{ position: 'fixed', background: 'white', padding: '1rem 0', top: 200, zIndex: 100 }} className=' w-100'>
        Please select a payment method: Credit Card (includes a 2.75% service fee), Debit Card or ACH.
      </h2>
      <ul style={{ paddingTop: 40 }} className="nav nav-tabs nav-line-tabs mb-5 fs-6 nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs">
        {methodType.map((method) => (
          <li className='nav-item col-lg-4 fs-5' key={method.type}>
            <a
              id={method.type}
              className={paymentType === method.type ? 'nav-link active' : 'nav-link'}
              data-bs-toggle='tab'
              href={`#pmt_${method.label}`}
              onClick={(e: any) => handlePaymentTypeChange(e)}
            >
              {method.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="col-md-6">
        {paymentType === "creditCard" && (<i className='text-muted'>A service fee of 2.75% will be applied to all credit card transactions.</i>)}
      </div>
      
      <PaymentMethodInputs
        paymentType={paymentType}
        selectPMethod={selectPMethod}
        pMethodSaved={pMethodSaved}
        pMethodSavedCredit={pMethodSavedCredit}
        pMethodSavedACH={pMethodSavedACH}
        pMethodSavedDebit={pMethodSavedDebit}
        ccCount={ccCount}
        ACHCount={ACHCount}
        dcCount={dcCount}
        handlePMethodSelect={handlePMethodSelect}
        handleInputChange={handleInputChange}
        creditCardInfo={creditCardInfo}
        bankAccountInfo={bankAccountInfo}
        setCreditCardInfo={setCreditCardInfo}
        setBankAccountInfo={setBankAccountInfo}
      />
      
      <div className="mb-3 col-md-6">
        <div className='d-flex gap-1'>
          <label className="form-label">Pay Amount</label>
          {payAmount === 'payDue' && (
            <div className='cursor-pointer'>
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip(`For families enrolled in the payment plan, 
                  remaining balance will be split into three payments: March 10, 
                  April 10, May 10. Applications submitted after the listed payment dates, 
                  the remaining balance will be split between the remaining date(s). 
                  Applications submitted after May 10, full balance is due within 48 hours of acceptance.`)}
              >
                <span className="tooltip-icon ms-2">
                  <IoIosInformationCircleOutline size={20} />
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>
          <select required className="form-select form-select-lg" value={payAmount || ''} onChange={handlePayAmountChange}>
            <option value="" disabled>Select Payment Option</option>
            <option value="payInFull">Pay in Full</option>
            <option value="payDue">Pay Deposit + Payment Plan</option>
          </select>
      </div>
      
    </div>
  );
};

export default PaymentOptions;
