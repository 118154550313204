import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import { useAuth } from '../modules/auth'
import DatePickerField from '../modules/Shared/components/DatePickerField'
import { GRADE_ENTERING, T_SHIRT_SIZE } from '../modules/application/core/_models'
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../utils'
import * as Yup from 'yup'
import { baseTextYup, phoneRegExp } from '../modules/Shared/core/_models'
import { axiosPrivate } from '../../axiosInstance'
import { UserInformation } from '../modules/accounts/components/settings/SettingsModel'
import { Alert, Button } from 'react-bootstrap'
import ChangeEmailModal from './ChangeEmail'
import { useState } from 'react'
import { ImProfile } from "react-icons/im";
import { ScrollTopComponent } from '../../_metronic/assets/ts/components'
import { useNavigate, useParams } from 'react-router-dom'
import { Form as BootstrapForm,  } from 'react-bootstrap';

const profileDetailsSchema = Yup.object().shape({
  first_name: baseTextYup.required('First name is required'),
  last_name: baseTextYup.required('Last name is required'),
  country: Yup.string().label('Country').required(),
  city: Yup.string().label('City').required(),
  state_province: Yup.string().label('State/Province').required(),
  postal_code: Yup.string().label('Postal Code').required(),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  birth_date: Yup.date().label('Birthday').required('Birthday is required'),
  address: Yup.string().label('Address').required(),
  profile_url: Yup.string().nullable(),
})

interface IMessage {
  error: string;
  success: string;
}
interface User {
  profile_url: string | null;
}

const UpdateProfilePage = () => {
  const [contact, setContact] = useState<User | null>(null);
  const {currentUser} = useAuth()
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('')
  const [message, setMessage] = useState<IMessage>({error: '', success: ''});
  const currentRoute = window.location.pathname.split('/')[2] || ''
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
    
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const {
    firstName = '',
    lastName = '',
    account_contacts: {
      phone = '',
      country = '',
      birth_date = '',
      address = '',
      state_province = '',
      postal_code = '',
      city = '',
    } = {}
  } = currentUser || {};

  const initialValue: UserInformation = {
    first_name: firstName,
    last_name: lastName,
    phone, //
    country, //
    birth_date, //
    address, // 
    state_province,
    postal_code,
    city,
  }

  const onSubmit = async (values: FormikValues) => {
    try {
      const response = await axiosPrivate.post('auth/update_contacts', values)
      if (response.status === 200) {
        setMessage({error: '', success: response?.data?.message})
        navigate(`/dashboard/${currentRoute}`);
        window.location.reload();
      }
    } catch (error: any) {
      setMessage({error: error?.response?.message, success: ''})
    }
    finally {
      ScrollTopComponent.goTop()
      setTimeout(() => {
        setMessage({error: '', success: ''})
      }, 5000)
    }
  }

  const ChangeEmailHandler = async (email: string) => {
    try {
      const res = await axiosPrivate.post('/auth/email-change-request', {email})
      if (res.data.statusCode === 1) {
        setSuccessMessage('Your email has been successfully changed.');
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    setSubmitting(true);
    try {
      const res = await axiosPrivate.post(`/contact/${currentUser?.uuid}/upload-profile`, formData);
      if (res.status === 200) {
        setMessage({ success: 'Photo uploaded successfully!', error: '' });
      }
    } catch (error: any) {
      setMessage({ success: '', error: error.response.data.message || 'There is something wrong with the server' });
    } finally {
      setSubmitting(false); 
      setTimeout(() => {
        setMessage({ error: '', success: '' });
      }, 5000);
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={profileDetailsSchema}
      onSubmit={onSubmit}
    >
      {({values, isSubmitting, errors}) => {
        return (
          <Form>
            <div>
              <h1>Profile Details</h1>
              <div>
                <div className='card-body border-top p-9'>
                  <div className='row mb-10'>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                      <ImProfile style={{ color: '#009ef7' }} size={100} /> <h2 style={{ color: '#009ef7' }} className='fw-bolder fs-2'>Personal Information</h2>
                        {contact?.profile_url && (
                          <img 
                            style={{
                              width: '200px',
                              height: '200px',
                              display: 'block',
                            }} 
                            className='rounded-circle mb-2 mx-auto'
                            src={`${contact?.profile_url}`} 
                            alt='Profile' 
                          />
                        )}
                      </div>
                      {message ?  
                        <div className='mt-2'>
                          {message.success && <Alert variant='success'>{message.success}</Alert>}
                          {message.error && <Alert variant='danger'>{message.error}</Alert>}
                        </div>
                      : ''} 
                      <div className="mb-4">
                        <BootstrapForm.Group controlId="formFile">
                          <BootstrapForm.Label>Upload Profile Photo</BootstrapForm.Label>
                          <BootstrapForm.Control type="file" onChange={handleFileChange} />
                        </BootstrapForm.Group>
                        <Button className="mt-2" onClick={handleFileUpload} disabled={submitting}>
                          {submitting ? 'Uploading...' : 'Upload'}
                        </Button>
                      </div>
                    <div className='f-row row mb-7'>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6 required'>
                          First Name
                        </div>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='first_name'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='first_name' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6 required'>
                          Last Name
                        </div>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='last_name'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='last_name' />
                        </div>
                      </div>
                    </div>
                    <div className='fv-row row mb-7'>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6'>Email</div>
                        <Field 
                          type='email'
                          name='email'
                          value={currentUser?.email}
                          className='form-control form-control-lg form-control-solid'
                          disabled
                        />
                       <Button onClick={handleShowModal} className='mt-3'>
                        Change Email Request
                      </Button>

                      <ChangeEmailModal
                        show={showModal}
                        handleClose={handleCloseModal}
                        handleSubmit={ChangeEmailHandler}
                      />

                      </div>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6 required'>
                          Phone Number
                        </div>
                        <Field
                          type='string'
                          className='form-control form-control-lg form-control-solid'
                          name='phone'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='phone' />
                        </div>
                      </div>
                    </div>
                    <div className='f-row row mb-7'>
                        <section>
                          <div className='fv-row row mb-7'>
                            {/* Date of Birth */}
                            <div className='col-lg-6'>
                              <div className='form-label fw-bolder text-dark fs-6 required'>
                                Date of Birth (MM/DD/YYYY)
                              </div>
                              <DatePickerField
                                className='form-control form-control-lg form-control-solid'
                                name='birth_date'
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage name='birth_date' />
                              </div>
                            </div>
                          </div>
                        </section>
                    </div>
                    <h2 className='text-center'>Address Information</h2>
                    <div className='f-row row mb-7'>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6'>Address</div>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='address'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='address' />
                        </div>
                      </div>
                    </div>
                    <div className='f-row row mb-7'>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6 required'>City</div>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='city'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='city' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6 required'>Postal Code</div>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='postal_code'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='postal_code' />
                        </div>
                      </div>
                    </div>
                    <div className='f-row row mb-7'>
                      <div className='col-lg-6'>
                        <div className='form-label fw-bolder text-dark fs-6 required'>
                          Country
                        </div>
                        <Field
                          as='select'
                          name='country'
                          className='form-select form-select-lg form-select-solid'
                        >
                          <option>Select Country</option>
                          {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                            return (
                              <option value={c.alpha_3} key={c.alpha_3+'_'+idx}>
                                {c.name}
                              </option>
                            )
                          })}
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='country' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>State/Province</span>
                        </div>
                        <Field
                          as='select'
                          name='state_province'
                          className='form-select form-select-lg form-select-solid'
                        >
                          <option>Select State/Province</option>
                          {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                            if (c.alpha_3 == values.country) {
                              let provinces = c.divisions?.map((d, id) => {
                                return (
                                  <option value={d.code} key={d.code+'_'+id}>
                                    {d.name}
                                  </option>
                                )
                              })
                              return provinces
                            }
                          })}
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='state_province' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button 
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateProfilePage