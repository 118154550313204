import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import './SelectStudentProfile.scss';
import { useNavigate } from 'react-router-dom';

interface StudentTypeProps {
  formData: any;
  setFormData: (data: any) => void;
}

const SelectParentProfile: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
  const [linkContacts, setLinkContacts] = useState([]);
  const [selectedParent, setSelectedParent] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const fetchLinkContacts = async () => {
    try {
      const response = await axiosPrivate.get(`/contact/linked-accounts/guardian`);
      setLinkContacts(response.data.data);
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error fetching contacts');
    }
  };

  useEffect(() => {
    fetchLinkContacts();
    
    const storedParent = sessionStorage.getItem('selectedParent');
    if (storedParent) {
      setSelectedParent(storedParent);
      setFormData((prevData: any) => ({ ...prevData, account_holder_uuid: storedParent }));
    }
  }, []);

  const selectContactHandler = (contact_id: string) => {
    const newSelectedParent = selectedParent === contact_id ? null : contact_id;
    setSelectedParent(newSelectedParent);
    setFormData({ ...formData, account_holder_uuid: newSelectedParent });
    
    if (newSelectedParent) {
      sessionStorage.setItem('selectedParent', newSelectedParent);
    } else {
      sessionStorage.removeItem('selectedParent');
    }
  };

  return (
    <div className='my-5 mx-1'>
      {linkContacts.length === 0 ? (
        <div className="contact-list">
        <div className='add-contact-btn' onClick={() => navigate('/add-contact/47821104', { state: { page: 'enrollment' }})}>
          <div style={{ height: '220px'}} className='inner-contact-btn'>
            Add Contact
          </div>
        </div>
      </div>
      ) : (
        <div className="contact-list">
          {linkContacts.map((contact: any) => {
            const account_holder_uuid = contact.type === 'account_holder' ? contact.account_holder : contact.contact_id;
            const isActiveParent = selectedParent === account_holder_uuid;
            return (
              <div
                key={contact.id}
                className={`student-selection mt-3 ${isActiveParent ? 'selected' : ''}`}
                onClick={() => selectContactHandler(account_holder_uuid)}
              >
                {contact.student_contacts.profile_url ? (
                  <img
                    src={`${contact.student_contacts.profile_url}`}
                    alt='profile'
                    className='rounded-circle'
                  />
                ) : (
                  <div className='no-pic' style={{ height: '100px', width: '100px' }}>
                    Profile
                  </div>
                )}
                <div className='contact-information'>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Name:</div>
                    <div className='card-text'>{contact.student_contacts.first_name}</div>
                  </div>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Email:</div>
                    <div className='card-text'>{contact.student_contacts.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className='fw-bold text-capitalize card-text'>{contact.relationship}</div>
                  </div>
                  <div className="d-flex">
                    <div className="fw-bold me-2">Phone:</div>
                    <div className='text-capitalize card-text'>{contact.student_contacts.phone}</div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className='add-contact-btn' onClick={() => navigate('/add-contact/47821104', { state: { page: 'enrollment' }})}>
            <div className='inner-contact-btn'>
              Add Contact
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectParentProfile;
