import React, { useEffect, useState } from 'react';
import { axiosInstance, axiosPrivate } from '../../axiosInstance';
import { Widget } from '@typeform/embed-react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const TypeformButton: React.FC<any> = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const {student_uuid, program_uuid, form_id, remaining_forms, auth, rawTypeformData = [], formsPage = false}: any  = location.state || '' ;
  const [isError, setIsError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<string | null>(null);
  const [campusName, setCampusName] = useState<string | null>(null);
  const [get3Forms, set3Forms] = useState<boolean>(false);
  
  useEffect(() => {
    const sendAllFormsSubmitted = async () => {
      try {
        console.log("Sending email for all submitted forms...");
        const data = { student_uuid, program_uuid }
        const response = await axiosPrivate.post('/api/typeform-allFormsSubmitted', data);
        if (response.status === 200) {
          console.log("Email sent successfully.");
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
    };

    if(get3Forms){
      sendAllFormsSubmitted();
      set3Forms(false)
    }
  })

  useEffect(() => {
      const campus = localStorage.getItem('selectedCampus')
      setCampusName(campus)
  }, [])

  const handleFormSubmit = async () => {
    try {
      const response = await axiosInstance.get('/api/typeform-responses', {
        params: { student_uuid, program_uuid, form_id: form_id },
      });
  
      if (response.data.message !== 'Response saved to database') {
        setIsError('Form not yet completed.')
        setTimeout(handleFormSubmit, 20000)
        return; 
      }

      setIsError(null);

      if (remaining_forms.length > 0) {
        setShowModal(true)
        return
      }
      set3Forms(true)
      if (formsPage) {
        localStorage.setItem('currentStep', '8')
        navigate(`/dashboard/${auth}/program/${campusName}`);
      } else {
        setTimeout(() => navigate(`/dashboard/${auth}`), 2000)
      }
    } catch (error) {
      setIsError('An error occurred while submitting the form: ' + error);
    }
  };

  const getFormName = (formId: string) => {
    if (!rawTypeformData) return formId;
    const formData = rawTypeformData.find((form: any) => form.form_id === formId);
    return formData ? formData.name  : formId;
  };

  const handleFormSelect = (form: string) => {
    setSelectedForm(form);
  };

  const handleContinue = () => {
    if (selectedForm) {
      const updatedRemainingForms = remaining_forms.filter((form: string) => form !== selectedForm);

      navigate(`/dashboard/${auth}/forms/${selectedForm}`, {
        replace: true,
        state: {
          student_uuid,
          program_uuid,
          form_id: selectedForm,
          remaining_forms: updatedRemainingForms,
          auth,
          rawTypeformData,
          formsPage: formsPage ? formsPage : null
        },
      });
      setShowModal(false);
      setSelectedForm(null);
    }
  };

  const handleNo = () => {
    if (formsPage) {
      navigate(`/dashboard/${auth}/program/${campusName}`)
    } else {
      navigate(`/dashboard/${auth}`, { replace: true });
    }
  };

  return (
    <>
      <div>
        {isError && <p style={{ color: 'red' }}>{isError}</p>}  
        {!showModal && (
          <Widget
            height="700"
            id={form_id}
            className="my-form"
            hidden={{ uuid: student_uuid, auth: auth }}
            onSubmit={handleFormSubmit}
          />
        )}
      </div>

      <Modal show={showModal} onHide={handleNo}>
        <Modal.Header closeButton>
          <Modal.Title>Continue to Next Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select the next form to proceed:</h5>
          {remaining_forms.map((form: string) => (
            <Button
              key={form}
              onClick={() => handleFormSelect(form)}
              variant="outline-primary"
              className="m-1"
            >
              {getFormName(form)}
            </Button>
          ))}
        </Modal.Body>
        <Modal.Footer>
          {formsPage ? (
            <Button variant="secondary" onClick={handleNo}>
              Go Back
            </Button>
          ):(
            <Button variant="secondary" onClick={handleNo}>
              Return to dashboard
            </Button>
          )}
          
          <Button variant="primary" onClick={handleContinue} disabled={!selectedForm}>
            Continue the application
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TypeformButton;