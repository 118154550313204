/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import useIsAdminOrSuperAdmin from '../../../../app/modules/auth/hooks/useRole'
import {useAuth} from '../../../../app/modules/auth'
import {ROLES} from '../../../../app/routing/core/_models'
import useAppRequests from '../../../../app/modules/application/Hooks/useAppRequests'
import {useQuery} from 'react-query'
import _queryKeys from '../../../../app/modules/application/core/_queryKeys'
import {IFlight} from '../../../../app/modules/application/core/_models'
import { useNavigate } from 'react-router-dom'

export function AsideMenuMainTerra() {
  const {currentUser} = useAuth()
  const {isAdminOrSuperAdmin} = useIsAdminOrSuperAdmin()
  const intl = useIntl()
  const {getListOfFlightsByUser, getCurrentClientDetails} = useAppRequests()
  const {
    isLoading,
    isError,
    data: flights,
  } = useQuery(_queryKeys.getListOfFlightsByUser, getListOfFlightsByUser)
  const {data: client} = useQuery(_queryKeys.getCurrentClientDetails, getCurrentClientDetails)
  const currentRoute = window.location.pathname.split('/')[2] || ''

  const [buttonText, setButtonText] = useState('Create New Application');

  return (
    <>
      <AsideMenuItem
        to={`/dashboard/${currentRoute}`}
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
        {currentUser?.role === ROLES.parent && (
          <>
            <AsideMenuItem  
              to={`/application/${currentRoute}/add-program`}
              title={`${buttonText}`}
            />
            <AsideMenuItem  
              to={`/profile/${currentRoute}`}
              title={'Account Details'}
            />
            <AsideMenuItem
              to={`/my-contacts/${currentRoute}`}
              title={'Account Members'}
            />
            <AsideMenuItem
              to={`/add-contact/${currentRoute}`}
              title={'Add Member'}
            />
            <AsideMenuItem
              to={`/billing-and-invoice/${currentRoute}`}
              title={'Billing Overview'}
            />
              <AsideMenuItem
              to={`/portal/${currentUser?.currentClient}/payment_management`}
              title='Payment Management'
            />
          </>
        )}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {isAdminOrSuperAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Features</span>
            </div>
          </div>
          {/* <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/opportunities-management/opportunities`}
            icon='/media/icons/duotune/art/art002.svg'
            title={'Opportunities'}
            fontIcon='bi-app-indicator'
          /> */}
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/flight-management/flights`}
            icon='/media/icons/duotune/abstract/abs042.svg'
            title={'Flights'}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/enrollment-group-management/enrollment-group`}
            icon='/media/icons/duotune/general/gen025.svg'
            title='Enrollment Group'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/enrollment-form-management/enrollment-form`}
            icon='/media/icons/duotune/general/gen025.svg'
            title='Enrollment Form'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/email-template-management/email-template`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Email Templates'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/typeform-management/typeform`}
            icon='/media/icons/duotune/general/gen025.svg'
            title='Type Form'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/program-management/program`}
            icon='/media/icons/duotune/general/gen025.svg'
            title='Program Forms'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/discount-management/list`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Discount'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/coupon-code-management/coupon-code-list`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Coupon Code'
            fontIcon='bi-layers'
          />
        </>
      )}

      {isAdminOrSuperAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Payment Tools</span>
            </div>
          </div>
          <AsideMenuItem
            to={`/payment-tools/${currentUser?.currentClient}/pending-actions`}
            icon='/media/icons/duotune/finance/fin001.svg'
            title={'Pending Actions'}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to={`/payment-tools/${currentUser?.currentClient}/auto-pay-action`}
            icon='/media/icons/duotune/finance/fin001.svg'
            title={'Auto Payment List'}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/payment-schedules-management/payment-schedules`}
            icon='/media/icons/duotune/general/gen014.svg'
            title={'Payment Schedules'}
            fontIcon='bi-app-indicator'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Hello Sign</span>
            </div>
          </div>
          <AsideMenuItem
            to={`/hello-sign/${currentUser?.currentClient}/document-list`}
            icon='/media/icons/duotune/ecommerce/ecm009.svg'
            title={'Document List'}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to={`/hello-sign/${currentUser?.currentClient}/program-document`}
            icon='/media/icons/duotune/art/art002.svg'
            title={'Program Documents'}
            fontIcon='bi-app-indicator'
          />
        </>
      )}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {client?.abbreviation} Accounts{' '}
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to={`/accounts/${currentUser?.currentClient}`}
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to={`/accounts/${currentUser?.currentClient}/overview`}
          title='Overview'
          hasBullet={true}
        />
        <AsideMenuItem
          to={`/accounts/profile`}
          title='Profile Settings'
          hasBullet={true}
        />
        {isAdminOrSuperAdmin &&(
          <AsideMenuItem
            to={`/accounts/${currentUser?.currentClient}/audit-logs`}
            title='Audit Logs'
            hasBullet={true}
          />
        )}
        {currentUser?.role === ROLES.parent && (
          <>
            <AsideMenuItem
              to={`/portal/${currentUser?.currentClient}/payment_management`}
              title='Payment Management'
              hasBullet={true}
            />
          </>
        )}
      </AsideMenuItemWithSub> */}

      {isAdminOrSuperAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Reports
              </span>
            </div>
          </div>
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/reports/list`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Progress Reports'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/user-progress-management/user-progress`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Abondon Reports'
            fontIcon='bi-layers'
          />
        </>
      )}

      {isAdminOrSuperAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Account</span>
            </div>
          </div>
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/user-management/users`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='User management'
            fontIcon='bi-layers'
          />
          {/* TODO:: New Waitlist Management */}
          <AsideMenuItem
            to={`/apps/${currentUser?.currentClient}/waitist-management/waitlist-user`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Waitlist Management'
            fontIcon='bi-layers'
          />
        </>
      )}
      {/* {currentUser?.role === ROLES.parent && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Account</span>
            </div>
          </div>
          <AsideMenuItem
            to={`/portal/${currentUser?.currentClient}/payment_management`}
            icon='/media/icons/duotune/general/gen051.svg'
            title='Payment Management'
            fontIcon='bi-layers'
          />
        </>
      )} */}
      {/* {currentUser?.role === ROLES.parent && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Students</span>
            </div>
          </div>
          <AsideMenuItem
            to={`/portal/${currentUser?.currentClient}/student/list`}
            icon='/media/icons/duotune/communication/com006.svg'
            title='Students'
            fontIcon='bi-layers'
          />
        </>
      )} */}
      {/* {(currentUser?.role === ROLES.parent || currentUser?.role === ROLES.student) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Flight Rosters
              </span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/flights'
            title='Flight Rosters'
            icon='/media/icons/duotune/communication/com006.svg'
            fontIcon='bi-person'
          >
            $
            {flights?.map((fl: IFlight, idx) => {
              return (
                <AsideMenuItem
                  key={`${idx}-${fl.program_summary}`}
                  to={`/portal/${currentUser?.currentClient}/flight-plan/${fl.id}`}
                  icon='/media/icons/duotune/general/gen051.svg'
                  title={`${fl.program_summary}`}
                  fontIcon='bi-layers'
                />
              )
            })}
          </AsideMenuItemWithSub>
        </>
      )} */}
    </>
  )
}
