// MyContactList.jsx
import { useEffect, useState } from "react"
import { axiosPrivate } from "../../axiosInstance"
import ContactCard from "./ContactCard"
import './MyContactList.scss'

const MyContactList = () => {
  const [linkContacts, setLinkContacts] = useState([])
  const [message, setMessage] = useState('')

  const fetchLinkContacts = async () => {
    try {
      const response = await axiosPrivate.get('/contact/linked-accounts')
      setLinkContacts(response.data.data)
      setMessage(response.data.message)
    } catch (error) {
      setMessage('Error fetching contacts')
    }
  }

  useEffect(() => {
    fetchLinkContacts()
  }, [])

  return (
    <div>
      <h1>My Contact List</h1>
      {linkContacts.length === 0 ? (
        <div className="w-100 h-100">
          <p>No users found</p>
        </div>
      ) : (
        <div className="my-contact-list">
          {linkContacts.map((contact: any) => (
            <ContactCard key={contact.id} contact={contact} />
          ))}
        </div>
      )}
    </div>
  )
}

export default MyContactList
