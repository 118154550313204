import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import { axiosPrivate } from '../../axiosInstance';
import PaymentProtectionPlan from '../modules/application/PaymentProtectionPlan';
import { downloadFile } from '../../network/api';

interface PaymentSummaryProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

interface IScholarship {
  description: string
  due: number
  price: number
  transaction_type: string
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ formData, setFormData }) => {
  const { selectedProgram, selectedSession, student_Type } = formData;
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isTPPChecked, setIsTPPChecked] = useState<boolean>(false);
  const [tuitionPlan, setTuitionPlan] = useState<any>([]);
  const [programSummary, setProgramSummary] = useState<any>('');
  
  // Scholarship State
  const [scholarshipCodes, setScholarshipCodes] = useState<IScholarship[]>([]);
  const [scholarshipCode, setScholarshipCode] = useState<string>('');
  const [scholarshipCodeMsg, setScholarshipCodeMsg] = useState<{ success: string, error: string }>({ success: '', error: '' });

  const ProgramDue = 600; 
  const getProgramPrice = () => {
    if (!selectedProgram || !student_Type) return 0;
    const pricing = selectedProgram.pricing.find(
      (price: any) => price.tuitionName.toLowerCase() === student_Type.toLowerCase()
    );

    return pricing ? pricing.price : 0;
  };

  useEffect(() => {
    const savedScholarships = localStorage.getItem('scholarshipCodes');
    if (savedScholarships) {
      setScholarshipCodes(JSON.parse(savedScholarships));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('scholarshipCodes', JSON.stringify(scholarshipCodes));
  }, [scholarshipCodes]);

  const getEnrollmentExtras = () => {
    const extras = sessionStorage.getItem('selectedExtras');
    return extras ? JSON.parse(extras) : [];
  };

  const getEnrollmentExtrasTotal = () => {
    const enrollment_extras = getEnrollmentExtras();
    if (!enrollment_extras) return 0;
    return enrollment_extras.reduce((total: number, extra: any) => total + extra.pricing, 0);
  };

  const getNonRefundableFee = () => {
    const enrollment_extras = getEnrollmentExtras();
    if (!enrollment_extras) return 0;
    const fee = enrollment_extras.find((extra: any) => extra.isRequired === true);
    return fee ? fee.pricing : 0;
  };

  const getSupplementPrice = () => {
    if (!selectedProgram?.programData?.supplement) return 0;
    return selectedProgram?.programData?.supplement?.price || 0;
  };

  const calculateTotalAmount = () => {
    const programPrice = getProgramPrice();
    const enrollmentExtrasTotal = getEnrollmentExtrasTotal();
    const supplementPrice = getSupplementPrice();
    const TPPPrice = tuitionPlan && isTPPChecked ? tuitionPlan?.pricing : 0;
    const totalScholarshipDiscount = scholarshipCodes.reduce((acc, scholarship) => acc + scholarship.price, 0);

    return Number(programPrice) + Number(enrollmentExtrasTotal) + Number(supplementPrice) + Number(TPPPrice) - Number(totalScholarshipDiscount);
  }

  const calculateRemainingBalance = () => {
    const totalAmount = calculateTotalAmount();
    const totalDueNow = ProgramDue + getNonRefundableFee() + (tuitionPlan && isTPPChecked ? tuitionPlan?.pricing : 0);
    return totalAmount - totalDueNow;
  };

  useEffect(() => {
    const tncFromSession = sessionStorage.getItem('tnc');
    setIsChecked(tncFromSession === 'true'); // Convert from string to boolean if needed

    const tuitionPlanFromSession = sessionStorage.getItem('tuition_plan');
    setIsTPPChecked(tuitionPlanFromSession === 'true'); // Convert from string to boolean if needed

  }, []);

    // Checkbox change handlers
    const handleCheckboxChange = () => {
      const newCheckedState = !isChecked;
      setIsChecked(newCheckedState);
      sessionStorage.setItem('tnc', newCheckedState.toString()); // Store in sessionStorage
  
      // Update formData
      setFormData((prevData: any) => ({
        ...prevData,
        tnc: newCheckedState,
      }));
    };
  
    const handleTPPCheckboxChange = () => {
      const newTPPCheckedState = !isTPPChecked;
      setIsTPPChecked(newTPPCheckedState);
      sessionStorage.setItem('tuition_plan', newTPPCheckedState.toString()); // Store in sessionStorage
  
      // Update formData
      setFormData((prevData: any) => ({
        ...prevData,
        tuition_plan: newTPPCheckedState,
      }));
    };

  useEffect(() => {
    const fetchTuitionPlan = async () => {
      try {
        const response = await axiosPrivate.get('/terra-programs/fetch-tuition-plan')
        setTuitionPlan(response.data)
      } catch (error) {
        console.log(error);
      }
    }

    fetchTuitionPlan()
  }, [])

  useEffect(() => {
    const ReviewLedgerFetch = async () => {
      const response = await axiosPrivate.post('/review-ledger', formData);
      setProgramSummary(response.data.data);
    };

    ReviewLedgerFetch();
  }, [formData]);

  const downloadPdfHandler = async () => {
    downloadFile()
  };

  const checkCodeHandler = async () => {
  try {
      const response = await axiosPrivate.post('/user/verify-code', { discountCode: scholarshipCode });
      if (response.status === 200) {
          const newScholarship = response.data;

          // Check if the code is already applied
          if (scholarshipCodes.some(scholarship => scholarship.description === newScholarship.description)) {
              setScholarshipCodeMsg({ success: '', error: 'Code already applied' });
          } else {
              // Update the scholarship codes state
              setScholarshipCodes(prevCodes => [...prevCodes, newScholarship]);
              setScholarshipCodeMsg({ success: 'Scholarship code applied successfully!', error: '' });

              // Update formData with the new discount
              setFormData((prevData: any) => ({
                  ...prevData,
                  discounts: [
                      ...(prevData.discounts || []),  // Keep existing discounts
                      {
                          description: newScholarship.description,
                          transaction_type: newScholarship.transaction_type,
                          price: newScholarship.price, 
                          due: newScholarship.due,
                      },
                  ],
              }));
          }

          setScholarshipCode(''); // Reset the input field

          setTimeout(() => {
              setScholarshipCodeMsg({ success: '', error: '' });
          }, 3000);
      }
  } catch (error) {
      setScholarshipCodeMsg({ success: '', error: 'Invalid Scholarship Code' });

      setTimeout(() => {
          setScholarshipCodeMsg({ success: '', error: '' });
      }, 3000);
  }
  };

  useEffect(() => {
    const totalDueNow = ProgramDue + getNonRefundableFee() + (isTPPChecked ? tuitionPlan?.pricing : 0);

    setFormData((prevData: any) => ({
        ...prevData,
        total: calculateTotalAmount(),
        payment_due: totalDueNow,
        payment_full: calculateTotalAmount(),
    }));
    
}, [isTPPChecked, isChecked]);

  return (
    <>
      <PaymentProtectionPlan handleTPPCheckboxChange={handleTPPCheckboxChange} isChecked={isTPPChecked} />
      <div className='card mx-3' style={{ fontSize: 18 }}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 rounded-start'>
                    <h1>Program</h1>
                  </th>
                  <th className='rounded-end text-end px-2'>
                    <h3>Total <br /> Amount</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='ps-4'>
                    <span>{programSummary?.program_summary}</span>
                  </td>
                  <td className='text-end px-2'>${Number(getProgramPrice()).toLocaleString()}</td>                
                </tr>
                <tr>
                  <td className='fw-bold ps-4'>Supplements & Extras</td>
                </tr>
                {selectedProgram?.programData?.supplement && Object.keys(selectedProgram?.programData?.supplement).length > 0 && (
                  <tr>
                    <td className='ps-20'>{selectedProgram?.programData?.supplement?.name}</td>
                    <td className='text-end px-2'>${selectedProgram?.programData?.supplement?.price?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  </tr>
                )}
                {getEnrollmentExtras().map((extra: any) => (
                  <tr key={extra.id}>
                    <td className='ps-20'>{extra.supplement_name}</td>
                    <td className='text-end px-2'>
                      ${extra.pricing.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </td>
                  </tr>
                ))}
                {isTPPChecked && (
                  <tr>
                    <td className='ps-20'>Tuition Protection Plan</td>
                    <td className='text-end px-2'>
                      ${tuitionPlan?.pricing?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0}
                    </td>
                  </tr>
                )}
                {scholarshipCodes.length > 0 && (
                  <>
                    <tr>
                      <td className='fw-bold ps-4'>Discounts & Scholarships</td>
                    </tr>
                    {scholarshipCodes.map((scholarship, index) => (
                      <tr key={index}>
                        <td className='ps-20'>{scholarship?.description}</td>
                        <td className='text-end px-2'>- ${scholarship?.price.toFixed(2)}</td>
                      </tr>
                    ))}
                  </>
                )}
                <tr>
                  <td className='ps-4 fw-bold'>Total</td>
                  <td className='text-end fw-bold px-2'>
                    ${calculateTotalAmount().toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
                  <tr>
                    <td className='ps-4'>Deposit Due Now (Portion of Tuition)</td>
                    <td className='text-end px-2'>
                      ${ProgramDue.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </td>
                  </tr>
                  {isTPPChecked && (
                  <tr>
                    <td className='ps-4'>Tuition Protection Plan</td>
                    <td className='text-end px-2'>
                      ${tuitionPlan?.pricing?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className='ps-4'>App Fee Now</td>
                  <td className='text-end px-2'>
                    ${getNonRefundableFee().toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
                <tr>
                  <td className='ps-4 fw-bold'>Total Due Now</td>
                  <td className='text-end fw-bold px-2'>
                    ${(getNonRefundableFee() + ProgramDue + (tuitionPlan && isTPPChecked ? tuitionPlan?.pricing : 0)).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
                <tr>
                  <td className='ps-4'>Remaining Balance due later</td>
                  <td className='text-end fw-bold px-2'>
                    ${calculateRemainingBalance().toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='m-3'>
        {scholarshipCodeMsg.success && <Alert variant='success'>{scholarshipCodeMsg.success}</Alert>}
        {scholarshipCodeMsg.error && <Alert variant='danger'>{scholarshipCodeMsg.error}</Alert>}
      </div>
      <div style={{ flexDirection: 'column' }} className='m-3 d-flex fs-3 gap-3'>
        <div>
          If you were given a scholarship code, please enter it below:
        </div>
        <div style={{fontSize: '15px', fontWeight: 'bold'}}>
          All codes are case-sensitive.
        </div>
        <div className='d-flex fs-3 gap-3 col-md-3'>
          <input
            type='text'
            className='form-control form-control-lg'
            placeholder='Scholarship Code'
            name='discountCode'
            maxLength={30}
            value={scholarshipCode}
            onChange={(e) => setScholarshipCode(e.target.value)}
          />
          <Button style={{ flex: 1 }} className='btn btn-hover' onClick={checkCodeHandler}>
            Submit Code
          </Button>
        </div>
      </div>
      <div className='d-flex align-items-center gap-2 form-check mx-3'>
        <input
          style={{
            width: 23,
            height: 23,
            cursor: 'pointer'
          }}
          type='checkbox'
          className='form-check-input'
          id='termsCheckbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className='d-flex align-items-center gap-2 form-check-label fs-5 mb-0' htmlFor='termsCheckbox'>
          I acknowledge that I have read and agree to the{' '}
          <Button variant='link' onClick={downloadPdfHandler} className='p-0 fs-5'>
            Terms and Conditions
          </Button>
          <span className='text-danger fs-3'>*</span>
        </label>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Terms and Conditions content goes here.</p>
          <p>Ensure that all relevant information is included.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentSummary;
