import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import { Form } from 'react-bootstrap';

interface Extra {
  id: number;
  supplement_name: string;
  category: string;
  pricing: number;
  isRequired?: boolean;
  tuition_type: any[];
  created_at: string;
  updated_at: string;
}

interface FormData {
  student_Type: string;
  enrollment_extras: Extra[];
}

interface SelectExtrasProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const SelectExtras: React.FC<SelectExtrasProps> = ({ formData, setFormData }) => {
  const [extras, setExtras] = useState<Extra[]>([]);
  const [selectedExtras, setSelectedExtras] = useState<Extra[]>(() => {
    const savedExtras = sessionStorage.getItem('selectedExtras');
    return savedExtras ? JSON.parse(savedExtras) : formData?.enrollment_extras || [];
  });

  // Sync selectedExtras with formData.enrollment_extras on component load
  useEffect(() => {
    if (formData?.enrollment_extras?.length > 0) {
      setSelectedExtras(formData.enrollment_extras);
    }
  }, [formData.enrollment_extras]);

  // Fetch extras from the server
  useEffect(() => {
    const fetchExtras = async () => {
      try {
        const response = await axiosPrivate.get(`/terra-programs/supplements/${formData.student_Type}`);
        const allExtras = response.data.extras;

        // Automatically add isRequired: true items to selectedExtras
        const requiredExtras = allExtras.filter((extra: Extra) => extra.isRequired);
        const filteredExtras = allExtras.filter((extra: Extra) => !extra.isRequired);

        // Only add required items that are not already in selectedExtras
        setSelectedExtras(prev => {
          const requiredNotInSelected = requiredExtras.filter(
            (requiredExtra: any) => !prev.some(e => e.id === requiredExtra.id)
          );
          const newSelectedExtras = [...prev, ...requiredNotInSelected];

          // Update formData when new required extras are added
          setFormData(prevFormData => ({
            ...prevFormData,
            enrollment_extras: newSelectedExtras
          }));

          return newSelectedExtras;
        });

        setExtras(filteredExtras);
      } catch (error) {
        console.error('Error fetching extras:', error);
      }
    };

    fetchExtras();
  }, [formData.student_Type, setFormData]);

  // Sync selectedExtras with sessionStorage and formData whenever it changes
  useEffect(() => {
    sessionStorage.setItem('selectedExtras', JSON.stringify(selectedExtras));

    // Update formData.enrollment_extras when selectedExtras changes
    setFormData(prevFormData => ({
      ...prevFormData,
      enrollment_extras: selectedExtras
    }));
  }, [selectedExtras, setFormData]);

  // Handle changes to checkbox (add/remove extra)
  const handleCheckboxChange = (extra: Extra) => {
    const isChecked = selectedExtras.some(e => e.id === extra.id);
    const updatedExtras = isChecked ? selectedExtras.filter(e => e.id !== extra.id) : [...selectedExtras, extra];

    // Update selectedExtras and formData together
    setSelectedExtras(updatedExtras);
  };

  return (
    <div className='mx-3 my-5' style={{ fontSize: 18 }}>
      <h3>Select Extras</h3>
      {extras.map((extra) => {
        if (extra.supplement_name === 'Tuition Protection Plan') return null
        return (
          <Form.Check
            key={extra.id}
            type="checkbox"
            id={`extra-${extra.id}`}
            label={`${extra.supplement_name} - $${extra.pricing}`}
            checked={selectedExtras.some(e => e.id === extra.id)}
            onChange={() => handleCheckboxChange(extra)}
          />
        )
      })}
    </div>
  );
};

export default SelectExtras;
